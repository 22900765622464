import React, {NgStateService} from 'common/react-vendor';
import {
	Modes,
	defaultMode,
	getQueryProperty,
	isRulesBasedModelMode,
	isSegmentationMode,
	setModeFromState,
	setPublicProperty,
} from 'common/stores/query';
import {useSelector} from 'react-redux';
import {
	FeatureFlags,
	isBuyerJourneyEnabled,
	isEnableQueryBuilderRedesign,
	isFeatureFlagEnabled,
	isHorizontalNavigationEnabled,
	isSegmentationV2Enabled,
	isTimeSeriesSegmentEnabled,
} from 'common/stores/tenantConfig';
import {CurrentRating} from 'atlas/stores/ratingsEngine';
import {ICategoryDict} from 'atlas/data/CategoryConst';
import {IEntityMapping} from 'atlas/data/EntityConst';
import {ISegment} from 'atlas/data/SegmentConst';
import {IRootRestriction} from 'atlas/data/RestrictionConst';
import {
	getCube,
	getDataCloudProperty,
	getEnrichmentsMap,
	getEntityList,
	getRatingsEngineAttributes,
} from 'common/stores/datacloud';
import {EnrichmentsMap} from 'common/stores/datacloud/types';
import {
	MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SUCCESS_CRITERIA,
} from 'atlas/navigation/header/components/page-navigation.utils';
import {
	BucketToRuleMap,
	EntityMappingResponse,
	RatingModel,
	RatingRule,
	StatsTopNResponse,
} from 'common/components/datacloud/datacloud.types';
import {
	RatingCounts,
	RatingEngine,
	RatingsBuckets,
	SegmentIdModelRulesCoverageMap,
} from 'atlas/ratingsengine/ratingsengine.types';
import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {getSubSegmentStatus} from 'atlas/segmentation/SegmentationUtility';
import {QueryPublic, QueryState} from 'common/stores/query/types';
import {useApi} from 'common/app/hooks/useApi';
import {
	checkRatingsBuckets,
	generateRatingsBuckets,
	getRating,
} from 'atlas/ratingsengine/ratingsengine.helpers';
import {getCategories} from 'common/components/datacloud/datacloud.helpers';
import {
	CategoryMetadata,
	loadCategoryMetadata,
} from '../../../datacloud.service.vanilla';
import {IQueryBuilder} from '../QueryBuilderType';
import {Restriction} from '../../query.types';
import {SegmentMember} from '../tree/tree.constants';
import {RuleCountBkt} from '../../query.helpers';
import {InitAdvancedQueryContext} from '../AdvancedQuery.helper';
import {Attribute} from '../tree/types/Attribute.types';
import {CubeMap} from '../tree/types';
import {IQueryTreeContext} from '../tree/context/QueryTreeContext';

type IDataUpdate<T> = React.Dispatch<React.SetStateAction<T>>;

const {createContext, useEffect, useState, useMemo} = React;
// eslint-disable-next-line @typescript-eslint/no-empty-function
const DefaultDataUpdate = (): void => {};

interface IAdvancedQueryContext extends IQueryBuilder {
	isLoading: boolean;
	saved: boolean;
	stickyBar: boolean;
	IsBuyerJourneyEnabled: boolean;
	isEditable: boolean;
	enableQueryBuilderRedesign: boolean;
	isNewRedesign: boolean;
	totalRules: number;
	currentRatingEngine: CurrentRating;
	isAccountFitModelingSuccess: boolean;
	member_restriction?: IRootRestriction;
	state?: NgStateService;
	rootScope?: RootScope;
	isRulesBasedModelMode: boolean;
	droppedItemAppend?: React.MutableRefObject<boolean | undefined>;
	draggedContainer?: HTMLElement;
	draggedClone?: React.MutableRefObject<HTMLElement | undefined>;
	mouseDownTimer?: React.MutableRefObject<NodeJS.Timeout | undefined>;
}

const DefaultAdvancedQueryContext: IAdvancedQueryContext = {
	isLoading: false,
	mode: defaultMode,
	saved: false,
	segmentObj: {} as ISegment,
	inModel: false,
	cube: {} as CubeMap,
	inRatingEngine: false,
	history: [],
	restriction: {} as IRootRestriction,
	isRules: false,
	account_restriction: {} as IRootRestriction,
	contact_restriction: {} as IRootRestriction,
	enrichmentsMap: {},
	eventEnrichmentsMap: {},
	enrichments: [],
	eventEnrichments: [],
	categoryMetadata: [],
	enrichmentTopAttributes: {} as ICategoryDict,
	entityList: {},
	segmentsList: [],
	rating_rule: {
		bucketToRuleMap: {},
		defaultBucketName: 'A',
	},
	ratingEngineModel: {},
	rating_buckets: {},
	bucket: 'A',
	buckets: [],
	bucketsMap: {} as RatingCounts,
	bucketLabels: [],
	default_bucket: 'A',
	coverage_map: {} as SegmentIdModelRulesCoverageMap,
	rating_id: '',
	RuleRecordMap: {},
	rulesInputTree: {} as Restriction,
	accountRulesTree: [],
	contactRulesTree: [],
	memberRulesTree: [],
	segment: '',
	isTAM: false,
	treeMode: 'account',
	segmentInputTreeInit: [] as Restriction[],
	droppedItem: undefined,
	draggedItem: undefined,
	items: [],
	canEdit: false,
	labelIncrementor: 0,
	showSegmentationV2: false,
	showSegmentBlock: false,
	showTimeSeries: false,
	listSegmentMaterialization: false,
	stickyBar: false,
	IsBuyerJourneyEnabled: false,
	isEditable: false,
	enableQueryBuilderRedesign: false,
	isNewRedesign: false,
	totalRules: 0,
	currentRatingEngine: {},
	isAccountFitModelingSuccess: false,
	isRulesBasedModelMode: false,
};

interface IAdvancedQueryContextUpdate {
	setIsLoading: IDataUpdate<boolean>;
	setTotalRules: IDataUpdate<number>;
	setBuckets: IDataUpdate<RuleCountBkt[]>;
	setCoverageMap: IDataUpdate<SegmentIdModelRulesCoverageMap | undefined>;
	setRuleRecordMap: IDataUpdate<Record<string, Restriction>>;
	setAccountRulesTree: IDataUpdate<Restriction[]>;
	setContactRulesTree: IDataUpdate<Restriction[]>;
	setMemberRulesTree: IDataUpdate<Restriction[]>;
	setRulesInputTree: IDataUpdate<Restriction>;
	setEnrichments: IDataUpdate<Attribute[]>;
	setEnrichmentsMap: IDataUpdate<EnrichmentsMap>;
	setEventEnrichments: IDataUpdate<Attribute[]>;
	setEventEnrichmentsMap: IDataUpdate<EnrichmentsMap>;
	setSegmentInputTreeInit: IDataUpdate<Restriction[]>;
	setSaved: IDataUpdate<boolean>;
	setBucket: IDataUpdate<keyof RatingsBuckets>;
	setDraggedContainer: IDataUpdate<HTMLElement | undefined>;
}
const DefaultAdvancedQueryContextUpdate: IAdvancedQueryContextUpdate = {
	setIsLoading: DefaultDataUpdate,
	setTotalRules: DefaultDataUpdate,
	setBuckets: DefaultDataUpdate,
	setCoverageMap: DefaultDataUpdate,
	setRuleRecordMap: DefaultDataUpdate,
	setAccountRulesTree: DefaultDataUpdate,
	setContactRulesTree: DefaultDataUpdate,
	setMemberRulesTree: DefaultDataUpdate,
	setRulesInputTree: DefaultDataUpdate,
	setEnrichments: DefaultDataUpdate,
	setEnrichmentsMap: DefaultDataUpdate,
	setEventEnrichments: DefaultDataUpdate,
	setEventEnrichmentsMap: DefaultDataUpdate,
	setSegmentInputTreeInit: DefaultDataUpdate,
	setSaved: DefaultDataUpdate,
	setBucket: DefaultDataUpdate,
	setDraggedContainer: DefaultDataUpdate,
};

const AdvancedQueryContext = createContext(DefaultAdvancedQueryContext);
const AdvancedQueryContextUpdate = createContext(
	DefaultAdvancedQueryContextUpdate
);

type RootScope = {
	$apply(): void;
};

interface IAdvancedQueryComponent {
	$state: NgStateService;
	$stateParams: common.$stateParams;
	$rootScope: RootScope;
	cube: CubeMap;
	ratingEngineModel: RatingModel;
	currentRatingEngine: CurrentRating;
	categoryMetadata: CategoryMetadata[];
	enrichmentTopAttributes: ICategoryDict;
	entityList: IEntityMapping;
}

interface IAdvancedQueryContextProvider {
	scope: IAdvancedQueryComponent;
	children: React.ReactNode;
}
const GetData = (
	ratingId?: string
): {
	isFetching: boolean;
	cube: CubeMap;
	ratingEngine: RatingEngine | string;
	categoryMetadata: CategoryMetadata[];
	ratingEngineModel: RatingModel;
	enrichmentTopAttributes: ICategoryDict;
	entityList: IEntityMapping;
} => {
	const {isFetching: ratingEngineFetching, data: ratingEngine = ''} = useApi<
		RatingEngine | string
	>('getRating', () => getRating(ratingId));
	const {isFetching: cubeFetching, data: cube = {}} = useApi<CubeMap>(
		'getCube',
		() => getCube()
	);
	const {isFetching: categoryMetadataFetching, data: categoryMetadata = []} =
		useApi<CategoryMetadata[]>('loadCategoryMetadata', () =>
			loadCategoryMetadata()
		);
	const {
		isFetching: enrichmentTopAttributesFetching,
		data: enrichmentTopAttributes = {},
	} = useApi<StatsTopNResponse['Categories'] | Record<string, unknown>>(
		'getCategories',
		() => getCategories()
	);

	const {isFetching: entityListFetching, data: entityList = {}} =
		useApi<EntityMappingResponse>('getEntityList', () => getEntityList());
	let ratingEngineModel: RatingModel = {};
	if (ratingId) {
		getRatingsEngineAttributes(ratingId)
			.then((data): void => {
				ratingEngineModel = data && data[0] ? data[0] : {};
				if (
					!ratingEngineModel?.rule?.ratingRule.bucketToRuleMap &&
					ratingEngineModel.rule?.ratingRule
				) {
					ratingEngineModel = {
						rule: {
							...ratingEngineModel.rule,
							ratingRule: {
								...ratingEngineModel.rule?.ratingRule,
								bucketToRuleMap:
									generateRatingsBuckets() as unknown as BucketToRuleMap,
							},
						},
					};
				}
				if (ratingEngineModel.rule?.ratingRule.bucketToRuleMap) {
					checkRatingsBuckets(
						ratingEngineModel.rule?.ratingRule.bucketToRuleMap
					);
				}
			})
			.catch(() => {
				console.log('getRatingsEngineAttributes  error!');
			});
		const selectedAttributes = getDataCloudProperty<string[]>(
			'ratingsEngineAttributes'
		);

		if (selectedAttributes && ratingEngineModel?.rule) {
			ratingEngineModel = {
				...ratingEngineModel,
				rule: {
					...ratingEngineModel?.rule,
					selectedAttributes,
				},
			};
		}
	}
	return {
		isFetching:
			ratingEngineFetching &&
			cubeFetching &&
			categoryMetadataFetching &&
			enrichmentTopAttributesFetching &&
			entityListFetching,
		cube,
		ratingEngine,
		categoryMetadata,
		ratingEngineModel,
		enrichmentTopAttributes: enrichmentTopAttributes as ICategoryDict,
		entityList,
	};
};
const AdvancedQueryContextProvider = ({
	scope,
	children,
}: IAdvancedQueryContextProvider): React.ReactElement => {
	const {$state, $stateParams, $rootScope} = scope;

	const {
		isFetching,
		cube,
		ratingEngine,
		categoryMetadata,
		ratingEngineModel,
		enrichmentTopAttributes,
		entityList,
	} = GetData($stateParams.rating_id);
	const [isLoading, setIsLoading] = useState(true);
	const [totalRules, setTotalRules] = useState(0);
	const [buckets, setBuckets] = useState<RuleCountBkt[]>([]);
	const [coverageMap, setCoverageMap] = useState<
		SegmentIdModelRulesCoverageMap | undefined
	>();
	const [RuleRecordMap, setRuleRecordMap] = useState({});
	const [accountRulesTree, setAccountRulesTree] = useState<Restriction[]>([]);
	const [contactRulesTree, setContactRulesTree] = useState<Restriction[]>([]);
	const [memberRulesTree, setMemberRulesTree] = useState<Restriction[]>([]);
	const [rulesInputTree, setRulesInputTree] = useState<Restriction>({});
	const [enrichments, setEnrichments] = useState<Attribute[]>([]);
	const [enrichmentsMap, setEnrichmentsMap] = useState(
		getEnrichmentsMap() as EnrichmentsMap
	);
	const [eventEnrichments, setEventEnrichments] = useState<Attribute[]>([]);
	const [eventEnrichmentsMap, setEventEnrichmentsMap] =
		useState<EnrichmentsMap>(getDataCloudProperty('eventEnrichmentsMap'));
	const [segmentInputTreeInit, setSegmentInputTreeInit] = useState<
		Restriction[]
	>([]);
	const [saved, setSaved] = useState(false);
	const [bucket, setBucket] = useState(
		getQueryProperty<keyof RatingsBuckets>('selectedBucket')
	);
	const draggedItem = React.useRef<IQueryTreeContext>();
	const droppedItem = React.useRef<IQueryTreeContext | IAdvancedQueryContext>();
	const droppedItemAppend = React.useRef<boolean>(false);
	const [draggedContainer, setDraggedContainer] = useState<HTMLElement>();
	const draggedClone = React.useRef<HTMLElement>();
	const mouseDownTimer = React.useRef<NodeJS.Timeout | undefined>(undefined);
	const initValue = useMemo(() => {
		const queryPublic: QueryPublic = getQueryProperty('public');
		const {labelIncrementor} = queryPublic;
		const bucketsMap = {A: 0, B: 1, C: 2, D: 3, E: 4, F: 5};
		const bucketLabels: (keyof RatingsBuckets)[] = [
			'A',
			'B',
			'C',
			'D',
			'E',
			'F',
		];
		const currentRatingEngine = ratingEngine as unknown as CurrentRating;
		const segmentObj: ISegment =
			getQueryProperty('segment') ||
			(currentRatingEngine ? currentRatingEngine.segment : {});
		const inRatingEngine = currentRatingEngine !== null;
		const isEditable = inRatingEngine
			? !!currentRatingEngine?.viewOnly
			: segmentObj?.viewOnly;
		const canEdit =
			!isEditable && RBAC.hasAccess(RBACInterface.MODELS, RBACActions.EDIT);
		const mode: Modes = getQueryProperty('mode');
		const stickyBar =
			($state?.current?.name?.split('.')[1] === 'segment' ||
				$state.current.name ===
					MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT ||
				$state.current.name ===
					MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT) &&
			isHorizontalNavigationEnabled();
		const IsBuyerJourneyEnabled = isBuyerJourneyEnabled();
		const inModel = $state?.current?.name?.split('.')[1] === 'model';
		const history: [] = getQueryProperty('history');
		const restriction: IRootRestriction =
			getQueryProperty('accountRestriction');
		const account_restriction: IRootRestriction =
			getQueryProperty('accountRestriction');
		const contact_restriction: IRootRestriction =
			getQueryProperty('contactRestriction');
		const member_restriction: IRootRestriction =
			getQueryProperty('memberRestriction');
		const isRules = isSegmentationMode();
		const segmentsList: ISegment[] = getQueryProperty('segments');
		const showSegmentationV2 = isSegmentationV2Enabled();
		const enableQueryBuilderRedesign = isEnableQueryBuilderRedesign();
		const isNewRedesign =
			isSegmentationV2Enabled() && isEnableQueryBuilderRedesign();
		const showTimeSeries = isTimeSeriesSegmentEnabled();
		const listSegmentMaterialization = isFeatureFlagEnabled(
			FeatureFlags.LIST_SEGMENT_MATERIALIZATION
		);
		let showSegmentBlock =
			typeof segmentObj.isSubSegment === 'undefined' || segmentObj.isSubSegment;
		if ($stateParams?.segment !== 'Create') {
			const segment = segmentsList.find(
				(item) => item.name === $stateParams?.segment
			);
			showSegmentBlock = segment?.subSegments.length === 0;
		}
		const isTAM = !!$stateParams?.isTAM;
		const isAccountFitModelingSuccess = !!$state?.current?.name?.includes(
			MODELS_CREATE_ACCOUNT_FIT_MODELING_SUCCESS_CRITERIA
		);
		let rating_rule: RatingRule | undefined;
		let rating_buckets: BucketToRuleMap | undefined;
		let default_bucket: keyof RatingsBuckets = 'A';
		const isRulesBasedModel = isRulesBasedModelMode();
		if (isRulesBasedModel) {
			rating_rule = ratingEngineModel?.rule?.ratingRule;
			rating_buckets = rating_rule?.bucketToRuleMap;
			default_bucket = rating_rule?.defaultBucketName || 'A';
			if ($stateParams.segment === 'Create' && $stateParams?.subSegment) {
				setPublicProperty('enableSaveSegmentButton', true);
			}
			setPublicProperty(
				'enableExportSegmentButton',
				!segmentObj.status ||
					getSubSegmentStatus(segmentObj.status) === 'Active'
			);
			// FIXME: The query builder should be given configuration from its
			// parent; not infer functionality based on its environment
			if ($state?.current?.name) {
				setModeFromState($state?.current?.name);
			}
		}
		return {
			state: $state,
			rootScope: $rootScope,
			isLoading,
			mode,
			saved,
			segmentObj,
			inModel,
			cube,
			inRatingEngine,
			history,
			restriction,
			account_restriction,
			contact_restriction,
			member_restriction,
			enrichmentsMap,
			eventEnrichmentsMap,
			isRules,
			enrichments,
			eventEnrichments,
			categoryMetadata,
			enrichmentTopAttributes,
			entityList,
			stickyBar,
			IsBuyerJourneyEnabled,
			isEditable,
			segmentsList,
			showSegmentationV2,
			enableQueryBuilderRedesign,
			isNewRedesign,
			showTimeSeries,
			listSegmentMaterialization,
			showSegmentBlock,
			isTAM,
			rating_rule,
			ratingEngineModel,
			rating_buckets,
			default_bucket,
			bucket,
			buckets,
			bucketsMap,
			bucketLabels,
			coverage_map: coverageMap,
			rating_id: $stateParams?.rating_id || '',
			treeMode: SegmentMember.Account,
			segment: $stateParams.segment,
			RuleRecordMap,
			segmentInputTreeInit, // for undo
			rulesInputTree,
			accountRulesTree,
			contactRulesTree,
			memberRulesTree,
			mouseDownTimer,
			droppedItem,
			draggedItem,
			items: [],
			canEdit,
			labelIncrementor,
			totalRules,
			currentRatingEngine,
			isAccountFitModelingSuccess,
			isRulesBasedModelMode: isRulesBasedModel,
			droppedItemAppend,
			draggedContainer,
			draggedClone,
		};
	}, [
		$state,
		$rootScope,
		$stateParams.segment,
		$stateParams?.subSegment,
		$stateParams?.rating_id,
		$stateParams?.isTAM,
		RuleRecordMap,
		accountRulesTree,
		buckets,
		categoryMetadata,
		contactRulesTree,
		coverageMap,
		cube,
		ratingEngine,
		enrichmentTopAttributes,
		enrichments,
		enrichmentsMap,
		entityList,
		eventEnrichments,
		eventEnrichmentsMap,
		isLoading,
		memberRulesTree,
		ratingEngineModel,
		rulesInputTree,
		segmentInputTreeInit,
		totalRules,
		saved,
		bucket,
		droppedItem,
		draggedItem,
		droppedItemAppend,
		draggedContainer,
		draggedClone,
		mouseDownTimer,
	]);
	const initAction = useMemo(
		() => ({
			setIsLoading,
			setTotalRules,
			setBuckets,
			setCoverageMap,
			setRuleRecordMap,
			setAccountRulesTree,
			setContactRulesTree,
			setMemberRulesTree,
			setRulesInputTree,
			setEnrichments,
			setEnrichmentsMap,
			setEventEnrichments,
			setEventEnrichmentsMap,
			setSegmentInputTreeInit,
			setSaved,
			setBucket,
			setDraggedContainer,
		}),
		[]
	);
	const clearSegment = useSelector(
		(state: {query: QueryState}) => state?.query?.public.clearSegment
	);
	useEffect(() => {
		if (isLoading || clearSegment) {
			InitAdvancedQueryContext(initValue, initAction);
			if (clearSegment) {
				setPublicProperty('clearSegment', false);
			}
		}
	}, [isLoading, initValue, initAction, clearSegment]);
	return (
		<AdvancedQueryContext.Provider value={initValue}>
			<AdvancedQueryContextUpdate.Provider value={initAction}>
				{!isLoading && !isFetching && children}
			</AdvancedQueryContextUpdate.Provider>
		</AdvancedQueryContext.Provider>
	);
};

export {
	AdvancedQueryContext,
	AdvancedQueryContextProvider,
	AdvancedQueryContextUpdate,
	DefaultAdvancedQueryContext,
};
export type {
	IAdvancedQueryComponent,
	IAdvancedQueryContextProvider,
	IAdvancedQueryContext,
	IAdvancedQueryContextUpdate,
};
