import {get, isEmpty} from 'lodash';
import httpService from 'common/app/http/http-service';
import Observer from 'common/app/http/observer';
import {
	FIELD_MAPPING_MARKETO,
	FIELD_MAPPING_OUTREACH,
	FIELD_MAPPING_PARDOT,
} from './confWindowService';
import {hasAllMandatoryFields} from './EIF/Configuration/Activation/Component/FieldMapping/helpers/fieldMapping.helpers';
import { SystemType } from './EIF/Data/SystemType';
import { SystemConfigMap } from './EIF/Data/SystemConst';

export const MARKETO = 'Marketo';
export const SALESFORCE = 'Salesforce';
export const ELOQUA = 'Eloqua';
export const AWS_S3 = 'AWS_S3';
export const LINKEDIN = 'LinkedIn';
export const FACEBOOK = 'Facebook';
export const OUTREACH = 'Outreach';
export const PARDOT = 'Pardot';
export const SALESLOFT = 'SalesLoft';
export const GOOGLE_ADS = 'GoogleAds';
export const GOOGLE_DV = 'Google_Display_N_Video_360';
export const APP_NEXUS = 'AppNexus';
export const MEDIA_MATH = 'MediaMath';
export const ADOBE_AUDIENCE_MANAGER = 'Adobe_Audience_Mgr';
export const VERIZON_OATH = 'Verizon_Media';
export const TRADE_DESK = 'TradeDesk';
export const VENDEMORE = 'Vendemore';
export const GENERICDSP = 'GenericDsp';
export const HUB_SPOT = 'HubSpot';

const EMAIL = {
	displayName: 'email',
	fieldName: 'email',
};

export const EDIT_MODE = 'edit';
export const CREATE_MODE = 'create';

export const TASK = 'TASK';
export const AUDIENCE = 'AUDIENCE';
export const externalSystemsWithChangeableAudienceTypes = [
	MARKETO,
	LINKEDIN,
	OUTREACH,
]; // some connectors can change audience types and these sometimes require special handling

export const ACCOUNTS = 'ACCOUNTS';
export const CONTACTS = 'CONTACTS';
export const ATTRIBUTES_DEFAULT_GROUP = 'Default_Group';
export const ATTRIBUTES_REQUIRED_FIELD = 'Email';
export const ATTRIBUTES_DEFAULT_SOURCE_FIELD = ATTRIBUTES_REQUIRED_FIELD;

const assetBasePath = '/atlas/assets/images';
const svgBasePath = '/atlas/assets/svgs';

class ConnectorService {
	constructor() {
		if (!ConnectorService.instance) {
			ConnectorService.instance = this;
			this.connectorInfo = {
				name: '',
			};
			this._connectors = {
				DataVision: {
					name: 'DataVision',
					systemName: 'DataVision',
					displayName: 'Explore Module',
					tag: 'DataVision',
					programsLabel: '',
					defaultConnector: false,
					canMapAccountId: false,
					canMapContactId: false,
					canEditMapping: false,
					isConfigurable: false,
					isExternallyAuthenticated: true,
					isAdPlatform: true,
					showAudienceName: true,
					config: {
						img: `${svgBasePath}/logo-dnb.svg`,
						icon: `${svgBasePath}/dnb-favicon.svg`,
						text: 'Profile, visualize, and segment your global customer and prospect data to identify the right targets for growth.',
					},
				},
				[SALESFORCE]: {
					name: 'Salesforce',
					systemName: 'Salesforce',
					programsLabel: '',
					defaultConnector: false,
					canMapAccountId: true,
					canMapContactId: true,
					canMapLeadId: true,
					canEditMapping: false,
					isConfigurable: true,
					isExternallyAuthenticated: false,
					isAdPlatform: false,
					config: {
						img: `${svgBasePath}/salesforce_logo.svg`,
						icon: `${svgBasePath}/logo_salesforce_icon.svg`,
						img_select: `${svgBasePath}/salesforce_logo_white.svg`,
						text: 'Send and receive recommendations about how likely leads, accounts and customers are to buy, what they are likely to buy and when by connecting to this CRM',
					},
					mandatoryDestinationFields: {
						ACCOUNTS: ['Name', 'Phone'],
						CONTACTS: ['FirstName', 'LastName', 'Phone', 'Email'],
						LEADS: ['FirstName', 'LastName', 'Phone', 'Email', 'Company'],
					},
					mandatoryDestinationFieldOperations: ['CREATE'],
				},
				[SystemType.Microsoft_Dynamics365]: {
					name: SystemType.Microsoft_Dynamics365,
					systemName: SystemType.Microsoft_Dynamics365,
					programsLabel: '',
					defaultConnector: false,
					canMapAccountId: true,
					canMapContactId: true,
					canMapLeadId: true,
					canEditMapping: false,
					isExternallyAuthenticated: false,
					isAdPlatform: false,
					config: {
						img: SystemConfigMap[SystemType.Microsoft_Dynamics365].icon,
						icon: SystemConfigMap[SystemType.Microsoft_Dynamics365].icon,
					},
					mandatoryDestinationFields: {
						ACCOUNTS: ['name'],
						CONTACTS: ['firstname', 'lastname'],
						LEADS: ['topic', 'lastname'],
					},
					mandatoryDestinationFieldOperations: ['CREATE'],
				},
				[HUB_SPOT]: {
					name: 'HubSpot',
					systemName: 'HubSpot',
					programsLabel: '',
					defaultConnector: false,
					canMapAccountId: true,
					canMapContactId: true,
					canMapLeadId: false,
					canEditMapping: false,
					isExternallyAuthenticated: false,
					isAdPlatform: false,
					config: {
						img: `${svgBasePath}/logo_hubspot.svg`,
						icon: `${svgBasePath}/logo_hubspot_icon.svg`,
					},
					mandatoryDestinationFields: {
						ACCOUNTS: [['name', 'domain']],
						CONTACTS: ['email'],
					},
					mandatoryDestinationFieldOperations: ['CREATE'],
				},
				SalesforceMarketingCloud: {
					name: 'SalesforceMarketingCloud',
					displayName: 'Salesforce Marketing Cloud',
					systemName: 'SalesforceMarketingCloud',
					programsLabel: '',
					defaultConnector: false,
					canMapAccountId: false,
					canMapContactId: true,
					canMapLeadId: false,
					canEditMapping: false,
					isConfigurable: true,
					isExternallyAuthenticated: false,
					isAdPlatform: false,
					config: {
						img: `${svgBasePath}/logo_SFMC_icon.svg`,
						icon: `${svgBasePath}/logo_SFMC_icon.svg`,
						img_select: `${svgBasePath}/salesforce_logo_white.svg`,
						text: '',
					},
					mandatoryDestinationFields: {
						CONTACTS: [
							'Email',
							'SubscriberId',
							'FirstName',
							'LastName',
							'Company',
						],
					},
				},
				[ELOQUA]: {
					name: 'Eloqua',
					systemName: 'Eloqua',
					programsLabel: 'List',
					defaultConnector: false,
					canMapAccountId: true,
					canMapContactId: true,
					canEditMapping: false,
					isConfigurable: true,
					isExternallyAuthenticated: false,
					isAdPlatform: false,
					showAudienceName: true,
					mandatoryFields: [
						{
							displayName: ATTRIBUTES_REQUIRED_FIELD,
							fieldName: ATTRIBUTES_REQUIRED_FIELD,
							destinationField: 'C_EmailAddress', // optional
						},
					],
					config: {
						img: `${svgBasePath}/eloqua_logo.svg`,
						img_select: `${svgBasePath}/eloqua_logo_white.svg`,
						icon: `${svgBasePath}/logo_eloqua_icon.svg`,
						text: 'Activate audience segments based on your Customer 360 data to power your email campaigns by connecting to Eloqua',
					},
				},
				[AWS_S3]: {
					name: 'AWS_S3',
					systemName: 'AWS_S3',
					displayName: 'AWS S3',
					programsLabel: '',
					defaultConnector: true,
					canMapAccountId: true,
					canMapContactId: true,
					canEditMapping: false,
					isConfigurable: true,
					isExternallyAuthenticated: false,
					isAdPlatform: false,
					config: {
						img: `${assetBasePath}/logo_aws_s3.png`,
						icon: `${svgBasePath}/logo_aws_s3_icon.svg`,
						text: "Send to Amazon S3 as a stepping stone to automate to other systems that we don't have direct connection with.",
					},
				},
				[LINKEDIN]: {
					name: 'LinkedIn',
					systemName: 'LinkedIn',
					tag: 'LinkedIn',
					programsLabel: 'Audience',
					defaultConnector: false,
					canMapAccountId: false,
					canMapContactId: false,
					canEditMapping: false,
					isConfigurable: false,
					isExternallyAuthenticated: true,
					isAdPlatform: true,
					showAudienceName: true,
					showEntityType: true,
					config: {
						img: `${svgBasePath}/logo_linkedin.svg`,
						icon: `${svgBasePath}/logo_linkedin_icon.svg`,
						text: 'Generate leads and build brand awareness by running social ads on the world’s largest professional network',
					},
				},
				[FACEBOOK]: {
					name: 'Facebook',
					systemName: 'Facebook',
					tag: 'Facebook',
					programsLabel: 'Audience',
					defaultConnector: false,
					canMapAccountId: false,
					canMapContactId: false,
					canEditMapping: false,
					isConfigurable: false,
					isExternallyAuthenticated: true,
					isAdPlatform: true,
					showAudienceName: true,
					config: {
						img: `${svgBasePath}/logo_facebook.svg`,
						icon: `${svgBasePath}/logo_facebook_icon.svg`,
						text: 'Display social ads to reach your future consumers by marketing to 2 billion people on Facebook',
					},
				},
				[MARKETO]: {
					name: 'Marketo',
					systemName: 'Marketo',
					tag: 'Marketo',
					programsLabel: 'List',
					defaultConnector: false,
					canMapAccountId: true,
					canMapContactId: true,
					canEditMapping: true,
					isConfigurable: true,
					isExternallyAuthenticated: true,
					isAdPlatform: false,
					showAudienceName: true,
					hasAfterIframeSteps: true,
					show: 1,
					mandatoryFields: [EMAIL],
					config: {
						img: `${svgBasePath}/marketo_logo.svg`,
						img_select: `${svgBasePath}/marketo_logo_white.svg`,
						icon: `${svgBasePath}/logo_marketo_icon.svg`,
						text: 'Activate audience segments based on your Customer 360 data to power your email campaigns by connecting to Marketo',
					},
					fieldMappingKey: FIELD_MAPPING_MARKETO,
					trayConfigValues: [
						{
							id: FIELD_MAPPING_MARKETO,
							type: 'field_mapping',
							saveInConfigValues: false,
							hidden: true,
						},
					],
				},
				[PARDOT]: {
					name: 'Pardot',
					systemName: 'Pardot',
					tag: 'pardot',
					programsLabel: '',
					defaultConnector: false,
					canMapAccountId: true,
					canMapContactId: true,
					canEditMapping: true,
					isConfigurable: true,
					isExternallyAuthenticated: true,
					isAdPlatform: false,
					showAudienceName: true,
					hasAfterIframeSteps: true,
					show: 1,
					mandatoryFields: [
						{
							displayName: 'Contact Email',
							fieldName: 'prospect_email_address',
						},
					],
					config: {
						img: `${svgBasePath}/pardot_logo.svg`,
						icon: `${svgBasePath}/logo_pardot_logo.svg`,
						img_select: `${svgBasePath}/pardot_logo_white.svg`,
						text: 'Activate audience segments based on your Customer 360 data to power your email campaigns by connecting to Salesforce Pardot',
					},
					fieldMappingKey: FIELD_MAPPING_PARDOT,
					trayConfigValues: [
						{
							id: 'ampsea_enabled',
							displayName: 'AMPSEA Enabled',
							type: 'boolean',
							saveInConfigValues: true,
							hidden: true,
						},
						{
							id: FIELD_MAPPING_PARDOT,
							type: 'field_mapping',
							saveInConfigValues: false,
							hidden: true,
						},
					],
				},
				[OUTREACH]: {
					name: 'Outreach',
					systemName: 'Outreach',
					tag: 'outreach',
					programsLabel: 'Sequence',
					defaultConnector: false,
					canMapAccountId: true,
					canMapContactId: false,
					canEditMapping: true,
					canProspectOwner: true,
					isConfigurable: true,
					isExternallyAuthenticated: true,
					isAdPlatform: false,
					showAudienceName: true,
					hasAfterIframeSteps: true,
					show: 1,
					hideEntityTypeForLaunchTypes: [TASK],
					mandatoryFields: [EMAIL],
					config: {
						img: `${assetBasePath}/outreach.png`,
						icon: `${svgBasePath}/logo_outreach_icon.svg`,
						text: 'Activate audience segments to power your Outreach Sequences and alert SDRs on an important engagement related to their target accounts and contacts',
					},
					fieldMappingKey: FIELD_MAPPING_OUTREACH,
					trayConfigValues: [
						{
							id: FIELD_MAPPING_OUTREACH,
							type: 'field_mapping',
							saveInConfigValues: false,
							hidden: true,
						},
					],
				},
				[GOOGLE_ADS]: {
					name: 'GoogleAds',
					systemName: 'GoogleAds',
					tag: 'google',
					programsLabel: 'Audience',
					defaultConnector: false,
					canMapAccountId: false,
					canMapContactId: false,
					canEditMapping: false,
					isConfigurable: false,
					isExternallyAuthenticated: true,
					isAdPlatform: true,
					showAudienceName: true,
					config: {
						img: `${assetBasePath}/logo_google-ads.png`,
						icon: `${svgBasePath}/logo_google_ads_icon.svg`,
						text: 'Display social ads to reach your future consumers by marketing to 2 billion people on Google Ads',
					},
				},
				[GOOGLE_DV]: {
					name: 'Google_Display_N_Video_360',
					tag: 'liveramp',
					systemName: 'Google_Display_N_Video_360', // this overrides using tag to set it, important for connectors that share tags like liveramps
					displayName: 'Google DV 360',
					programsLabel: 'Audience',
					defaultConnector: false,
					canMapAccountId: false,
					canMapContactId: false,
					canEditMapping: false,
					isConfigurable: false,
					isExternallyAuthenticated: true,
					isAdPlatform: false,
					showAudienceName: true,
					show: 1,
					config: {
						img: `${assetBasePath}/logo_google_dv.png`,
						icon: `${assetBasePath}/logo_google_dv_icon.png`,
						text: 'Activate audience segments of your targeted accounts to power your display advertisement campaigns by connecting to Google Display & Video 360',
					},
					trayConfigValues: [
						{
							id: 'connection_name',
							displayName: 'Name',
							type: 'string',
							saveInConfigValues: false,
							hidden: true,
							requiredForDatastore: true,
						},
						{
							id: 'entity_id',
							displayName: 'Entity ID',
							type: 'string',
							saveInConfigValues: true,
							hidden: false,
							requiredForDatastore: true,
						},
						{
							id: 'account_type',
							displayName: 'Account Type',
							type: 'string',
							saveInConfigValues: true,
							hidden: false,
						},
						{
							id: 'product',
							displayName: 'Product',
							type: 'string',
							saveInConfigValues: true,
							hidden: false,
							exclusivelyRequiredForDatastore: true,
						},
						{
							id: 'is_data_store',
							displayName: 'Data cost',
							type: 'bool',
							hidden: false,
							saveInConfigValues: true,
							requiredForDatastore: true,
						},
					],
				},
				[APP_NEXUS]: {
					name: 'Xandr_Invest',
					tag: 'liveramp',
					systemName: 'AppNexus',
					displayName: 'Xandr Invest',
					programsLabel: 'Audience',
					defaultConnector: false,
					canMapAccountId: false,
					canMapContactId: false,
					canEditMapping: false,
					isConfigurable: false,
					isExternallyAuthenticated: true,
					isAdPlatform: false,
					showAudienceName: true,
					show: 1,
					config: {
						img: `${svgBasePath}/logo_xandr.svg`,
						img_select: `${svgBasePath}/logo_xandr_white.svg`,
						icon: `${svgBasePath}/logo_xandr_icon.svg`,
						text: 'Activate audience segments of your targeted accounts to power your display advertisement campaigns by connecting to Xandr Invest',
					},
					trayConfigValues: [
						{
							id: 'connection_name',
							displayName: 'Name',
							type: 'string',
							saveInConfigValues: false,
							hidden: true,
							requiredForDatastore: true,
						},
						{
							id: 'client_id',
							displayName: 'Client ID',
							type: 'string',
							saveInConfigValues: true,
							hidden: false,
							requiredForDatastore: true,
						},
						{
							id: 'is_data_store',
							displayName: 'Data cost',
							type: 'bool',
							hidden: false,
							saveInConfigValues: true,
							requiredForDatastore: true,
						},
					],
				},
				[MEDIA_MATH]: {
					name: 'MediaMath',
					tag: 'liveramp',
					systemName: 'MediaMath',
					displayName: 'Infillion Pixel',
					programsLabel: '',
					defaultConnector: false,
					canMapAccountId: false,
					canMapContactId: false,
					canEditMapping: false,
					isConfigurable: false,
					isExternallyAuthenticated: true,
					isAdPlatform: false,
					showAudienceName: true,
					show: 1,
					config: {
						img: `${assetBasePath}/logo_infillion.png`,
						icon: `${assetBasePath}/logo_infillion_icon.png`,
						text: 'Activate audience segments of your targeted accounts to power your display advertisement campaigns by connecting to Infillion',
					},
					trayConfigValues: [
						{
							id: 'connection_name',
							displayName: 'Name',
							type: 'string',
							saveInConfigValues: false,
							hidden: true,
							requiredForDatastore: true,
						},
						{
							id: 'org_id',
							displayName: 'Org ID',
							type: 'string',
							saveInConfigValues: true,
							hidden: false,
							requiredForDatastore: true,
						},
						{
							id: 'is_data_store',
							displayName: 'Data cost',
							type: 'bool',
							hidden: false,
							saveInConfigValues: true,
							requiredForDatastore: true,
						},
					],
				},
				[ADOBE_AUDIENCE_MANAGER]: {
					name: 'Adobe_Audience_Mgr',
					tag: 'liveramp',
					systemName: 'Adobe_Audience_Mgr',
					displayName: 'Adobe Audience Manager',
					programsLabel: '',
					defaultConnector: false,
					canMapAccountId: false,
					canMapContactId: false,
					canEditMapping: false,
					isConfigurable: false,
					isExternallyAuthenticated: true,
					isAdPlatform: false,
					showAudienceName: true,
					show: 1,
					config: {
						img: `${assetBasePath}/logo_adobe_audience_manager.png`,
						icon: `${assetBasePath}/logo_adobe_audience_manager_icon.png`,
						text: 'Activate audience segments of your targeted accounts to power your display advertisement campaigns by connecting to Adobe Audience Manager',
					},
					trayConfigValues: [
						{
							id: 'connection_name',
							displayName: 'Name',
							type: 'string',
							saveInConfigValues: false,
							hidden: true,
							requiredForDatastore: true,
						},
						{
							id: 'adobe_contact_email',
							displayName: 'Adobe Contact Email',
							type: 'string',
							saveInConfigValues: true,
							hidden: true,
						},
						{
							id: 'web_data_source_id',
							displayName: 'Web Data Source ID',
							type: 'string',
							saveInConfigValues: true,
							hidden: false,
						},
					],
				},
				[VERIZON_OATH]: {
					name: 'Verizon_Media',
					tag: 'liveramp',
					systemName: 'Verizon_Media',
					displayName: 'Yahoo',
					programsLabel: 'Audience',
					defaultConnector: false,
					canMapAccountId: false,
					canMapContactId: false,
					canEditMapping: false,
					isConfigurable: false,
					isExternallyAuthenticated: true,
					isAdPlatform: false,
					showAudienceName: true,
					show: 1,
					config: {
						img: `${svgBasePath}/logo_yahoo.svg`,
						icon: `${svgBasePath}/logo_yahoo.svg`,
						text: 'Activate audience segments of your targeted accounts to power your display advertisement campaigns by connecting to Yahoo',
					},
					trayConfigValues: [
						{
							id: 'connection_name',
							displayName: 'Name',
							type: 'string',
							saveInConfigValues: false,
							hidden: true,
							requiredForDatastore: true,
						},
						{
							id: 'mdm_id',
							displayName: 'MDM ID',
							type: 'string',
							saveInConfigValues: true,
							hidden: false,
							requiredForDatastore: true,
						},
						{
							id: 'is_data_store',
							displayName: 'Data cost',
							type: 'bool',
							hidden: false,
							saveInConfigValues: true,
							requiredForDatastore: true,
						},
					],
				},
				[TRADE_DESK]: {
					name: 'TradeDesk',
					tag: 'liveramp',
					systemName: 'TradeDesk',
					displayName: 'The Trade Desk',
					programsLabel: 'Audience',
					defaultConnector: false,
					canMapAccountId: false,
					canMapContactId: false,
					canEditMapping: false,
					isConfigurable: false,
					isExternallyAuthenticated: true,
					isAdPlatform: false,
					showAudienceName: true,
					show: 1,
					config: {
						img: `${assetBasePath}/logo_trade_desk.png`,
						icon: `${svgBasePath}/logo_trade_desk_icon.svg`,
						text: 'Activate audience segments of your targeted accounts to power your display advertisement campaigns by connecting to TradeDesk',
					},
					trayConfigValues: [
						{
							id: 'connection_name',
							displayName: 'Name',
							type: 'string',
							saveInConfigValues: false,
							hidden: true,
							requiredForDatastore: true,
						},
						{
							id: 'advertiser_id',
							displayName: 'Advertiser ID',
							type: 'string',
							saveInConfigValues: true,
							hidden: false,
						},
						{
							id: 'encryption_key',
							displayName: 'Encryption Key',
							type: 'string',
							saveInConfigValues: true,
							hidden: true,
						},
						{
							id: 'partner_id',
							displayName: 'Partner ID',
							type: 'string',
							saveInConfigValues: true,
							hidden: false,
							exclusivelyRequiredForDatastore: true,
						},
						{
							id: 'is_data_store',
							displayName: 'Data cost',
							type: 'bool',
							hidden: false,
							saveInConfigValues: true,
							requiredForDatastore: true,
						},
					],
				},
				[VENDEMORE]: {
					name: 'Ads Manager',
					systemName: 'Vendemore',
					programsLabel: '',
					defaultConnector: true,
					canMapAccountId: false,
					canMapContactId: false,
					canEditMapping: false,
					isConfigurable: false,
					isExternallyAuthenticated: false,
					isAdPlatform: true,
					config: {
						img: `${svgBasePath}/logo_ads_manager.svg`,
						icon: `${svgBasePath}/logo_ads_manager_icon.svg`,
						text: "Send to our custom DSP so you don't have to repeat connections.",
					},
				},
				[GENERICDSP]: {
					name: 'Generic DSP',
					systemName: GENERICDSP,
					programsLabel: '',
					defaultConnector: false,
					canMapAccountId: false,
					canMapContactId: false,
					canEditMapping: false,
					isConfigurable: false,
					isExternallyAuthenticated: false,
					isAdPlatform: false,
					config: {
						img: `/pls/generic-dsp/dsp/icon?pid=103`,
						icon: `/pls/generic-dsp/dsp/icon?pid=103`,
						text: '',
					},
				},
			};
			this._connectorsList = [];
			this.partnerId = 'lattice-engines';
		}

		return ConnectorService.instance;
	}

	getAllChannelEnablements = () => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					resolve(response.data || {});
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.get('/pls/tenantconfig/getAllChannelEnablements', observer);
		});
	};

	getImgByConnector(connectorName, pid) {
		const element = this._connectors[connectorName];
		if (connectorName === GENERICDSP && pid) {
			return this.getGenericDSPIcon(pid);
		}
		if (element) {
			return element.config.img;
		}
		return '';
	}

	getIconByConnector(connectorName, pid) {
		const element = this._connectors[connectorName];
		if (connectorName === GENERICDSP && pid) {
			return this.getGenericDSPIcon(pid);
		}
		if (element) {
			return element.config.icon;
		}
		return '';
	}

	getGenericDSPIcon(pid) {
		return `/pls/generic-dsp/dsp/icon?pid=${pid}`;
	}

	getConnectorCreationTitle(otherTxt) {
		switch (this.connectorInfo.name) {
			case SALESFORCE:
				return `${'SFDC'} ${otherTxt || ''}`;
			case ELOQUA:
				return `${'ELOQUA'} ${otherTxt || ''}`;
			default:
				return otherTxt;
		}
	}

	getConnectorCreationBody() {
		const system = this.getConnectorCreationTitle();
		const h5 = `${'<h5>'}${system} ${'org Authentication</h5>'}`;
		let to = 'BIS';
		if (ELOQUA === this.connectorInfo.name) {
			to = 'CDP';
		}
		const p = `${'<p>Generate a One-time Authentication token below to connect the'} ${to} ${'application with the D&B Rev.Up ABX platform</p>'}`;
		return `${h5}${p}`;
	}

	setConnectorName(name) {
		this.connectorInfo.name = name;
	}

	getConnectorName() {
		return this.connectorInfo.name;
	}

	getConnectorTag(connectorName) {
		return this._connectors[connectorName].tag;
	}

	getConnectorTrayConfigValues(connectorName) {
		return this._connectors[connectorName]?.trayConfigValues ?? [];
	}

	getConnectorTrayConfigValuesToSave(connectorName, isDatastore) {
		const allConfigValues = this.getConnectorTrayConfigValues(connectorName);
		if (isDatastore) {
			return allConfigValues.filter(
				(configValue) =>
					configValue.saveInConfigValues &&
					(configValue.requiredForDatastore ||
						configValue.exclusivelyRequiredForDatastore)
			);
		}
		return allConfigValues.filter(
			(configValue) =>
				configValue.saveInConfigValues &&
				!configValue.exclusivelyRequiredForDatastore
		);
	}

	getConnector(connectorName) {
		return this._connectors[connectorName];
	}

	getConnectorDisplayName(connectorName) {
		return (
			this._connectors[connectorName]?.displayName ||
			this._connectors[connectorName]?.name
		);
	}

	setList(list) {
		this._connectorsList = list;
	}

	showAudienceName(name) {
		if (
			name &&
			this._connectors &&
			this._connectors[name] &&
			this._connectors[name].showAudienceName
		) {
			return true;
		}
		return false;
	}

	hasAfterIframeSteps(connectorName) {
		return this._connectors[connectorName]?.hasAfterIframeSteps || false;
	}

	hideEntityTypeForLaunchTypes(externalSystemName, launchBaseType) {
		if (
			launchBaseType &&
			externalSystemName &&
			this._connectors &&
			this._connectors[externalSystemName] &&
			this._connectors[externalSystemName].hideEntityTypeForLaunchTypes &&
			this._connectors[
				externalSystemName
			].hideEntityTypeForLaunchTypes.includes(launchBaseType)
		) {
			return true;
		}
		return false;
	}

	getList(externalIntegrationEnabled) {
		if (this._connectorsList.length === 0) {
			Object.keys(this._connectors).forEach((element) => {
				const el = this._connectors[element];
				if (
					el &&
					!el.defaultConnector &&
					(!this.isExternallyAuthenticatedSystem(element) ||
						externalIntegrationEnabled[el.systemName])
				) {
					this._connectorsList.push(this._connectors[element]);
				}
			});
		}
		return this._connectorsList;
	}

	getConnectors() {
		return this._connectors;
	}

	isExternallyAuthenticatedSystem(systemName) {
		return this._connectors[systemName]?.isExternallyAuthenticated;
	}

	isAdPlatform(systemName) {
		return this._connectors[systemName]?.isAdPlatform;
	}

	getConnectionSetup(connectionName) {
		const connection = this._connectors[connectionName];
		return connection;
	}

	getConnectorMandatoryFields(connectionName) {
		return this._connectors[connectionName].mandatoryFields || [];
	}

	getIframeParams(mode, show, start, connectorName) {
		let params = '';
		const connector = this.getConnector(connectorName);
		const showParam = connector.show ? `&show=[${connector.show}]` : '';

		const authBaseUrl = 'auth.tray.lattice-engines.com';
		const authBaseUrlParam =
			connector.name === GOOGLE_ADS ? `&authBaseUrl=${authBaseUrl}` : '';
		switch (mode) {
			case EDIT_MODE:
				params = `${'&show=['}${show}${']&start='}${start}${'&customValidation=true'}${authBaseUrlParam}`;
				return params;
			case CREATE_MODE:
				params = `${'&customValidation=true'}${showParam}${authBaseUrlParam}`;
				return params;

			default:
				return params;
		}
	}

	getPopupUrl(solutionInstanceId, authorizationCode, params) {
		// create only validation
		// edit marketo 2,2
		const baseUrl = `https://embedded.tray.io/external/solutions/${this.partnerId}/configure/${solutionInstanceId}?code=${authorizationCode}${params}`;
		return baseUrl;
	}

	canEditMapping(systemName) {
		const system = this._connectors[systemName];
		if (system && system.canEditMapping) {
			return system.canEditMapping;
		}
		return false;
	}

	areMandatoryMapped(systemName, fieldsMappedSet) {
		const system = this._connectors[systemName];
		if (system) {
			const mandatory = system.mandatoryFields;
			if (mandatory && mandatory.length > 0 && fieldsMappedSet) {
				let fieldsMissing = '';
				mandatory.forEach((field) => {
					const {displayName, fieldName} = field;
					if (!fieldsMappedSet.has(fieldName)) {
						fieldsMissing = `${fieldsMissing} ${displayName}`;
					}
				});
				return fieldsMissing;
			}
			return '';
		}
		return `${'System '}${systemName}${' does not exist'}`;
	}

	hasAllMandatoryDestinations(systemName, fieldsMapping) {
		const mandatoryDestinationFields = get(
			this._connectors[systemName],
			'mandatoryDestinationFields',
			{}
		);
		const mandatoryDestinationFieldOperations = get(
			this._connectors[systemName],
			'mandatoryDestinationFieldOperations',
			[]
		);

		const mandatoryDestinationKeys = Object.keys(mandatoryDestinationFields);

		const validateOperation = (operation) => {
			const hasSpecificOperations = !isEmpty(
				mandatoryDestinationFieldOperations
			);

			if (!hasSpecificOperations) {
				return true;
			}

			return mandatoryDestinationFieldOperations.includes(operation);
		};

		if (isEmpty(mandatoryDestinationKeys)) {
			return true;
		}

		return mandatoryDestinationKeys.every((audienceType) => {
			const hasAudience = fieldsMapping?.some(
				(field) =>
					field.audienceType === audienceType &&
					validateOperation(field.operation)
			);

			// If the audience type is not checked then we don't need to validate if all the mandatory fields of that section are selected.
			if (!hasAudience) {
				return true;
			}

			// If the audience type is checked we validate that all the mandatory destination fields are selected for that audience.
			return mandatoryDestinationFields[audienceType].every(
				(destinationField) =>
					fieldsMapping.some(
						(field) =>
							(Array.isArray(destinationField)
								? destinationField.includes(field.destinationField)
								: field.destinationField === destinationField) &&
							field.audienceType === audienceType
					)
			);
		});
	}

	validateMandatoryMappings(systemName, fieldsMapping) {
		const unmappedFields = this.areMandatoryMapped(
			systemName,
			new Set(fieldsMapping?.map((f) => f.sourceField))
		);
		if (unmappedFields !== '') {
			return false;
		}

		const hasAllMandatoryDestinations = this.hasAllMandatoryDestinations(
			systemName,
			fieldsMapping
		);
		if (!hasAllMandatoryDestinations) {
			return false;
		}

		if (!hasAllMandatoryFields(systemName, fieldsMapping)) {
			return false;
		}

		const mandatoryFieldsWithRequiredDestinations =
			this.getConnectorMandatoryFields(systemName).filter(
				(f) => f.destinationField
			);
		return mandatoryFieldsWithRequiredDestinations.every(
			(f) =>
				fieldsMapping.find((m) => m.sourceField === f.fieldName)
					?.destinationField === f.destinationField
		);
	}

	getAllLookupIdMapping = (entity) => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					const tmp = response.data;
					const addSystemType = (sysType, arrayElements) => {
						arrayElements.forEach((el) => {
							el.systemType = sysType;
						});
						return arrayElements;
					};
					let data = [];
					Object.keys(tmp).forEach((element) => {
						let t = tmp[element];
						t = addSystemType(element, t);
						data = data.concat(t);
					});
					httpService.unsubscribeObservable(observer);
					resolve(data);
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.get(
				`/pls/lookup-id-mapping/available-lookup-ids${
					entity ? `?audienceType=${entity}` : ''
				}`,
				observer
			);
		});
	};

	saveLookupIdMapping = (system) => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					resolve(response.data || {});
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.put(
				`/pls/lookup-id-mapping/config/${system.configId}`,
				system,
				observer
			);
		});
	};

	getAttributeSets = (system) => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					resolve(response.data || {});
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.get('/pls/attrconfig/attributeset', observer);
		});
	};

	getAttributeSetAttributes = (attributeGroup, externalSystemName) => {
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					resolve(response.data || {});
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.get(
				`/pls/datacollection/attributes/${attributeGroup}`,
				observer,
				null,
				{externalSystemName}
			);
		});
	};

	getSystemFields = (systemName, opts = {}) => {
		const {trayAuthId, trayUserToken, configValues} = opts;
		const headers = {
			'Tray-User-Token': trayUserToken,
		};
		const params = {
			trayAuthId,
			configValues,
		};
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					resolve(response.data || {});
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.get(
				`/pls/externalsystemdata/${systemName.toLowerCase()}/fields`,
				observer,
				headers,
				params
			);
		});
	};

	makeSolutionInstancesObject = (solutionInstance, system) => {
		const externalFieldMapping = {};
		const externalLatticeFields = {};
		const externalFieldMappingKey = this.getConnector(
			system.externalSystemName
		).fieldMappingKey;
		system.exportFieldMappings.forEach((field) => {
			externalFieldMapping[field.sourceField] = field.destinationField;
			externalLatticeFields[field.sourceField] = field.sourceFieldDisplayName;
		});
		return {
			solutionInstanceId: solutionInstance.id,
			solutionInstanceName: solutionInstance.name,
			authValues: solutionInstance.authValues[0],
			configValues: [
				{
					externalId: externalFieldMappingKey,
					value: externalFieldMapping,
				},
				{
					externalId: 'external_lattice_fields',
					value: externalLatticeFields,
				},
			],
		};
	};

	getSolutionInstance = (solutionsInstanceId, accessToken) => {
		const headers = {
			useraccesstoken: accessToken,
		};
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					resolve(response.data || {});
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.get(
				`/tray/solutionInstances/${solutionsInstanceId}`,
				observer,
				headers
			);
		});
	};

	saveSolutionInstance = (solutionsInstanceObj, accessToken) => {
		const headers = {
			useraccesstoken: accessToken,
		};
		return new Promise((resolve, reject) => {
			const observer = new Observer(
				(response) => {
					httpService.unsubscribeObservable(observer);
					resolve(response.data || {});
				},
				(error) => {
					httpService.unsubscribeObservable(observer);
					reject(error);
				}
			);
			httpService.put(
				`/tray/solutionInstances/${solutionsInstanceObj.solutionInstanceId}`,
				solutionsInstanceObj,
				observer,
				headers
			);
		});
	};
}

const instance = new ConnectorService();
Object.freeze(instance);

export default instance;
