import {axios} from 'common/network.vendor';
import {
	ECdpDataType,
	GenericFieldDefinitionsRecordsMap,
} from 'atlas/connectors/EIF/schema/schemaTypes';
import {AxiosPromise, AxiosRequestConfig} from 'common/node_modules/axios';
import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import {DocumentType} from '../../Data/DocumentType';
import {SystemType} from '../../Data/SystemType';
import {IGetSourceByIdResponse} from '../../Data/API/BE/GetSourceById';
import {IInboundSourceInfo} from '../../Data/API/BE/InboundSource';
import {ICommonResponse} from '../../Data/API/FE/CommonStatusResponse';

export interface GeneralResponse<T> {
	Errors: string[];
	Success: boolean;
	Result: T;
}

export type GeneralSourceResponse = GeneralResponse<string>;

export interface InboundChannelConnectionDocumentType {
	attrName: string;
	displayName: string;
	type: string;
	sourceObjects: string[];
}

interface InboundChannelConnectionDocumentTypeResponse {
	fields: InboundChannelConnectionDocumentType[];
	primaryKey: string[];
}

export function getInboundConnectionDocumentType(
	channelName: string
): AxiosPromise<InboundChannelConnectionDocumentTypeResponse> {
	return axiosInstance({
		url: `/pls/inboundchannel/channel/${channelName}/schema`,
	});
}

interface IChannelConfig {
	audienceId: string;
	audienceName: string;
	audienceType: string;
	suppressAccountsWithoutContacts: boolean;
	suppressAccountsWithoutLookupId: boolean;
	suppressContactsWithoutEmails: boolean;
	systemName: SystemType;
}

interface InboundChannel {
	channelConfig: IChannelConfig;
	created: string;
	dataStreamId: string;
	displayName: string;
	documentType: DocumentType;
	dropPath: string;
	dropPathHash: string;
	externalId: string;
	inboundConnection: {
		active: boolean;
		application: 'Lattice';
		authentication: {
			brokerAuthInfo: {
				authCode: string;
				baseUrl: string;
				email: string;
				encryptedPassword: string;
				expirationDate: string;
				expirationDateFormat: string;
				externalAuthName: string;
				instanceUrl: string;
				isSandbox: boolean;
				refreshToken: string;
				siteName: string;
				trayTriggerUrl: string;
				userName: string;
			};
			created: string;
			externalId: string;
			initiator: 'Lattice';
			name: string;
			owner: 'Lattice';
			systemType: SystemType;
			updated: string;
		};
		brokerType: 'FlatFile';
		connectionConfig: {
			isSandBox: string;
		};
		connectionType: DocumentType;
		created: string;
		createdBy: string;
		description: string;
		displayName: string;
		name: string;
		priority: 0;
		scheduler: {
			cronExpression: string;
			startTime: string;
		};
		sourceId: string;
		status: unknown;
		systemExternalId: string;
		updated: string;
		updatedBy: string;
		version: string;
	};
	lastAggregationWorkflowId: number;
	lastSyncTime: string;
	name: string;
	nextScheduledTime: string;
	selected: boolean;
	sourceId: string;
	updated: string;
}

export type InboundChannelResponse = GeneralResponse<InboundChannel>;

export function getInboundChannel(
	connectionName: string,
	documentType: string,
	sourceId?: string,
	channelName?: string
): AxiosPromise<InboundChannelResponse> {
	const data = {
		connectionName,
		documentType,
		sourceId,
		channelName,
	};
	if (channelName) {
		// @ts-ignore
		delete data.documentType;
	}
	return axiosInstance({
		method: 'post',
		url: '/pls/inboundchannel/channel',
		data,
	});
}

export interface SourceFieldDefinitionRecord {
	approvedUsage: string[];
	category: string;
	cdpDataType: ECdpDataType;
	cdpMaxLength: number;
	cdpMinLength: number;
	columnName?: string;
	dateFormat: string;
	defaultValue: string;
	description: string;
	displayName?: string;
	externalDisplayName: string;
	externalSystemName: string;
	externalSystemType: string;
	fieldName: string;
	fieldType: string;
	fundamentalType: string;
	idType: DocumentType;
	ignored: boolean;
	inCurrentImport: boolean;
	logicalDataType: string;
	mappedToLatticeId: boolean;
	matchingColumnNames: string[];
	mergingColumnNames: string[];
	nullable: boolean;
	required: boolean;
	screenName: string;
	statisticalType: string;
	subcategory: string;
	tags: string[];
	timeFormat: string;
	timeZone: string;
	enrichAble?: boolean;
}

export type SourceFieldDefinitionRecordRecordsMap =
	GenericFieldDefinitionsRecordsMap<SourceFieldDefinitionRecord>;

export interface SourceData {
	defaultSchema: boolean;
	schemaType: DocumentType;
	systemName: string;
	systemObject: string;
	systemType: string;
	fieldDefinitionsRecordsMap: SourceFieldDefinitionRecordRecordsMap;
}

export type SourceMappingResponse = GeneralResponse<SourceData>;

export function getAutoMap(
	channelName: string,
	sourceSchemaType?: DocumentType
): AxiosPromise<SourceMappingResponse> {
	return axiosInstance({
		url: '/pls/inboundsource/automap',
		params: {channelName, sourceSchemaType},
	});
}

export function getSourceMap(
	sourceUniqueId: string
): AxiosPromise<SourceMappingResponse> {
	return axiosInstance({
		url: '/pls/inboundsource/currentmap',
		params: {sourceUniqueId},
	});
}

export function getSourceData(
	sourceUniqueId: string
): AxiosPromise<IGetSourceByIdResponse> {
	return axiosInstance({
		url: `/pls/inboundsource/summary/${sourceUniqueId}`,
	});
}

export function getComplexSourceData(
	sourceUniqueId: string
): AxiosPromise<ICommonResponse<IInboundSourceInfo>> {
	return axiosInstance({
		url: `/pls/inboundsource/v2/sourceid/${sourceUniqueId}`,
	});
}

export const createSource = (
	channelName: string,
	schemaType: string,
	displayName: string,
	cdpSchemaSpec: SourceData
): AxiosPromise<GeneralSourceResponse> =>
	axiosInstance({
		method: 'post',
		url: `/pls/inboundsource/${channelName}/${schemaType}`,
		data: {
			displayName,
			cdpSchemaSpec,
		},
	});

export const createSourceV2 = (
	sourceInfo: IInboundSourceInfo
): AxiosPromise<GeneralSourceResponse> =>
	axiosInstance({
		method: 'post',
		url: '/pls/inboundsource/v2',
		data: sourceInfo,
	});

export const deleteSource = (
	sourceId: string
): AxiosPromise<GeneralSourceResponse> =>
	axiosInstance({
		method: 'delete',
		url: `/pls/inboundsource/${sourceId}`,
	});

export const updateSource = (
	uniqueId: string,
	data: SourceData
): AxiosPromise<GeneralSourceResponse> =>
	axiosInstance({
		method: 'post',
		url: `/pls/inboundsource/update/${uniqueId}`,
		data,
	});

export const updateSourceV2 = (
	data: IInboundSourceInfo
): AxiosPromise<GeneralSourceResponse> =>
	axiosInstance({
		method: 'post',
		url: '/pls/inboundsource/v2/update',
		data,
	});

export type UploadFlatSourceResponse = GeneralResponse<IUploadedFile>;

export const uploadFlatSource = (
	params: {
		displayName: string;
	},
	data: FormData,
	config: AxiosRequestConfig
): AxiosPromise<UploadFlatSourceResponse> =>
	axios({
		...config,
		method: 'post',
		url: '/pls/models/uploadfile/flatsource',
		params,
		data,
	});

export enum EConnectionStatus {
	RUNNING = 'RUNNING',
	SUCCEEDED = 'SUCCEEDED',
	READY = 'READY',
	FAILED = 'FAILED',
	NOT_READY = 'NOT_READY',
}

export const getConnectionStatus = (
	connectionName: string
): AxiosPromise<{
	errorMessage: string;
	status: EConnectionStatus;
}> =>
	axios({
		method: 'get',
		url: `/pls/inboundchannel/connection/status/${connectionName}`,
	});

interface ConnectionData {
	active: boolean;
	authentication: {
		brokerAuthInfo: {
			accessToken: string;
			authCode: string;
			baseUrl: string;
			createdAt: 0;
			encryptedPassword: string;
			expirationDate: string;
			expirationDateFormat: string;
			expiresIn: number;
			externalAuthName: string;
			instanceUrl: string;
			isSandbox: boolean;
			refreshToken: string;
			siteName: string;
			tokenType: string;
			trayTriggerUrl: string;
			userName: string;
		};
		created: string;
		externalId: string;
		initiator: string;
		name: string;
		owner: string;
		systemType: string;
		updated: string;
	};
	connectionType: string;
	created: string;
	createdBy: string;
	description: string;
	displayName: string;
	fromSystem: boolean;
	name: string;
	priority: number;
	scheduler: {
		cronExpression: string;
		startTime: number;
	};
	sourceId: string;
	update: string;
	updatedBy: string;
}

export const getConnectionData = (
	connectionName: string
): AxiosPromise<ConnectionData> =>
	axios({
		method: 'get',
		url: `/pls/inboundchannel/connection/${connectionName}`,
	});

interface InboundChannelSummary {
	channelConfig: IChannelConfig;
	created: string;
	dataStreamId: string;
	displayName: string;
	documentType: string;
	name: string;
	s3Path: string;
	selected: boolean;
	sourceId: string;
	updated: string;
}

export type InboundChannelListResponse = GeneralResponse<
	InboundChannelSummary[]
>;

export const getInboundChannelList = (
	connectionName: string,
	types: string[]
): AxiosPromise<InboundChannelListResponse> =>
	axiosInstance({
		method: 'post',
		url: `/pls/inboundchannel/connection/${connectionName}/bulkcreate`,
		data: types,
	});

interface DropBoxData {
	UseAccessMode: string;
	LatticeUser: string;
	AccessKey: string;
	SecretKey: string;
	Bucket: string;
	DropBox: string;
	Region: string;
}

export const getS3Credentials = (): AxiosPromise<DropBoxData> =>
	axiosInstance({
		url: '/pls/dropbox/key/v2',
	});

export interface S3File {
	byte_size: number;
	file_name: string;
	file_path: string;
	file_size: string;
	file_type: string;
	is_directory: boolean;
	last_modified: number;
}

export const getS3Files = (s3Path: string): AxiosPromise<S3File[]> =>
	axios({
		url: '/pls/cdl/s3import/fileList',
		params: {s3Path},
	});

export interface IUploadedFile {
	name: string;
	display_name: string;
}

export const importS3File = (
	entity = '',
	file: S3File
): AxiosPromise<{
	Success: boolean;
	Result: IUploadedFile;
}> =>
	axiosInstance({
		method: 'post',
		url: '/pls/models/uploadfile/importFile',
		params: {entity, setInitialSchema: true},
		data: file,
	});
