import {get} from 'lodash';
import NgState from 'atlas/ng-state';
import {
	ExternalSystem,
	ISegment,
	ListSystemType,
} from 'atlas/data/SegmentConst';
import {SystemType} from 'atlas/connectors/EIF/Data/SystemType';
import {navigate, SegmentNgState} from 'atlas/helpers/NavigateHelper';
import RBAC, {
	AccessGroups,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACInterface,
	RBACActions,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {
	hasModule,
	isBuyerJourneyEnabled,
	Modules,
	FeatureFlags,
	isFeatureFlagEnabled,
} from 'common/stores/tenantConfig';
import {setSessionSegmentState} from 'common/components/datacloud/query/results/rebuild/segment.helpers';
import {Connection} from 'atlas/playbook/content/metadataLiveramplaunch/types';
import {
	CAMPAIGN_CREATE_NAME,
	SEGMENTS_ACCOUNTLIST,
	SEGMENTS_CONTACTLIST,
} from 'atlas/navigation/header/components/page-navigation.utils';
import {
	createOrUpdateSegment,
	getSegmentByName,
} from 'common/components/datacloud/segment/segment.queries';
import getSanitizedSegmentByConfig from 'common/components/datacloud/tabs/subheader/SubheaderUtility';
import {getQueryProperty, setPublicProperty} from 'common/stores/query';
import {Query} from 'common/components/datacloud/datacloud.types';
import NoticeService from 'common/components/notice/NoticeService';
import {resetRestrictions} from 'common/components/datacloud/query/query.helpers';
import userValuesUtility from 'common/widgets/utilities/user-values.utility';
import localStorageUtility from 'common/widgets/utilities/local-storage.utility';
import {setKeyCacheData} from 'common/app/utilities/cacheUtility/cacheUtility';
import {getDataCloudProperty} from 'common/stores/datacloud';
import {deleteAngularHashKey} from 'common/components/datacloud/segment/segment.helpers';
import {NewlyAddedId} from 'atlas/JourneyStage/Data/JourneyStageData';
import {SystemTypes} from '../segmentation.constants';
import redirectToDataVision from '../SegmentationUtility';
import {actions} from '../segmentation.redux';
import {API, ISubJourneyStage} from '../SegmentDashboard/ApiCalls';
import {INameDescriptionData} from '../Components/NameDescriptionHeader';

const isExploreModuleSegment = (segment: ISegment): boolean =>
	segment.dataType?.startsWith('Explore Module');

/**
 * Flag indicates if a segment is a list segment.
 * @param segment @ISegment
 * @returns True on list segment.
 */
const isListSegment = (segment: ISegment): boolean => {
	return hasModule(Modules.LIST_SEGMENT) && segment.type === 'List';
};

const isAccountOrContactListSegmentType = (segment: ISegment): boolean => {
	// TODO: convert to Enum
	const listSegmentSystemTypes = {
		CompanyList: ListSystemType.CompanyList,
		ContactList: ListSystemType.ContactList,
	};

	const systemType = get(segment, 'listSegment.systemType', '');

	return (
		!isExploreModuleSegment(segment) &&
		isListSegment(segment) &&
		Object.values(listSegmentSystemTypes).includes(systemType as ListSystemType)
	);
};

/**
 * Flag indicates if a list segment can create sub segment.
 * @param segment @ISegment
 * @returns True on list segment can create.
 */
const isListSegmentCanCreateSub = (segment: ISegment): boolean => {
	const listSegmentMaterialization = isFeatureFlagEnabled(
		FeatureFlags.LIST_SEGMENT_MATERIALIZATION
	);
	const systemType = get(
		segment,
		'listSegment.systemType',
		''
	) as ListSystemType;
	return (
		listSegmentMaterialization &&
		isListSegment(segment) &&
		!(
			isExploreModuleSegment(segment) ||
			[ListSystemType.ContactList, ListSystemType.DataVision].includes(
				systemType
			)
		)
	);
};

const hasAdminAccess = (): boolean => {
	return RBAC.userHasAccessLevel(AccessGroups.ADMINS);
};

/**
 * Flag indicates if list segment but not company or contact.
 * @param segmnet @ISegment
 * @returns True on success
 */
const isListSegmentNotCompanyOrContactList = (segment: ISegment): boolean => {
	const isSupportedListSegment = isAccountOrContactListSegmentType(segment);
	return isListSegment(segment) && !isSupportedListSegment;
};

const disableEditOnListSegmentNotCompanyOrContactList = (
	segment: ISegment
): boolean => {
	return isListSegmentNotCompanyOrContactList(segment)
		? !hasAdminAccess() &&
				segment.created_by !== userValuesUtility.getUserEmail()
		: true;
};

const userHasTeams = (): boolean => {
	const clientSession = localStorageUtility.getClientSession();
	return !!clientSession?.TeamIds?.length;
};

/**
 * Segment click handler.
 * Refactor to extract method for reusable.
 * @param segment @ISegment
 */
const handleSegmentClick = (segment: ISegment): void => {
	const enabledListSegment = hasModule(Modules.LIST_SEGMENT);

	if (!RBAC.hasAccess(RBACInterface.SEGMENTS, RBACActions.DRILL)) {
		return;
	}

	if (
		isAccountOrContactListSegmentType(segment) &&
		(!isFeatureFlagEnabled(FeatureFlags.ENABLE_LIST_SEGMENT_IMPROVEMENT) ||
			segment.status !== 'Active')
	) {
		return;
	}

	if (
		enabledListSegment &&
		segment.listSegment?.externalSegmentId &&
		segment.listSegment.externalSystem === ExternalSystem.DataVision
	) {
		redirectToDataVision(segment.listSegment?.externalSegmentId);
		return;
	}

	const state = NgState.getAngularState();

	const {systemType} = segment.listSegment ?? {};

	if (systemType === ListSystemType.CompanyList) {
		setSessionSegmentState({
			isCompanyList: true,
		});

		state.go('home.segment.accounts', {segment: segment.name}, {reload: true});

		return;
	}

	if (systemType === ListSystemType.ContactList) {
		setSessionSegmentState({
			isContactList: true,
		});

		state.go('home.segment.contacts', {segment: segment.name}, {reload: true});

		return;
	}

	setSessionSegmentState({
		isCompanyList: false,
		isContactList: false,
	});

	const route =
		isBuyerJourneyEnabled() && !segment.isSubSegment
			? SegmentNgState.Dashboard
			: SegmentNgState.QueryBuilder;

	state.go(route, {segment: segment.name}, {reload: true});
};

const getPlayChannels = (playLaunchChannels: Connection[]): SystemType[] => {
	return playLaunchChannels?.map(
		(channel) =>
			channel?.lookupIdMap?.externalSystemName as unknown as SystemType
	);
};

interface ICreateCampaignInterface {
	segment_name: string;
	segment_type?: string;
	buyerJourneyStage?: {
		name: string;
		id: number;
	};
	buyerJourneySubStage?: {
		name: string;
		pid: number;
	};
}

/**
 * Create campaign from journey stage.
 * @param segment_name Segment name
 * @param segment_type Segment type
 * @param buyerJourneyStage Buyer journey stage info.
 */
const createCampaign = ({
	segment_name,
	segment_type,
	buyerJourneyStage,
	buyerJourneySubStage,
}: ICreateCampaignInterface): void => {
	navigate(
		CAMPAIGN_CREATE_NAME,
		{
			segment_name,
			segment_type,
			buyerJourneyStage,
			buyerJourneySubStage,
		},
		true
	);
};

/**
 * Do one-off import.
 * @param item @ISegment
 */
const doOneoffImport = (item: ISegment): void => {
	const {name, display_name, listSegment} = item;
	const listSegmentSystemType = listSegment?.systemType;
	const segmentType =
		listSegmentSystemType === ListSystemType.ContactList
			? SystemTypes.Contact
			: SystemTypes.Account;
	navigate(
		listSegmentSystemType === ListSystemType.ContactList
			? SEGMENTS_CONTACTLIST
			: SEGMENTS_ACCOUNTLIST,
		{
			segmentName: name,
			segmentDisplayName: display_name,
			segmentType,
			defaultMapping:
				listSegment?.[
					`${segmentType.toLocaleLowerCase()}Mapping` as keyof typeof listSegment
				],
		}
	);
};

/**
 * Save segment with cached queries.
 * @param name Segment name
 */
const saveSegment = (name: string): void => {
	const xhrSaveSegment = ({name}: Query): void => {
		const sanitizedSegment = getSanitizedSegmentByConfig({name});
		setPublicProperty('enableSaveSegmentButton', false);
		createOrUpdateSegment(sanitizedSegment)
			.then((response) => {
				// Dark magic to be able to persist the segment in the react component AccountFitModelingWizard
				window.postMessage({segment: response.data});
				NoticeService.success({
					title: 'The segment was saved successfully',
				});
			})
			.catch(() => undefined);
	};
	setPublicProperty('enableSaveSegmentButton', false);
	getSegmentByName(name)
		.then(xhrSaveSegment)
		.catch(() => undefined);
};

/**
 * Save sub journey stage with cached queries.
 * @param segmentName Segment name.
 * @param stageId Journey stage id.
 * @param data Name description data.
 */
const saveSubJourneyStage = (
	segmentName: string,
	stageId: number,
	data: INameDescriptionData
): void => {
	const sanitizedSegment = getSanitizedSegmentByConfig();
	setPublicProperty('enableSaveSegmentButton', false);
	const substage = getQueryProperty<ISubJourneyStage | undefined>(
		'segmentSubstage'
	);
	API.createOrUpdateSubJourneyStage({
		segmentName,
		buyerJourneyStageId: stageId,
		name: substage?.name || data.name,
		description: substage?.description || data.name,
		pid: data.id === NewlyAddedId ? undefined : data.id,
		accountRestriction: JSON.stringify(
			deleteAngularHashKey(sanitizedSegment.account_restriction)
		),
		contactRestriction: JSON.stringify(
			deleteAngularHashKey(sanitizedSegment.contact_restriction)
		),
	} as ISubJourneyStage)
		.then(() => {
			NoticeService.success({
				title: 'The sub stage was saved successfully',
			});
		})
		.catch(() => undefined);
};

/**
 * Clear segment
 * @remarks
 * Keep memeber restriction if segment is custom sub segment.
 */
const clearSegment = (): void => {
	setPublicProperty('enableSaveSegmentButton', false);
	const {customParentSegmentName, member_restriction} =
		getQueryProperty<ISegment>('segment') || {};
	resetRestrictions(
		customParentSegmentName ? ({member_restriction} as ISegment) : null
	);
	setPublicProperty('clearSegment', true);
};

const isActiveOrSuccess = ({status}: ISegment): boolean => {
	const isActive = status === 'Active';
	const isSuccess = status === 'Success';
	return status ? isActive || isSuccess : true;
};

const hasSegmentCreateAccess = (): boolean =>
	RBAC.hasAccess(RBACInterface.SEGMENTS, RBACActions.CREATE);

/**
 * Refresh segment list.
 * @param nocache True if no cache enabled.
 * @remarks Porting from legacy code.
 */
const refreshList = (nocache?: boolean): void => {
	/**
	 * TODO: Hacky solution for unstable navbar in app...
	 * PLS-29220
	 * https://github.com/dnb-main/lat-leui/blob/develop/projects/atlas/app/navigation/sidebar/sidebar.component.js#L188
	 */
	setKeyCacheData('getSegments', 'sessionStorage', null);

	const enrichments = getDataCloudProperty('enrichments') || [];
	const cube = getDataCloudProperty('cube') || {};

	actions.getEnrichments(nocache ? [] : enrichments);
	actions.getCube(nocache ? null : cube);
	actions.getSegments();
};

export type {ICreateCampaignInterface};
export {
	isListSegment,
	isExploreModuleSegment,
	isListSegmentCanCreateSub,
	isListSegmentNotCompanyOrContactList,
	isAccountOrContactListSegmentType,
	isActiveOrSuccess,
	userHasTeams,
	hasSegmentCreateAccess,
	disableEditOnListSegmentNotCompanyOrContactList,
	handleSegmentClick,
	getPlayChannels,
	createCampaign,
	doOneoffImport,
	clearSegment,
	saveSegment,
	refreshList,
	saveSubJourneyStage,
};
