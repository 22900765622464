import {hasModule, isFeatureFlagEnabled} from './utilities';
import {FeatureFlags, Modules} from './enums';

const isCalculateKnownContactEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_CALCULATE_KNOWN_CONTACT);

const isCDPPersonalizationEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.CDP_PERSONALIZATION);

const isEIFInboundEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENTERPRISE_INTEGRATION);

const isBuyerJourneyEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_BUYER_JOURNEY_STAGE);

const isBuyerJourneyAnalyticsEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_BUYER_JOURNEY_ANALYTICS);

const isBuyerJourneySubstageEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_BUYER_JOURNEY_SUBSTAGE);

const isEntityMatchEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_ENTITY_MATCH);

const isHorizontalNavigationEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_HORIZONTAL_NAVIGATION);

const isTimeSeriesSegmentEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_TIME_SERIES_SEGMENT);

const isSegmentationV2Enabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_SEGMENTATION_V2);

const isSSVIModule = (): boolean =>
	hasModule(Modules.SSVI) && !hasModule(Modules.CDL);

const isSSVIFlag = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.SSVI) && !hasModule(Modules.CDL);

const isSubscriptionPageAnalyticsEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_SUBSCRIPTION_PAGE_ANALYTICS);

const isNewWebInsightsEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_WEB_INSIGHTS_NEW_UI);

const isSalesforceMarketingCloudEnabled = (): boolean =>
	isFeatureFlagEnabled(
		FeatureFlags.ENABLE_SALESFORCE_MARKETING_CLOUD_INTEGRATION
	);

const isActivationMicrosoftDynamicsEnabled = (): boolean =>
	isFeatureFlagEnabled(
		FeatureFlags.ENABLE_ACTIVATION_MSFT_DYNAMICS_INTEGRATION
	);

const isImportMicrosoftDynamicsEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_MS_DYNAMICS365_INTEGRATION);

const isActivationHubSpotEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_ACTIVATION_HUB_SPOT_INTEGRATION);

const isActivationSalesLoftEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_ACTIVATION_SALES_LOFT_INTEGRATION);

const isGenericDSPEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_XANDR_CURATE_INTEGRATION);

const isWebInsightsCreateSegmentEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_WEB_INSIGHTS_CREATE_SEGMENT);

const isEnableQueryBuilderRedesign = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_QUERY_BUILDER_REDESIGN);
const isG2ConnectionEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_G2_CONNECTION);

const isModelingTrainingDataSegmentEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_MODELING_WITH_TRAINING_DATA_SEGMENT);

const isImportSFMCTrayEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_SFMC_TO_S3_TRAY_INTEGRATION);

const isImportConnectionSFMCEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_SFMC_TO_S3_STITCH_INTEGRATION) ||
	isImportSFMCTrayEnabled();

const isSavingAttributesEnabled = (): boolean =>
	isFeatureFlagEnabled(
		FeatureFlags.ENABLE_SAVING_ATTRIBUTES_IN_ATTRIBUTE_GROUP
	);
const isAC360NewDesignEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_AC360_REDESIGN);

const isAITalkingPointsEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_AI_TALKING_POINTS);

const isEDSPBrandSafetySegmentEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_DSP_BRAND_SAFETY_SEGMENTS);

const isHubSpotConnectionEnabled = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_IMPORT_HUB_SPOT);

const isQueryBuilderRefactorEnable = (): boolean =>
	isFeatureFlagEnabled(FeatureFlags.ENABLE_QUERY_BUILDER_REFACTOR);

export {
	isCalculateKnownContactEnabled,
	isCDPPersonalizationEnabled,
	isEIFInboundEnabled,
	isBuyerJourneyEnabled,
	isBuyerJourneyAnalyticsEnabled,
	isBuyerJourneySubstageEnabled,
	isEntityMatchEnabled,
	isHorizontalNavigationEnabled,
	isTimeSeriesSegmentEnabled,
	isSegmentationV2Enabled,
	isSSVIModule,
	isSSVIFlag,
	isSubscriptionPageAnalyticsEnabled,
	isNewWebInsightsEnabled,
	isSalesforceMarketingCloudEnabled,
	isGenericDSPEnabled,
	isWebInsightsCreateSegmentEnabled,
	isEnableQueryBuilderRedesign,
	isG2ConnectionEnabled,
	isActivationMicrosoftDynamicsEnabled,
	isImportMicrosoftDynamicsEnabled,
	isActivationHubSpotEnabled,
	isActivationSalesLoftEnabled,
	isModelingTrainingDataSegmentEnabled,
	isImportSFMCTrayEnabled,
	isImportConnectionSFMCEnabled,
	isSavingAttributesEnabled,
	isAC360NewDesignEnabled,
	isAITalkingPointsEnabled,
	isEDSPBrandSafetySegmentEnabled,
	isHubSpotConnectionEnabled,
	isQueryBuilderRefactorEnable,
};
