import React from 'common/react-vendor';
import {DNBTypography} from 'common/dnb-uux-vendor';
import {isEmpty} from 'lodash';
import {
	GetOperationValueReturn,
	getOperationLabel,
	getOperationValue,
	showTo,
	showType,
} from '../tree.helpers';
import {Restriction} from '../../../query.types';
import {BucketType, TreeType} from '../../../query.enums';
import {EnumAttribute} from '../enum-attribute/EnumAttribute';
import {segmentRelationOptions} from '../tree.constants';
import {getBucketLabel} from '../../AdvancedQuery.helper';
import {DateAttribute} from '../date-attribute/DateAttribute/DateAttribute';
import {IQueryBlockType} from '../../queryBlock/queryBlockTypes';
import {TransactionItem} from '../transaction/TransactionItem';
import {PercentItem} from '../percent/item/PercentItem';

const QueryTreeItem = ({
	tree,
	type,
	memberType,
}: {
	tree: Restriction;
	type: string | undefined;
	memberType: string | undefined;
}): React.ReactElement => {
	const showSegment = tree.segmentMemberRestriction;
	const showItem = (typeToShow: string): boolean => {
		if (tree.segmentMemberRestriction) {
			return type === typeToShow;
		}
		return tree.bucketRestriction
			? showType({
					bucketRestriction: tree.bucketRestriction,
					type: type as BucketType & TreeType,
					typeToShow: typeToShow as BucketType,
			  })
			: false;
	};
	const getOperation = (
		type: string,
		pos?: number
	): GetOperationValueReturn => {
		const val = tree.bucketRestriction
			? getOperationValue({
					bucketRestriction: tree.bucketRestriction,
					type: type as BucketType & TreeType,
					index: pos,
			  })
			: 'Unknown';
		return type === 'String' && typeof val === 'string' ? [val] : val;
	};
	const chipsOperations = [
		'EQUAL',
		'IN_COLLECTION',
		'NOT_EQUAL',
		'NOT_IN_COLLECTION',
		'CONTAINS',
		'NOT_CONTAINS',
	];
	const isNumericalChips = (): boolean => {
		if (!tree.bucketRestriction?.bkt?.Cmp) return false;
		return chipsOperations.indexOf(tree.bucketRestriction?.bkt?.Cmp) > -1;
	};
	const getSuffixLabel = (): string => {
		return tree.bucketRestriction && showTo(tree.bucketRestriction)
			? ' -'
			: ',';
	};
	const getQuerySectionItemValue = (type?: string): React.ReactElement => {
		let ret = <></>;
		if (showItem('String') && !isEmpty(getOperation('String'))) {
			ret = (
				<EnumAttribute
					id={getBucketLabel(tree).toLocaleString()}
					values={getOperation('String') as string[]}
					suffix=','
				/>
			);
		}
		if (showItem('Boolean')) {
			ret = <strong>{getOperation('Boolean')?.toLocaleString()}</strong>;
		}
		if (showItem('Date')) {
			ret = (
				<div className='dateAttribute'>
					<DateAttribute
						bucketRestriction={tree.bucketRestriction}
						type={type as BucketType & TreeType}
					/>
				</div>
			);
		}
		if (showItem('Numerical')) {
			ret = isNumericalChips() ? (
				<EnumAttribute
					id={getBucketLabel(tree).toLocaleString()}
					values={getOperation('Enum') as string[]}
					suffix={getSuffixLabel()}
				/>
			) : (
				<span>
					<strong>{getOperation('Numerical', 0)}</strong>
					{tree.bucketRestriction && showTo(tree.bucketRestriction) && (
						<>
							<span className='operationAnd'>-</span>
							<strong>{getOperation('Numerical', 1)}</strong>
						</>
					)}
				</span>
			);
		}
		if (showItem('Enum') && !isEmpty(getOperation('String'))) {
			ret = (
				<EnumAttribute
					id={getBucketLabel(tree).toLocaleString()}
					values={getOperation('Enum') as string[]}
					suffix={getSuffixLabel()}
				/>
			);
		}
		if (showItem('Transaction')) {
			ret = (
				<div className='transactionAttribute'>
					<DNBTypography
						component='span'
						variant='compact-bold'
						className='transactionLable'>
						{ret}
					</DNBTypography>
					<TransactionItem
						purchased={!!getOperation('Boolean')}
						type={type as TreeType}
						bucketRestriction={tree.bucketRestriction}
					/>
				</div>
			);
		}
		return ret;
	};
	const getRelationLable = (): string => {
		const relation =
			tree?.segmentMemberRestriction?.relation === 'INCLUDE'
				? 'INCLUDE'
				: 'EXCLUDE';
		const relation_options = segmentRelationOptions;
		return relation_options[relation];
	};
	return (
		<>
			{!showSegment && !showItem('Percent') && (
				<span className='querySectionItemOperation'>
					<DNBTypography
						component='span'
						variant='compact-body'
						className='operationLabel'>
						<span>
							{getOperationLabel({
								type: type as BucketType & TreeType,
								bucketRestriction: tree.bucketRestriction!,
							})}
						</span>
					</DNBTypography>
					<DNBTypography
						component='span'
						variant='compact-bold'
						className='querySectionItemValue'>
						{getQuerySectionItemValue(type)}
					</DNBTypography>
				</span>
			)}
			{showSegment && (
				<span className='querySectionItemOperation'>
					<DNBTypography
						component='span'
						variant='compact-body'
						className='querySectionItemValue'>
						{getRelationLable()}
					</DNBTypography>
					{memberType === IQueryBlockType.Union && (
						<DNBTypography
							component='span'
							variant='compact-body'
							className='querySectionItemValue'>
							only
						</DNBTypography>
					)}
					<DNBTypography
						component='span'
						variant='compact-body'
						className='querySectionItemValue'>
						records in
					</DNBTypography>
				</span>
			)}
			{showItem('Percent') && tree?.bucketRestriction && (
				<span className='querySectionItemOperation'>
					<DNBTypography
						component='span'
						variant='compact-bold'
						className='querySectionItemValue'>
						<PercentItem bucketRestriction={tree.bucketRestriction} />
					</DNBTypography>
				</span>
			)}
		</>
	);
};

export {QueryTreeItem};
