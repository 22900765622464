import React from 'common/react-vendor';
import {
	DNBButton,
	DNBDivider,
	DNBSelect,
	DNBSelectOption,
	DNBTextField,
	DNBTypography,
	ViewWeekIcon,
} from 'common/dnb-uux-vendor';
import classNames from 'classnames';
import {isRulesBasedModelMode} from 'common/stores/query';
import {useSelector} from 'react-redux';
import {QueryState} from 'common/stores/query/types';
import {IChip} from 'common/components/chips/ChipsTypes';
import {ChipsComponent} from 'common/components/chips/ChipsComponent';
import {showType} from '../tree.helpers';
import {BucketCmp, BucketType, TreeType} from '../../../query.enums';
import {
	booleanOperation,
	booleanOperationTransaction,
	segmentRelationOptions,
	stringOperations,
} from '../tree.constants';
import {getCategoryMetadata, showSubCategory} from '../QueryTree.helpers';
import {
	callbackChangedNumericalValue,
	changeBooleanValue,
	changeCmpValue,
	changePreset,
	changeRelationValue,
	clickEditMode,
	clickSet,
	clickUnset,
	getCubeBktList,
	initVariables,
	isValid,
	isValidatedNumber,
	showChips,
	showChipsNoList,
	showInput,
	showItem,
	showNumericRange,
	updateChips,
} from '../item/QueryTreeItem.helpers';
import {
	IQueryTreeItemEdit,
	QueryTreeItemEditContextProvider,
} from '../context/QueryTreeItemEditContext';
import {useQueryTreeItemEdit} from '../hook/useQueryTreeItemEdit';
import {NumericalRangeWithAngularProps} from './NumericalRange/NumericalRange';
import {BucketRestriction, InputChangeData} from '../../../query.types';
import {useQueryTree} from '../hook/useQueryTree';
import {TransactionEditItem} from '../transaction/TransactionEditItem/TransactionEditItem';
import {DateAttributeEditWithAngularProps} from '../date-attribute/DateAttributeEdit/DateAttributeEdit';
import {useAdvancedQuery} from '../../hook/useAdvancedQuery';
import {IQueryBlockType} from '../../queryBlock/queryBlockTypes';
import {Cube} from '../tree.types';
import {PercentEdit} from '../percent/edit/PercentEdit';

const RenderStringSelect = ({
	callBack,
}: {
	callBack: () => void;
}): React.ReactElement => {
	const {data, setData} = useQueryTreeItemEdit();
	const {operation, root} = data.current;
	const onChange = (value: BucketCmp | null): void => {
		if (value !== null) {
			setData('operation', value);
			changeCmpValue(data, setData, callBack);
		}
	};
	return (
		<DNBSelect<BucketCmp>
			value={operation}
			size='compact'
			disabled={!root.canEdit}
			onChange={(_, value) => onChange(value)}>
			{Object.entries(stringOperations).map(([key, val]) => (
				<DNBSelectOption key={key} value={key}>
					{val}
				</DNBSelectOption>
			))}
		</DNBSelect>
	);
};

const RenderBooleanSelect = ({
	callBack,
}: {
	callBack: () => void;
}): React.ReactElement => {
	const {data, setData} = useQueryTreeItemEdit();
	const {booleanValue, root, tree, type} = data.current;
	const onChange = (value: string | null): void => {
		if (value !== null) {
			changeBooleanValue(value, data, setData);
			callBack && callBack();
		}
	};
	const operation = showItem(tree, type, 'Transaction')
		? booleanOperationTransaction
		: booleanOperation;
	return (
		<DNBSelect<string>
			value={booleanValue}
			size='compact'
			disabled={!root.canEdit}
			onChange={(_, value) => onChange(value)}>
			{Object.entries(operation).map(([key, val]) => (
				<DNBSelectOption key={key} value={val}>
					{key}
				</DNBSelectOption>
			))}
		</DNBSelect>
	);
};

const RenderNumericalSelect = ({
	callBack,
}: {
	callBack: () => void;
}): React.ReactElement => {
	const {data, setData} = useQueryTreeItemEdit();
	const {context: queryTree} = useQueryTree();
	const {numerical_operations} = queryTree;
	const {operation, root} = data.current;
	const onChange = (value: BucketCmp | null): void => {
		if (value !== null) {
			setData('operation', value);
			changeCmpValue(data, setData, callBack);
			callBack && callBack();
		}
	};
	return (
		<DNBSelect<BucketCmp>
			value={operation}
			size='compact'
			disabled={!root.canEdit}
			onChange={(_, value) => onChange(value)}>
			{Object.entries(numerical_operations).map(([key, val]) => (
				<DNBSelectOption key={key} value={key}>
					{val}
				</DNBSelectOption>
			))}
		</DNBSelect>
	);
};

const RenderCubeBktListSelect = ({
	callBack,
}: {
	callBack: () => void;
}): React.ReactElement => {
	const {data, setData} = useQueryTreeItemEdit();
	const {context: queryTree} = useQueryTree();
	const {context: advancedQuery} = useAdvancedQuery();
	const {item} = queryTree;
	const {root, presetOperation} = data.current;
	const onChange = (value: string | number | null): void => {
		if (value !== null) {
			setData('presetOperation', value);
			changePreset(advancedQuery, data, setData);
			callBack && callBack();
		}
	};
	return (
		<DNBSelect<string | number>
			value={presetOperation}
			size='compact'
			disabled={!root.canEdit}
			onChange={(_, value) => onChange(value)}>
			{getCubeBktList(item?.Stats as Cube).map((bucket) => (
				<DNBSelectOption key={bucket?.Lbl} value={bucket?.Lbl}>
					{bucket?.Lbl}
				</DNBSelectOption>
			))}
		</DNBSelect>
	);
};

const RenderRelationSelect = ({
	callBack,
}: {
	callBack: () => void;
}): React.ReactElement => {
	const {data, setData} = useQueryTreeItemEdit();
	const {relation, root} = data.current;
	const onChange = (value: string | null): void => {
		if (value !== null) {
			setData('relation', value);
			changeRelationValue(value, data, setData);
			callBack && callBack();
		}
	};
	return (
		<DNBSelect<string>
			value={relation}
			size='compact'
			disabled={!root.canEdit}
			onChange={(_, value) => onChange(value)}>
			{Object.entries(segmentRelationOptions)?.map(([key, val]) => (
				<DNBSelectOption key={val} value={key}>
					{val}
				</DNBSelectOption>
			))}
		</DNBSelect>
	);
};

const QueryTreeItemEdit = ({
	tree: initTree,
	root,
	item,
	type,
	memberType,
}: IQueryTreeItemEdit): React.ReactElement => {
	const {data, setData} = useQueryTreeItemEdit();
	const [_refresh, setRefresh] = React.useState(1);
	const callBack = React.useCallback(
		(refresh = 0): void => {
			// This part is for Treeitem data update and needs to be re-rendered
			setRefresh(_refresh + 1 + refresh);
		},
		[_refresh, setRefresh]
	);
	React.useEffect(() => {
		initVariables(data, setData, callBack);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const {
		loading,
		isActivity,
		vals,
		stringPlaceholder,
		buckets,
		rangeConfig,
		showFromNumerical,
		showToNumerical,
		operation,
		header,
		editMode,
		booleanValue,
		booleanChanged,
		relation,
		DisplayName,
		tree = initTree,
	} = data.current;
	const {context: queryTree, setContext: setQueryTree} = useQueryTree();
	const {context: value, setContext: action} = useAdvancedQuery();
	const {records_updating} = queryTree;
	const disableAllTreeRestrictions = useSelector(
		(state: {query: QueryState}) =>
			state?.query?.public.disableAllTreeRestrictions
	);
	const isSegment = type === 'Member';
	const count = isSegment
		? tree.segmentMemberRestriction?.bkt?.Cnt
		: tree?.bucketRestriction?.bkt?.Cnt;
	const showItem = React.useCallback(
		(typeToShow: string): boolean => {
			if (tree.segmentMemberRestriction) {
				return type === typeToShow;
			}
			return tree.bucketRestriction
				? showType({
						bucketRestriction: tree.bucketRestriction,
						type: type as BucketType & TreeType,
						typeToShow: typeToShow as BucketType,
				  })
				: false;
		},
		[tree.bucketRestriction, tree.segmentMemberRestriction, type]
	);
	const changeValue = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
			setData('vals', [e.target.value]);
			callBack();
			e.preventDefault();
		},
		[callBack, setData]
	);

	const RenderStringItem = React.useMemo((): React.ReactElement => {
		return (
			<span className='querySectionItemOperation'>
				<RenderStringSelect callBack={callBack} />
				{showInput(operation) && !showChips(data) && !showChipsNoList(data) && (
					<DNBTextField
						size='compact'
						value={vals}
						onChange={changeValue}
						required
					/>
				)}
				{showChips(data) && (
					<ChipsComponent
						placeholder={stringPlaceholder}
						initialvalue={vals as IChip[]}
						datasource={buckets}
						callback={(chips) => updateChips(chips, data, setData, callBack)}
						displayname='Lbl'
						icon='search'
						showicon={true}
						singleSelection={false}
						allowcustomvalues={true}
						dontrandomizeid={true}
						context={data.current}
						updatedContext={setData}
						inputdelimiter=','
						usepagination={true}
						showloading={loading && isActivity && showChips(data)}
					/>
				)}
				{showChipsNoList(data) && (
					<ChipsComponent
						placeholder='Add new value'
						initialvalue={vals as IChip[]}
						datasource={[]}
						callback={(chips) => updateChips(chips, data, setData, callBack)}
						displayname='Lbl'
						icon='plus'
						showicon={true}
						singleSelection={false}
						allowcustomvalues={true}
						dontrandomizeid={true}
						context={data.current}
						updatedContext={setData}
						inputdelimiter=','
						usepagination={true}
						chipsposition='bottom'
					/>
				)}
			</span>
		);
	}, [
		buckets,
		callBack,
		changeValue,
		data,
		loading,
		operation,
		setData,
		vals,
		isActivity,
		stringPlaceholder,
	]);
	const RenderEnumItem = React.useMemo((): React.ReactElement => {
		return (
			<span className='querySectionItemOperation'>
				<RenderStringSelect callBack={callBack} />
				{showInput(operation) && !showChips(data) && (
					<DNBTextField
						size='compact'
						value={vals}
						onChange={changeValue}
						required
					/>
				)}
				{showNumericRange(operation, setData) && (
					<NumericalRangeWithAngularProps
						config={rangeConfig}
						showfrom={showFromNumerical}
						showto={showToNumerical}
						bucketrestriction={tree.bucketRestriction}
						changed={({index, value}: InputChangeData) =>
							callbackChangedNumericalValue({
								index,
								value,
								data,
								setData,
								callBack,
							})
						}
					/>
				)}
				{showChips(data) && (
					<ChipsComponent
						placeholder='Search for attributes'
						initialvalue={vals as IChip[]}
						datasource={buckets}
						callback={(chips) => updateChips(chips, data, setData, callBack)}
						displayname='Lbl'
						icon='search'
						chipsposition='bottom'
						showicon={true}
						singleSelection={false}
						allowcustomvalues={true}
						dontrandomizeid={true}
						context={data.current}
						updatedContext={setData}
						inputdelimiter=','
						usepagination={true}
						showloading={loading}
						header={header}
						sortid='Cnt'
					/>
				)}
				{showChipsNoList(data) && (
					<ChipsComponent
						placeholder='Add new value'
						initialvalue={vals as IChip[]}
						datasource={[]}
						callback={(chips) => updateChips(chips, data, setData, callBack)}
						displayname='Lbl'
						icon='plus'
						showicon={true}
						singleSelection={false}
						allowcustomvalues={true}
						dontrandomizeid={true}
						context={data.current}
						updatedContext={setData}
						inputdelimiter=','
						usepagination={true}
						chipsposition='bottom'
					/>
				)}
			</span>
		);
	}, [
		buckets,
		callBack,
		changeValue,
		data,
		header,
		loading,
		operation,
		rangeConfig,
		setData,
		showFromNumerical,
		showToNumerical,
		tree.bucketRestriction,
		vals,
	]);
	const RenderNumbericalItem = React.useMemo((): React.ReactElement => {
		return (
			<span className='querySectionItemOperation'>
				<div className='numEditRow'>
					{!showItem('Percent') && (
						<div
							className='buttonGroup'
							onClick={() =>
								clickEditMode(
									editMode === 'Custom' ? 'Preset' : 'Custom',
									value,
									data,
									setData,
									callBack
								)
							}>
							{editMode === 'Custom' && (
								<DNBButton
									size='compact'
									variant='secondary'
									sx={{textDecoration: 'none'}}
									endIcon={<ViewWeekIcon />}>
									Custom
								</DNBButton>
							)}
							{editMode === 'Preset' && (
								<DNBButton
									size='compact'
									variant='secondary'
									sx={{textDecoration: 'none'}}
									startIcon={<ViewWeekIcon />}>
									Preset
								</DNBButton>
							)}
						</div>
					)}
					{editMode === 'Custom' && (
						<span>
							<RenderNumericalSelect callBack={callBack} />
						</span>
					)}
					{editMode === 'Custom' && (showFromNumerical || showToNumerical) && (
						<NumericalRangeWithAngularProps
							config={rangeConfig}
							showfrom={showFromNumerical}
							showto={showToNumerical}
							bucketrestriction={tree.bucketRestriction}
							showmessage={true}
							changed={({index, value}: InputChangeData) =>
								callbackChangedNumericalValue({
									index,
									value,
									data,
									setData,
									callBack,
								})
							}
						/>
					)}
					{(showChips(data) || showChipsNoList(data)) && (
						<ChipsComponent
							placeholder='Add new value'
							initialvalue={vals as IChip[]}
							datasource={[]}
							callback={(chips) => updateChips(chips, data, setData, callBack)}
							displayname='Lbl'
							icon='plus'
							showicon={true}
							singleSelection={false}
							allowcustomvalues={true}
							dontrandomizeid={true}
							context={data.current}
							updatedContext={setData}
							inputdelimiter=','
							usepagination={true}
						/>
					)}
					{editMode === 'Preset' && (
						<span>
							<RenderCubeBktListSelect callBack={callBack} />
						</span>
					)}
				</div>
			</span>
		);
	}, [
		editMode,
		showItem,
		value,
		callBack,
		data,
		rangeConfig,
		setData,
		showFromNumerical,
		showToNumerical,
		tree.bucketRestriction,
		vals,
	]);
	const handleBucketRestrictionChange = (
		newBucketRestriction: BucketRestriction
	): void => {
		setData('tree', {
			...tree,
			bucketRestriction: newBucketRestriction,
		});
		callBack();
	};
	return (
		<form>
			<div className='le-grid editing-container'>
				<div className='le-row'>
					<div className='le-col-span-10'>
						<div className='querySectionItemCategory'>
							<DNBTypography component='div' variant='compact-body'>
								{getCategoryMetadata(item?.Category || '', root)?.displayName ||
									item?.Category}
								{showSubCategory(tree, item) ? ` - ${item?.Subcategory}` : ''}
							</DNBTypography>
							<div
								className={classNames({
									flexContainer: true,
									model: isRulesBasedModelMode(),
								})}>
								<DNBTypography
									component='span'
									variant='compact-bold'
									className='querySectionItemName'
									title={item?.DisplayName}>
									{item?.DisplayName}
								</DNBTypography>
								{showItem('String') &&
									!showItem('Percent') &&
									!showItem('Transaction') &&
									RenderStringItem}
								{showItem('Enum') && !showItem('Percent') && RenderEnumItem}
								{showItem('Boolean') && !showItem('Percent') && (
									<span className='querySectionItemOperation'>
										<div className='booleanItem'>
											{showItem('Boolean') && (
												<DNBTypography
													component='span'
													variant='compact-bold'
													className='querySectionItemValue'>
													is
												</DNBTypography>
											)}
											<RenderBooleanSelect callBack={callBack} />
										</div>
									</span>
								)}
								{showItem('Numerical') &&
									!showItem('Percent') &&
									RenderNumbericalItem}
								{showItem('Transaction') && !showItem('Percent') && (
									<TransactionEditItem
										bucketRestriction={tree.bucketRestriction}
										type={type as TreeType}
										purchased={booleanValue}
										booleanChanged={booleanChanged}
										onBucketRestrictionChange={handleBucketRestrictionChange}
										onNumericalRangeValidation={(isValid: boolean) =>
											setData('isValid', isValid)
										}
										setBooleanChanged={(newBooleanChanged: boolean) =>
											setData('newBooleanChanged', newBooleanChanged)
										}
									/>
								)}
								{showItem('Percent') && tree?.bucketRestriction && (
									<span className='querySectionItemOperation'>
										<PercentEdit
											bucketRestriction={tree.bucketRestriction}
											onBucketRestrictionChange={handleBucketRestrictionChange}
										/>
									</span>
								)}
								{showItem('Date') && (
									<span className='querySectionItemOperation'>
										<DateAttributeEditWithAngularProps
											bucketrestriction={tree.bucketRestriction}
											type={type}
											onnumericalrangevalidation={(isValid: boolean) =>
												setData('isValid', isValid)
											}
											onbucketrestrictionchange={handleBucketRestrictionChange}
										/>
									</span>
								)}
								{showItem('Member') && (
									<span className='querySectionItemOperation Member'>
										{memberType !== IQueryBlockType.Union && (
											<RenderRelationSelect callBack={callBack} />
										)}
										{memberType === IQueryBlockType.Union && (
											<DNBTypography
												component='span'
												variant='compact-body'
												className='querySectionItemValue'>
												{relation?.toLowerCase()} only
											</DNBTypography>
										)}
										<DNBTypography
											component='span'
											variant='compact-body'
											className='querySectionItemValue'>
											records in
										</DNBTypography>
										<DNBTypography
											component='span'
											variant='compact-bold'
											className='querySectionItemValue'
											title={DisplayName}>
											{DisplayName}
										</DNBTypography>
									</span>
								)}
							</div>
						</div>
					</div>
					<div className='le-col-span-2'>
						<div className='flexContainerController'>
							{(tree?.segmentMemberRestriction ||
								!tree?.bucketRestriction?.entityType) && (
								<>
									<DNBDivider
										sx={{
											borderWidth: '1px',
											borderRadius: '4px',
											height: '40px',
										}}
										className='queryBuilderListHr'
										orientation='horizontal'
										variant='lightIncidental'
									/>
									<div
										className={classNames({
											querySectionItemRecords: true,
											model: isRulesBasedModelMode(),
										})}>
										<span className='querySectionItemRecordsNumber'>
											<DNBTypography
												component='div'
												className='querySectionItemRecordsLabel'
												sx={{
													color: (theme) => theme.colors.ColorGraySecondary,
												}}
												variant='body'>
												Records
											</DNBTypography>
											<div
												className={classNames({
													'expanding-bars-init': true,
													'expanding-bars-spinner':
														records_updating ||
														count === -1 ||
														disableAllTreeRestrictions,
												})}>
												<DNBTypography component='div' variant='compact-body'>
													{count?.toLocaleString('en-US')}
												</DNBTypography>
											</div>
										</span>
									</div>
									<DNBDivider
										sx={{
											borderWidth: '1px',
											borderRadius: '4px',
											height: '40px',
										}}
										className='queryBuilderListHr'
										orientation='horizontal'
										variant='lightIncidental'
									/>
								</>
							)}
							<div>
								<div>
									<DNBButton
										className={classNames({
											'button blue-button set': true,
											'disabled': !isValid(data),
											'model': isRulesBasedModelMode(),
										})}
										onClick={(e) =>
											clickSet(
												e,
												data,
												setData,
												queryTree,
												setQueryTree,
												value,
												action,
												callBack
											)
										}
										disabled={!isValid(data)}>
										Done
									</DNBButton>
								</div>
								{isRulesBasedModelMode() && (
									<div>
										<DNBButton
											className='button gray-button unset model'
											onClick={(e) =>
												clickUnset(
													e,
													data,
													queryTree,
													setQueryTree,
													value,
													action
												)
											}
											disabled={!isValid(data)}>
											UNSET
										</DNBButton>
									</div>
								)}
								{!isValidatedNumber(data) && (
									<span className='errorMessage'>Not valid number!</span>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

const QueryTreeItemEditComponent = (
	props: IQueryTreeItemEdit
): React.ReactElement => {
	return (
		<QueryTreeItemEditContextProvider scope={props || {}}>
			<QueryTreeItemEdit {...props} />
		</QueryTreeItemEditContextProvider>
	);
};

export {QueryTreeItemEdit, QueryTreeItemEditComponent};
