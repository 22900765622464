import React from 'react';
import {styled} from '@mui/material';
import {
	DNBCheckbox,
	DNBTextField,
	DNBTypography,
	InputAdornment,
	SearchOutlinedIcon,
} from 'common/dnb-uux-vendor';
import {StyledDNBAccordion} from './FilterAccordion.styled';
import {FilterAccordionProps} from './FilterAccordion.types';
import {FilterRadioAccordion} from './FilterRadioAccordion';

const StyledUL = styled('ul')`
	padding: 0 ${({theme}) => theme.spacing(3)} 0 ${({theme}) => theme.spacing(4)};
`;

const StyledLi = styled('li')`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const StyledFiltersCountDNBTypography = styled(DNBTypography)`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: ${({theme}) => theme.borders.BorderRadiusFull};
	width: ${({theme}) => theme.sizing.SizingLarge};
	height: ${({theme}) => theme.sizing.SizingLarge};
	color: ${({theme}) => theme.colors.ColorPrimaryBlue};
	background-color: ${({theme}) => theme.colors.ColorPrimaryBlueAlpha1};
`;

interface FilterAccordionCheckboxOption {
	label: React.ReactNode;
	value: string;
	title?: string;
	count?: number;
	hideCount?: boolean;
	searchField?: string;
}

interface FilterAccordionCheckboxItem {
	id: string;
	name: string;
	options: FilterAccordionCheckboxOption[];
	selectedFilters: string[];
	onFilterChange(checked: boolean, value: string): void;
	onSelectAllChange?(checked: boolean): void;
	hasSearchField?: boolean;
	radioGroups?: boolean;
	radioCheckedVal?: string;
	onRadioFilterChange?(value: string): void;
}

type FilterCheckboxAccordionProps = FilterAccordionProps &
	FilterAccordionCheckboxItem;

const FilterCheckboxAccordion = ({
	id,
	name,
	options,
	selectedFilters,
	onFilterChange,
	onSelectAllChange,
	expanded,
	onExpandedChange,
	hasSearchField,
	radioGroups,
	radioCheckedVal,
	onRadioFilterChange,
}: FilterCheckboxAccordionProps): React.ReactElement => {
	const optionsLength = options.length;

	const selectedFiltersLength = radioGroups ? 1 : selectedFilters.length;

	const accordionId = `${id}-${name}-filter-accordion`;

	const [search, setSearch] = React.useState('');

	return (
		<StyledDNBAccordion
			id={accordionId}
			data-test-id={accordionId}
			primaryTitle={
				<>
					{onSelectAllChange ? (
						<DNBCheckbox
							data-test-id={`${id}-${name}-primary-checkbox`}
							checked={selectedFiltersLength === optionsLength}
							indeterminate={
								selectedFiltersLength > 0 &&
								selectedFiltersLength < optionsLength
							}
							onChange={(_, checked) => onSelectAllChange(checked)}
							size='compact'
							label={name}
							labelProps={{
								onClick: (event) => event.stopPropagation(), // Stop propagation to prevent collapsing the accordion.
							}}
						/>
					) : (
						<DNBTypography variant='compact-body'>{name}</DNBTypography>
					)}

					{!expanded && selectedFiltersLength > 0 && (
						<StyledFiltersCountDNBTypography component='span' variant='caption'>
							{selectedFiltersLength}
						</StyledFiltersCountDNBTypography>
					)}
				</>
			}
			expanded={expanded}
			onChange={(_, newState) => onExpandedChange(newState)}>
			{hasSearchField && (
				<DNBTextField
					size='compact'
					placeholder='Search'
					sx={{width: '240px', mb: 2, pl: 6}}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<SearchOutlinedIcon />
							</InputAdornment>
						),
					}}
					value={search}
					onChange={(ev) => setSearch(ev.target.value)}
				/>
			)}
			{radioGroups ? (
				<FilterRadioAccordion
					options={options}
					onFilterChange={onFilterChange}
					radioCheckedVal={radioCheckedVal}
					onRadioFilterChange={onRadioFilterChange}
				/>
			) : (
				<StyledUL aria-label={name}>
					{options
						?.filter(
							({searchField}) =>
								searchField === undefined ||
								searchField.toLowerCase().includes(search.toLowerCase())
						)
						.map(({label, title, value, count = 0, hideCount}) => {
							return (
								<StyledLi key={value}>
									<DNBCheckbox
										data-test-id={`${id}-${name}-${value}-checkbox`}
										checked={selectedFilters?.includes(value)}
										onChange={(_, checked) => onFilterChange(checked, value)}
										label={label}
										containerProps={{
											flex: 1,
											width: 0,
										}}
										labelProps={{
											title,
											sx: {width: '100%'},
											componentsProps: {
												typography: {
													noWrap: true,
												},
											},
										}}
										size='compact'
									/>
									{!hideCount && (
										<DNBTypography
											color={(theme) => theme.colors.ColorGraySecondary}
											component='span'
											variant='compact-body'>
											{count}
										</DNBTypography>
									)}
								</StyledLi>
							);
						})}
				</StyledUL>
			)}
		</StyledDNBAccordion>
	);
};

export {FilterCheckboxAccordion};
export type {FilterAccordionCheckboxItem, FilterAccordionCheckboxOption};
