import {Bkt} from 'common/components/datacloud/query/query.types';
import {AttributeEntityType} from './AttributeConst';

interface ITopAttribute {
	Entity: AttributeEntityType;
	Attribute: string;
	Count: number;
	TopBkt: Bkt;
}

/**
 * Sub category name to attribute top info map.
 */
type ISubcategoryDict = Record<string, ITopAttribute[]>;

/**
 * Filter option interface
 * @param DisplayName Filter display name eg, "DisplayName": "1 week till today"
 * @param value Filter value eg, "Value": "wi_1_w"
 */
interface IFilterOption {
	DisplayName: string;
	value: string;
}

interface IFilterOptions {
	Label: string;
	Options: IFilterOption[];
}

/**
 * Sub category config interface
 * @param ShowSubCategory True to show sub category
 * @param AltName Alternative display name
 * @param FilterOptions Refers to IFilterOptions
 * @param Subcategories Sub category list
 */
interface ISubCategoryConfig {
	ShowSubCategory: boolean;
	AltName: string;
	FilterOptions: IFilterOptions;
	Subcategories?: ISubcategoryDict;
}

/**
 * Category type to sub category config map.
 * Refers to API pls/datacollection/statistics/topn?topbkt=true
 */
type ICategoryDict = Record<CategoryType, ISubCategoryConfig>;

/**
 * Category type from account and contact attributes.
 * Sync with Category.java of LEDP.
 * Refers to API pls/attributecategory/categories
 */
enum CategoryType {
	RATING = 'Lattice Ratings',
	FIRMOGRAPHICS = 'Firmographics',
	ACCOUNT_ATTRIBUTES = 'My Attributes',
	CURATED_ACCOUNT_ATTRIBUTES = 'Curated Account Attributes',
	CONTACT_ATTRIBUTES = 'Contact Attributes',
	CURATED_CONTACT_ATTRIBUTES = 'Curated Contact Attributes',
	PRODUCT_SPEND = 'Product Spend Profile',
	INTENT = 'Intent',
	WEBSITE_PROFILE = 'Website Profile',
	TECHNOLOGY_PROFILE = 'HG Technology Profile',
	ONLINE_PRESENCE = 'Online Presence',
	GROWTH_TRENDS = 'Growth Trends',
	WEBSITE_KEYWORDS = 'Website Keywords',
	ACCOUNT_INFORMATION = 'Account Information',
	LEAD_INFORMATION = 'Lead Information',
	DEFAULT = 'Default',
	AI_INSIGHTS = 'AI Insights',
	WEB_VISIT_PROFILE = 'My Website Visits',
	OPPORTUNITY_PROFILE = 'My Opportunities',
	ACCOUNT_MARKETING_ACTIVITY_PROFILE = 'My Account Marketing Activity',
	CONTACT_MARKETING_ACTIVITY_PROFILE = 'My Contact Marketing Activity',
	DNBINTENTDATA_PROFILE = 'D&B Buyer Intent',
	COVID_19 = 'COVID-19',
	DNB_TECHNOLOGY_PROFILE = 'D&B Technology Profile',
	ACCOUNT_SALES_ACTIVITY_PROFILE = 'My Account Sales Activity',
	CONTACT_SALES_ACTIVITY_PROFILE = 'My Contact Sales Activity',
	ACCOUNT_CAMPAIGN_LAUNCH_HISTORY = 'ACCOUNT_CAMPAIGN_LAUNCH_HISTORY',
	CONTACT_CAMPAIGN_LAUNCH_HISTORY = 'CONTACT_CAMPAIGN_LAUNCH_HISTORY',
	CONTACT_WEB_VISIT_PROFILE = 'My Contact Website Visits',
}

/**
 * Category metadata for account / contact attributes.
 * @param accountMasterCategory Account master category
 * @param categoryEnumName Category enum key refers to CategoryType
 * @param defaultMaximumAttributes Default maximum attributes count
 * @param displayName Category display name
 * @param iconUri Icon uri
 * @param isPremium True on premium attribute
 * @param isVisible True on visible
 * @param legacyDisplayName Legacy display name
 * @param templateID Template ID
 * @param themeColor Color
 * @param topBucketDisplayStrategy Top bucket display strategy
 * @param uIOrderAttrAdminPremiumAttribute Order for attribute admin
 * @param uIOrderMyData Order for my data
 */
interface ICategoryMeta {
	accountMasterCategory: string;
	categoryEnumName: string;
	defaultMaximumAttributes: number;
	displayName: string;
	iconUri: string;
	isPremium: boolean;
	isVisible: boolean;
	legacyDisplayName: string;
	templateID: number;
	themeColor: string;
	topBucketDisplayStrategy: string;
	uIOrderAttrAdminPremiumAttribute: number;
	uIOrderMyData: number;
}

type ICategoryMetaMap = ICategoryMeta[];

const OtherCategory = 'Other';

export type {ICategoryDict, ICategoryMeta, ICategoryMetaMap};
export {CategoryType, OtherCategory};
