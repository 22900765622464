import {addCustomValue, getEmptyMatchingItem} from './ChipsController';
import {IChips, IChipsComponent} from './ChipsTypes';

interface IChipsInit {
	initChips: IChips;
}

const ChipsInit = (scope: IChipsComponent): IChipsInit => {
	const {id, displayname, datasource, allowcustomvalues, initialvalue} = scope;
	const key = id || displayname;
	let initChips = new Map();

	if (Array.isArray(initialvalue)) {
		if (allowcustomvalues && typeof initialvalue[0] === 'string') {
			initialvalue.forEach((value) => {
				if (value) initChips.set(value as string, {[key]: value});
			});
		} else {
			initialvalue.forEach((value) => {
				const item = datasource.find((data) => data[key] === value);
				const newKey = item?.[key]?.toString();
				if (newKey)
					initChips.set(newKey, item || getEmptyMatchingItem(key, value, true));
			});
		}
	} else {
		const segmentName = initialvalue.name;
		initChips = {
			...initChips,
			...addCustomValue(key, segmentName),
		};
	}
	return {initChips: Object.fromEntries(initChips)};
};

export {ChipsInit};
export type {IChipsInit};
