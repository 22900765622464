import {CategoryMetadata} from 'common/components/datacloud/datacloud.service.vanilla';
import {
	Restrictions,
	LogicalRestrictionRecord,
	Bkt,
} from 'common/components/datacloud/query/query.types';
import {Cube} from 'common/components/datacloud/query/advanced/tree/tree.types';
import {
	CounterMap,
	IAttributeValues,
} from 'atlas/modelsOld/modals/AddAttributeModal/Helper/CounterMap';
import {IAttribute} from 'atlas/data/AttributeConst';
import {ICategoryDict} from 'atlas/data/CategoryConst';
import {IEntityMapping} from 'atlas/data/EntityConst';
import {Entity} from 'common/components/datacloud/query/query.enums';
import {
	EntityType,
	IActivityRestrictionType,
} from 'atlas/data/RestrictionConst';
import {ISegment} from 'atlas/data/SegmentConst';
import {
	IModalAttribute,
	IModalAttributeMap,
	IModalCategory,
	IModalSubCategoryMap,
} from './AddAttributesModalConst';

enum SelectionMode {
	Single = 'Single',
	Multiple = 'Multiple',
}

/**
 * @param enrichmentTopAttributes Top attributes refers to @ICategoryDict
 * @param entityList The entity type list refers to @IEntityMapping
 * @param categoryMetadata The category meta data map refers to @CategoryMetadata
 * @param segmentsList All segment list
 * @param segment Current segment name
 * @param setTotalRuleCount To set total rule count
 * @param saveState To save state
 */
interface IBkts extends Cube {
	Cnt: number;
}
interface IStats {
	[x: string]: IBkts;
}
type Icube = {
	[key in Entity]: {
		Cnt: number;
		Stats: IStats;
	};
};
interface IRoot {
	cube: Icube;
	enrichmentTopAttributes: ICategoryDict;
	entityList: IEntityMapping;
	categoryMetadata: CategoryMetadata[];
	segmentsList: ISegment[];
	segment: string;
	setTotalRuleCount: () => void;
	saveState: () => void;
	goAttributes: () => void;
	isNewRedesign: boolean;
}

/**
 * AddAttributeModal's parent scope
 * @param mode Single selection or multiple selection.
 * @param entity The entity type refers to EntityType.
 * @param type 'Event' indicates activity stream.
 * @param usableAttributes All usable attribute list
 * @param root Refers to IRoot
 * @param activityRestriction Activity restriction refers to @IActivityRestrictionType
 * @param relation Segment relation
 * @param onSaveClick Override of default onSaveClick.
 * @param onCancelClick Override of default onCancelClick.
 * @param initRestriction Initail restriction for selection.
 */
interface IAddAttributeModalParentScope {
	mode: SelectionMode;
	entity: EntityType;
	isCategorySelectEnabled?: boolean;
	type: string;
	usableAttributes: IAttribute[];
	numerical_operations: Record<string, string>;
	root: IRoot;
	activityRestriction?: IActivityRestrictionType;
	relation: string;
	onSaveClick?: (attrIds: string[]) => void;
	onCancelClick?: () => void;
	showBackButton?: boolean;
	wizard?: {
		isFinalStep?: boolean;
	};
	onBackClick?: (attributes: IAttributeValues[]) => void;
	title?: string;
	subTitle?: string;
	initRestriction?: LogicalRestrictionRecord;
	isSaveButtonDisabled?: (scope: IAddAttributeModalScope) => boolean;
}

/**
 * The AddAttributeModal scope
 * @param mode Single selection or multiple selection.
 * @param entity EntityType
 * @param isActivity True if activity stream attribute
 * @param title The modal title
 * @param restrictions The attribute restrictions
 * @param searchText The search text
 * @param searchPlaceholder The search placeholder
 * @param initialSelectedAttributes  The list of default selected attribute names
 * @param initialSelectedAttributesMap  The data structure of default select attribute counter map
 * @param selectedAttributes The list of selected attribute names
 * @param selectedAttributesMap The data structure of selected attribute names map
 * @param isSaving True if modal is saving.
 * @param isSaveButtonDisabled Func to check if save button is disabled
 * @param filteredCategories Filtered categories per entity type
 * @param attributes Filtered attribute map per entity type
 * @param activeCategory The active category
 * @param activityRestriction The activity restriction
 * @param initActivityRestriction The init activity restriction
 * Used on cancel handler for data recovering.
 */
interface IAddAttributeModalScope {
	removeSelectedCategory: () => void;
	isCategoryCanSelect: (category: IModalCategory) => boolean;
	isCategoryChecked: (category: IModalCategory) => boolean;
	selectedCategory: string;
	setSelectedCategory: (e: Event, category: IModalCategory) => void;
	numerical_operations: Record<string, string>;
	parent: IAddAttributeModalParentScope;
	mode: SelectionMode;
	entity: EntityType;
	isActivity: boolean;
	isNewRedesign: boolean;
	title: string;
	restrictions: Restrictions;
	searchText: string;
	searchPlaceholder: string;
	initialSelectedAttributes: () => IAttributeValues[];
	initialSelectedAttributesMap: CounterMap;
	selectedAttributes: () => IAttributeValues[];
	selectedAttributesMap: CounterMap;
	isSaving: boolean;
	getString: (key: string, replacements: string[]) => string;
	setSearchText: (searchText: string) => void;
	isSaveButtonDisabled: () => boolean;
	filteredCategories: IModalCategory[];
	initialFilteredCategories: IModalCategory[];
	attributes: IModalAttributeMap;
	activeCategory?: IModalCategory;
	isActiveCategory: (category: IModalCategory) => boolean;
	isActiveSubCategory: (curExpendedSub: string) => boolean;
	setCategoryActive: (category?: IModalCategory, isSearch?: boolean) => void;
	setSubCategoryActive: (
		category?: IModalCategory,
		subCategory?: string
	) => void;
	activityRestriction?: IActivityRestrictionType;
	initActivityRestriction?: IActivityRestrictionType;
	searchFilter: () => void;
	clearSearchFilter: () => void;
	clearSelectedAttributes: () => void;
	getSelectedCountText: () => string;
	tooltipPosition: (
		e: MouseEvent,
		entity: EntityType,
		attr: IModalAttribute
	) => void;
	scrollTo: (category: IModalCategory, subCategory: string) => void;
	setSelectedAttributes: (
		e: MouseEvent,
		attrId: string,
		category: IModalCategory,
		bkt?: Bkt
	) => void;
	setSelectedAttributeValues: (
		attrId: string,
		addNewOneBkt: boolean,
		hasValue?: boolean,
		bkt?: Bkt
	) => void;
	removeSelectedAttributes: (attributeId: string, bkt?: Bkt) => void;
	attributeCheckboxDisabled: (attributeId: string) => boolean;
	onSaveClick: (e: Event) => void;
	onCancelClick: () => void;
	filterColIds: (attrId: string) => IAttributeValues[];
	filterCategories: (
		colIds: IModalSubCategoryMap,
		subCategory: string
	) => number;
	getColIdsCount: (colIds: IModalSubCategoryMap) => number;
	oldSelectedAttributes: () => string[];
	checkedAttribute: (colId: string) => boolean;
}

type IAddAttributeState = {
	entity: EntityType;
	activityRestriction?: IActivityRestrictionType;
	eventEnrichments: IAttribute[];
	enrichments: IAttribute[];
	root: IRoot;
	enableQueryBuilderRedesign: boolean;
	tree: LogicalRestrictionRecord | null;
	numerical_operations: Record<string, string>;
	showSegmentationV2: boolean;
};

interface IAddAttributesContext {
	vm: IAddAttributeState;
	type: string;
	relation: string;
	parentScope?: IAddAttributeModalParentScope;
	isDialogOpen: boolean;
	setIsDialogOpen: (open: boolean) => void;
}

interface IAddAttributesDialog {
	modalScope: IAddAttributeModalScope;
	isDialogOpen: boolean;
	setIsDialogOpen: (open: boolean) => void;
	entity: EntityType;
}

type IAddAttributesComponent = IAddAttributesContext & {
	disabled: boolean;
	showButton: boolean;
	buttonText: string;
};

export type {
	Icube,
	IRoot,
	IAddAttributeModalParentScope,
	IAddAttributeModalScope,
	IAddAttributesDialog,
	IAddAttributesComponent,
	IAddAttributeState,
	IAddAttributesContext,
};
export {SelectionMode};
