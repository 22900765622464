import {ObjectType} from 'atlas/connectors/EIF/Data/ConnectionType';
import {ISegment, ISegmentCampaign} from 'atlas/data/SegmentConst';
import {Connections} from 'atlas/playbook/content/metadataLiveramplaunch/metadata.enums';
import {AttributeStats} from 'common/components/datacloud/query/advanced/tree/types';
import {ICreateOrUpdateCardParam, ISubJourneyStage} from '../ApiCalls';

/**
 * Percentage data interface.
 * @param current current val as an array. BE provided.
 * @param currentTotal Total of the current array. FE computed.
 * @param total total val. FE computed.
 */
interface IPercentageData {
	current: number[];
	currentTotal: number;
	total: number;
}

/**
 * Journey stage overview interface.
 * @param accounts FE data structure. See @totalAccount
 * @param contacts FE data structure. See @totalContact
 * @param isTotalStage Flag indicates that total stage row used by FE only.
 * @param buyerJourneyMapId Journey stage map data id which stage belongs to.
 * @param buyerJourneyMapName Journey stage map name which stage belongs to. FE only.
 * @param segmentDisplayName Segment display name which stage belongs to. FE only.
 * @param rawData Refers to @ISubJourneyStage used by substage to get it's parant stage data.
 */
type IJourneyStageOverview = ObjectType &
	ISegmentCampaign & {
		stageId: number;
		stageName: string;
		stageNumber: number;
		stageDescription: string;
		opportunityAmount: number;
		accountVelocity: number;
		spendAmount: number;
		totalAccount: number;
		totalContact: number;
		accounts: number;
		contacts: number;
		isTotalStage?: boolean;
		buyerJourneyMapId: number;
		buyerJourneyMapName?: string;
		segmentName?: string;
		segmentDisplayName?: string;
	} & {
		rawData?: ISubJourneyStage;
	};

/**
 * Refill JourneyStageOverview data.
 * @param data @IJourneyStageOverview
 * @returns Refilled data.
 * @remarks Computed FE only for rendering purpose.
 */
const RefillJourneyStageOverview = (
	data: IJourneyStageOverview
): IJourneyStageOverview => {
	return {
		...data,
		numberOfCampaigns: (data.plays || []).length,
		channelList: (data.plays || [])
			.flatMap(({playLaunchChannels}) => playLaunchChannels)
			.map(({id, lookupIdMap: {orgName, externalSystemName}}) => {
				return {
					id,
					name: orgName,
					system: externalSystemName as Connections,
				};
			}),
		accounts: data.totalAccount,
		contacts: data.totalContact,
	};
};

/**
 * Total journey stage data used by FE.
 */
const TotalJourneyStage: IJourneyStageOverview = {
	stageId: 0,
	stageName: 'Total',
	stageNumber: 0,
	stageDescription: 'Total',
	opportunityAmount: 0,
	accountVelocity: 0,
	spendAmount: 0,
	totalAccount: 0,
	totalContact: 0,
	accounts: 0,
	contacts: 0,
	plays: [],
	numberOfCampaigns: 0,
	channelList: [],
	isTotalStage: true,
	buyerJourneyMapId: 0,
};

interface IEntityData {
	count: number;
	insight: string;
	data: Record<string, number>;
}

/**
 * Journey overview interface.
 * @param name Name.
 * @param stages Journey stages list.
 */
interface IJourneyOverview extends ObjectType {
	name: string;
}

enum TechnologyProfileType {
	High = 'High',
	Medium = 'Medium',
	Low = 'Low',
}

enum DNBIntentType {
	Researching = 'Researching',
	Buying = 'Buying',
}

enum BomboraIntentType {
	High = 'High',
	Moderate = 'Moderate',
	Normal = 'Normal',
}

/**
 * Attribute status interface.
 * @param name Attribute name as id.
 * @param displayName Attribute display name
 * @param displayType @DisplayType
 * @param description Description for tooltip display.
 * @param Stats Attribute name and @AttributeStats key value pair.
 */
type IAttributeStats = {
	name: string;
	displayName: string;
	description: string;
	displayType: DisplayType;
	Stats?: Record<string, AttributeStats>;
};

/**
 * Stats cube response interface.
 * @param AccountSegmentStats Single attribute group.[VARIANT]
 * @param 'D&B Intent' DNB intent data [FIXED].
 * @param Intent Bombora intent data [FIXED].
 * @param 'D&B Technology Profile' Technology Profile data. [FIXED]
 * @remark @AccountSegmentStats attributes in this groups are variant
 * User could do modification through "Edit" action menu per each stat card.
 */
type ISegmentStatsResponse = {
	Fixed: IAttributeStats[];
	Variant: IAttributeStats[];
};

/**
 * Card display type
 * @param Pie Pie
 * @param Line Line
 * @param Map Map
 */
enum DisplayType {
	Pie = 'PIE',
	Line = 'LINE',
	Map = 'MAP',
}

/**
 * StatsCube interface.
 * Refers to api /pls/datacollection/segments/stats/{segmentName}
 * @param StatsCubes BE response, containing all attributes under category.
 * @param accounts Stats accounts data for FE stats card display.
 * @param contacts Stats contacts data for FE stats card display.
 * @param insights Insights accounts data for FE stats card display.
 */
type IStatsCube = ObjectType & {
	accounts?: IEntityData;
	contacts?: IEntityData;
	insights?: string[];
	bomboraBuyerIntent?: ICreateOrUpdateCardParam;
	bomboraBuyerIntentSummary?: string[];
};

/**
 * Segment overview interface.
 * The overall data construction that FE use to render.
 * Passing to @SegmentDashboard
 * Refers to @ISegment @IJourneyStageMapData @IJourneyOverview @IStatsCube
 */
type ISegmentOverview = ObjectType &
	ISegment & {
		stats: IStatsCube;
	};

/**
 * Journey variant data configuration interface.
 * @param id Order number.
 * @param displayType @DisplayType
 * @param data Data to display per display type.
 * @param gridHeader Grid header displayed inner card. @IGridHeader
 */
interface IJourneyVariantDataConfig {
	id: number;
	displayType: DisplayType;
	data: Record<string, number> | Record<string, IPercentageData>;
}

/**
 * Get message if not any data.
 * @param name Attribute name
 * @returns Message if not any @type of data.
 */
const getNoDataMessage = (name: string): string =>
	`You don’t have any ${name} data.`;

const CountCol2 = 'Count';

/**
 * Card grid header interface.
 * @param Col1 Column 1 title.
 * @param Col2 Column 2 title.
 */
interface IGridHeader {
	Col1: string;
	Col2: string;
	GridHasScroll: boolean;
}

/**
 * List all UI display attributes.
 * All these attributes are coming from attribute list get api.
 * Region card: @LDC_State @LDC_Country
 * Account sources card: @Account__EntityCreatedSource
 * Revenue card: @LE_REVENUE_RANGE
 * Industry card: @LDC_PrimaryIndustry
 * Employee range card: @LE_EMPLOYEE_RANGE
 */
enum AccountAttribute {
	LDC_State = 'LDC_State',
	LDC_Country = 'LDC_Country',
	Account__EntityCreatedSource = 'Account__EntityCreatedSource',
	LE_REVENUE_RANGE = 'LE_REVENUE_RANGE',
	LDC_PrimaryIndustry = 'LDC_PrimaryIndustry',
	LE_EMPLOYEE_RANGE = 'LE_EMPLOYEE_RANGE',
}

const CategoryEntity = 'Category';

/**
 * List all UI display Group attributes.
 */
enum GroupAttribute {
	'DNB_TECHNOLOGY_PROFILE' = 'DNB_TECHNOLOGY_PROFILE',
	'DNBINTENTDATA_PROFILE' = 'DNBINTENTDATA_PROFILE',
	'INTENT' = 'INTENT',
}

const GroupAttributeNameToIdMap: Record<string, GroupAttribute> = {
	'D&B Technology Profile': GroupAttribute.DNB_TECHNOLOGY_PROFILE,
	'D&B Intent': GroupAttribute.DNBINTENTDATA_PROFILE,
	'Bombora Intent': GroupAttribute.INTENT,
};

const isSupportedGroupAttribute = (attributeName: string): boolean =>
	attributeName in GroupAttribute;

/**
 * Group attribute and supporting type map
 * key: @GroupAttribute
 * value: supporting enum type.
 */
const GroupAttributeMap: Record<GroupAttribute, Record<string, string>> = {
	[GroupAttribute.DNB_TECHNOLOGY_PROFILE]: TechnologyProfileType,
	[GroupAttribute.INTENT]: BomboraIntentType,
	[GroupAttribute.DNBINTENTDATA_PROFILE]: DNBIntentType,
};

interface IGroupAttribute {
	attributeEntity: string;
	displayName: string;
	description: string;
}

/**
 * Region name which is so special case.
 */
const RegionAttribute = 'Region';

const GroupAttributeConfig: Record<GroupAttribute, IGroupAttribute> = {
	[GroupAttribute.DNB_TECHNOLOGY_PROFILE]: {
		attributeEntity: 'Account',
		displayName: 'Technology Profile',
		description:
			'Information related to prospect account’s technology stack including their current tools.',
	},
	[GroupAttribute.DNBINTENTDATA_PROFILE]: {
		attributeEntity: 'CustomIntent',
		displayName: 'D&B Buyer Intent',
		description:
			'Custom models that combs through billions of digital events and use proprietary analytics to pinpoint the prospect accounts that are showing buying activity toward your keywords.',
	},
	[GroupAttribute.INTENT]: {
		attributeEntity: 'Account',
		displayName: 'Bombora Buyer Intent',
		description:
			'Bombora measure prospect account’s digital journey across 5000+ premium websites helping you identify what exactly the prospect accounts are in market for.',
	},
};

export type {
	IPercentageData,
	IJourneyStageOverview,
	IJourneyOverview,
	ISegmentOverview,
	IStatsCube,
	IEntityData,
	IAttributeStats,
	ISegmentStatsResponse,
	IJourneyVariantDataConfig,
	IGridHeader,
};
export {
	TechnologyProfileType,
	DNBIntentType,
	BomboraIntentType,
	TotalJourneyStage,
	CountCol2,
	getNoDataMessage,
	AccountAttribute,
	CategoryEntity,
	RegionAttribute,
	GroupAttribute,
	GroupAttributeNameToIdMap,
	isSupportedGroupAttribute,
	DisplayType,
	RefillJourneyStageOverview,
	GroupAttributeConfig,
	GroupAttributeMap,
};
