import {react2angular} from 'common/react-vendor';
import {Provider} from 'react-redux';
import React from 'react';
import store from './store';
import {MyDataSubheader} from './my-data-subheader.component';

const MyDataSubheaderWithRedux = ({...props}) => {
	return (
		<Provider store={store}>
			<MyDataSubheader {...props} />
		</Provider>
	);
};

angular
	.module('common.datacloud.tabs.mydatasubheader', [])
	.component(
		'myDataSubheader',
		react2angular(
			MyDataSubheaderWithRedux,
			['vmEnrichments', 'vmTeams'],
			[
				'$scope',
				'$state',
				'$rootScope',
				'$stateParams',
				'$timeout',
				'DuplicateSegmentModal',
				'ExportSegmentModal',
			]
		)
	);
