import {Bkt} from 'common/components/datacloud/query/query.types';
import {SegmentType} from '../../../data/SegmentConst';

/**
 * Modal Category interface used by UI.
 * @param name Category name also used as unique id
 * @param Subcategory Subcategory to display
 * @param curExpendedSub Subcategory which display
 * @param colIds Attributes of current category
 * @param expanded True if expanded category
 * @param order Category order if defined
 */

type IModalSubCategoryMap = Record<string, string[]>;

interface IModalCategory {
	name: string;
	subCategories: string[];
	showSubCategories: boolean;
	curExpendedSub: string;
	colIds: IModalSubCategoryMap;
	filterColIds: IModalSubCategoryMap;
	expanded: boolean;
	order?: number;
	searched?: boolean;
}

type IModalCategoryMap = Record<string, IModalCategory>;

/**
 * Modal attribute item interface used by UI
 * @param Category Category to display
 * @param Subcategory Subcategory to display
 * @param ShowSubcategory True if show subcategory
 * Rules for now
 * - Account / Contact attribute: True if subcategory is not 'Other'
 * - Activity attribute: True if attribute entity type is 'Opportunity'
 * @param Title Title display on hover
 * @param AttrName Attribute name used as ID
 * @param name Attribute display name
 * @param Description Description
 * @param bucketCount Bucket count
 * Attribute Only Fields:
 * @param topbkt Attribute top bucket used for Account / Contact / Time series
 * Segment Only Fields:
 * @param type Segment type used for segment item only
 * @param relation Segment relation used for segment item only
 */
interface IModalAttribute {
	Category: string;
	Subcategory: string;
	ShowSubcategory: boolean;
	Title: string;
	AttrName: string;
	name: string;
	Description: string;
	bucketCount?: number;
	topbkt?: Bkt;
	type?: SegmentType;
	relation?: string;
}

type IModalAttributeMap = Record<string, IModalAttribute>;

const AttributesHardCodedConfig: Record<
	string,
	Record<
		string,
		{
			description: string;
			sortOrder: number;
		}
	>
> = {
	TrustRadiusIntent: {
		// Date
		EventTimestamp: {
			description: 'The date that the intent activity took place.',
			sortOrder: 0,
		},
		// Product
		StrAttr2: {
			description:
				'Products that visitors on TrustRadius are researching, including your products and competitor products.',
			sortOrder: 1,
		},
		// Category
		StrAttr1: {
			description:
				'Categories that appear in your TrustRadius intent data. Filtering by a Category includes all accounts researching any products in the category and accounts researching the category itself.',
			sortOrder: 2,
		},
		// Vendor
		StrAttr3: {
			description:
				'Vendors that sell the products that visitors on TrustRadius are researching.',
			sortOrder: 3,
		},
		// Activity Type (Activity Label)
		EventType: {
			description:
				'This is the specific type of content or feature that a visitor engaged with when researching a product or category.',
			sortOrder: 4,
		},
	},
	G2Intent: {
		// Date
		EventTimestamp: {
			description: 'The date that the intent activity took place.',
			sortOrder: 1,
		},
		// Product
		Detail1: {
			description:
				'The product for which G2 Buyer Intent signals were triggered. This will be the product name entered when creating the connection.',
			sortOrder: 2,
		},
		// Buying Stage
		StrAttr1: {
			description:
				"Evaluates your buyer's journey across G2 using multiple funnel-dependent factors, including page engagement and signal type.",
			sortOrder: 3,
		},
		// Activity Level
		StrAttr2: {
			description:
				"A measure of a buyer's general activity on G2 by aggregating buyer activity data, such as time spent per page and the total number of pages visited.",
			sortOrder: 4,
		},
	},
	CustomIntent: {
		// Buying Indicator
		user__BuyingIndicator: {
			description:
				'Contains either a "Buying" or "Researching" value based on the content accounts engaged with. If the content is related to intent to purchase, it will be classified as "Buying", while accounts that engage with content associated with information gathering will be classified as "Researching".',
			sortOrder: 1,
		},
		// Buying Score
		NumAttr6: {
			description:
				'Numeric values between 0 - 100 that represent the type of content the account was engaging with. Values below 50 indicate "Researching" buying indicator, while values 50 and greater represent "Buying" activity.',
			sortOrder: 2,
		},
		// Date
		EventTimestamp: {
			description:
				'The date on which the D&B Buyer Intent signal was captured.',
			sortOrder: 3,
		},
		// DUNS
		StrAttr1: {
			description: 'The DUNS number of the account associated with the intent.',
			sortOrder: 4,
		},
		// Intent Score
		NumAttr7: {
			description:
				'Numeric values between 80 - 100. D&B Buyer Intent signals are only attributed to accounts that score 80 or higher during the model creation process. ',
			sortOrder: 5,
		},
		// Model Name
		Detail1: {
			description:
				'The name of the D&B Buyer Intent model created using the keywords provided.',
			sortOrder: 6,
		},
		// Top Keyword
		StrAttr3: {
			description:
				'The top keyword (from the keyword file used to create the model) that resulted in an account showing intent.',
			sortOrder: 7,
		},
	},
};

export type {
	IModalCategory,
	IModalCategoryMap,
	IModalAttribute,
	IModalAttributeMap,
	IModalSubCategoryMap,
};

export {AttributesHardCodedConfig};
