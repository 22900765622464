import React from 'common/react-vendor';
import {
	DNBIconButton,
	DNBTag,
	DNBTooltip,
	DNBTypography,
	DeleteOutlineOutlinedIcon,
} from 'common/dnb-uux-vendor';
import {Entity} from 'common/components/datacloud/query/query.enums';
import {IAddAttributeModalScope} from '../AddAttributeModalScopeConst';

export const AddAttributesRightSection = ({
	data,
	status,
	setStatus,
	refresh,
}: {
	data: IAddAttributeModalScope;
	status: boolean;
	setStatus: (status: boolean) => void;
	refresh: React.Dispatch<React.SetStateAction<number>>;
}): React.ReactElement => {
	const {
		parent,
		attributes,
		selectedCategory,
		removeSelectedCategory,
		selectedAttributes,
		getSelectedCountText,
		clearSelectedAttributes,
		removeSelectedAttributes,
	} = data;
	return (
		<div className='addAttributesOrder'>
			<div className='addAttributesSelectedCount'>
				<DNBTypography component='span' variant='overline' fontWeight={850}>
					{!selectedCategory ? getSelectedCountText() : '1 attribute selected'}
				</DNBTypography>
				{selectedAttributes().length > 0 && (
					<DNBIconButton
						sx={{padding: '6px'}}
						size='small'
						onClick={() => {
							refresh((key) => -key);
							clearSelectedAttributes();
							setStatus(!status);
						}}>
						<DeleteOutlineOutlinedIcon />
					</DNBIconButton>
				)}
			</div>
			{!selectedCategory ? (
				<div className='selectedAttributes'>
					{selectedAttributes().map(({attrId, bkt}, index) => {
						const countSelected = index;
						const [entity, id] = attrId.split('.');
						const Stats = parent?.root?.cube?.[entity as Entity]?.Stats;
						const Bkts = Stats?.[id || entity || ''];
						const addNewOneBkt =
							Bkts?.Bkts?.Type !== 'Enum' || Bkts?.Bkts?.List.length <= 5;
						const vals = addNewOneBkt ? [bkt?.Lbl] : bkt?.Vals;
						return (
							<DNBTag
								type='default'
								key={`${attrId}-${countSelected}`}
								className='attribute'
								sx={{
									color: (theme) => theme.colors.ColorGraySecondary,
								}}
								onDelete={() => {
									refresh((key) => -key);
									removeSelectedAttributes(attrId, bkt);
									setStatus(!status);
								}}
								label={
									<DNBTypography
										component='div'
										variant='caption'
										sx={{display: 'flex', alignItems: 'center'}}>
										<DNBTooltip
											content={`${attributes?.[attrId]?.Category} -> ${
												attributes?.[attrId]?.Title
											}${
												Bkts?.Bkts?.List && vals ? `= ${vals.join(',')}` : ''
											}`}
											placement='top'
											arrow>
											<span className='text'>
												{attributes?.[attrId]?.name}
												{Bkts?.Bkts?.List && vals ? `= ${vals.join(',')}` : ''}
											</span>
										</DNBTooltip>
									</DNBTypography>
								}
							/>
						);
					})}
				</div>
			) : (
				<div className='selectedAttributes'>
					<DNBTag
						type='default'
						key='selectedCategory'
						className='attribute'
						sx={{
							color: (theme) => theme.colors.ColorGraySecondary,
						}}
						onDelete={() => {
							refresh((key) => -key);
							removeSelectedCategory();
							setStatus(!status);
						}}
						label={
							<DNBTypography
								component='div'
								variant='caption'
								sx={{display: 'flex', alignItems: 'center'}}>
								<DNBTooltip content={selectedCategory} placement='top' arrow>
									<span className='text'>{selectedCategory}</span>
								</DNBTooltip>
							</DNBTypography>
						}
					/>
				</div>
			)}
		</div>
	);
};
