import {cloneDeep} from 'lodash';
import {MutableRefObject} from 'react';
import {
	AttributeEntityType,
	TimeSeriesEntityType,
} from 'atlas/data/AttributeConst';
import {
	dispatchQueryPropertyValue,
	isRulesBasedModelMode,
	setPublicProperty,
} from 'common/stores/query';
import NoticeService from 'common/components/notice/NoticeService';
import {IChip} from 'common/components/chips/ChipsTypes';
import {findAndUpdatedLogicalRestriction} from 'common/components/datacloud/segment/segment.helpers';
import {getActivityEntityBuckets, getEntityFieldBuckets} from '../tree.queries';
import {BucketCmp, BucketType, TreeType} from '../../../query.enums';
import {
	Bkt,
	Bucket,
	InputRangeConfig,
	Restriction,
	Val,
	Vals,
} from '../../../query.types';
import {IQueryTreeItemEditData} from '../context/QueryTreeItemEditContext';
import {noInputs, stringOperations} from '../tree.constants';
import {
	changeBooleanValue as changeTreeBooleanValue,
	changeCmpValue as changeTreeCmpValue,
	changeValue,
	changeValues,
	getBooleanModel,
	getCmp,
	getOperationValue,
	getValue,
	showType,
	changeNumericalCmpValue,
	getCubeBktList,
} from '../tree.helpers';
import {Attribute, AttributeEntity} from '../types';
import {
	getListRestrictionsWithTooManyValuesError,
	getRuleAllBuckets,
} from '../../../query.helpers';
import {isNumericalRangeValid} from '../edit/NumericalRange/numericalRange.validations';
import {isDateRangeValid} from '../edit/DateRange/dateRange.validations';
import {
	getRootRestrictionByEntity,
	updateBucketCountAndRecords,
} from '../QueryTree.helpers';
import {
	IQueryTreeContext,
	IQueryTreeContextUpdate,
} from '../context/QueryTreeContext';
import {SetTotalRuleCount, UpdateCount} from '../../AdvancedQuery.helper';
import {
	IAdvancedQueryContext,
	IAdvancedQueryContextUpdate,
} from '../../context/AdvancedQueryContext';
import {Cube} from '../tree.types';

const showItem = (
	tree: Restriction,
	type: string | undefined,
	typeToShow: string
): boolean => {
	if (tree.segmentMemberRestriction) {
		return type === typeToShow;
	}
	return tree.bucketRestriction
		? showType({
				bucketRestriction: tree.bucketRestriction,
				type: type as BucketType & TreeType,
				typeToShow: typeToShow as BucketType,
		  })
		: false;
};

const showNumericalRange = (
	operation: BucketCmp,
	setData: (key: string, val: unknown) => void
): void => {
	let showFromNumerical = false;
	let showToNumerical = false;
	switch (operation) {
		case 'GREATER_OR_EQUAL':
		case 'GREATER_THAN':
			showFromNumerical = true;
			showToNumerical = false;
			break;
		case 'LESS_THAN':
		case 'LESS_OR_EQUAL':
			showFromNumerical = false;
			showToNumerical = true;
			break;
		case 'GT_AND_LT':
		case 'GT_AND_LTE':
		case 'GTE_AND_LTE':
		case 'GTE_AND_LT':
			showFromNumerical = true;
			showToNumerical = true;
			break;
		default:
			showFromNumerical = false;
			showToNumerical = false;
	}
	setData('showFromNumerical', showFromNumerical);
	setData('showToNumerical', showToNumerical);
};

const convertEqualToCollection = (
	data: MutableRefObject<IQueryTreeItemEditData>,
	setData: (key: string, val: unknown) => void
): void => {
	const {tree} = data.current;
	const bucket = tree?.bucketRestriction?.bkt;
	if (tree?.bucketRestriction && bucket?.Cmp) {
		let {Cmp} = bucket;
		if (bucket?.Cmp === BucketCmp.EQUAL) {
			Cmp = BucketCmp.IN_COLLECTION;
		}
		if (bucket?.Cmp === BucketCmp.NOT_EQUAL) {
			Cmp = BucketCmp.NOT_IN_COLLECTION;
		}
		const newTree = {
			...tree,
			bucketRestriction: {
				...tree?.bucketRestriction,
				bkt: {
					...tree.bucketRestriction.bkt,
					Cmp,
				},
			},
		};
		setData('tree', newTree);
	}
};

const getBuckets = (
	data: MutableRefObject<IQueryTreeItemEditData>,
	setData: (key: string, val: unknown) => void,
	callBack?: (refresh: number) => void
): void => {
	const {isActivity, item, tree} = data.current;
	if (!isActivity) {
		const id = item?.ColumnId;
		const entity = item?.Entity;
		if (entity && id) {
			getEntityFieldBuckets(entity, id)
				.then((response): void => {
					setData('buckets', []);
					if (response && response.Bkts && response.Bkts.List) {
						const buckets: Bucket[] = [];
						response.Bkts.List.forEach((item) => {
							if (item?.Cnt && item?.Cnt > 0) {
								buckets.push(item as Bucket);
							}
						});
						setData('buckets', buckets);
					}
					setData('clear', true);
					setData('loading', false);
					callBack && callBack(1);
				})
				.catch(() => {
					console.log('getEntityFieldBuckets error!');
				});
		}
	} else if (tree?.bucketRestriction) {
		const {attr, entityType} = tree?.bucketRestriction;
		const [entity, attributeId] = attr.split('.');

		if (entityType && entity && attributeId) {
			getActivityEntityBuckets(entityType, entity, attributeId)
				.then((response): void => {
					setData('buckets', []);
					if (response) {
						const buckets: Bucket[] = [];
						response.forEach((val, index) => {
							const newItem = {
								Cmp: 'EQUAL',
								Cnt: 1,
								Id: index + 1,
								Lbl: val,
								Vals: [val],
							};
							buckets.push(newItem as Bucket);
						});
						setData('buckets', buckets);
					}
					setData('clear', true);
					setData('loading', false);
					callBack && callBack(1);
				})
				.catch(() => {
					console.log('getActivityEntityBuckets error!');
				});
		}
	}
	callBack && callBack(1);
};

const initNumericalRange = (
	data: MutableRefObject<IQueryTreeItemEditData>,
	setData: (key: string, val: unknown) => void
): void => {
	const {tree, type, operation} = data.current;
	const fromNumerical = getValue({
		bucketRestriction: tree.bucketRestriction,
		type: type as BucketType & TreeType,
		index: 0,
	});
	const toNumerical = getValue({
		bucketRestriction: tree.bucketRestriction,
		type: type as BucketType & TreeType,
		index: 1,
	});
	const from = fromNumerical != null ? Number(fromNumerical) : undefined;
	const to = toNumerical != null ? Number(toNumerical) : undefined;
	const rangeConfig: InputRangeConfig = {
		from: {
			name: 'from-numerical',
			value: from,
			index: 0,
			type: BucketType.Numerical,
			step: 1,
		},
		to: {
			name: 'to-numerical',
			value: to,
			index: 1,
			type: BucketType.Numerical,
			step: 1,
		},
	};
	setData('rangeConfig', rangeConfig);
	showNumericalRange(operation, setData);
};

const showChips = (data: MutableRefObject<IQueryTreeItemEditData>): boolean => {
	const {editMode, operation, tree, type} = data.current;
	const equals = ['EQUAL', 'IN_COLLECTION', 'NOT_EQUAL', 'NOT_IN_COLLECTION'];
	const isPreset = editMode === 'Preset';
	const check = showItem;
	const cmp = equals.indexOf(operation) > -1;
	return (
		!isPreset &&
		cmp &&
		(check(tree, type, 'Enum') ||
			check(tree, type, 'Numerical') ||
			check(tree, type, 'String'))
	);
};

const showChipsNoList = (
	data: MutableRefObject<IQueryTreeItemEditData>
): boolean => {
	const {editMode, tree, type, operation} = data.current;
	const equals = ['CONTAINS', 'NOT_CONTAINS'];
	const isPreset = editMode === 'Preset';
	const check = showItem;
	const cmp = equals.indexOf(operation) > -1;
	return (
		!isPreset &&
		cmp &&
		(check(tree, type, 'Enum') ||
			check(tree, type, 'Numerical') ||
			check(tree, type, 'String'))
	);
};

const initVariables = (
	data: MutableRefObject<IQueryTreeItemEditData>,
	setData: (key: string, val: unknown) => void,
	callBack?: () => void
): void => {
	const {
		tree,
		item,
		isActivity,
		buckets,
		showSegmentationV2,
		header,
		root,
		entity,
		type,
	} = data.current;
	setData('string_operations', stringOperations);
	if (
		showItem(tree, type, 'Boolean') &&
		!showItem(tree, type, 'Percent') &&
		tree?.bucketRestriction
	) {
		setData('booleanValue', getBooleanModel(tree?.bucketRestriction));
	}

	if (tree?.bucketRestriction && showItem(tree, type, 'String')) {
		convertEqualToCollection(data, setData);
		const value = getOperationValue({
			bucketRestriction: tree.bucketRestriction,
			type: BucketType.String,
		});
		setData('operation', getCmp(tree.bucketRestriction));

		const val = (value || []) as Vals;
		setData('vals', val);
		if (isActivity) {
			if (buckets.length === 0 && val) {
				setData('buckets', [tree.bucketRestriction.bkt as Bucket]);
			}
			getBuckets(data, setData, callBack);
		}
	}
	if (tree?.bucketRestriction && showItem(tree, type, 'Enum')) {
		convertEqualToCollection(data, setData);
		switch (item?.FundamentalType) {
			case 'enum':
			case 'email':
			case 'alpha':
				setData('operation', getCmp(tree.bucketRestriction));
				break;
			case 'numeric':
				setData('operation', getCmp(tree.bucketRestriction));
				setData('string_operations', stringOperations);

				initNumericalRange(data, setData);
				break;
		}
		if (!showSegmentationV2 || item?.Entity === AttributeEntity.Rating) {
			const newHeader = header;
			newHeader[1] = `${item?.Entity}s`;
			setData('header', newHeader);
		}
		const val = tree?.bucketRestriction?.bkt?.Vals;
		setData('vals', val);
		if (buckets.length === 0 && val) {
			setData('buckets', [tree.bucketRestriction.bkt as Bucket]);
		}
		if (
			!root?.entityList ||
			(showSegmentationV2 &&
				root.entityList &&
				entity &&
				item?.Entity &&
				root.entityList?.[entity]?.includes(
					item?.Entity as unknown as AttributeEntityType | TimeSeriesEntityType
				) &&
				showChips(data))
		) {
			getBuckets(data, setData, callBack);
		}
	}
	if (tree?.bucketRestriction && showItem(tree, type, 'Numerical')) {
		setData('operation', getCmp(tree.bucketRestriction));
		const val = tree?.bucketRestriction?.bkt?.Vals;
		setData('vals', val);
		initNumericalRange(data, setData);
	}
	callBack && callBack();
};

const checkBoolOpSelected = (value: string, tree: Restriction): boolean => {
	if (tree.bucketRestriction?.bkt) {
		const {bkt} = tree.bucketRestriction;
		switch (bkt.Cmp) {
			case 'EQUAL':
				return value === (bkt?.Vals && bkt.Vals?.length > 0 ? bkt.Vals[0] : '');
			case 'IS_NULL':
				return value === 'Empty';
			case 'IS_NOT_NULL':
				return value === 'Present';
		}
	}
	return false;
};

const changeBooleanValue = (
	value: string,
	data: MutableRefObject<IQueryTreeItemEditData>,
	setData: (key: string, val: unknown) => void
): void => {
	const {tree} = data.current;
	setData('booleanValue', value);
	const fn = (operation: BucketCmp): string => {
		setData('operation', operation);
		const newTree = {
			...tree,
			bucketRestriction: changeTreeCmpValue({
				bucketRestriction: tree.bucketRestriction,
				value: operation,
			}),
		};
		setData('tree', newTree);
		return '';
	};
	let newValue = value;
	switch (value) {
		case 'Present':
			newValue = fn(BucketCmp.IS_NOT_NULL);
			break;
		case 'Empty':
			newValue = fn(BucketCmp.IS_NULL);
			break;
		case 'Yes':
			fn(BucketCmp.EQUAL);
			break;
		case 'No':
			fn(BucketCmp.EQUAL);
			break;
	}
	if (tree.bucketRestriction) {
		const newTree = {
			...tree,
			bucketRestriction: changeTreeBooleanValue(
				tree.bucketRestriction,
				newValue
			),
		};
		setData('tree', newTree);
	}
	checkBoolOpSelected(newValue, tree);
};

const isNumerical = (item: Attribute | undefined): boolean => {
	const funType = item?.FundamentalType;
	let bktType;
	// need find type
	if (item?.cube && item?.cube?.Stats?.[0]?.Bkts) {
		bktType = item.cube?.Stats?.[0]?.Bkts?.Type;
	}
	return funType === 'enum' || funType === 'numeric' || bktType === 'Numerical';
};

const changeCmpValue = (
	data: MutableRefObject<IQueryTreeItemEditData>,
	setData: (key: string, val: unknown) => void,
	callBack?: () => void
): void => {
	const {tree, vals, chipsOperations, rangeConfig, item, operation} =
		data.current;
	setData('clear', true);
	const _operation = tree.bucketRestriction?.bkt?.Cmp;
	const numerical = isNumerical(item);

	switch (operation) {
		case 'EQUAL':
		case 'IN_COLLECTION':
			setData('operation', BucketCmp.IN_COLLECTION);
			break;
		case 'NOT_EQUAL':
		case 'NOT_IN_COLLECTION':
			setData('operation', BucketCmp.IN_COLLECTION);
			break;
		case 'CONTAINS':
		case 'NOT_CONTAINS':
			break;
		default:
			if (vals && vals?.length > 0) {
				setData('vals', []);
			}
	}

	if (
		_operation &&
		chipsOperations.indexOf(_operation) < 0 &&
		chipsOperations.indexOf(operation) > -1
	) {
		setData('vals', []);
	}

	if (numerical) {
		showNumericalRange(operation, setData);

		if (rangeConfig) {
			rangeConfig.from.value = undefined;
			rangeConfig.to.value = undefined;
		}
		if (tree.bucketRestriction) {
			const newTree = {
				...tree,
				bucketRestriction: changeNumericalCmpValue(
					tree.bucketRestriction,
					operation
				),
			};
			newTree.bucketRestriction.bkt = {
				...newTree.bucketRestriction.bkt,
				Vals: data.current.vals,
			};
			setData('tree', newTree);
		}
	} else {
		const newTree = {
			...tree,
			bucketRestriction: changeTreeCmpValue({
				bucketRestriction: tree.bucketRestriction,
				value: operation,
			}),
		};
		if (newTree.bucketRestriction) {
			newTree.bucketRestriction.bkt = {
				...newTree.bucketRestriction.bkt,
				Vals: data.current.vals,
			};
		}
		setData('tree', newTree);
	}
	initVariables(data, setData, callBack);
};

const checkAlphaNumeric = (operation: BucketCmp): string => {
	const types = {
		[BucketCmp.STARTS_WITH]: 'alpha',
		[BucketCmp.ENDS_WITH]: 'alpha',
		[BucketCmp.GREATER_THAN]: 'numeric',
		[BucketCmp.GREATER_OR_EQUAL]: 'numeric',
		[BucketCmp.LESS_THAN]: 'numeric',
		[BucketCmp.LESS_OR_EQUAL]: 'numeric',
		[BucketCmp.GT_AND_LT]: 'numeric',
		[BucketCmp.GT_AND_LTE]: 'numeric',
		[BucketCmp.GTE_AND_LT]: 'numeric',
		[BucketCmp.GTE_AND_LTE]: 'numeric',
	};
	return (
		Object.entries(types).filter(([key]) => key === operation)?.[0]?.[1] || ''
	);
};

const showInput = (operation: BucketCmp): boolean => {
	const not_hidden = noInputs.indexOf(operation) < 0;
	const is_alpha = checkAlphaNumeric(operation) === 'alpha';
	return not_hidden && is_alpha;
};

const updateChips = (
	items: IChip[],
	data: MutableRefObject<IQueryTreeItemEditData>,
	setData: (key: string, val: unknown) => void,
	callBack: () => void
): void => {
	const {clear, tree, operation} = data.current;
	if (clear) {
		setData('vals', []);
		setData('clear', false);
	}
	let uniqueUpdatedValues = [
		...new Set([...items.map(({Lbl}) => Lbl), ...(data.current.vals || [])]),
	];
	if (tree.bucketRestriction?.bkt) {
		const error = getListRestrictionsWithTooManyValuesError({
			...tree,
			bucketRestriction: {
				...tree.bucketRestriction,
				bkt: {
					...tree.bucketRestriction?.bkt,
					Vals: uniqueUpdatedValues,
				},
			},
		});
		if (error) {
			uniqueUpdatedValues = [];
			NoticeService.warning(error);
		}
		tree.bucketRestriction = changeValues(
			tree.bucketRestriction,
			uniqueUpdatedValues
		);
		setData('vals', uniqueUpdatedValues);
		setData('operation', operation);
		changeCmpValue(data, setData, callBack);
	}
};

const callbackChangedNumericalValue = ({
	index,
	value,
	data,
	setData,
	callBack,
}: {
	index: number;
	value: Val;
	data: MutableRefObject<IQueryTreeItemEditData>;
	setData: (key: string, val: unknown) => void;
	callBack: () => void;
}): void => {
	const {tree, type} = data.current;
	const newTree = cloneDeep(tree);
	changeValue({
		bucketRestriction: newTree.bucketRestriction,
		type: type as TreeType,
		value,
		index,
	});
	setData('tree', newTree);
	callBack && callBack();
};

const showNumericRange = (
	operation: BucketCmp,
	setData: (key: string, val: unknown) => void
): boolean => {
	const not_hidden = noInputs.indexOf(operation) < 0;
	const is_numeric = checkAlphaNumeric(operation) === 'numeric';
	showNumericalRange(operation, setData);
	return not_hidden && is_numeric;
};

const changePreset = (
	value: IAdvancedQueryContext,
	data: MutableRefObject<IQueryTreeItemEditData>,
	setData: (key: string, val: unknown) => void,
	bucket?: Bkt
): void => {
	const {canEdit} = value;
	const {tree, item, presetOperation} = data.current;
	if (!canEdit || !tree.bucketRestriction) {
		return;
	}
	const label = presetOperation;
	const buckets = item?.Stats?.Bkts?.List;
	let bkt = cloneDeep(bucket);
	if (bucket === undefined) {
		bkt = buckets?.filter(function (item) {
			return item.Lbl === label;
		})[0];
	}

	let restriction = tree.bucketRestriction.bkt;

	restriction = {
		Cmp: bkt?.Cmp,
		Id: bkt?.Id,
		Cnt: bkt?.Cnt,
		Lbl: bkt?.Lbl,
		Vals: bkt?.Vals,
	};
	const newTree = {
		...tree,
		bucketRestriction: {
			...tree.bucketRestriction,
			bkt: restriction,
		},
	};
	setData('tree', newTree);
	setData('presetOperation', bkt?.Lbl);
};

const clickEditMode = (
	value: string,
	advancedQuery: IAdvancedQueryContext,
	data: MutableRefObject<IQueryTreeItemEditData>,
	setData: (key: string, val: unknown) => void,
	callBack: () => void
): void => {
	const {showSegmentationV2, item, tree} = data.current;
	if (!showSegmentationV2 || item?.Entity === AttributeEntity.Rating) {
		setData('editMode', value);
		if (value !== 'Custom') {
			const bucket = getCubeBktList(item?.Stats as Cube)[0];
			changePreset(advancedQuery, data, setData, bucket);
			callBack && callBack();
		} else {
			const newtree = {
				...tree,
				bucketRestriction: changeTreeCmpValue({
					bucketRestriction: tree.bucketRestriction,
					value: BucketCmp.IN_COLLECTION,
				}),
			};
			setData('tree', newtree);
			initVariables(data, setData, callBack);
		}
	}
};

const getOperationValueByIndex = (
	tree: Restriction,
	operation?: string,
	index?: number
): Val => {
	return tree?.bucketRestriction
		? (getOperationValue({
				bucketRestriction: tree.bucketRestriction,
				type: operation as BucketType,
				index,
		  }) as Val)
		: 'Unknown';
};

const isValidatedNumber = (
	data: MutableRefObject<IQueryTreeItemEditData>
): boolean => {
	const {operation, type, vals} = data.current;
	const operations = ['IN_COLLECTION', 'NOT_IN_COLLECTION'];
	if (operations.includes(operation) && type === 'Numerical') {
		return (
			vals?.filter((number) => !isNaN(Number(number))).length === vals?.length
		);
	}
	return true;
};

const isValid = (data: MutableRefObject<IQueryTreeItemEditData>): boolean => {
	const {
		tree,
		type,
		rangeConfig,
		showFromNumerical,
		showToNumerical,
		isTransactionEditValid,
		isDateAttributeValid,
	} = data.current;
	const validatedNumber = isValidatedNumber(data);

	const fromValue = getOperationValueByIndex(tree, type, 0);
	const toValue = getOperationValueByIndex(tree, type, 1);

	const isValidRange = isNumericalRangeValid({
		vals: [fromValue, toValue],
		rangeConfig,
		showFrom: showFromNumerical,
		showTo: showToNumerical,
	});

	const isValidDate = tree?.bucketRestriction
		? isDateRangeValid(tree.bucketRestriction)
		: true;

	const isValid =
		validatedNumber &&
		isValidRange &&
		isValidDate &&
		isTransactionEditValid &&
		isDateAttributeValid;
	setPublicProperty('enableSaveSegmentButton', isValid);
	return isValid;
};

const updatedTreeItemByLabelGlyph = (
	tree: Restriction | undefined,
	type: string | undefined
): void => {
	if (tree && type) {
		const {RootRestriction, restrictionKey} = getRootRestrictionByEntity(type);
		const allBuckets = getRuleAllBuckets(
			RootRestriction.restriction.logicalRestriction?.restrictions
		);
		const oldTree =
			allBuckets.find((bucket) => bucket.labelGlyph === tree.labelGlyph) ||
			tree;
		const newRestriction = {
			...RootRestriction,
			restriction: findAndUpdatedLogicalRestriction(
				RootRestriction.restriction,
				oldTree,
				tree
			),
		};
		dispatchQueryPropertyValue(restrictionKey, newRestriction);
	}
};

const finishEditing = async (
	data: MutableRefObject<IQueryTreeItemEditData>,
	queryTree: IQueryTreeContext,
	setQueryTree: IQueryTreeContextUpdate,
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate
): Promise<void> => {
	const {tree, entity} = data.current;
	const {setEditing} = setQueryTree;
	setEditing(false);
	if (tree.bucketRestriction) {
		const {entityType} = tree.bucketRestriction;
		if (!entityType) {
			const {updateTree} = queryTree;
			// update tree item before update count
			if (tree.bucketRestriction?.bkt) {
				// eslint-disable-next-line no-param-reassign
				tree.bucketRestriction.bkt.Cnt = -1;
			}
			updatedTreeItemByLabelGlyph(tree, entity);
			updateTree && updateTree(entity, false);
			// end
			await updateBucketCountAndRecords(tree, setQueryTree);
		}
	}
	updatedTreeItemByLabelGlyph(tree, entity);
	UpdateCount(value, action);
	SetTotalRuleCount(action);
};

const clickSet = (
	e: React.MouseEvent,
	data: MutableRefObject<IQueryTreeItemEditData>,
	setData: (key: string, val: unknown) => void,
	queryTree: IQueryTreeContext,
	setQueryTree: IQueryTreeContextUpdate,
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate,
	callBack: () => void
): void => {
	const {tree, operation, string_operations, ChipsController} = data.current;
	if (tree.bucketRestriction) {
		// add any free text in ChipsController query as an item
		if (
			ChipsController &&
			ChipsController.query.toString() &&
			ChipsController.customVals
		) {
			ChipsController.addCustomValuesWithDelimiterCheck(ChipsController.query);
		}

		// Verify that saving this restriction passes validation
		const error = getListRestrictionsWithTooManyValuesError({
			...tree,
			bucketRestriction: {
				...tree.bucketRestriction,
				bkt: {
					...tree.bucketRestriction.bkt,
					Vals: data.current.vals, // chips will change vals
				},
			},
		});

		if (error) {
			NoticeService.warning(error);
			setData('vals', []);
		}

		// is string operation, make sure vm.vals populates bucketRestriction
		if (noInputs.indexOf(operation) === -1 && string_operations[operation]) {
			const newTree = {
				...tree,
				bucketRestriction: {
					...tree.bucketRestriction,
					bkt: {
						...tree.bucketRestriction.bkt,
						Vals: data.current.vals,
					},
				},
			};
			tree.bucketRestriction = cloneDeep(newTree.bucketRestriction);
		}

		// if vals is empty, set operation to check IF PRESENT
		const val = tree.bucketRestriction?.bkt?.Vals || [];

		if (noInputs.indexOf(operation) === -1 && val.length === 0) {
			const _operation = BucketCmp.IS_NOT_NULL;
			const bucketRestriction = {
				...tree.bucketRestriction,
				bkt: {
					...tree.bucketRestriction.bkt,
					Cmp: _operation,
				},
			};
			tree.bucketRestriction = bucketRestriction;
			setData('operation', _operation);
			changeCmpValue(data, setData, callBack);
		}

		// In the rules based modeling page, clicking set should enable the
		// item (set ignored to false)
		if (isRulesBasedModelMode()) {
			tree.bucketRestriction.ignored = false;
		}
	}
	if (tree.segmentMemberRestriction) {
		if (isRulesBasedModelMode()) {
			tree.segmentMemberRestriction.ignored = false;
		}
	}

	e.preventDefault();
	e.stopPropagation();

	finishEditing(data, queryTree, setQueryTree, value, action);
};

const clickUnset = (
	e: React.MouseEvent,
	data: MutableRefObject<IQueryTreeItemEditData>,
	queryTree: IQueryTreeContext,
	setQueryTree: IQueryTreeContextUpdate,
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate
): void => {
	const {tree} = data.current;
	if (tree.bucketRestriction) {
		tree.bucketRestriction.bkt = {};
		tree.bucketRestriction.ignored = true;
	}
	e.preventDefault();
	e.stopPropagation();
	finishEditing(data, queryTree, setQueryTree, value, action);
};

const changeRelationValue = (
	value: string,
	data: MutableRefObject<IQueryTreeItemEditData>,
	setData: (key: string, val: unknown) => void
): void => {
	const {tree} = data.current;
	const newTree = {
		...tree,
		segmentMemberRestriction: {
			...tree.segmentMemberRestriction,
			relation: value,
		},
	} as Restriction;
	setData('tree', newTree);
};

export {
	initVariables,
	changeCmpValue,
	showItem,
	showInput,
	showChips,
	showChipsNoList,
	updateChips,
	callbackChangedNumericalValue,
	showNumericRange,
	changeBooleanValue,
	clickEditMode,
	getCubeBktList,
	isValidatedNumber,
	isValid,
	clickSet,
	clickUnset,
	changeRelationValue,
	changePreset,
	updatedTreeItemByLabelGlyph,
};
