import React from 'react';
import {DNBRadio} from 'common/dnb-uux-vendor';
import {FilterAccordionCheckboxOption} from './FilterCheckboxAccordion';
import '../FilterDrawer.module.scss';

interface IFilterRadioAccordion {
	options: FilterAccordionCheckboxOption[];
	onFilterChange(checked: boolean, value: string): void;
	radioCheckedVal?: string;
	onRadioFilterChange?(value: string): void;
}

const FilterRadioAccordion = ({
	options,
	onFilterChange,
	radioCheckedVal,
	onRadioFilterChange,
}: IFilterRadioAccordion): React.ReactElement => {
	return (
		<div className='FilterRadioAccordion'>
			{options?.map(({label, value}) => {
				return (
					<>
						<DNBRadio
							key={value}
							size='small'
							sx={{ml: 2, display: 'flex'}}
							label={label as string}
							value={value}
							checked={radioCheckedVal === value}
							onChange={(e) => {
								if (e.target.checked) {
									onFilterChange(e.target.checked, value);
									onRadioFilterChange?.(value);
								}
							}}
						/>
					</>
				);
			})}
		</div>
	);
};

export {FilterRadioAccordion};
