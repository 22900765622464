import React from 'common/react-vendor';
import {DNBTypography} from 'common/dnb-uux-vendor';
import {
	getBucketCmp,
	getPeriodValue,
	getValues as getTreeValues,
} from '../tree.helpers';
import {cmpMap, transactionMap} from '../tree.constants';
import {
	BucketCmp,
	BucketType,
	TransactionKey,
	TreeType,
} from '../../../query.enums';
import {BucketRestriction, Val} from '../../../query.types';
import {SubType} from '../tree.types';

interface TransactionItemProps {
	type?: TreeType;
	bucketRestriction?: BucketRestriction;
	purchased: boolean;
}

const TransactionItem = ({
	type,
	bucketRestriction,
	purchased,
}: TransactionItemProps): React.ReactElement => {
	const getCmp = (subType: SubType | BucketType): BucketCmp | '' => {
		const ret = getBucketCmp({
			bucketRestriction,
			type,
			subType,
		});

		switch (subType) {
			case TransactionKey.Time: {
				// TODO: Refactor to use 'BucketCmp.EVER' and remove 'EVER_LOW_CASE' from BucketCmp
				const cmp =
					ret === ''
						? BucketCmp.EVER_LOW_CASE
						: (transactionMap[ret] as BucketCmp);
				return cmp;
			}
			case TransactionKey.Amt:
			case TransactionKey.Qty: {
				return ret === '' ? BucketCmp.ANY : (cmpMap[ret] as BucketCmp);
			}
			default: {
				return ret;
			}
		}
	};
	const getPeriod = (): string => {
		const period = getPeriodValue({
			bucketRestriction,
			type,
			subType: TransactionKey.Time,
		});
		// TODO: Refactor to use 'BucketCmp.EVER' and remove 'EVER_LOW_CASE' from BucketCmp
		if (
			period !== 'Date' &&
			getCmp(TransactionKey.Time) !== BucketCmp.EVER_LOW_CASE
		) {
			return `${period}(s)`;
		}
		return '';
	};

	const showSubTypeSelection = (subType: SubType | BucketType): boolean => {
		switch (subType) {
			case TransactionKey.Amt:
			case TransactionKey.Qty: {
				return purchased === true;
			}
			default:
				return true;
		}
	};
	const showLabel = (subType: SubType | BucketType): boolean => {
		const cmp = getCmp(subType);
		if (subType === TransactionKey.Amt) {
			return cmp !== BucketCmp.ANY;
		}
		return false;
	};

	const getValues = (subType: SubType | BucketType): string | Val => {
		const ret = getTreeValues({
			bucketRestriction,
			type,
			subType,
		});

		switch (ret?.length) {
			case 0: {
				return '';
			}
			case 1: {
				if (subType === TransactionKey.Time && ret[0] === -1) {
					return '';
				}
				return ret[0];
			}
			case 2: {
				return `${ret[0]} - ${ret[1]}`;
			}
			default:
				return '';
		}
	};
	return (
		<>
			{/** In time frame (Txn) */}
			<span className='querySectionItemValue'>
				<DNBTypography
					component='span'
					variant='compact-body'
					className='transactionLable'>
					in timeframe
				</DNBTypography>
				<strong>
					{getCmp('Time')}
					{getValues('Time')}
				</strong>
				&nbsp;
				{getCmp('Time') !== 'ever' && <strong>{getPeriod()}</strong>}
			</span>

			{/** Unit Purchased (Qty) */}
			{showSubTypeSelection('Qty') && (
				<span className='querySectionItemValue'>
					<DNBTypography
						component='span'
						variant='compact-body'
						className='transactionLable'>
						units purchased
					</DNBTypography>
					<strong>
						{getCmp('Qty')}
						{getValues('Qty')}
					</strong>
				</span>
			)}
			{/** Amount Spent (Amt) */}
			{showSubTypeSelection('Amt') && (
				<span className='querySectionItemValue'>
					<DNBTypography
						component='span'
						variant='compact-body'
						className='transactionLable'>
						amount spent
					</DNBTypography>
					<strong>
						{getCmp('Amt')}
						{showLabel('Amt') && <span> $ </span>}
						{getValues('Amt')}
					</strong>
				</span>
			)}
		</>
	);
};

export {TransactionItem};
