import React from 'common/react-vendor';
import {
	DNBButton,
	DNBRadio,
	DNBRadioGroup,
	DNBTextField,
	DNBTooltip,
	DNBTypography,
	InfoOutlinedIcon,
} from 'common/dnb-uux-vendor';
import classNames from 'classnames';
import {
	DelimiterType,
	IChip,
	IDialogUpdated,
	delimiterLiterals,
} from '../ChipsTypes';
import styles from '../Chips.module.scss';
import {useChipsContext} from '../useChipsContext';
import {
	getChipsCount,
	getEmptyMatchingItem,
	getMaxNumChips,
	getSplitQuery,
	limitCheck,
} from '../ChipsController';

const ChipsLeftSection = (props: IDialogUpdated): React.ReactElement => {
	const {context, setContext} = useChipsContext();
	const {
		ID,
		bulkEntryDelimiterType,
		treeContext,
		datasource,
		previousItems,
		toBeAddedItems,
		lastPastedValue,
	} = context;
	const {setBulkEntryDelimiterType, setLastPastedValue, setToBeAddedItems} =
		setContext;
	const {isDialogUpdated, setIsDialogUpdated} = props;
	const noDataSource = datasource.length === 0;
	const getMatchingItemFromString = (itemString: string): IChip | undefined => {
		if (noDataSource) {
			return getEmptyMatchingItem(ID, itemString, true);
		}

		const matchingItem = datasource.find((datum) => {
			return datum[ID]?.toString().toLowerCase() === itemString.toLowerCase();
		});

		return matchingItem;
	};
	const addBulkEntryQuery = (splitQuery: string[]): void => {
		let invalidItemsNew = {};
		let toBeAddedItemsNew = {};
		splitQuery.forEach((queryItem) => {
			const matchingItem = getMatchingItemFromString(queryItem);
			if (!noDataSource && matchingItem) {
				const key = matchingItem[ID]?.toString() || '';
				const alreadySelected = Object.keys(previousItems).includes(key);
				if (!alreadySelected && key !== '') {
					toBeAddedItemsNew = {
						...toBeAddedItemsNew,
						[key]: matchingItem,
					};
				}
			} else {
				const alreadySelected = Object.keys(previousItems).includes(queryItem);
				if (!alreadySelected) {
					const item = getEmptyMatchingItem(ID, queryItem, true);
					const key = item[ID]?.toString();
					invalidItemsNew = {
						...invalidItemsNew,
						[key!]: item,
					};
				}
			}
		});
		if (toBeAddedItemsNew || invalidItemsNew) {
			setToBeAddedItems({
				...toBeAddedItems,
				...toBeAddedItemsNew,
				...invalidItemsNew,
			});
		}
	};

	const onBulkEntryChange = (
		val: string,
		remain: number,
		added = false
	): void => {
		setIsDialogUpdated(!isDialogUpdated);
		// clearBulkEntryModal(setContext);

		setLastPastedValue(val);
		const bulkEntryDelimiterStringLiteral =
			delimiterLiterals[bulkEntryDelimiterType as DelimiterType];
		const splitQuery = getSplitQuery(val, bulkEntryDelimiterStringLiteral);
		const willBeAddedItems = Array.from(new Set(splitQuery));
		if (willBeAddedItems.length > remain) {
			limitCheck(willBeAddedItems.length, treeContext, true, true);
			return;
		}
		if (added) {
			addBulkEntryQuery(willBeAddedItems);
		}
	};
	const setDelimiter = (value: DelimiterType): void => {
		setBulkEntryDelimiterType(value);
	};

	const count =
		getMaxNumChips(treeContext) -
		getChipsCount(previousItems) -
		getChipsCount(toBeAddedItems);
	const fundamentalType =
		treeContext?.item?.FundamentalType === 'numeric' ? 'number' : 'text';
	const textAreaHeight = noDataSource ? '499px !important' : '459px !important';
	const textAreaWidth = noDataSource ? '810px' : '260px';
	return (
		<DNBTypography
			component='div'
			className={classNames({
				[styles.ChipsLeftSection!]: true,
				[styles.noDataSource!]: noDataSource,
			})}>
			<div className={styles.delimiter}>
				<DNBTypography
					variant='compact-body'
					component='div'
					className={styles.pasteTitle}>
					Add values from your desktop
					<DNBTooltip
						arrow
						placement='right'
						content='You can paste values from your CSV, TSV, or spreadsheet below.'>
						<InfoOutlinedIcon
							sx={{
								cursor: 'pointer',
								width: '36px',
								height: '19px',
								gap: '10px',
								borderRadius: '40px',
								color: (theme) => theme.colors.ColorGraySecondary,
								fontSize: (theme) => theme.spacing(5),
							}}
						/>
					</DNBTooltip>
				</DNBTypography>
				<DNBTypography component='div'>
					<DNBTypography
						variant='compact-body'
						component='div'
						className={styles.delimiterLable}>
						Select delimiter
					</DNBTypography>
					<DNBRadioGroup
						value={bulkEntryDelimiterType}
						orientation='horizontal'
						onChange={(_, value) => {
							setDelimiter(value as DelimiterType);
						}}
						sx={{
							gap: '4px',
						}}>
						<DNBRadio
							label={DelimiterType.comma}
							value={DelimiterType.comma}
							id={DelimiterType.comma}
							size='compact'
							name='delimiter'
						/>
						<DNBRadio
							label={DelimiterType.tab}
							value={DelimiterType.tab}
							id={DelimiterType.tab}
							size='compact'
							name='delimiter'
						/>
						<DNBRadio
							label={DelimiterType.newline}
							value={DelimiterType.newline}
							id={DelimiterType.newline}
							size='compact'
							name='delimiter'
						/>
					</DNBRadioGroup>
				</DNBTypography>
			</div>
			<DNBTypography component='div' sx={{my: '4px'}}>
				<DNBTextField
					placeholder='Input values here'
					autoComplete='off'
					spellCheck={false}
					value={lastPastedValue}
					onChange={({target: {value}}) => onBulkEntryChange(value, count)}
					rows={12}
					name='inputValue'
					type={fundamentalType}
					size='compact'
					fullWidth
					multiline
					InputProps={{
						sx: {
							height: textAreaHeight,
							width: textAreaWidth,
							textarea: {
								height: textAreaHeight,
							},
							padding: '10px 12px',
							gap: '12px',
							background: (theme) =>
								`linear-gradient(0deg, #FFFFFF, #FFFFFF) linear-gradient(0deg, ${theme.colors.ColorGraySecondary}, ${theme.colors.ColorGraySecondary})`,
						},
					}}
					helperText={
						<DNBTypography variant='compact-body' component='span' sx={{mt: 0}}>
							{count.toLocaleString('en-US')} values remain
						</DNBTypography>
					}
				/>
				<DNBButton
					variant='secondary'
					size='compact'
					sx={{width: '100%', mt: 1}}
					disabled={!lastPastedValue || !bulkEntryDelimiterType}
					onClick={() => {
						onBulkEntryChange(lastPastedValue, count, true);
					}}>
					Add Input Values
				</DNBButton>
			</DNBTypography>
		</DNBTypography>
	);
};

export {ChipsLeftSection};
