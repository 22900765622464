import isEmpty from 'lodash/isEmpty';
import {
	DNBTextField,
	InputAdornment,
	DateRangeOutlinedIcon,
} from 'common/dnb-uux-vendor';
import classnames from 'classnames';
import moment from 'moment';
import Pikaday from 'pikaday';
import React from 'common/react-vendor';
import type {
	BucketRestriction,
	InputRangeConfig,
	InputChangeData,
} from '../../../../query.types';
import {schema, isFieldValid} from './dateRange.validations';
import {createPickaday, onSelectDate} from './dateRange.helpers';

const {useState, useEffect, useMemo, useRef} = React;

const DATE_FORMAT = 'YYYY-MM-DD';

interface DateRangeProps {
	config?: InputRangeConfig;
	showFrom?: boolean;
	fromLabel?: string;
	fromPlaceholder?: string;
	showTo?: boolean;
	toLabel?: string;
	toPlaceholder?: string;
	onChange(input: InputChangeData): void;
	bucketRestriction?: BucketRestriction;
	showInline?: boolean;
	hidePristineError?: boolean;
	hideIcon?: boolean;
	className?: string;
}

const DateRange = ({
	config,
	showFrom,
	fromLabel,
	fromPlaceholder,
	showTo,
	toLabel,
	toPlaceholder,
	onChange,
	bucketRestriction,
	showInline,
	hidePristineError,
	hideIcon,
	className,
}: DateRangeProps): React.ReactElement => {
	const fromInputConfig = config?.from || {};
	const toInputConfig = config?.to || {};

	const isFromInputVisible = fromInputConfig.visible || showFrom;
	const isToInputVisible = toInputConfig.visible || showTo;

	const isRangeConfig = isFromInputVisible && isToInputVisible;

	const errorMessage = isRangeConfig
		? 'Enter a valid range'
		: 'Enter a valid date';

	const fromInitialValue = fromInputConfig?.initial;
	const toInitialValue = toInputConfig?.initial;

	const fromInitialState = fromInitialValue
		? moment(fromInitialValue).format(DATE_FORMAT)
		: undefined;
	const toInitialState = toInitialValue
		? moment(toInitialValue).format(DATE_FORMAT)
		: undefined;

	const [fromValue, setFromValue] = useState<string | undefined>(
		fromInitialState
	);
	const [toValue, setToValue] = useState<string | undefined>(toInitialState);

	const fromPickerRef = useRef<Pikaday | null>(null);
	const toPickerRef = useRef<Pikaday | null>(null);

	const fromInputRef = useRef<HTMLInputElement | null>(null);
	const toInputRef = useRef<HTMLInputElement | null>(null);

	const rangeSchema = useMemo(() => schema(), []);

	const rangeValue = {
		showFrom: isFromInputVisible,
		from: fromValue,
		showTo: isToInputVisible,
		to: toValue,
	};

	const isFromValid = isFieldValid({
		key: 'from',
		rangeSchema,
		rangeValue,
	});

	const isToValid = isFieldValid({
		key: 'to',
		rangeSchema,
		rangeValue,
	});

	const isFromPristine = fromValue === fromInitialState;
	const isToPristine = toValue === toInitialState;

	const hasFromPristineError = hidePristineError ? !isFromPristine : true;
	const hasToPristineError = hidePristineError ? !isToPristine : true;

	const showErrorMessage =
		(!isFromValid && hasFromPristineError) ||
		(!isToValid && hasToPristineError);

	useEffect(() => {
		if (
			(fromInputConfig.initial === undefined || !isFromInputVisible) &&
			fromValue !== undefined
		) {
			setFromValue(undefined);
			fromPickerRef.current?.setDate('', false);
		}

		if (
			(toInputConfig.initial === undefined || !isToInputVisible) &&
			toValue !== undefined
		) {
			setToValue(undefined);
			toPickerRef.current?.setDate('', false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bucketRestriction?.bkt?.Cmp, config, bucketRestriction?.bkt?.Fltr?.Cmp]);

	/**
	 * Update the Pikaday select config
	 * to prevent issues with states.
	 */
	useEffect(() => {
		if (fromPickerRef.current) {
			fromPickerRef.current.config({
				onSelect: (date) =>
					onSelectDate({
						date,
						index: 0,
						setState: setFromValue,
						onChange,
					}),
			});
		}

		if (toPickerRef.current) {
			toPickerRef.current.config({
				onSelect: (date) =>
					onSelectDate({
						date,
						index: 1,
						setState: setToValue,
						onChange,
					}),
			});
		}
	}, [onChange]);

	/**
	 * Recreate pickaday elements
	 */
	useEffect(() => {
		if (fromInputRef.current) {
			fromPickerRef.current?.destroy();

			fromPickerRef.current = createPickaday({
				field: fromInputRef.current,
				index: 0,
				setState: setFromValue,
				onChange,
			});
		}

		if (toInputRef.current) {
			toPickerRef.current?.destroy();

			toPickerRef.current = createPickaday({
				field: toInputRef.current,
				index: 1,
				setState: setToValue,
				onChange,
			});
		}

		/**
		 * We only want to reset the pickaday elements
		 * if the visibility of an input changes.
		 *
		 * This help us to adjust the positioning
		 * and prevent unexpected behaviors of that
		 * library...
		 *
		 * Skips 'onChange'
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFromInputVisible, isToInputVisible]);

	// Remove Pikaday elements from the DOM when the date range component unmounts.
	useEffect(() => {
		return () => {
			if (fromPickerRef.current) {
				fromPickerRef.current.destroy();
				fromPickerRef.current = null;
			}

			if (toPickerRef.current) {
				toPickerRef.current.destroy();
				toPickerRef.current = null;
			}
		};
	}, []);

	return (
		<div className='range-container'>
			{!isEmpty(fromLabel) && isFromInputVisible && (
				<span className='transaction-element'>{fromLabel}</span>
			)}

			{isFromInputVisible && (
				<div
					className={classnames(
						'transaction-element',
						'date-picker-container',
						{
							'show-inline': showInline,
							'date-picker-container-with-label':
								isFromInputVisible && isToInputVisible,
						}
					)}>
					<DNBTextField
						ref={fromInputRef}
						size='compact'
						placeholder={fromPlaceholder}
						className={className}
						name={fromInputConfig?.name}
						InputProps={{
							sx: {
								paddingLeft: 0,
							},
							readOnly: true,
							startAdornment: (
								<InputAdornment position='start'>
									{!hideIcon && (
										<DateRangeOutlinedIcon
											className='date-trigger'
											onClick={() => fromPickerRef.current?.show()}
										/>
									)}
								</InputAdornment>
							),
						}}
						value={fromValue === undefined ? '' : fromValue}
						onChange={() => fromPickerRef.current?.show()}
					/>
				</div>
			)}

			{isFromInputVisible && isToInputVisible && (
				<span className='transaction-element from-lable'>{toLabel || '-'}</span>
			)}

			{isToInputVisible && (
				<div
					className={classnames(
						'transaction-element',
						'date-picker-container',
						{
							'show-inline': showInline,
						}
					)}>
					<DNBTextField
						ref={toInputRef}
						size='compact'
						placeholder={toPlaceholder}
						className={className}
						name={toInputConfig?.name}
						InputProps={{
							sx: {
								paddingLeft: 0,
							},
							readOnly: true,
							startAdornment: (
								<InputAdornment position='start'>
									{!hideIcon && (
										<DateRangeOutlinedIcon
											className='date-trigger'
											onClick={() => toPickerRef.current?.show()}
										/>
									)}
								</InputAdornment>
							),
						}}
						value={toValue === undefined ? '' : toValue}
						onChange={() => toPickerRef.current?.show()}
					/>
				</div>
			)}

			{showErrorMessage && (
				<span className='error-message'>{errorMessage}</span>
			)}
		</div>
	);
};

/**
 * TODO: Angular directive components can't use camelCase attributes...
 * Since we are migrating everything to React I feel that ideally
 * we should keep using camelCases for props, that is why I am creating this temporary
 * wrapper to pass the angular attributes to the React component...
 *
 * DONT use this component!
 * Use DateRange if you are refactoring an angular component to React.
 * This component purpose is only to be use in the temporary
 * date-range.component react2angular
 *
 * TODO: DELETE ME Once all instances of <date-range-directive />
 * are removed...
 */

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable  @typescript-eslint/no-explicit-any */
const DateRangeWithAngularProps = ({
	bucketrestriction,
	config,
	showfrom,
	showto,
	changed,
	fromlabel,
	tolabel,
	showinline,
	startplaceholder,
	endplaceholder,
	hidepristineerror,
	hideicon,
	inputclass,
}: Record<string, any>): React.ReactElement => (
	<DateRange
		config={config}
		showFrom={showfrom}
		fromLabel={fromlabel}
		fromPlaceholder={startplaceholder}
		showTo={showto}
		toLabel={tolabel}
		toPlaceholder={endplaceholder}
		onChange={changed}
		bucketRestriction={bucketrestriction}
		showInline={showinline}
		hidePristineError={hidepristineerror}
		hideIcon={hideicon}
		className={inputclass}
	/>
);

export {DateRange, DateRangeWithAngularProps, DATE_FORMAT};
