import React from 'common/react-vendor';
import {
	QueryTreeContextProvider,
	IQueryTreeComponent,
} from './context/QueryTreeContext';
import {QueryTreeContainer} from './QueryTreeContainer';

const QueryTreeComponent = (props: IQueryTreeComponent): React.ReactElement => {
	return (
		<QueryTreeContextProvider scope={props || {}}>
			<QueryTreeContainer />
		</QueryTreeContextProvider>
	);
};

export {QueryTreeComponent};
