import React from 'common/react-vendor';
import {DNBButton, DNBSelect, DNBSelectOption} from 'common/dnb-uux-vendor';
import classNames from 'classnames';
import {
	RatingsPageParams,
	StateService,
} from 'atlas/ratingsengine/ratingsengine.helpers';
import {useAdvancedQuery} from './hook/useAdvancedQuery';
import {
	ClickBucketTile,
	doesDisplayParentSegment,
	navigateParentSegment,
	parentSegment,
	saveRules,
	UpdateCount,
} from './AdvancedQuery.helper';
import {getRuleCount} from '../query.helpers';

const RuleSection = (): React.ReactElement => {
	const {context: advancedQueryContext, setContext} = useAdvancedQuery();
	const {setSaved} = setContext;
	const {
		isRulesBasedModelMode,
		state,
		mode,
		saved,
		canEdit,
		rating_rule,
		buckets,
		bucket: initBucket,
		treeMode,
		coverage_map,
		bucketLabels,
		segmentObj,
		segmentsList,
	} = advancedQueryContext;
	return (
		<>
			<div>
				{saved === true && (
					<div className='success-message'>
						<h3>Rules were saved successfully!</h3>
					</div>
				)}
			</div>
			{isRulesBasedModelMode && (
				<div className='query-rules-header'>
					{mode === 'dashboardrules' && (
						<DNBButton
							className='button blue-button pull-right'
							onClick={() =>
								saveRules(
									state as StateService<RatingsPageParams> | undefined,
									setSaved
								)
							}
							disabled={canEdit}>
							Save Changes to Rules
						</DNBButton>
					)}
					<h2>
						<i className='fa fa-random' />
						Rules - Define the rules for your targets
					</h2>
					<div className='query-rules-header-infotext'>
						A record will always get the highest rating it is eligible for.
					</div>
					<div className='query-rules-header-defaultbucket'>
						{`If a record doesn't match any rules, it should be given this rating:`}
						<DNBSelect
							value={rating_rule?.defaultBucketName}
							onChange={() => UpdateCount(advancedQueryContext, setContext)}
							disabled={!canEdit}>
							{buckets.map((bkt) => {
								return (
									<DNBSelectOption key={bkt.bucket} value={bkt.bucket}>
										{bkt.bucket}
									</DNBSelectOption>
								);
							})}
						</DNBSelect>
					</div>
				</div>
			)}
			{isRulesBasedModelMode && (
				<ul className='query-rules-buckets-container'>
					{buckets.map((bucket) => {
						return (
							<li
								key={bucket.bucket}
								onClick={() =>
									ClickBucketTile(bucket, advancedQueryContext, setContext)
								}
								className={classNames({
									'query-rules-buckets-bucket': true,
									'active': bucket?.bucket === initBucket,
								})}>
								<div className='bucket-box'>
									<div className='bucket-label'>
										<h2>{bucket?.bucket}</h2>
									</div>
									<div className='bucket-resource'>
										{treeMode?.toLocaleUpperCase()}
									</div>
									{bucket?.count && bucket.count < 0 && (
										<div className='bucket-resource-count'>
											<span className='query-section-item-records-number'>
												<i className='fa fa-spinner fa-spin' />
											</span>
										</div>
									)}
									{bucket?.count && bucket.count >= 0 && (
										<div className='bucket-resource-count'>
											<span className='count-number'>{bucket.count}</span>
											<span className='count-percentage'>
												(
												{(bucket.count / (coverage_map?.accountCount || 1)) *
													100}
												%)
											</span>
										</div>
									)}
								</div>
								<div className='bucket-rulecount'>
									{
										getRuleCount(
											bucket,
											rating_rule?.bucketToRuleMap || {},
											bucketLabels
										) as number
									}
									{` `}
									Rules
								</div>
							</li>
						);
					})}
				</ul>
			)}
			{doesDisplayParentSegment(state, segmentObj) && (
				// journey stage part to show parent segment if FF is enabled.
				<div className='parent-segment-container'>
					<p>
						Create a custom subsegment by narrowing down the parent segment
						below:
					</p>
					<DNBButton
						className='parent-segment-button button white-button'
						onClick={() => navigateParentSegment(state, segmentObj)}>
						<i className='fa fa-sitemap' />
						{parentSegment(segmentsList, segmentObj)}
					</DNBButton>
				</div>
			)}
		</>
	);
};
export {RuleSection};
