import React from 'common/react-vendor';
import classNames from 'classnames';
import {useAdvancedQuery} from './hook/useAdvancedQuery';
import {QueryBuilderListCard} from './list/QueryBuilderList';
import {
	ClickUndo,
	getBucketLabel,
	GetRulesInputTree,
	GetSegmentInputTree,
	ListItemClickCollapsed,
	ListItemClickOperator,
} from './AdvancedQuery.helper';
import {IQueryBuilderList} from './list/QueryBuilderListTypes';
import {LogicalRestriction} from '../query.types';
import {Operator} from '../query.enums';
import {RuleSection} from './AdvancedQueryRuleSection';
import {QueryBuilderSection} from './AdvancedQueryBuilderSection';
import {QueryButtonGroupWithRedux} from './buttongroup/query.button.group.ng';

const AdvancedQueryContainer = (): React.ReactElement => {
	const {context: advancedQueryContext, setContext} = useAdvancedQuery();
	const {mode, stickyBar, IsBuyerJourneyEnabled, state} = advancedQueryContext;
	const scope = {
		...advancedQueryContext,
		getBucketLabel,
		listItemClickOperator: (
			logicalRestriction: LogicalRestriction,
			operator: Operator
		) =>
			ListItemClickOperator(
				advancedQueryContext,
				setContext,
				logicalRestriction,
				operator
			),
		listItemClickCollapsed: (target: IQueryBuilderList) =>
			ListItemClickCollapsed(advancedQueryContext, target),
		clickUndo: () => ClickUndo(advancedQueryContext, setContext),
		getRulesInputTree: () => GetRulesInputTree(advancedQueryContext),
		getSegmentInputTree: () => GetSegmentInputTree(),
	};
	return (
		<>
			{IsBuyerJourneyEnabled && state && (
				<div className='query-button-group'>
					<QueryButtonGroupWithRedux $state={state} />
				</div>
			)}
			<div className={`queryBuilderContainer advancedQueryBuilder ${mode}`}>
				<RuleSection />
				<div>
					<div
						className={classNames({
							queryAlgorithm: true,
							stickyQuery: stickyBar,
							queryButtonGroup: stickyBar && IsBuyerJourneyEnabled,
						})}>
						<QueryBuilderListCard scope={scope} />
					</div>
					<QueryBuilderSection />
				</div>
			</div>
		</>
	);
};

export {AdvancedQueryContainer};
