import {IAttribute} from 'atlas/data/AttributeConst';
import {EntityType} from 'atlas/data/RestrictionConst';
import {IModalAttribute} from 'atlas/modelsOld/modals/AddAttributeModal/AddAttributesModalConst';
import {CategoryMetadata} from 'common/components/datacloud/datacloud.service.vanilla';
import {Bkt} from 'common/components/datacloud/query/query.types';
import {AccountContactDataController} from './AccountDataController';
import {IDataController} from './DataController';

/**
 * Time series data controller
 * wrap raw data from time series attribute api
 * provide modal attribute data for display usage
 */
class TimeSeriesDataController implements IDataController {
	controller: AccountContactDataController;

	attribute: IAttribute;

	entity: EntityType;

	constructor(
		categoryMetadata: CategoryMetadata[],
		attribute: IAttribute,
		topBucket: Bkt,
		entity: EntityType
	) {
		this.controller = new AccountContactDataController(
			categoryMetadata,
			attribute,
			topBucket
		);
		this.attribute = attribute;
		this.entity = entity;
	}

	/**
	 * Get attribute id key
	 * @returns The attribute id key
	 * For example:
	 * Account.Time_Attribute_name.Opportunity
	 */
	getKey = (): string => {
		const {
			entity,
			attribute: {AttrName, Entity},
		} = this;
		return `${entity}.${AttrName}.${Entity}`;
	};

	getCategory = (): string => {
		return this.controller.getCategory();
	};

	isShowSubCategory = (): boolean => {
		return this.controller.isShowSubCategory();
	};

	toModalAttribute = (): IModalAttribute => {
		const modalAttribute = this.controller.toModalAttribute();
		return {...modalAttribute, AttrName: this.getKey()};
	};
}

export {TimeSeriesDataController};
