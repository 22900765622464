import React from 'common/react-vendor';
import {
	ContentCopyIcon,
	DNBCard,
	DNBDivider,
	DNBIconButton,
	DNBTag,
	DNBToggle,
	DNBTypography,
	DeleteOutlineIcon,
	EditOutlinedIcon,
} from 'common/dnb-uux-vendor';
import {isEqual} from 'lodash';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {QueryState} from 'common/stores/query/types';
import {isRulesBasedModelMode, isSegmentationMode} from 'common/stores/query';
import {useQueryTree} from './hook/useQueryTree';
import {getBucketLabel} from '../AdvancedQuery.helper';
import {
	editBucket,
	getBackgroundColor,
	getCategoryMetadata,
	showSubCategory,
	mouseOver,
	mouseMove,
	mouseOverHighlight,
	mouseOutHighlight,
	mouseDown,
	clickClone,
	showToggleIgnored,
	setIgnored,
	clickDelete,
} from './QueryTree.helpers';
import {useAdvancedQuery} from '../hook/useAdvancedQuery';
import {QueryTreeItem} from './item/QueryTreeItem';
import {QueryTreeItemEditComponent} from './edit/QueryTreeItemEdit';
import {updateAllBucketCounts} from '../../query.helpers';
import {Restriction} from '../../query.types';
import {updatedTreeItemByLabelGlyph} from './item/QueryTreeItem.helpers';

const QueryTreeBucketRestriction = ({
	callBack,
}: {
	callBack: () => void;
}): React.ReactElement => {
	const {context: queryTree, setContext: setQueryTree} = useQueryTree();
	const {context: advancedQuery, setContext: setAdvancedQuery} =
		useAdvancedQuery();
	const {
		tree,
		root,
		type,
		memberType,
		editing,
		item,
		records_updating,
		entity,
		updateTree,
	} = queryTree;
	const draggedItem = root.draggedItem?.current;
	const draggedItemTree = draggedItem?.tree;
	// const tree = treeRef.current || initTree;
	const dragging = isEqual(draggedItemTree, tree);
	const color = getBackgroundColor(tree, root, item);
	const isSegment = type === 'Member';
	const [count, setCount] = React.useState(
		!isSegment
			? tree.bucketRestriction?.bkt?.Cnt
			: tree.segmentMemberRestriction?.bkt?.Cnt
	);
	const [ignored, setIgnore] = React.useState(
		isSegment
			? tree.segmentMemberRestriction?.ignored
			: tree?.bucketRestriction?.ignored
	);
	const [enableClick, setEnableClick] = React.useState(true);
	const {mouseDownTimer} = advancedQuery;
	const id = getBucketLabel(tree);
	const disableAllTreeRestrictions = useSelector(
		(state: {query: QueryState}) =>
			state?.query?.public.disableAllTreeRestrictions
	);
	const Buttonconfig = {
		edit: {
			show: !editing && advancedQuery.canEdit,
			title: '',
			dataTest: '',
			className: 'editOutlinedIcon',
			parentClass: 'querySectionItemEdit',
			icon: <EditOutlinedIcon />,
			onClick: () => {
				enableClick && editBucket(queryTree, setQueryTree);
			},
		},
		clone: {
			show:
				!editing &&
				advancedQuery.canEdit &&
				(isSegmentationMode() || !ignored) &&
				!isSegment,
			title: 'Clone Item',
			dataTest: `clone-item-button-${id || '-'}`,
			className: 'contentCopyIcon',
			parentClass: 'querySectionItemClone contentCopyIcon',
			icon: <ContentCopyIcon />,
			onClick: () => {
				clickClone(queryTree, advancedQuery, setAdvancedQuery);
			},
		},
		delete: {
			show: !editing && advancedQuery.canEdit,
			title: '',
			dataTest: '',
			className: 'deleteIcon',
			parentClass: 'querySectionItemControls',
			icon: <DeleteOutlineIcon />,
			onClick: () => {
				clickDelete(queryTree, setAdvancedQuery);
			},
		},
	};
	const callback = React.useCallback(
		(tree: Restriction): void => {
			updatedTreeItemByLabelGlyph(tree, entity);
			updateTree && updateTree(entity, false);
			setCount(
				!isSegment
					? tree.bucketRestriction?.bkt?.Cnt
					: tree.segmentMemberRestriction?.bkt?.Cnt
			);
			setIgnore(
				isSegment
					? tree.segmentMemberRestriction?.ignored
					: tree?.bucketRestriction?.ignored
			);
			setEnableClick(true);
		},
		[entity, isSegment, updateTree]
	);
	React.useEffect(() => {
		if (count === -1) {
			setEnableClick(false);
			updateAllBucketCounts([tree], callback);
		}
	}, [count, entity, tree, callback]);
	return (
		<>
			{!dragging && (
				<div
					className={classNames({
						querySectionTypeDotline: true,
						operation: tree?.logicalRestriction?.operator,
					})}
				/>
			)}
			<DNBCard
				draggable
				className={classNames({
					dashed: dragging,
					dashedTop: dragging,
					querySectionItem: true,
					editing,
					dragging,
					canEdit: root.canEdit,
				})}
				style={{
					borderLeftColor: getBackgroundColor(tree, root, item),
					width: '100%',
					overflow: 'unset',
					color: 'inherit',
				}}
				hasBorder
				variant='white'
				onMouseDown={(e) =>
					mouseDown(e, editing, queryTree, advancedQuery, setAdvancedQuery)
				}
				onMouseOver={(e) => {
					mouseOver(e, editing, queryTree, advancedQuery, callBack);
					mouseOverHighlight(id);
				}}
				onMouseMove={(e) => {
					mouseMove(
						e,
						editing,
						queryTree,
						advancedQuery,
						setAdvancedQuery,
						true
					);
				}}
				onMouseOut={() => {
					mouseOutHighlight(id);
				}}>
				<div style={{position: 'relative'}} id={`bucket-${id}`} />
				<DNBTag
					type='mild-scale'
					size='small'
					className='querySectionItemID'
					sx={{
						'padding': '2px 8px',
						'verticalAlign': 'baseline',
						'bgcolor': ignored ? '#aaa' : color,
						':focus': {
							bgcolor: ignored ? '#aaa' : color,
							outline: 'unset',
						},
						':hover': {
							bgcolor: ignored ? '#aaa' : color,
						},
					}}
					label={
						<DNBTypography component='span' variant='caption'>
							{id || '-'}
						</DNBTypography>
					}
				/>
				<div
					onClick={() => enableClick && editBucket(queryTree, setQueryTree)}
					className={classNames({
						cursorPointer: !editing && root.canEdit,
						editing,
						querySectionItemContent: true,
					})}>
					{!editing && (
						<DNBTypography
							component='span'
							variant='compact-body'
							className='querySectionItemCategory'>
							{getCategoryMetadata(item?.Category || '', root)?.displayName ||
								item?.Category}
							{showSubCategory(tree, item) ? ` - ${item?.Subcategory}` : ''}
						</DNBTypography>
					)}
					<span className='querySectionItemDisplayname'>
						{!editing && !isSegment && (
							<DNBTypography
								component='span'
								variant='compact-bold'
								className='querySectionItemName'
								title={item?.DisplayName}>
								{item?.DisplayName}
							</DNBTypography>
						)}
						{/**
						 * View mode
						 * Show when not editing and either in segmentation or in rules-based model with a non-ignored bucket
						 */}
						{!editing && (isSegmentationMode() || !ignored) && (
							<span
								className={classNames({
									itemBucketData: true,
									member: isSegment,
								})}>
								<QueryTreeItem
									tree={tree}
									type={type}
									memberType={memberType}
								/>
							</span>
						)}
						{!editing && isSegment && (
							<DNBTypography
								component='span'
								variant='compact-bold'
								className='querySectionItemName'
								title={item?.DisplayName}>
								{item?.DisplayName}
							</DNBTypography>
						)}
						{/* -- Editing Mode -- */}
						{editing && (
							<DNBTypography
								component='span'
								variant='compact-body'
								className='itemEditBucketData'>
								<QueryTreeItemEditComponent
									tree={tree}
									type={type}
									item={item}
									root={root}
									memberType={memberType}
								/>
							</DNBTypography>
						)}
					</span>
				</div>
				{!editing &&
					(isSegmentationMode() || !ignored) &&
					(isSegment ||
						(!tree.bucketRestriction?.entityType && !isSegment)) && (
						<>
							<DNBDivider
								sx={{
									borderWidth: '1px',
									borderRadius: '4px',
									height: '40px',
								}}
								orientation='horizontal'
								variant='lightIncidental'
							/>
							<div className='querySectionItemRecords'>
								<span className='querySectionItemRecordsNumber'>
									<DNBTypography
										component='div'
										className='querySectionItemRecordsLabel'
										sx={{
											color: (theme) => theme.colors.ColorGraySecondary,
										}}
										variant='body'>
										Records
									</DNBTypography>
									<div
										className={classNames({
											'expanding-bars-init': true,
											'expanding-bars-spinner':
												records_updating ||
												count === -1 ||
												disableAllTreeRestrictions,
										})}>
										<DNBTypography component='div' variant='compact-body'>
											{count?.toLocaleString('en-US')}
										</DNBTypography>
									</div>
								</span>
							</div>
						</>
					)}
				{!editing && advancedQuery.canEdit && (
					<DNBDivider
						sx={{
							borderWidth: '1px',
							borderRadius: '4px',
							height: '40px',
						}}
						orientation='horizontal'
						variant='lightIncidental'
					/>
				)}
				{Object.entries(Buttonconfig).map(([key, button]) => {
					const {parentClass, className, title, icon, dataTest, onClick} =
						button;
					return (
						button.show && (
							<div className={parentClass} key={key}>
								<DNBIconButton
									size='small'
									title={title}
									disabled={disableAllTreeRestrictions || !enableClick}
									className={className}
									data-test={dataTest}
									onClick={() => {
										if (mouseDownTimer?.current) {
											clearTimeout(mouseDownTimer.current);
											mouseDownTimer.current = undefined;
										}
										onClick();
									}}>
									{icon}
								</DNBIconButton>
							</div>
						)
					);
				})}
				{!editing && (
					<div
						className='querySectionItem__section-ignored toggle'
						style={{
							display:
								isRulesBasedModelMode() && !ignored ? 'none' : 'inline-flex',
							paddingRight: '1em',
						}}>
						{showToggleIgnored(advancedQuery.canEdit) && (
							<DNBToggle
								ariaLabel='Toggle this restriction'
								checked={!ignored}
								isDisabled={disableAllTreeRestrictions || !enableClick}
								data-test={`toggle-ignored-input-${id || 'n'}`}
								onChange={(event) => {
									if (mouseDownTimer?.current) {
										clearTimeout(mouseDownTimer.current);
										mouseDownTimer.current = undefined;
									}
									setIgnored(
										queryTree,
										setQueryTree,
										advancedQuery,
										setAdvancedQuery,
										!event.target.checked,
										callback
									);
								}}
							/>
						)}
						{ignored && !editing && isRulesBasedModelMode() && (
							<span className='querySectionItem__section-ignored__unused-indicator'>
								UNUSED
							</span>
						)}
					</div>
				)}
			</DNBCard>
		</>
	);
};

export {QueryTreeBucketRestriction};
