import {
	IAddAttributeModalParentScope,
	IAddAttributeModalScope,
	IAddAttributeState,
	SelectionMode,
} from '../AddAttributeModalScopeConst';
import {AddAttributesModalScope} from '../AddAttributesModalWrapper';

const initAttributesStore = {} as IAddAttributeModalScope;
let attributesStore = {} as IAddAttributeModalScope;
const subscribers = new Set();

interface IAttributesStoreSubscribe {
	vm: IAddAttributeState;
	type: string;
	relation: string;
	parentScope?: IAddAttributeModalParentScope;
}

export const AttributesStore = {
	subscribe(
		callback: () => void,
		{
			vm,
			type,
			relation,
			parentScope = {} as IAddAttributeModalParentScope,
		}: IAttributesStoreSubscribe
	): () => void {
		const parent = {
			mode: SelectionMode.Multiple,
			entity: vm?.entity,
			type,
			numerical_operations: vm.numerical_operations,
			usableAttributes:
				type === 'Event' ? vm?.eventEnrichments : vm?.enrichments,
			root: vm?.root,
			activityRestriction: vm?.activityRestriction,
			relation,
		};
		attributesStore = new AddAttributesModalScope(initAttributesStore, {
			...parent,
			...parentScope,
		}).getScope();
		subscribers.add(callback);
		return () => {
			subscribers.delete(callback);
			attributesStore = {} as IAddAttributeModalScope;
		};
	},
	getSnapShot(): IAddAttributeModalScope {
		return attributesStore;
	},
};
