import _ from 'lodash';
import {getString} from 'common/app/utilities/ResourceUtility';
import {TimeSeriesDataController} from 'atlas/modelsOld/modals/AddAttributeModal/DataController/TimeSeriesDataController';
import {
	CounterMap,
	IKeyValues,
	IAttributeValues,
} from 'atlas/modelsOld/modals/AddAttributeModal/Helper/CounterMap';
import {
	FeatureFlags,
	isFeatureFlagEnabled,
	isQueryBuilderRefactorEnable,
} from 'common/stores/tenantConfig';
import {
	isRulesBasedModelMode,
	setPublicProperty,
	getQueryProperty,
} from 'common/stores/query';
import {updateAllBucketCounts} from 'common/components/datacloud/query/query.helpers';
import type {
	Bkt,
	BucketRestriction,
	LogicalRestrictionRecord,
	Restrictions,
} from 'common/components/datacloud/query/query.types';
import {
	CategoryEntity,
	GroupAttributeNameToIdMap,
	isSupportedGroupAttribute,
} from 'atlas/segmentation/SegmentDashboard/Data/SegmentJourneyType';
import {SetBucketLabel} from 'common/components/datacloud/query/advanced/AdvancedQuery.helper';
import {
	IModalAttribute,
	IModalAttributeMap,
	IModalCategory,
	IModalSubCategoryMap,
} from './AddAttributesModalConst';
import {
	IAddAttributeModalParentScope,
	IAddAttributeModalScope,
	SelectionMode,
} from './AddAttributeModalScopeConst';
import {
	DefaultTopbkt,
	getActivityDefaultTopbkt,
	IAttribute,
} from '../../../data/AttributeConst';
import {CategoryType, ICategoryDict} from '../../../data/CategoryConst';
import {EntityType} from '../../../data/RestrictionConst';
import {SegmentType} from '../../../data/SegmentConst';
import {
	IModalController,
	toModalAttributeMap,
	toModalCategoryList,
} from './ModalController/ModalControllerType';
import {AttributeModalController} from './ModalController/AttributeModalController';
import {SegmentModalController} from './ModalController/SegmentModalController';
import {
	AttributeFilter,
	AttributeSorter,
	AccountContactDataController,
} from './DataController/AccountDataController';
import {
	SegmentFilter,
	SegmentSorter,
	SegmentDataController,
} from './DataController/SegmentDataController';

/**
 * Get attribute id key by bucket restriction
 * @param bucketRestriction Refers to @IBucketRestriction
 * @returns The attribute id key
 * The same id key as per @getAttributeIdKeyByAttribute
 */
const getAttributeIdKeyByRestriction = ({
	attr,
	entityType,
}: BucketRestriction): string => {
	return !entityType ? `${attr}` : `${attr}.${entityType}`;
};

/**
 * Get bucket restriction by attribute id key
 * @param attributeIdKey Attribute id key
 * @returns The bucket restriction
 */
const getRestrictionByAttributeIdKey = (
	attributeIdKey: string
): BucketRestriction => {
	const [entity, attributeName, entityType] = attributeIdKey.split('.');
	return {attr: `${entity}.${attributeName}`, entityType} as BucketRestriction;
};

/**
 * Get enabled segment list.
 * @returns The enabled segment list
 */
const getEnabledSegmentList = (): SegmentType[] => {
	const enabledSegmentList = [SegmentType.Query, SegmentType.TAM];

	const listSegmentMaterialization = isFeatureFlagEnabled(
		FeatureFlags.LIST_SEGMENT_MATERIALIZATION
	);

	if (listSegmentMaterialization) {
		enabledSegmentList.push(SegmentType.List);
	}
	return enabledSegmentList;
};

/**
 * Get top bucket from attribute.
 * @param categoryMap Category to attribute list map
 * @param attribute Attribute to query
 * @returns The top bucket from attribute
 */
const getTopBucketByAttribute = (
	categoryMap: ICategoryDict,
	{Category, Subcategory, AttrName}: IAttribute
): Bkt => {
	const subcategory =
		categoryMap[Category as CategoryType]?.Subcategories?.[Subcategory];
	const findAttributes = subcategory?.find(
		({Attribute}) => Attribute === AttrName
	);
	return findAttributes?.TopBkt || DefaultTopbkt;
};

/**
 * AdAttributesModal's scope in ts version.
 * Delegate js version scope.
 */
class AddAttributesModalScope {
	scope: IAddAttributeModalScope;

	modalController: IModalController;

	constructor(
		scope: IAddAttributeModalScope,
		parentScope: IAddAttributeModalParentScope
	) {
		const {
			mode = SelectionMode.Multiple,
			entity,
			type,
			activityRestriction,
			numerical_operations,
			isCategorySelectEnabled,
			title,
			initRestriction,
			isSaveButtonDisabled,
		} = parentScope;

		const {
			logicalRestriction: {restrictions},
		} = initRestriction ||
			getQueryProperty<LogicalRestrictionRecord>('addBucketTreeRoot') || {
				logicalRestriction: {restrictions: []},
			};

		// a ref copy to scope is needed.
		this.scope = scope;

		this.scope.parent = parentScope;
		this.scope.mode = mode;
		this.scope.numerical_operations = numerical_operations;
		this.scope.entity = entity;
		const isActivity = type === 'Event';
		this.scope.isActivity = isActivity;
		this.scope.searchText = '';
		this.scope.isSaving = false;
		this.scope.restrictions = restrictions;
		this.scope.activityRestriction = activityRestriction;
		this.scope.initActivityRestriction = _.cloneDeep(activityRestriction);
		// Function fields.
		this.scope.getString = getString;
		this.scope.setSearchText = this.setSearchText;
		this.scope.isSaveButtonDisabled = isSaveButtonDisabled
			? () => isSaveButtonDisabled?.(this.scope)
			: this.isSaveButtonDisabled;
		this.scope.setCategoryActive = this.setCategoryActive;
		this.scope.setSubCategoryActive = this.setSubCategoryActive;
		this.scope.isActiveCategory = this.isActiveCategory;
		this.scope.isActiveSubCategory = this.isActiveSubCategory;
		this.scope.searchFilter = this.searchFilter;
		this.scope.clearSearchFilter = this.clearSearchFilter;
		this.scope.clearSelectedAttributes = this.clearSelectedAttributes;
		this.scope.tooltipPosition = this.tooltipPosition;
		this.scope.scrollTo = this.scrollTo;
		this.scope.setSelectedAttributes = this.setSelectedAttributes;
		this.scope.setSelectedAttributeValues = this.setSelectedAttributeValues;
		this.scope.removeSelectedAttributes = this.removeSelectedAttributes;
		this.scope.onSaveClick = this.onSaveClick;
		this.scope.onCancelClick = this.onCancelClick;
		this.scope.filterColIds = this.filterColIds;
		this.scope.filterCategories = this.filterCategories;
		this.scope.getColIdsCount = this.getColIdsCount;
		// Computed fields.
		const [filteredCategories, attributes] = this.getModalViewData(parentScope);
		this.scope.attributes = attributes;
		this.scope.filteredCategories = filteredCategories;
		this.scope.initialFilteredCategories = [...filteredCategories];

		const getAttributeModalController = (): IModalController =>
			new AttributeModalController(
				attributes,
				restrictions,
				entity,
				isActivity
			);
		const getSegmentModalController = (): IModalController =>
			new SegmentModalController(attributes, restrictions);
		const ModalControllerGetterMap = {
			[EntityType.Account]: getAttributeModalController,
			[EntityType.Contact]: getAttributeModalController,
			[EntityType.Member]: getSegmentModalController,
		};
		this.modalController = ModalControllerGetterMap[entity]();

		this.scope.title = title || this.modalController.getTitle();
		this.scope.searchPlaceholder = this.modalController.searchPlaceHolder();
		this.scope.selectedAttributesMap = this.modalController.getCounterMap();
		this.scope.selectedAttributes = () =>
			this.scope.selectedAttributesMap.toArray();
		this.scope.initialSelectedAttributesMap = new CounterMap(
			this.scope.selectedAttributesMap.toArray()
		);
		this.scope.initialSelectedAttributes = () =>
			this.scope.initialSelectedAttributesMap.toArray();
		this.scope.getSelectedCountText = this.modalController.getSelectedCountText;
		this.scope.attributeCheckboxDisabled =
			this.modalController.isAttributeDisabled;

		const initCategory = this.getActiveCategory();
		this.scope.setCategoryActive(initCategory, true);
		this.scope.oldSelectedAttributes = this.oldSelectedAttributes;
		this.scope.checkedAttribute = this.checkedAttribute;
		this.scope.isCategoryCanSelect = ({name}: IModalCategory) => {
			return (
				!!isCategorySelectEnabled &&
				isSupportedGroupAttribute(GroupAttributeNameToIdMap[name] || '')
			);
		};
		this.scope.setSelectedCategory = (
			e: Event,
			category: IModalCategory
		): void => {
			const isChecked = (e.target as HTMLInputElement).checked;
			this.scope.selectedCategory = isChecked ? category.name : '';
			if (isChecked) {
				this.scope.selectedAttributesMap.clear();
			}
		};
		this.scope.removeSelectedCategory = () => {
			this.scope.selectedCategory = '';
		};
		this.scope.isCategoryChecked = (category: IModalCategory): boolean => {
			return this.scope.selectedCategory === category.name;
		};
	}

	getScope = (): IAddAttributeModalScope => {
		return this.scope;
	};

	setSearchText = (searchText: string): void => {
		this.scope.searchText = searchText;
	};

	/**
	 * Get active category.
	 * @returns The initialized category
	 */
	getActiveCategory = (): IModalCategory | undefined => {
		const {
			scope: {filteredCategories},
		} = this;
		return filteredCategories.find(
			(category) => Object.values(category.filterColIds).flat().length > 0
		);
	};

	/**
	 * Set active category to scope.
	 * Rules:
	 * Previous category is equals to current category
	 * - expand if current category is unexpanded.
	 * Previous category is not equals to current category
	 * - Collapse previous category
	 * - Expand active category
	 * @param category Category to set
	 */
	setCategoryActive = (category?: IModalCategory, init = false): void => {
		if (!category) {
			return;
		}
		const {scope} = this;
		const preCategory = scope.activeCategory;
		// pre and current are the same category.
		if (preCategory && _.isEqual(category, preCategory)) {
			preCategory.expanded = !preCategory.expanded;
			return;
		}
		// pre and current are not the same.
		if (preCategory && preCategory.name !== category.name) {
			preCategory.expanded = false;
		}
		scope.activeCategory = category;
		scope.activeCategory.expanded = !init;
		scope.activeCategory.curExpendedSub =
			scope.activeCategory.subCategories[0]!;
	};

	/**
	 * Set active sub category to scope.
	 * Rules:
	 * Previous sub category is equals to current sub category
	 * - expand if current sub category is unexpanded.
	 * Previous sub category is not equals to current sub category
	 * - Collapse previous sub category
	 * - Expand active sub category
	 * @param category Category to set
	 * @param subCategory sub Category to set
	 */
	setSubCategoryActive = (
		category?: IModalCategory,
		subCategory?: string
	): void => {
		if (!category) {
			return;
		}
		const {scope} = this;
		const preCategory = scope.activeCategory;
		// pre and current are the same category.
		if (
			preCategory?.curExpendedSub &&
			category.name === preCategory?.name &&
			category.curExpendedSub === preCategory?.curExpendedSub &&
			(!subCategory || subCategory === preCategory?.curExpendedSub)
		) {
			scope.activeCategory = category;
			scope.activeCategory.curExpendedSub = '';
			return;
		}
		// pre and current are not the same.
		if (subCategory) {
			scope.activeCategory = category;
			scope.activeCategory.expanded = true;
			scope.activeCategory.curExpendedSub = subCategory;
		}
	};

	/**
	 * Check if category is active category.
	 * @param category Category to check
	 * @returns True if active category
	 */
	isActiveCategory = ({name}: IModalCategory): boolean => {
		const {
			scope: {activeCategory: {name: activeName} = {name: ''}},
		} = this;
		return activeName === name;
	};

	/**
	 * Check if sub category is active category.
	 * @param curExpendedSub subCategory to check
	 * @returns True if active sub category
	 */
	isActiveSubCategory = (curExpendedSub: string): boolean => {
		const {
			scope: {
				activeCategory: {curExpendedSub: activeName} = {curExpendedSub: ''},
			},
		} = this;
		return activeName === curExpendedSub;
	};

	/**
	 * Is save button disabled.
	 * @returns True if save button is disabled.
	 * Rules:
	 * True if is saving
	 * True if no data changed
	 */
	isSaveButtonDisabled = (): boolean => {
		const {
			scope: {
				isSaving,
				initialSelectedAttributes,
				selectedAttributes,
				initActivityRestriction,
				activityRestriction,
			},
		} = this;
		const hasDataChanged =
			this.scope.selectedCategory ||
			!(
				_.isEqual(selectedAttributes(), initialSelectedAttributes()) &&
				_.isEqual(activityRestriction, initActivityRestriction)
			);
		return isSaving || !hasDataChanged;
	};

	/**
	 * Get modal view data by parent scope.
	 * @param scope The scope of modal parent
	 * @returns The modal category list and the modal attribute map.
	 */
	getModalViewData = (
		scope: IAddAttributeModalParentScope
	): [IModalCategory[], IModalAttributeMap] => {
		const {
			relation,
			root: {
				entityList: entityListMap,
				enrichmentTopAttributes,
				segmentsList,
				categoryMetadata,
				segment,
			},
			entity: entityType,
			type,
			usableAttributes: attributeList,
		} = scope;
		const isActivity = type === 'Event';
		const getModalAccountAttributeList = (): IModalAttribute[] => {
			return attributeList
				.filter((attribute) =>
					AttributeFilter(attribute, isActivity, entityType, entityListMap)
				)
				.sort(AttributeSorter)
				.map((attribute) => {
					const {FundamentalType} = attribute;
					const topbkt = isActivity
						? getActivityDefaultTopbkt(FundamentalType)
						: getTopBucketByAttribute(enrichmentTopAttributes, attribute);
					const dataController = isActivity
						? new TimeSeriesDataController(
								categoryMetadata,
								attribute,
								topbkt,
								entityType
						  )
						: new AccountContactDataController(
								categoryMetadata,
								attribute,
								topbkt
						  );
					return dataController.toModalAttribute();
				});
		};

		const getModalSegmentAttributeList = (): IModalAttribute[] => {
			return segmentsList
				.filter(({name}) => name !== segment)
				.filter(SegmentFilter)
				.sort(SegmentSorter)
				.map((segment) =>
					new SegmentDataController(segment, relation).toModalAttribute()
				);
		};

		const modalAttributeListGetterMap = {
			[EntityType.Account]: getModalAccountAttributeList,
			[EntityType.Contact]: getModalAccountAttributeList,
			[EntityType.Member]: getModalSegmentAttributeList,
		};

		const modalAttributeList = modalAttributeListGetterMap[entityType]();
		const modalCategory = toModalCategoryList(modalAttributeList);
		const modalAttributeMap = toModalAttributeMap(modalAttributeList);
		return [modalCategory, modalAttributeMap];
	};

	/**
	 *
	 * @param colIds Attribute list for active category
	 * @param searchText search text
	 * @param attributes Attribute list
	 * @returns matched colIds
	 */
	getSearchFilterColIds = (
		colIds: IModalSubCategoryMap,
		searchText: string,
		attributes: IModalAttributeMap
	): IModalSubCategoryMap => {
		const entities = Object.entries(colIds)
			.map(([subCategory, subColIds]) => [
				subCategory,
				subColIds.filter(
					(colId) =>
						attributes[colId]?.name
							.toLowerCase()
							.includes(searchText.toLowerCase()) ||
						attributes[colId]?.Subcategory.toLowerCase().includes(
							searchText.toLowerCase()
						)
				),
			])
			.filter((attr) => attr[1]?.length);
		return Object.fromEntries(entities);
	};

	/**
	 * search callback function.
	 * update filteredCategories and activeCategory after each search.
	 */
	searchFilter = (): void => {
		const {scope} = this;
		const {searchText, attributes, initialFilteredCategories} = scope;
		const newFilteredCategories = initialFilteredCategories
			.map((category) => {
				const filterColIds = this.getSearchFilterColIds(
					category.colIds,
					searchText,
					attributes
				);
				const filterSubcategories = category.subCategories.filter(
					(subCategory) => filterColIds[subCategory]?.length
				);
				let showSubCategories = true;
				if (
					filterSubcategories &&
					filterSubcategories.length === 1 &&
					['Other', category.name].includes(filterSubcategories[0]!)
				) {
					showSubCategories = false;
				}
				return {
					...category,
					filterColIds,
					showSubCategories,
					subCategories: filterSubcategories,
					searched: Object.values(filterColIds).flat().length > 0,
				};
			})
			.filter(
				(category) =>
					scope.parent.root.isNewRedesign ||
					Object.values(category.filterColIds).flat().length > 0
			);
		scope.filteredCategories = newFilteredCategories;
		scope.setCategoryActive(this.getActiveCategory());
	};

	/**
	 * clear search callback function.
	 */
	clearSearchFilter = (): void => {
		const {scope} = this;
		scope.searchText = '';
		this.searchFilter();
	};

	/**
	 * clear select attributes callback function.
	 */
	clearSelectedAttributes = (): void => {
		const {scope} = this;
		scope.selectedAttributesMap.clear();
	};

	/**
	 * On tooltip hover callback.
	 * @param e Mouse event
	 * @param entity Entity type
	 * @param attr Current attribute
	 */
	tooltipPosition = (
		e: MouseEvent,
		entity: EntityType,
		{Title, name}: IModalAttribute
	): void => {
		const currentTarget = e.currentTarget as HTMLElement;
		const {offsetParent, offsetTop, classList} = currentTarget;
		if (!currentTarget || !offsetParent) {
			return;
		}
		if (offsetTop - offsetParent.scrollTop > 150) {
			classList.add('top');
		} else {
			classList.remove('top');
		}
		if (entity !== EntityType.Member && name.length > 21) {
			if (Title.length > 50) {
				classList.add('full-right-corner');
				classList.remove('right');
			} else if (Title.length > 30) {
				classList.add('right');
				classList.remove('full-right-corner');
			}
		}
	};

	/**
	 * use class alt-category scrollTo category
	 * use class alt-subCategory scrollTo sub category
	 * Scroll to specific category.
	 * Update active category name.
	 * Update expanded field to filtered category list.
	 * @param category Category to scroll to
	 * @param subCategory sub Category to scroll to
	 */
	scrollTo = (category: IModalCategory, subCategory: string): void => {
		const {scope} = this;
		if (subCategory) {
			scope.setSubCategoryActive(category, subCategory);
			const subCategoryItems = Array.from(
				document.getElementsByClassName('alt-subCategory')
			);
			const subCategoryItem = subCategoryItems.find((item) =>
				item.textContent?.includes(subCategory)
			);
			if (subCategoryItem) {
				setTimeout(() => {
					subCategoryItem.scrollIntoView(true);
				});
			}
		} else {
			scope.setCategoryActive(category);
			const isActiveCategory = scope.isActiveCategory(category);
			if (isActiveCategory) {
				const categoryItems = Array.from(
					document.getElementsByClassName('alt-category')
				);
				const categoryItem = categoryItems.find((item) =>
					item.textContent?.includes(category.name)
				);
				setTimeout(() => {
					categoryItem?.scrollIntoView(true);
				});
			}
		}
	};

	setSelectedCommon = (
		attrId: string,
		opsMap: Record<
			SelectionMode,
			Record<
				'true' | 'false',
				((key: string, bkt?: Bkt, addNewOneBkt?: boolean) => void)[]
			>
		>,
		addNewOneBkt?: boolean,
		hasValue?: boolean,
		bkt?: Bkt
	): void => {
		this.scope.selectedCategory = '';
		const {scope} = this;
		const {mode, selectedAttributesMap, attributes} = scope;
		let topbkt = bkt;
		if (!topbkt) {
			topbkt = attributes[attrId]?.topbkt;
		}
		const hasAttribute =
			hasValue === undefined ? selectedAttributesMap.has(attrId) : hasValue;
		const operatorList = opsMap[mode][hasAttribute ? 'true' : 'false'];
		operatorList.forEach((operator) =>
			addNewOneBkt === undefined
				? operator(attrId, _.cloneDeep(topbkt))
				: operator(attrId, _.cloneDeep(topbkt), addNewOneBkt)
		);
	};

	/**
	 * Select attribute setter.
	 * Set selected attributes, attribute category.
	 * @param attrId The selected attribute name
	 */
	setSelectedAttributes = (
		_e: MouseEvent,
		attrId: string,
		_category: IModalCategory,
		bkt?: Bkt
	): void => {
		const {scope} = this;
		const {selectedAttributesMap} = scope;
		const opsMap = {
			[SelectionMode.Single]: {
				true: [selectedAttributesMap.deleteKey],
				false: [selectedAttributesMap.clear, selectedAttributesMap.add],
			},
			[SelectionMode.Multiple]: {
				true: [selectedAttributesMap.deleteKey],
				false: [selectedAttributesMap.add],
			},
		};
		this.setSelectedCommon(attrId, opsMap, undefined, undefined, bkt);
	};

	setSelectedAttributeValues = (
		attrId: string,
		addNewOneBkt: boolean,
		hasValue?: boolean,
		bkt?: Bkt
	): void => {
		const {scope} = this;
		const {selectedAttributesMap} = scope;
		const opsMap = {
			[SelectionMode.Single]: {
				true: [selectedAttributesMap.deleteValue],
				false: [selectedAttributesMap.clear, selectedAttributesMap.addValue],
			},
			[SelectionMode.Multiple]: {
				true: [selectedAttributesMap.deleteValue],
				false: [selectedAttributesMap.addValue],
			},
		};
		this.setSelectedCommon(attrId, opsMap, addNewOneBkt, hasValue, bkt);
	};

	/**
	 * Remove attribute by id.
	 * @param attrId The attribute id
	 */
	removeSelectedAttributes = (attrId: string, bkt?: Bkt): void => {
		const {scope} = this;
		const {selectedAttributesMap} = scope;
		selectedAttributesMap.delete(attrId, bkt);
	};

	/**
	 * Get added and deleted attributes.
	 * @returns Added attribute names and Deleted attribute names
	 */
	getAddedAndDeletedAttributes = (): [IKeyValues[], string[]] => {
		const {
			scope: {initialSelectedAttributesMap, selectedAttributesMap},
		} = this;
		const addedAttributes = selectedAttributesMap
			.toKeyValueArray()
			.filter(({attrId, bkt}) => {
				let unchanged = true;
				initialSelectedAttributesMap
					.toKeyValueArray()
					.forEach(({attrId: initAttrId, bkt: initBkt}) => {
						if (initBkt && bkt && initAttrId === attrId) {
							unchanged = _.isEqual(initBkt, bkt);
						}
					});
				return !initialSelectedAttributesMap.has(attrId) || !unchanged;
			});
		const deletedAttributes = initialSelectedAttributesMap
			.toKeyValueArray()
			.filter(({attrId: initAttrId, bkt: initBkt}) => {
				let unchanged = true;
				selectedAttributesMap.toKeyValueArray().forEach(({attrId, bkt}) => {
					if (initBkt && bkt && initAttrId === attrId) {
						unchanged = _.isEqual(initBkt, bkt);
					}
				});
				return !selectedAttributesMap.has(initAttrId) || !unchanged;
			})
			.flatMap(({attrId, count}) => {
				return new Array(count).fill(0).map(() => attrId);
			});
		return [addedAttributes, deletedAttributes];
	};

	/**
	 * Delete restriction by selected attributes and entity type,
	 * return the new restriction array.
	 */
	deleteRestrictions = (deleteAttributes: string[]): void => {
		const {
			modalController,
			scope: {restrictions},
		} = this;
		const doDelete = (): void => {
			let i = 0;
			while (i < restrictions.length) {
				const restriction = restrictions[i]!;
				const key = modalController.toAttributeKey(restriction);
				// ignore invalid bucket restriction type.
				if (!key) {
					++i;
				} else {
					const findIndex = deleteAttributes.indexOf(key);
					if (findIndex >= 0) {
						deleteAttributes.splice(findIndex, 1);
						restrictions.splice(i, 1);
					} else {
						++i;
					}
				}
			}
		};
		doDelete();
	};

	onSaveClick = (e: Event): void => {
		if (e?.preventDefault != null) {
			e.preventDefault();
		}

		const {scope} = this;

		const {
			restrictions,
			parent: {root, onSaveClick},
		} = scope;

		const [addAttributes, deleteAttributes] =
			this.getAddedAndDeletedAttributes();

		if (onSaveClick) {
			if (this.scope.selectedCategory) {
				onSaveClick([[CategoryEntity, this.scope.selectedCategory].join('.')]);
			} else {
				onSaveClick(scope.selectedAttributesMap.toKeyArray());
			}
			return;
		}

		scope.isSaving = true;

		const hasDeletedAttributes = deleteAttributes.length > 0;

		if (hasDeletedAttributes) {
			this.deleteRestrictions(deleteAttributes);
		}

		const needUpdateCountRestrictions: Restrictions = [];

		addAttributes.forEach(({attrId, bkt}) => {
			if (bkt) {
				bkt.forEach((inner) => {
					if (inner) {
						const newRestriction = this.modalController.toRestriction(
							attrId,
							isRulesBasedModelMode(),
							inner
						);
						const newLabelTree = isQueryBuilderRefactorEnable()
							? SetBucketLabel(newRestriction)
							: newRestriction;
						restrictions.push(newLabelTree);
						if (this.modalController.needUpdateRestrictionCount()) {
							needUpdateCountRestrictions.push(newLabelTree);
						}
					}
				});
			} else {
				const newRestriction = this.modalController.toRestriction(
					attrId,
					isRulesBasedModelMode()
				);
				const newLabelTree = isQueryBuilderRefactorEnable()
					? SetBucketLabel(newRestriction)
					: newRestriction;
				restrictions.push(newLabelTree);
				if (this.modalController.needUpdateRestrictionCount()) {
					needUpdateCountRestrictions.push(newLabelTree);
				}
			}
		});

		if (!isRulesBasedModelMode()) {
			updateAllBucketCounts(needUpdateCountRestrictions);
		}

		setPublicProperty('enableSaveSegmentButton', true);

		root.saveState();
		root.setTotalRuleCount();
	};

	onCancelClick = (): void => {
		const {
			parent: {onCancelClick},
		} = this.scope;
		if (onCancelClick) {
			onCancelClick?.();
			return;
		}
		const {initActivityRestriction, activityRestriction} = this.scope;
		if (!activityRestriction || !initActivityRestriction) {
			return;
		}
		const {
			activityRestriction: {cnt = [], cmp},
		} = initActivityRestriction;
		activityRestriction.activityRestriction.cnt = [...cnt];
		activityRestriction.activityRestriction.cmp = cmp;
	};

	/**
	 * Get filtered attribute list per specific attribute id.
	 * @param attrId Attribute id to query
	 * @returns The filtered attribute
	 */
	filterColIds = (attrId: string): IAttributeValues[] => {
		const {selectedAttributesMap} = this.scope;
		return selectedAttributesMap
			.toArray()
			.filter((values) => values.attrId === attrId);
	};

	/**
	 * Get selected attribute count for active category.
	 * @param colIds Attribute list for active category
	 * @param subCategory subCategory for active category
	 * @returns The selected attribute count for active category
	 */
	filterCategories = (
		colIds: IModalSubCategoryMap,
		subCategory: string
	): number => {
		const {selectedAttributesMap} = this.scope;
		return selectedAttributesMap.toArray().reduce((pre, attribute) => {
			if (subCategory) {
				const hasAttribute = colIds[subCategory]?.includes(attribute.attrId);
				return hasAttribute ? pre + 1 : pre;
			}
			const hasAttribute = Object.values(colIds)
				.flat()
				.includes(attribute.attrId);
			return hasAttribute ? pre + 1 : pre;
		}, 0);
	};

	/**
	 * @param colIds  Attribute list for active category
	 * @returns Attribute list count
	 */
	getColIdsCount = (colIds: IModalSubCategoryMap): number => {
		return Object.values(colIds).flat().length;
	};

	/**
	 * @returns
	 */
	oldSelectedAttributes = (): string[] => {
		return this.scope.selectedAttributes().map((a) => a.attrId);
	};

	/**
	 * @param colId
	 * @returns
	 */
	checkedAttribute = (colId: string): boolean => {
		return (
			this.scope
				.selectedAttributes()
				.findIndex((values) => values.attrId === colId) > -1
		);
	};
}

export {
	getAttributeIdKeyByRestriction,
	getRestrictionByAttributeIdKey,
	getEnabledSegmentList,
	getTopBucketByAttribute,
	AddAttributesModalScope,
};
