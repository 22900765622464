import React from 'common/react-vendor';
import {DNBSelect, DNBSelectOption, DNBTypography} from 'common/dnb-uux-vendor';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {QueryState} from 'common/stores/query/types';
import {Operator} from '../../query.enums';
import {useQueryTree} from './hook/useQueryTree';
import {AdvancedQueryIcon} from '../AdvancedQueryIcon';
import {
	ClickCollapsed,
	clickDelete,
	ClickOperator,
	ClickSubType,
	OperatorLabel,
	getKey,
	mouseMove,
	mouseOver,
	mouseOverHighlight,
} from './QueryTree.helpers';
import {useAdvancedQuery} from '../hook/useAdvancedQuery';
import {IQueryTreeContext} from './context/QueryTreeContext';
import {IAdvancedQueryContextUpdate} from '../context/AdvancedQueryContext';
import {IQueryBlockType} from '../queryBlock/queryBlockTypes';
import {QueryTreeActivity} from './QueryTreeActivity';
import {getBucketLabel} from '../AdvancedQuery.helper';

const RenderOperatorSection = (
	operator: Operator,
	context: IQueryTreeContext,
	setAdvancedQuery: IAdvancedQueryContextUpdate,
	disableAllTreeRestrictions: boolean
): React.ReactElement => {
	const {root} = context;
	const onChange = (value: Operator | null): void => {
		if (value !== null) {
			ClickOperator(value, context, setAdvancedQuery);
		}
	};
	return (
		<DNBSelect<Operator>
			value={operator}
			size='compact'
			disabled={!root.canEdit || disableAllTreeRestrictions}
			onChange={(_, value) => onChange(value)}>
			{Object.keys(Operator).map((key) => (
				<DNBSelectOption key={key} value={key}>
					{OperatorLabel[key as Operator]}
				</DNBSelectOption>
			))}
		</DNBSelect>
	);
};
const QueryTreeContainerOperator = (): React.ReactElement => {
	const {context: queryTree} = useQueryTree();
	const {context: advancedQuery, setContext: setAdvancedQuery} =
		useAdvancedQuery();
	const {
		tree,
		root,
		memberType,
		subType,
		enable_calculate_known_contact,
		entity,
		activityRestriction,
		editing,
	} = queryTree;
	const draggedItem = root.draggedItem?.current;
	const draggedItemTree = draggedItem?.tree || draggedItem?.tree;
	const entityLowerCase = entity.toLocaleLowerCase();
	// const {setTree} = setContext;
	const getHelperText = (type: string | undefined): string => {
		let helperText = 'Build a segment with';
		switch (type) {
			case IQueryBlockType.Union:
				helperText = 'Expand the target audience by unifying existing segment';
				break;
			case IQueryBlockType.Intersection:
				helperText =
					'Narrow down the target audience by taking intersection existing segment';
				break;
			default:
				helperText = `Build a segment with ${entityLowerCase}s that have`;
				break;
		}
		return helperText;
	};
	const disableAllTreeRestrictions = useSelector(
		(state: {query: QueryState}) =>
			state?.query?.public.disableAllTreeRestrictions
	);
	const OperatorSection = (): React.ReactElement =>
		RenderOperatorSection(
			tree?.logicalRestriction?.operator || Operator.AND,
			queryTree,
			setAdvancedQuery,
			disableAllTreeRestrictions
		);
	return (
		<>
			{tree.logicalRestriction && (
				// -- AND/OR operator container --
				<div
					key={getKey(JSON.stringify(tree))}
					className={classNames({
						querySection: true,
						querySectionAll: tree.logicalRestriction.operator === 'AND',
						querySectionAny: tree.logicalRestriction.operator === 'OR',
						canEdit: root.canEdit,
					})}
					style={{
						minHeight: '36px',
					}}
					onMouseOver={(e) => {
						mouseOver(e, editing, queryTree, advancedQuery);
						if (draggedItemTree) {
							mouseOverHighlight(getBucketLabel(draggedItemTree));
						}
					}}
					onMouseMove={(e) => {
						mouseMove(
							e,
							editing,
							queryTree,
							advancedQuery,
							setAdvancedQuery,
							true
						);
					}}>
					<div className='querySectionTypeDotline operation' />
					<div
						className={classNames({
							querySectionLabel: true,
							dragging: root.draggedItem?.current === queryTree,
							dropped: root.droppedItem?.current === queryTree,
							canEdit: root.canEdit,
						})}>
						<AdvancedQueryIcon
							disabled={disableAllTreeRestrictions}
							icon={tree.collapsed ? 'ExpandMoreIcon' : 'ExpandLessIcon'}
							className='querySectionLabelExpand icon dnbButtonIcon'
							onClick={() => ClickCollapsed(queryTree)}
						/>
						<span className='queryHelperText'>
							<DNBTypography component='span' variant='compact-medium'>
								{getHelperText(memberType)}
							</DNBTypography>
							{enable_calculate_known_contact &&
								entityLowerCase === 'contact' && (
									<select
										className='querySectionSubtypeDropdown'
										value={subType}
										onChange={() => ClickSubType(queryTree, setAdvancedQuery)}>
										<option value='Known'>Known {entityLowerCase}s</option>
										<option value='Unknown'>Unknown {entityLowerCase}s</option>
										<option value='All'>All {entityLowerCase}s</option>
									</select>
								)}
						</span>
						<div className={`querySectionOperatorDropdown ${entity}`}>
							{entityLowerCase !== 'member' && OperatorSection()}
						</div>
						<div className='querySectionControls'>
							<AdvancedQueryIcon
								icon='DeleteOutlineIcon'
								className='deleteIcon'
								onClick={() => {
									clickDelete(queryTree, setAdvancedQuery);
								}}
							/>
						</div>
						{/* -- activity event number container -- */}
						{activityRestriction && <QueryTreeActivity />}
					</div>
				</div>
			)}
		</>
	);
};

export {QueryTreeContainerOperator};
