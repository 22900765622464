import React from 'common/react-vendor';
import {
	DNBCard,
	DNBDivider,
	DNBIconButton,
	DNBTag,
	DNBTagTypes,
	DNBTooltip,
	DNBTypography,
	DeleteOutlineOutlinedIcon,
	ExpandLessIcon,
	ExpandMoreIcon,
	InfoOutlinedIcon,
	theme,
} from 'common/dnb-uux-vendor';
import classNames from 'classnames';
import {VariableSizeList as List} from 'common/widgets/react-window';
import {IChip, IChips, IDialogUpdated, RightSection} from '../ChipsTypes';
import styles from '../Chips.module.scss';
import {useChipsContext} from '../useChipsContext';
import {getChipsCount, getDisplayName} from '../ChipsController';

const sectionHeaderHeight = 40;
const rightSectionHeight = 660;

interface ICardConfig {
	title: string;
	tooltip: string;
	isExpend: boolean;
	tagType: DNBTagTypes;
	icon?: React.ReactElement;
	color?: string;
}

type IRightSectionCardConfig = Record<RightSection, ICardConfig>;

const defaultRightSectionCardConfig: IRightSectionCardConfig = {
	[RightSection.NEWLY]: {
		title: 'NEWLY ADDED',
		tooltip: 'The additional values will be added.',
		isExpend: true,
		color: theme.colors.ColorGraySecondary,
		tagType: 'default',
	},
	[RightSection.PREVIOUSLY]: {
		title: 'PREVIOUSLY ADDED',
		tooltip: '',
		isExpend: true,
		color: theme.colors.ColorGraySecondary,
		tagType: 'default',
	},
};

const SectionHeader = ({
	sectionKey,
	count,
	section,
	resetItems,
	RightSectionCardConfig,
	setRightSectionCardConfig,
	isDialogUpdated,
	setIsDialogUpdated,
}: {
	sectionKey: string;
	count: number;
	section: ICardConfig;
	resetItems: (item: IChips) => void;
	RightSectionCardConfig: IRightSectionCardConfig;
	setRightSectionCardConfig: (config: IRightSectionCardConfig) => void;
	isDialogUpdated: boolean;
	setIsDialogUpdated: (update: boolean) => void;
}): React.ReactElement => {
	const setCardExpend = (key: RightSection, expanded = false): void => {
		const isExpend = RightSectionCardConfig[key]?.isExpend;
		const newIsCardExpend = {
			...RightSectionCardConfig,
			[key]: {
				...RightSectionCardConfig[key],
				isExpend: expanded || !isExpend,
			},
		};
		setRightSectionCardConfig(newIsCardExpend);
	};
	return (
		<DNBTypography
			component='div'
			variant='overline'
			sx={{fontWeight: 850}}
			id={`${sectionKey}-header`}
			className={styles.sectionHeader}>
			<DNBTypography
				component='div'
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}>
				<DNBTypography
					component='span'
					variant='overline'
					sx={{paddingLeft: '4px', fontWeight: 850}}>
					{`${count} ${section.title}`}
				</DNBTypography>
				{section.tooltip && (
					<DNBTooltip arrow placement='right' content={section.tooltip}>
						<InfoOutlinedIcon
							fontSize='small'
							sx={{
								cursor: 'pointer',
								marginLeft: 2,
								color: (theme) => theme.colors.ColorGraySecondary,
								fontSize: (theme) => theme.spacing(5),
							}}
						/>
					</DNBTooltip>
				)}
			</DNBTypography>
			<div>
				<DNBIconButton
					size='small'
					sx={{padding: '6px'}}
					onClick={() => {
						resetItems({});
						setIsDialogUpdated(!isDialogUpdated);
					}}>
					<DeleteOutlineOutlinedIcon fontSize='small' />
				</DNBIconButton>
				<DNBIconButton
					size='small'
					sx={{padding: '6px'}}
					onClick={() => setCardExpend(sectionKey as RightSection)}>
					{section.isExpend ? (
						<ExpandMoreIcon fontSize='small' />
					) : (
						<ExpandLessIcon fontSize='small' />
					)}
				</DNBIconButton>
			</div>
		</DNBTypography>
	);
};

const SectionCard = ({
	sectionKey,
	listHeight,
	section,
	sourceList,
	displayName,
	deleteFunction,
	disabled,
	isDialogUpdated,
	setIsDialogUpdated,
}: {
	sectionKey: string;
	listHeight: number;
	section: ICardConfig;
	sourceList: [string, IChips][];
	displayName: keyof IChip;
	deleteFunction: (item: IChip) => void;
	disabled: boolean;
	isDialogUpdated: boolean;
	setIsDialogUpdated: (update: boolean) => void;
}): React.ReactElement => {
	return (
		<DNBCard
			variant='white'
			cardColor='ColorGrayPrimary'
			className={styles.sectionBody}
			id={`${sectionKey}-body`}>
			<div className={styles.chip}>
				<List
					height={listHeight}
					width='100%'
					itemSize={() => 26}
					itemCount={sourceList.length}>
					{({index, style}) => {
						const [key, item] = sourceList[index] || [];
						if (item) {
							const name = getDisplayName(displayName, item);
							return (
								<DNBTypography component='div' key={key} style={style} p={2}>
									<DNBTag
										type={section.tagType}
										key={key}
										className={styles.content}
										sx={{
											color: () => section.color,
										}}
										onDelete={() => {
											deleteFunction(item);
											setIsDialogUpdated(!isDialogUpdated);
										}}
										disabled={disabled}
										label={
											<DNBTypography
												component='div'
												variant='caption'
												sx={{
													display: 'flex',
													alignItems: 'center',
												}}>
												<DNBTooltip content={name} placement='top' arrow>
													<div className={styles.text}>
														<span>{name}</span>
													</div>
												</DNBTooltip>
											</DNBTypography>
										}
										leadingIcon={section.icon}
									/>
								</DNBTypography>
							);
						}
						return <></>;
					}}
				</List>
			</div>
		</DNBCard>
	);
};

const RightSectionCard = (props: IDialogUpdated): React.ReactElement => {
	const {context, setContext} = useChipsContext();
	const {
		ID,
		displayName,
		toBeAddedItems,
		disabled = false,
		previousItems,
	} = context;
	const {setToBeAddedItems, setPreviousItems} = setContext;
	const {isDialogUpdated, setIsDialogUpdated} = props;
	const removeToBeAddedItem = (item: IChip): void => {
		if (Object.hasOwn(toBeAddedItems, item[ID] as string)) {
			delete toBeAddedItems[item[ID] as string];
		}
		setToBeAddedItems(toBeAddedItems);
		setIsDialogUpdated(!isDialogUpdated);
	};
	const removePlatFormItems = (item: IChip): void => {
		if (Object.hasOwn(previousItems, item[ID] as string)) {
			delete previousItems[item[ID] as string];
		}
		setPreviousItems(previousItems);
		setIsDialogUpdated(!isDialogUpdated);
	};
	const [RightSectionCardConfig, setRightSectionCardConfig] =
		React.useState<IRightSectionCardConfig>(defaultRightSectionCardConfig);

	const cardDataAndAction = {
		[RightSection.NEWLY]: {
			resetItems: setToBeAddedItems,
			deleteFunction: removeToBeAddedItem,
			dataSource: toBeAddedItems,
			showDivider: false,
		},
		[RightSection.PREVIOUSLY]: {
			resetItems: setPreviousItems,
			deleteFunction: removePlatFormItems,
			dataSource: previousItems,
			showDivider: getChipsCount(toBeAddedItems) > 0,
		},
	};

	const expandedCount = Object.entries(RightSectionCardConfig).filter(
		([key, card]) =>
			card.isExpend &&
			Object.values(cardDataAndAction[key as RightSection]?.dataSource).length >
				0
	).length;

	return (
		<div className={classNames(styles.section)}>
			{Object.entries(RightSectionCardConfig).map(([sectionKey, section]) => {
				const {dataSource, showDivider, deleteFunction, resetItems} =
					cardDataAndAction[sectionKey as RightSection];
				const count = getChipsCount(dataSource);
				const sourceList = Object.entries(dataSource);
				const listHeight =
					(rightSectionHeight - sectionHeaderHeight * 2) / expandedCount;
				return (
					<div key={`${sectionKey}-header`} className={sectionKey}>
						{count > 0 && (
							<div>
								{showDivider && <DNBDivider />}
								<SectionHeader
									sectionKey={sectionKey}
									count={count}
									resetItems={resetItems}
									section={section}
									RightSectionCardConfig={RightSectionCardConfig}
									setRightSectionCardConfig={setRightSectionCardConfig}
									isDialogUpdated={isDialogUpdated}
									setIsDialogUpdated={setIsDialogUpdated}
								/>
								{section.isExpend && (
									<SectionCard
										sectionKey={sectionKey}
										listHeight={listHeight}
										section={section}
										sourceList={sourceList}
										displayName={displayName}
										deleteFunction={deleteFunction}
										disabled={disabled}
										isDialogUpdated={isDialogUpdated}
										setIsDialogUpdated={setIsDialogUpdated}
									/>
								)}
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};

const ChipsRightSection = (props: IDialogUpdated): React.ReactElement => {
	return (
		<DNBTypography component='div' className={styles.ChipsRightSection}>
			<RightSectionCard {...props} />
		</DNBTypography>
	);
};

export {ChipsRightSection};
