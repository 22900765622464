import {NgStateService as IState} from 'common/react-vendor';
import {
	Modes,
	dispatchQueryPropertyValue,
	dispatchSetAddBucketTreeRoot,
	dispatchSetCountLoading,
	getQueryProperty,
	setEntitiesProperty,
	setPublicProperty,
	setResourceTypeCount,
	setRestrictions,
	updatePublicRefresh,
} from 'common/stores/query';
import {store} from 'store';
import {clone, cloneDeep, isEmpty, isEqual, isNil} from 'lodash';
import {QueryPublic, RestrictionType} from 'common/stores/query/types';
import {
	CoverageRequest,
	CoverageResponse,
	RatingCounts,
	RatingsBuckets,
} from 'atlas/ratingsengine/ratingsengine.types';
import {
	RatingsPageParams,
	StateService,
	getBucketRuleCounts,
	getCoverageMap,
	saveRatingEngineRules,
} from 'atlas/ratingsengine/ratingsengine.helpers';
import {IRootRestriction} from 'atlas/data/RestrictionConst';
import {
	getEnrichments,
	getEventEnrichments,
	setDataCloudPropertyValue,
} from 'common/stores/datacloud';
import {dispatchRatingsEnginePropertyValue} from 'atlas/stores/ratingsEngine';
import {SEGMENTS_EXPLORER} from 'atlas/navigation/header/components/page-navigation.utils';
import {ISegment} from 'atlas/data/SegmentConst';
import {isBuyerJourneyEnabled} from 'common/stores/tenantConfig';
import {
	BucketRestriction,
	LogicalRestriction,
	Restriction,
	Restrictions,
} from '../query.types';
import {IQueryBuilderList} from './list/QueryBuilderListTypes';
import {QueryButtonGroupHeigh, StickyHeight} from '../../datacloud.constants';
import {
	RuleCountBkt,
	getAllActivityBuckets,
	getEntitiesCounts,
	getRuleAllBuckets,
	getRuleCount,
	refreshCounts,
} from '../query.helpers';
import {RatingModel} from '../../datacloud.types';
import {BucketCmp, Operator} from '../query.enums';
import {
	IAdvancedQueryContext,
	IAdvancedQueryContextUpdate,
} from './context/AdvancedQueryContext';
import {
	findAndUpdatedLogicalRestriction,
	removeEmptyBuckets,
	sanitizeSegment,
	sanitizeSegmentRestriction,
} from '../../segment/segment.helpers';
import {IQueryTreeContext} from './tree/context/QueryTreeContext';
import {IQueryBlockType} from './queryBlock/queryBlockTypes';
import {getNextLabelGlyph} from './tree/QueryTree.helpers';

const getBucketLabel = (bucket: Restriction, forceUpdate = false): number => {
	const queryPublic: QueryPublic = getQueryProperty('public');
	const {resetLabelIncrementor} = queryPublic;
	if (resetLabelIncrementor && forceUpdate) {
		setPublicProperty('labelIncrementor', 0);
		setPublicProperty('resetLabelIncrementor', false);
	}

	if (bucket && bucket.labelGlyph && !forceUpdate) {
		return bucket.labelGlyph;
	}
	const newLabelIncrementor = getNextLabelGlyph();
	setPublicProperty('labelIncrementor', newLabelIncrementor);
	return newLabelIncrementor;
};
const SetBucketLabel = (
	bucket: Restriction,
	forceUpdate = false
): Restriction => {
	let newBucket: Restriction = {};
	if (bucket?.activityRestriction?.restriction?.logicalRestriction) {
		const newRestrictions =
			bucket.activityRestriction.restriction.logicalRestriction?.restrictions.map(
				(innerBucket) => {
					const newBucketHasLabel = SetBucketLabel(innerBucket, forceUpdate);
					return newBucketHasLabel;
				}
			);
		newBucket = {
			...bucket,
			activityRestriction: {
				...bucket.activityRestriction,
				restriction: {
					...bucket.activityRestriction.restriction,
					logicalRestriction: {
						...bucket.activityRestriction.restriction.logicalRestriction,
						restrictions: newRestrictions,
					},
				},
			},
		};
	} else if (bucket?.bucketRestriction || bucket?.segmentMemberRestriction) {
		const newBucketHasLabel = {
			...bucket,
			labelGlyph: getBucketLabel(bucket, forceUpdate),
		};
		return newBucketHasLabel;
	} else if (bucket?.logicalRestriction?.restrictions) {
		const newRestrictions = bucket.logicalRestriction.restrictions.map(
			(innerBucket) => {
				const newBucketHasLabel = SetBucketLabel(innerBucket, forceUpdate);
				return newBucketHasLabel;
			}
		);
		newBucket = {
			...bucket,
			logicalRestriction: {
				...bucket.logicalRestriction,
				restrictions: newRestrictions,
			},
		};
	}
	return newBucket;
};
const getRestrictionEntity = function (entity: string): string {
	const isContact =
		entity === 'Contact' ||
		entity === 'ContactMarketingActivity' ||
		entity === 'CuratedContact';
	return isContact ? 'contact' : 'account';
};

const InitCoverageMap = (
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate,
	map?: CoverageResponse
): void => {
	const {bucketLabels, bucketsMap} = value;
	const {setBuckets, setCoverageMap} = action;
	const n = map ? 0 : -1;
	const buckets: RuleCountBkt[] = [];
	bucketLabels.forEach((bucketName) => {
		buckets.push({bucket: bucketName, count: n});
	});

	if (map) {
		const segmentId = Object.keys(map.segmentIdModelRulesCoverageMap)[0];
		if (segmentId) {
			const coverage_map = map.segmentIdModelRulesCoverageMap[segmentId];
			setCoverageMap(coverage_map);

			if (coverage_map) {
				coverage_map.bucketCoverageCounts.forEach(function (bkt) {
					const index = bucketsMap?.[bkt.bucket as keyof RatingCounts];
					let bucket = buckets?.[index];
					if (bucket) {
						bucket = {
							...bucket,
							count: bkt.count,
						};
					}
				});
			}
		}
	}
	setBuckets(buckets);
};

const GetAllBucketRestrictions = (
	value: IAdvancedQueryContext
): Restrictions => {
	const {ratingEngineModel, bucketLabels} = value;
	const RatingEngineCopy = ratingEngineModel;
	const BucketMap = RatingEngineCopy?.rule?.ratingRule.bucketToRuleMap;
	let restrictions: Restrictions = [];
	bucketLabels.forEach(function (bucketName) {
		const accountRestriction = BucketMap?.[bucketName]?.account_restriction;
		let accountLogical = {
			operator: 'AND',
			restrictions: [] as Restriction[],
		};
		if (accountRestriction) {
			accountLogical = {
				...accountLogical,
				...accountRestriction?.logicalRestriction,
			};
		} else {
			accountLogical = {operator: 'AND', restrictions: []};
			// TO DO
			// BucketMap?.[bucketName]?.['account_restriction'] = {
			// 	logicalRestriction: accountLogical,
			// };
		}
		const contactRestriction = BucketMap?.[bucketName]?.contact_restriction;
		let contactLogical = {
			operator: 'AND',
			restrictions: [] as Restriction[],
		};
		if (contactRestriction) {
			contactLogical = {
				...contactLogical,
				...contactRestriction?.logicalRestriction,
			};
		} else {
			contactLogical = {operator: 'AND', restrictions: []};
			// contactRestriction = {
			// 	logicalRestriction: contactLogical,
			// };
		}
		restrictions = getAllActivityBuckets(
			accountLogical.restrictions,
			restrictions
		);
		restrictions = getAllActivityBuckets(
			contactLogical.restrictions,
			restrictions
		);
	});
	return restrictions;
};

const GetRuleRecordCounts = function (
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate
): CoverageRequest {
	const restrictions = GetAllBucketRestrictions(value);
	const {currentRatingEngine} = value;
	const {setRuleRecordMap} = action;
	const segmentId = currentRatingEngine?.segment?.name || '';
	const RuleRecordMap: Record<string, Restriction> = {};
	restrictions.map((bucket, index) => {
		const newBucket = clone(bucket);
		if (bucket.bucketRestriction) {
			let {bkt} = bucket.bucketRestriction;
			bkt = {
				...bkt,
				Cnt: -1,
			};
			newBucket.bucketRestriction = {
				...bucket.bucketRestriction,
				bkt,
			};
			RuleRecordMap[`${bucket.bucketRestriction.attr}_${index}`] = bucket;
		} else if (bucket.activityRestriction) {
			let {activityRestriction} = bucket;
			activityRestriction = {
				...activityRestriction,
				count: -1,
			};
			newBucket.activityRestriction = activityRestriction;
			RuleRecordMap[`activityRestriction_${index}`] = bucket;
		}
		return newBucket;
	});
	setRuleRecordMap(RuleRecordMap);
	return getBucketRuleCounts(
		cloneDeep(restrictions),
		segmentId
	) as CoverageRequest;
};

const GetRatingsAndRecordCounts = (
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate,
	model: RatingModel,
	segmentName: string
): void => {
	const {RuleRecordMap} = value;
	const newRuleRecordMap = RuleRecordMap;
	const {setRuleRecordMap} = action;
	const rulesForCounts = GetRuleRecordCounts(value, action);
	getCoverageMap(model, segmentName, rulesForCounts)
		.then(function (result) {
			if (typeof result !== 'string') {
				InitCoverageMap(value, action, result);
				const buckets = result?.segmentIdAndSingleRulesCoverageMap;
				if (buckets) {
					Object.keys(buckets).forEach(function (key) {
						if (newRuleRecordMap[key]) {
							const bucketRestriction =
								newRuleRecordMap[key]?.bucketRestriction;
							if (bucketRestriction) {
								const label = bucketRestriction?.attr;
								const type = getRestrictionEntity(label.split('.')[0] || '');
								const subKey =
									type === 'account' ? 'accountCount' : 'contactCount';

								newRuleRecordMap[key] = {
									...newRuleRecordMap[key],
									bucketRestriction: {
										...bucketRestriction,
										bkt: {
											...bucketRestriction?.bkt,
											Cnt: buckets[key]?.[subKey],
										},
									},
								};
							}
							const activityRestriction =
								newRuleRecordMap[key]?.activityRestriction;
							if (activityRestriction) {
								newRuleRecordMap[key] = {
									...newRuleRecordMap[key],
									activityRestriction: {
										...activityRestriction,
										count:
											(buckets[key]?.accountCount || 0) +
											(buckets[key]?.contactCount || 0),
									},
								};
							}
						}
					});
				}
				setRuleRecordMap(newRuleRecordMap);
			}
		})
		.catch(() => {
			// $rootScope.$apply();
			console.log('getCoverageMap error!');
		});
};

const UpdateCount = (
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate,
	refresh = true
): void => {
	const {
		treeMode,
		ratingEngineModel,
		bucketLabels,
		currentRatingEngine,
		isAccountFitModelingSuccess,
		isRulesBasedModelMode,
		rootScope,
	} = value;
	setPublicProperty('enableSaveSegmentButton', true);
	if (isRulesBasedModelMode) {
		dispatchSetCountLoading(`${treeMode}s`, true);
		const RatingEngineCopy = cloneDeep(ratingEngineModel);
		const BucketMap = RatingEngineCopy?.rule?.ratingRule.bucketToRuleMap;
		const buckets: RuleCountBkt[] = [];
		bucketLabels.forEach((bucketName) => {
			buckets.push({bucket: bucketName, count: -1});
			// vm.buckets[vm.bucketsMap[bucketName]].count = -1;
			const [accountRestriction, contactRestriction, memberRestriction] =
				removeEmptyBuckets([
					BucketMap?.[bucketName]?.account_restriction || {},
					BucketMap?.[bucketName]?.contact_restriction || {},
					BucketMap?.[bucketName]?.member_restriction || {},
				]);
			const [
				sanitizeAccountRestriction,
				sanitizeContactRestriction,
				sanitizeMemberRestriction,
			] = sanitizeSegmentRestriction([
				accountRestriction || {},
				contactRestriction || {},
				memberRestriction || {},
			]);
			if (BucketMap?.[bucketName]) {
				BucketMap[bucketName] = {
					...BucketMap[bucketName],
					account_restriction: sanitizeAccountRestriction,
					contact_restriction: sanitizeContactRestriction,
					member_restriction: sanitizeMemberRestriction,
				};
			}
		});
		dispatchRatingsEnginePropertyValue('rule', RatingEngineCopy);
		setTimeout(function () {
			GetRatingsAndRecordCounts(
				value,
				action,
				RatingEngineCopy,
				currentRatingEngine?.segment?.name || ''
			);
		}, 250);
	} else {
		setEntitiesProperty('loading', true);
		setTimeout(function () {
			const segment = {
				free_form_text_search: '',
				page_filter: {
					num_rows: 10,
					row_offset: 0,
				},
				account_restriction: cloneDeep(
					getQueryProperty<Restriction>('accountRestriction')
				),
				contact_restriction: cloneDeep(
					getQueryProperty<Restriction>('contactRestriction')
				),
				member_restriction: cloneDeep(
					getQueryProperty<Restriction>('memberRestriction')
				),
			};
			// setPublicProperty('labelIncrementor', 0);
			if (isAccountFitModelingSuccess) {
				// Dark magic to be able to persist the modelLabelQuery in the react component AccountFitModelingWizard
				window.postMessage({query: sanitizeSegment(segment)});
			} else {
				getEntitiesCounts(sanitizeSegment(segment), undefined, refresh)
					.then(function (result) {
						setResourceTypeCount('accounts', false, result.Account);
						setResourceTypeCount('contacts', false, result.Contact);
					})
					.finally(() => {
						rootScope?.$apply();
					})
					.catch(() => {
						console.log('getEntitiesCounts error!');
					});
			}
		}, 250);
	}
};

const compareTree = (old?: Restriction[], current?: Restriction[]): boolean => {
	// remove AQB properties like labelGlyph/collapse
	if (!old || !current) {
		return false;
	}
	const oldTree = sanitizeSegmentRestriction(cloneDeep(cloneDeep(old)), true);
	const currentTree = sanitizeSegmentRestriction(cloneDeep(current), true);
	return isEqual(oldTree, currentTree);
};

const SetCurrentSavedTree = (value: IAdvancedQueryContext): void => {
	const {isRules, rulesInputTree} = value;
	const segmentInputTree = getQueryProperty<Restriction>('segmentInputTree');
	dispatchQueryPropertyValue(
		'currentSavedTree',
		isRules ? cloneDeep([segmentInputTree]) : cloneDeep([rulesInputTree])
	);
};

const SetSegmentInputTree = (
	value: IAdvancedQueryContext,
	forceUpdate = false
): void => {
	const account_restriction =
		getQueryProperty<Restriction>('accountRestriction');
	const contact_restriction =
		getQueryProperty<Restriction>('contactRestriction');
	const member_restriction = getQueryProperty<Restriction>('memberRestriction');
	let segmentHistoryTree: Restriction = {
		logicalRestriction: {
			operator: Operator.AND,
			restrictions: [
				account_restriction.restriction || {},
				contact_restriction.restriction || {},
				member_restriction.restriction || {},
			],
		},
	};
	if (forceUpdate) {
		setPublicProperty('labelIncrementor', 0);
		setPublicProperty('resetLabelIncrementor', true);
	}
	segmentHistoryTree = SetBucketLabel(segmentHistoryTree, forceUpdate);
	dispatchQueryPropertyValue('segmentHistoryTree', segmentHistoryTree);
	const rootTree = segmentHistoryTree.logicalRestriction?.restrictions;
	const segmentInputTree: Restriction[] = [];
	const accountLen = rootTree?.[0]?.logicalRestriction?.restrictions.length;
	const contactLen = rootTree?.[1]?.logicalRestriction?.restrictions.length;

	const {restrictions} = rootTree?.[2]?.logicalRestriction || {};

	const unionTree = restrictions?.[0] || {};
	const unionTreeLen = unionTree?.logicalRestriction?.restrictions.length || 0;
	const intersectionTree = restrictions?.[1] || {};
	const intersectionTreeLen =
		intersectionTree?.logicalRestriction?.restrictions.length || 0;
	/** （ ( (account) and (contact) ）or (union) ） and (intersection)
	 * 1 account
	 * 2 contact
	 * 3 union
	 * 4 intersection
	 * 5 account and contact
	 * 6 account or union
	 * 7 account and intersection
	 * 8 contact or union
	 * 9 contact and intersection
	 * 10 union and intersection
	 * 11 (account and contact) or union
	 * 12 account and contact and intersection
	 * 13 (account or union) and intersection
	 * 14 (contact or union) and intersection
	 * 15 ( (account) and (contact) ）or (union) ） and (intersection)
	 * 16 []
	 */
	const orTree = {
		logicalRestriction: {
			operator: Operator.OR,
			restrictions: [],
		} as LogicalRestriction,
	};
	const andTree = {
		logicalRestriction: {
			canNotEditOperator: false,
			operator: Operator.AND,
			restrictions: [],
		} as LogicalRestriction,
	};
	if (accountLen && !contactLen && !unionTreeLen && !intersectionTreeLen) {
		segmentInputTree.push({
			logicalRestriction: rootTree?.[0]?.logicalRestriction,
		}); // 1 account
	} else if (
		!accountLen &&
		contactLen &&
		!unionTreeLen &&
		!intersectionTreeLen
	) {
		segmentInputTree.push({
			logicalRestriction: rootTree?.[1]?.logicalRestriction,
		}); // 2 contact
	} else if (
		!accountLen &&
		!contactLen &&
		unionTreeLen &&
		!intersectionTreeLen
	) {
		segmentInputTree.push(unionTree); // 3 union
	} else if (
		!accountLen &&
		!contactLen &&
		!unionTreeLen &&
		intersectionTreeLen
	) {
		segmentInputTree.push(intersectionTree); //  4 intersection
	} else if (
		accountLen &&
		contactLen &&
		!unionTreeLen &&
		!intersectionTreeLen
	) {
		andTree.logicalRestriction.restrictions.length = 0;
		andTree.logicalRestriction.canNotEditOperator = true;
		andTree.logicalRestriction.restrictions.push({
			logicalRestriction: rootTree?.[0]?.logicalRestriction,
		});
		andTree.logicalRestriction.restrictions.push({
			logicalRestriction: rootTree?.[1]?.logicalRestriction,
		});
		segmentInputTree.push(andTree); //  5 account and contact
	} else if (
		accountLen &&
		!contactLen &&
		unionTreeLen &&
		!intersectionTreeLen
	) {
		orTree.logicalRestriction.restrictions.length = 0;
		orTree.logicalRestriction.restrictions.push({
			logicalRestriction: rootTree?.[0]?.logicalRestriction,
		});
		orTree.logicalRestriction.restrictions.push(unionTree);
		segmentInputTree.push(orTree); // 6 account or union
	} else if (
		accountLen &&
		!contactLen &&
		!unionTreeLen &&
		intersectionTreeLen
	) {
		andTree.logicalRestriction.restrictions.length = 0;
		andTree.logicalRestriction.restrictions.push({
			logicalRestriction: rootTree?.[0]?.logicalRestriction,
		});
		andTree.logicalRestriction.restrictions.push(intersectionTree);
		segmentInputTree.push(andTree); // 7 account and intersection
	} else if (
		!accountLen &&
		contactLen &&
		unionTreeLen &&
		!intersectionTreeLen
	) {
		orTree.logicalRestriction.restrictions.length = 0;
		orTree.logicalRestriction.restrictions.push({
			logicalRestriction: rootTree?.[1]?.logicalRestriction,
		});
		orTree.logicalRestriction.restrictions.push(unionTree);
		segmentInputTree.push(orTree); // 8 contact or union
	} else if (
		!accountLen &&
		contactLen &&
		!unionTreeLen &&
		intersectionTreeLen
	) {
		andTree.logicalRestriction.restrictions.length = 0;
		andTree.logicalRestriction.restrictions.push({
			logicalRestriction: rootTree?.[1]?.logicalRestriction,
		});
		andTree.logicalRestriction.restrictions.push(intersectionTree);
		segmentInputTree.push(andTree); // 9 contact and intersection
	} else if (
		!accountLen &&
		!contactLen &&
		unionTreeLen &&
		intersectionTreeLen
	) {
		segmentInputTree.push({
			logicalRestriction: rootTree?.[2]?.logicalRestriction,
		}); // 10 union and intersection
	} else if (accountLen && contactLen && unionTreeLen && !intersectionTreeLen) {
		andTree.logicalRestriction.restrictions.length = 0;
		andTree.logicalRestriction.canNotEditOperator = true;
		andTree.logicalRestriction.restrictions.push({
			logicalRestriction: rootTree?.[0]?.logicalRestriction,
		});
		andTree.logicalRestriction.restrictions.push({
			logicalRestriction: rootTree?.[1]?.logicalRestriction,
		});
		orTree.logicalRestriction.restrictions.length = 0;
		orTree.logicalRestriction.restrictions.push(andTree);
		orTree.logicalRestriction.restrictions.push(unionTree);
		segmentInputTree.push(orTree); // 11 (account and contact) or union
	} else if (accountLen && contactLen && !unionTreeLen && intersectionTreeLen) {
		andTree.logicalRestriction.restrictions.length = 0;
		andTree.logicalRestriction.canNotEditOperator = true;
		andTree.logicalRestriction.restrictions.push({
			logicalRestriction: rootTree?.[0]?.logicalRestriction,
		});
		andTree.logicalRestriction.restrictions.push({
			logicalRestriction: rootTree?.[1]?.logicalRestriction,
		});
		andTree.logicalRestriction.restrictions.push(intersectionTree);
		segmentInputTree.push(andTree); // 12 account and contact and intersection
	} else if (accountLen && !contactLen && unionTreeLen && intersectionTreeLen) {
		orTree.logicalRestriction.restrictions.length = 0;
		orTree.logicalRestriction.restrictions.push({
			logicalRestriction: rootTree?.[0]?.logicalRestriction,
		});
		orTree.logicalRestriction.restrictions.push(unionTree);
		andTree.logicalRestriction.restrictions.length = 0;
		andTree.logicalRestriction.restrictions.push(orTree);
		andTree.logicalRestriction.restrictions.push(intersectionTree);
		segmentInputTree.push(andTree); // 13 (account or union) and intersection
	} else if (!accountLen && contactLen && unionTreeLen && intersectionTreeLen) {
		orTree.logicalRestriction.restrictions.length = 0;
		orTree.logicalRestriction.restrictions.push({
			logicalRestriction: rootTree?.[1]?.logicalRestriction,
		});
		orTree.logicalRestriction.restrictions.push(unionTree);
		andTree.logicalRestriction.restrictions.length = 0;
		andTree.logicalRestriction.restrictions.push(orTree);
		andTree.logicalRestriction.restrictions.push(intersectionTree);
		segmentInputTree.push(andTree); // 14 (contact or union) and intersection
	} else if (accountLen && contactLen && unionTreeLen && intersectionTreeLen) {
		const allTree = {
			logicalRestriction: {
				operator: Operator.AND,
				restrictions: [
					{
						logicalRestriction: {
							operator: Operator.OR,
							restrictions: [
								{
									logicalRestriction: {
										operator: Operator.AND,
										canNotEditOperator: true,
										restrictions: [
											{
												logicalRestriction: rootTree[0]?.logicalRestriction,
											},
											{
												logicalRestriction: rootTree[1]?.logicalRestriction,
											},
										],
									},
								},
								restrictions?.[0] ? restrictions?.[0] : {},
							],
						},
					},
					restrictions?.[1] ? restrictions?.[1] : {},
				],
			},
		};
		segmentInputTree.push(allTree); // 15 ( (account) and (contact) ）or (union) ） and (intersection)
	} else {
		const nullTree = {
			logicalRestriction: {
				operator: Operator.AND,
				restrictions: [],
			},
		};
		segmentInputTree.push(nullTree);
	}
	if (segmentInputTree[0]) {
		dispatchQueryPropertyValue('segmentInputTree', segmentInputTree);
		SetCurrentSavedTree(value);
	}
	if (rootTree?.[0]) {
		setRestrictions({
			type: 'account',
			restriction: {restriction: rootTree[0]},
		});
	}
	if (rootTree?.[1]) {
		setRestrictions({
			type: 'contact',
			restriction: {restriction: rootTree[1]},
		});
	}
	if (rootTree?.[2]) {
		setRestrictions({
			type: 'member',
			restriction: {restriction: rootTree[2]},
		});
	}
};

const SaveState = (
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate,
	forceUpdate = false,
	noCount = false
): void => {
	const history = getQueryProperty<Restriction[][]>('history');
	// init history
	if (history.length === 0) {
		const current = getQueryProperty<Restriction>('segmentHistoryTree');
		history.push([cloneDeep(current)]);
	}
	// updated tree before save history
	SetSegmentInputTree(value, forceUpdate);
	const current = getQueryProperty<Restriction>('segmentHistoryTree');
	const old = cloneDeep(history[history.length - 1]);
	if (!compareTree(old, [current])) {
		history.push([cloneDeep(current)]);
	}
	if (!noCount) {
		UpdateCount(value, action);
	}
};

const ListItemClickOperator = (
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate,
	logicalRestriction: LogicalRestriction,
	operator: Operator
): void => {
	const account_restriction: IRootRestriction =
		getQueryProperty('accountRestriction');
	const contact_restriction: IRootRestriction =
		getQueryProperty('contactRestriction');
	const search: LogicalRestriction = {
		...logicalRestriction,
	};
	const updated: LogicalRestriction = {
		restrictions: logicalRestriction?.restrictions,
		operator,
	};
	const newAccountRestriction = {
		...account_restriction,
		restriction: findAndUpdatedLogicalRestriction(
			account_restriction.restriction,
			search,
			updated
		),
	};
	const newContactRestriction = {
		...contact_restriction,
		restriction: findAndUpdatedLogicalRestriction(
			contact_restriction.restriction,
			search,
			updated
		),
	};
	if (!isEqual(account_restriction, newAccountRestriction)) {
		dispatchQueryPropertyValue('accountRestriction', newAccountRestriction);
	}
	if (!isEqual(contact_restriction, newContactRestriction)) {
		dispatchQueryPropertyValue('contactRestriction', newContactRestriction);
	}
	updatePublicRefresh();
	SaveState(value, action);
};

const sanitizeRemoveRestrictionCollapsed = (
	tree: Restrictions
): Restrictions => {
	if (!isEmpty(tree)) {
		tree.forEach(function (branch) {
			if (!isNil(branch?.collapsed)) {
				// eslint-disable-next-line no-param-reassign
				delete branch.collapsed;
			}

			if (branch?.activityRestriction) {
				if (!isNil(branch?.activityRestriction?.restriction?.collapsed)) {
					// eslint-disable-next-line no-param-reassign
					delete branch?.activityRestriction?.restriction?.collapsed;
				}
				sanitizeRemoveRestrictionCollapsed(
					branch?.activityRestriction?.restriction?.logicalRestriction
						?.restrictions || []
				);
			}

			if (branch?.logicalRestriction) {
				sanitizeRemoveRestrictionCollapsed(
					branch.logicalRestriction.restrictions
				);
			}

			if (branch?.restriction && !isNil(branch?.restriction?.collapsed)) {
				// eslint-disable-next-line no-param-reassign
				delete branch?.restriction?.collapsed;
			}
			if (branch?.restriction?.logicalRestriction) {
				sanitizeRemoveRestrictionCollapsed(
					branch?.restriction?.logicalRestriction.restrictions
				);
			}
		});
	}

	return tree;
};

const ListItemClickCollapsed = (
	value: IAdvancedQueryContext,
	target: IQueryBuilderList
): void => {
	const {IsBuyerJourneyEnabled} = value;
	const {tree, root} = target;
	let restrictionKey: RestrictionType = 'memberRestriction';
	if (
		JSON.stringify(root.account_restriction).indexOf(JSON.stringify(tree)) > -1
	) {
		restrictionKey = 'accountRestriction';
	} else if (
		JSON.stringify(root.contact_restriction).indexOf(JSON.stringify(tree)) > -1
	) {
		restrictionKey = 'contactRestriction';
	}
	const restriction = getQueryProperty<Restriction>(restrictionKey);
	const [sanitizeRestriction] = sanitizeRemoveRestrictionCollapsed([
		cloneDeep(restriction),
	]);
	if (!isEqual(restriction, sanitizeRestriction)) {
		dispatchQueryPropertyValue(restrictionKey, sanitizeRestriction);
		updatePublicRefresh();
	}
	const id = getBucketLabel(target.tree);
	const waiting = !document.getElementById(`bucket-${id}`);
	const scrollIntoView = (id: number): void => {
		const algorithm = document.getElementsByClassName(
			'queryAlgorithm'
		)?.[0] as HTMLElement;
		if (algorithm) {
			let stickyHeight = algorithm?.offsetHeight + StickyHeight;
			if (IsBuyerJourneyEnabled) {
				stickyHeight += QueryButtonGroupHeigh;
			}
			const bucketElement = document.getElementById(
				`bucket-${id}`
			) as HTMLElement;
			window.scrollTo({
				top:
					bucketElement.getBoundingClientRect().top +
					window.scrollY -
					bucketElement.offsetTop -
					stickyHeight,
				behavior: 'smooth',
			});
		}
	};
	if (waiting) {
		const scroll_timer = setInterval(() => {
			const elementExist = document.getElementById(`bucket-${id}`);
			if (elementExist) {
				scrollIntoView(id);
				clearInterval(scroll_timer);
			}
		}, 200);
	} else {
		scrollIntoView(id);
	}
};

const SetState = (
	value: IAdvancedQueryContext,
	newState: Restriction[]
): boolean => {
	const account_restriction =
		getQueryProperty<Restriction>('accountRestriction');
	const contact_restriction =
		getQueryProperty<Restriction>('contactRestriction');
	const member_restriction = getQueryProperty<Restriction>('memberRestriction');
	const current: Restriction = {
		logicalRestriction: {
			operator: Operator.AND,
			restrictions: [
				account_restriction.restriction || {},
				contact_restriction.restriction || {},
				member_restriction.restriction || {},
			],
		},
	};
	if (!compareTree(newState, [current])) {
		setPublicProperty('labelIncrementor', 0);
		const restrictions = cloneDeep(
			newState[0]?.logicalRestriction?.restrictions
		);
		setPublicProperty('enableSaveSegmentButton', true);
		if (restrictions?.[0]) {
			dispatchQueryPropertyValue('accountRestriction', {
				restriction: restrictions[0],
			});
		}
		if (restrictions?.[1]) {
			dispatchQueryPropertyValue('contactRestriction', {
				restriction: restrictions[1],
			});
		}
		if (restrictions?.[2]) {
			dispatchQueryPropertyValue('memberRestriction', {
				restriction: restrictions[2],
			});
		}
		SetSegmentInputTree(value);
		return true;
	}
	return false;
};

const SetTotalRuleCount = (action: IAdvancedQueryContextUpdate): void => {
	const {setTotalRules} = action;
	const current = getQueryProperty<Restriction>('segmentHistoryTree');
	const allBuckets = getRuleAllBuckets([current]);
	const totalRules = allBuckets.length || 0;
	setPublicProperty('labelIncrementor', totalRules);
	setTotalRules(totalRules);
};

const ClickUndo = (
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate
): void => {
	const history = getQueryProperty<Restriction[][]>('history');
	let lastState;
	while ((lastState = history.pop())) {
		if (SetState(value, lastState)) {
			UpdateCount(value, action);
			refreshCounts();
			break;
		}
	}
	SetTotalRuleCount(action);
	updatePublicRefresh();
};
const CheckAttributesSelected = (
	value: IAdvancedQueryContext,
	entity: string
): boolean => {
	const {buckets, bucketsMap, bucket, rating_rule, bucketLabels} = value;
	const bkt = buckets[bucketsMap[bucket]];
	if (bkt && rating_rule?.bucketToRuleMap) {
		const counts = getRuleCount(
			bkt,
			rating_rule?.bucketToRuleMap,
			bucketLabels,
			entity
		);
		if (typeof counts === 'number') {
			return counts > 0;
		}
		const count = counts[entity];
		return count !== undefined ? count > 0 : false;
	}
	return false;
};

const GetRulesInputTree = (value: IAdvancedQueryContext): Restriction[] => {
	const accountAttrSelected = CheckAttributesSelected(value, 'account');
	const contactAttrSelected = CheckAttributesSelected(value, 'contact');
	const {accountRulesTree, contactRulesTree, rulesInputTree} = value;
	if (accountRulesTree[0] && contactRulesTree[0]) {
		if (
			accountRulesTree[0]?.logicalRestriction?.restrictions.length !== 0 &&
			contactRulesTree[0]?.logicalRestriction?.restrictions.length !== 0 &&
			accountAttrSelected &&
			contactAttrSelected
		) {
			return [rulesInputTree];
		}
		if (
			(accountRulesTree[0]?.logicalRestriction?.restrictions.length &&
				contactRulesTree[0]?.logicalRestriction?.restrictions.length === 0) ||
			(accountAttrSelected && !contactAttrSelected)
		) {
			return accountRulesTree;
		}
		if (
			(contactRulesTree[0]?.logicalRestriction?.restrictions.length &&
				accountRulesTree[0]?.logicalRestriction?.restrictions.length === 0) ||
			(contactAttrSelected && !accountAttrSelected)
		) {
			return contactRulesTree;
		}
	}
	return [];
};

const GetSegmentInputTree = (): Restriction[] => {
	return getQueryProperty('segmentInputTree');
};

const GenerateRulesTree = (
	value: IAdvancedQueryContext,
	entity: string
): Restriction | undefined => {
	const {bucket: key, rating_rule, treeMode} = value;
	if (key) {
		const bucket = rating_rule?.bucketToRuleMap[key];
		const rEntity = entity ? entity.toLowerCase() : treeMode;
		return rEntity === 'account'
			? bucket?.account_restriction
			: bucket?.contact_restriction;
	}
	return undefined;
};

const SetRulesTree = (
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate
): void => {
	const {setAccountRulesTree, setContactRulesTree, setMemberRulesTree} = action;
	const accountRulesTree = GenerateRulesTree(value, 'Account');
	const contactRulesTree = GenerateRulesTree(value, 'Contact');

	dispatchQueryPropertyValue('accountBucketTreeRoot', accountRulesTree);
	dispatchQueryPropertyValue('contactBucketTreeRoot', contactRulesTree);
	const memberRulesTree = GenerateRulesTree(value, 'Member');
	if (memberRulesTree) {
		setMemberRulesTree([memberRulesTree]);
	}
	if (accountRulesTree) {
		setAccountRulesTree([accountRulesTree]);
	}
	if (contactRulesTree) {
		setContactRulesTree([contactRulesTree]);
	}
};

const InitAdvancedQueryContext = (
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate
): void => {
	const {
		ratingEngineModel,
		currentRatingEngine,
		accountRulesTree,
		contactRulesTree,
		memberRulesTree,
		enrichmentsMap,
		showTimeSeries,
		eventEnrichmentsMap,
		isRules,
		member_restriction,
		isRulesBasedModelMode,
		isLoading,
	} = value;
	const {
		setEnrichmentsMap,
		setEnrichments,
		setRulesInputTree,
		setEventEnrichments,
		setEventEnrichmentsMap,
		setIsLoading,
	} = action;
	if (isLoading) {
		getEnrichments()
			.then((enrichments): void => {
				const enrichmentsMapInit = clone(enrichmentsMap);
				enrichments.forEach(({Entity, ColumnId}, i) => {
					enrichmentsMapInit[`${Entity}.${ColumnId}`] = i;
				});
				setEnrichmentsMap(enrichmentsMapInit);
				setEnrichments(enrichments);
				store.dispatch(
					setDataCloudPropertyValue('enrichmentsMap', enrichmentsMapInit)
				);
			})
			.catch(() => {
				console.log('getEnrichments error!');
			});
		if (showTimeSeries) {
			getEventEnrichments()
				.then((eventEnrichments) => {
					const newEventEnrichmentsMap = eventEnrichmentsMap
						? {...eventEnrichmentsMap}
						: {};

					eventEnrichments.forEach(({Entity, ColumnId}, i) => {
						newEventEnrichmentsMap[`${Entity}.${ColumnId}`] = i;
					});
					setEventEnrichments(eventEnrichments);
					setEventEnrichmentsMap(newEventEnrichmentsMap);
					store.dispatch(
						setDataCloudPropertyValue(
							'eventEnrichmentsMap',
							newEventEnrichmentsMap
						)
					);
				})
				.catch(() => {
					console.log('getEventEnrichments error!');
				});
		}
	}
	if (isRulesBasedModelMode) {
		dispatchRatingsEnginePropertyValue('rule', ratingEngineModel);
		InitCoverageMap(value, action);
		GetRatingsAndRecordCounts(
			value,
			action,
			cloneDeep(ratingEngineModel),
			currentRatingEngine?.segment?.name || ''
		);
		dispatchQueryPropertyValue('accountBucketTreeRoot', accountRulesTree[0]);
		dispatchQueryPropertyValue('contactBucketTreeRoot', contactRulesTree[0]);
	}
	setTimeout(function () {
		if (isRulesBasedModelMode) {
			const rulesInputTree = {
				collapsed: false,
				logicalRestriction: {
					operator: Operator.AND,
					restrictions: [accountRulesTree[0] || {}, contactRulesTree[0] || {}],
				},
			};
			if (isEmpty(memberRulesTree)) {
				rulesInputTree.logicalRestriction.restrictions.push(
					memberRulesTree[0] || {}
				);
			}
			setPublicProperty('labelIncrementor', 0);
			const updatedTree = SetBucketLabel(rulesInputTree);
			setRulesInputTree(updatedTree);
			// this for refresh bucket label
			setPublicProperty('resetLabelIncrementor', true);
			SetTotalRuleCount(action);
		}
		SetCurrentSavedTree(value);
	}, 1);
	dispatchSetAddBucketTreeRoot(null);
	if (isRules) {
		// This part is to initialize member_restriction when creating new segment.
		const {
			compositeSegmentBlock,
			restrictions,
			operator = Operator.AND,
		} = member_restriction?.restriction?.logicalRestriction || {};
		if (!compositeSegmentBlock) {
			const unionTree = restrictions?.filter(
				(item) =>
					item.logicalRestriction &&
					item.logicalRestriction.operator === Operator.OR
			)[0];
			const intersectionTree = restrictions?.filter(
				(item) =>
					item.logicalRestriction &&
					item.logicalRestriction.operator === Operator.AND
			)[0];

			const oldSegmentTree: Restriction[] = [];
			restrictions?.filter((item, index, arr): boolean => {
				if (!item.logicalRestriction) {
					oldSegmentTree.push(item);
					arr.splice(index, 1);
					return true;
				}
				return false;
			});
			let newMemberRestriction0: Restriction = {};
			let newMemberRestriction1: Restriction = {};
			if (!unionTree) {
				const logicalRestriction = {
					logicalRestriction: {
						operator: Operator.OR,
						restrictions: [],
					},
				};
				newMemberRestriction0 = logicalRestriction;
			}
			if (!intersectionTree) {
				const logicalRestriction = {
					logicalRestriction: {
						operator: Operator.AND,
						restrictions: [],
					},
				};
				newMemberRestriction1 = logicalRestriction;
			}
			newMemberRestriction1?.logicalRestriction?.restrictions.push(
				...oldSegmentTree
			);
			const newMemberRestriction: Restriction = {
				restriction: {
					logicalRestriction: {
						...member_restriction?.restriction?.logicalRestriction,
						operator,
						compositeSegmentBlock: true,
						restrictions: [newMemberRestriction0, newMemberRestriction1],
					},
				},
			};
			dispatchQueryPropertyValue('memberRestriction', newMemberRestriction);
		}
		SetSegmentInputTree(value, true);
		refreshCounts();
	}
	SetTotalRuleCount(action);
	setIsLoading(false);
};

const saveRules = (
	state: StateService<RatingsPageParams> | undefined,
	setSaved: (saved: boolean) => void
): void => {
	saveRatingEngineRules.bind(state)();
	setSaved(true);
};

const resetRulesInputTree = (
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate
): void => {
	const {accountRulesTree, contactRulesTree} = value;
	const {setRulesInputTree} = action;
	const rulesInputTree: Restriction = {
		collapsed: false,
		logicalRestriction: {
			operator: Operator.AND,
			restrictions: [accountRulesTree[0] || {}, contactRulesTree[0] || {}],
		},
	};
	// this for refresh bucket label
	setPublicProperty('resetLabelIncrementor', true);
	SetTotalRuleCount(action);
	const rulesInputTreeHasLabel = SetBucketLabel(rulesInputTree);
	setRulesInputTree(rulesInputTreeHasLabel);
};

const setSelectedBucket = (bucket: string): void => {
	dispatchQueryPropertyValue('selectedBucket', bucket);
};

const ClickBucketTile = (
	bucket: RuleCountBkt,
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate
): void => {
	const {setBucket} = action;
	setPublicProperty('labelIncrementor', 0);
	setBucket(bucket.bucket as keyof RatingsBuckets);
	setSelectedBucket(bucket.bucket);
	SetRulesTree(value, action);
	resetRulesInputTree(value, action);
};

const doesDisplayParentSegment = (
	state: IState | undefined,
	segmentObj: ISegment
): boolean => {
	const isQueryBuilderRoute = state?.current?.name === SEGMENTS_EXPLORER;
	return (
		isBuyerJourneyEnabled() &&
		isQueryBuilderRoute &&
		!!segmentObj.customParentSegmentName
	);
};

const navigateParentSegment = (
	state: IState | undefined,
	segmentObj: ISegment
): void => {
	state?.go(SEGMENTS_EXPLORER, {
		segment: segmentObj.customParentSegmentName,
	});
};

const parentSegment = (
	segmentsList: ISegment[],
	segmentObj: ISegment
): string => {
	const parentSegment = segmentsList.find(
		(segment) => segment.name === segmentObj.customParentSegmentName
	);
	return `Parent Segment: ${parentSegment?.display_name}`;
};

const getAccountTree = (): Restriction[] => {
	const account_restriction =
		getQueryProperty<Restriction>('accountRestriction');
	return account_restriction?.restriction
		? [cloneDeep(account_restriction.restriction)]
		: [];
};

const getContactTree = (): Restriction[] => {
	const contact_restriction =
		getQueryProperty<Restriction>('contactRestriction');
	return contact_restriction?.restriction
		? [cloneDeep(contact_restriction.restriction)]
		: [];
};

const getSegmentUnionTree = (): Restriction[] => {
	const member_restriction = getQueryProperty<Restriction>('memberRestriction');
	return member_restriction?.restriction?.logicalRestriction?.restrictions
		? cloneDeep(
				member_restriction?.restriction?.logicalRestriction?.restrictions?.slice(
					0,
					1
				)
		  )
		: [];
};

const getSegmentIntersectionTree = (): Restriction[] => {
	const member_restriction = getQueryProperty<Restriction>('memberRestriction');
	return member_restriction?.restriction?.logicalRestriction?.restrictions
		? cloneDeep(
				member_restriction?.restriction?.logicalRestriction?.restrictions?.slice(
					1,
					2
				)
		  )
		: [];
};
const clickDelete = (
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate,
	treeType: string,
	type: IQueryBlockType
): void => {
	const restrictionKey = `${treeType}Restriction` as RestrictionType;
	const RootRestriction = getQueryProperty<IRootRestriction>(restrictionKey);
	if ([IQueryBlockType.Union, IQueryBlockType.Intersection].includes(type)) {
		const logicalRestriction = {
			...RootRestriction.restriction.logicalRestriction,
			restrictions: [
				{
					logicalRestriction: {
						...RootRestriction.restriction.logicalRestriction?.restrictions[0]
							?.logicalRestriction,
						restrictions:
							type === IQueryBlockType.Union
								? []
								: RootRestriction.restriction.logicalRestriction
										?.restrictions[0]?.logicalRestriction?.restrictions,
					},
				},
				{
					logicalRestriction: {
						...RootRestriction.restriction.logicalRestriction?.restrictions[1]
							?.logicalRestriction,
						restrictions:
							type === IQueryBlockType.Intersection
								? []
								: RootRestriction.restriction.logicalRestriction
										?.restrictions[1]?.logicalRestriction?.restrictions,
					},
				},
			],
		};
		const newRestriction = {
			restriction: {
				logicalRestriction,
			},
		};
		dispatchQueryPropertyValue(restrictionKey, newRestriction);
	} else {
		const newRestriction = {
			restriction: {
				logicalRestriction: {
					...RootRestriction.restriction.logicalRestriction,
					restrictions: [],
				},
			},
		};
		dispatchQueryPropertyValue(restrictionKey, newRestriction);
	}
	SaveState(value, action);
	SetTotalRuleCount(action);
};

const doesSegmentOrBlockShow = (value: IAdvancedQueryContext): boolean => {
	const {
		showSegmentationV2,
		showSegmentBlock,
		segmentObj,
		isAccountFitModelingSuccess,
	} = value;
	return (
		showSegmentationV2 &&
		showSegmentBlock &&
		!segmentObj?.customParentSegmentName &&
		!isAccountFitModelingSuccess
	);
};

const dropMoveItem = (
	dragged: IQueryTreeContext,
	dropped: IQueryTreeContext,
	droppedItemAppend: boolean,
	callBack: () => void
): void => {
	if (dropped.tree && dropped.parent?.logicalRestriction) {
		const draggedParent = dragged.parent?.logicalRestriction?.restrictions;
		const droppedParent = dropped.parent
			? dropped.parent.logicalRestriction?.restrictions
			: [];
		const droppedIndex = droppedParent?.indexOf(dropped.tree) || 0;
		const draggedItem = cloneDeep(dragged.tree);
		draggedParent?.splice(draggedParent?.indexOf(dragged.tree), 1);

		if (dropped.tree.logicalRestriction) {
			const {restrictions} = dropped.tree.logicalRestriction;

			if (droppedItemAppend) {
				restrictions.push(draggedItem);
			} else {
				restrictions.splice(0, 0, draggedItem);
			}
		} else {
			const inc = droppedItemAppend ? 1 : 0;
			droppedParent?.splice(droppedIndex + inc, 0, draggedItem);
		}
		callBack();
	}
};

const mouseUp = (
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate,
	callBack: () => void
): void => {
	const {
		draggedItem,
		droppedItem,
		droppedItemAppend,
		draggedClone,
		mouseDownTimer,
	} = value;
	const dragged = draggedItem?.current;
	const dropped = droppedItem?.current;
	if (
		dragged &&
		(!dropped ||
			(dropped &&
				(dropped as IQueryTreeContext)?.tree?.labelGlyph !==
					dragged?.tree?.labelGlyph))
	) {
		if (droppedItem) {
			droppedItem.current = value;
		}
		if (dropped) {
			dropMoveItem(
				dragged,
				dropped as IQueryTreeContext,
				droppedItemAppend?.current || false,
				callBack
			);
			SaveState(value, action);
		}
	}
	if (draggedItem?.current) {
		draggedItem.current = undefined;
	}
	if (droppedItem?.current) {
		droppedItem.current = undefined;
	}
	if (droppedItemAppend?.current) {
		droppedItemAppend.current = false;
	}

	const draggedCloned = draggedClone?.current;
	if (draggedCloned) {
		const {className} = draggedCloned;
		const dragNodes = document.getElementsByClassName(className);
		Array.from(dragNodes).forEach((node) => {
			node.remove();
		});
	}
	if (draggedClone?.current) {
		draggedClone.current = undefined;
	}
	if (mouseDownTimer?.current) {
		clearTimeout(mouseDownTimer.current);
		mouseDownTimer.current = undefined;
	}
};

const UpdateRestriction = (
	restrictions: Restriction[],
	subType?: string
): void => {
	let completed = false;
	const index = restrictions.findIndex((item) => {
		const [Entity, ColumnId] = item?.bucketRestriction?.attr.split('.') || [];
		return Entity === 'CuratedContact' && ColumnId === 'KnownContact';
	});

	if (index > -1) {
		completed = true;
		let item = restrictions[index];
		if (subType === 'Known' && item?.bucketRestriction?.bkt) {
			item = {
				...item,
				bucketRestriction: {
					...item?.bucketRestriction,
					bkt: {
						...item?.bucketRestriction?.bkt,
						Vals: ['Yes'],
					},
				},
			};
		} else if (subType === 'Unknown' && item?.bucketRestriction?.bkt) {
			item = {
				...item,
				bucketRestriction: {
					...item?.bucketRestriction,
					bkt: {
						...item?.bucketRestriction?.bkt,
						Vals: ['No'],
					},
				},
			};
		} else {
			restrictions.splice(index, 1);
		}
	}
	if (!completed && subType !== 'All') {
		const bucketRestriction: BucketRestriction = {
			attr: 'CuratedContact.KnownContact',
			bkt: {
				Lbl: '',
				Cmp: BucketCmp.EQUAL,
				Vals: [subType === 'Known' ? 'Yes' : 'No'],
			},
			ignored: false,
		};
		restrictions.push({bucketRestriction});
	}
};

const goAttributes = (advancedQuery: IAdvancedQueryContext): void => {
	// FIXME: Either `goAttributes` or `nextState` should be passed in from
	// the parent. The query builder shouldn't change its functionality
	// based on the UIRouter state
	// TODO: The entire state path may not be necessary if a consistent
	// relative path can be provided.
	const {state, inModel, segment} = advancedQuery;
	let nextState;
	if (getQueryProperty('mode') === Modes.RulesBasedModelDetails) {
		nextState = 'home.ratingsengine.rulesprospects.segment.attributes.add';
	} else if (getQueryProperty('mode') === Modes.RulesBasedModelDashboard) {
		nextState = 'home.ratingsengine.dashboard.segment.attributes.add';
	} else if (
		state?.current.name ===
		'home.ratingsengine.createAccountFitModeling.createSegment'
	) {
		nextState =
			'home.ratingsengine.createAccountFitModeling.createSegment.attributes';
	} else if (
		state?.current.name ===
		'home.ratingsengine.createAccountFitModeling.successCriteria'
	) {
		nextState =
			'home.ratingsengine.createAccountFitModeling.successCriteria.attributes';
	} else if (
		state?.current.name ===
		'home.ratingsengine.createAccountFitModeling.scoringSegment'
	) {
		nextState =
			'home.ratingsengine.createAccountFitModeling.scoringSegment.attributes';
	} else {
		nextState = inModel
			? 'home.model.analysis.explorer.attributes'
			: 'home.segment.explorer.attributes';
	}
	state?.go(nextState, {segment});
};

const getTrees = (
	type: IQueryBlockType,
	advancedQuery: IAdvancedQueryContext
): Restriction[] => {
	const {isRulesBasedModelMode, accountRulesTree, contactRulesTree} =
		advancedQuery;
	let trees: Restriction[] = [];
	switch (type) {
		case IQueryBlockType.Account:
			trees = isRulesBasedModelMode ? accountRulesTree : getAccountTree();
			break;
		case IQueryBlockType.Contact:
			trees = isRulesBasedModelMode ? contactRulesTree : getContactTree();
			break;
		case IQueryBlockType.Union:
			trees = getSegmentUnionTree();
			break;
		case IQueryBlockType.Intersection:
			trees = getSegmentIntersectionTree();
			break;
	}
	return trees;
};

export {
	getBucketLabel,
	SetBucketLabel,
	SaveState,
	ListItemClickOperator,
	ListItemClickCollapsed,
	ClickUndo,
	GetRulesInputTree,
	GetSegmentInputTree,
	InitCoverageMap,
	GetRatingsAndRecordCounts,
	SetRulesTree,
	SetTotalRuleCount,
	SetCurrentSavedTree,
	InitAdvancedQueryContext,
	saveRules,
	UpdateCount,
	ClickBucketTile,
	parentSegment,
	navigateParentSegment,
	doesDisplayParentSegment,
	getAccountTree,
	getContactTree,
	getSegmentUnionTree,
	getSegmentIntersectionTree,
	clickDelete,
	mouseUp,
	doesSegmentOrBlockShow,
	UpdateRestriction,
	goAttributes,
	getTrees,
};
