import React, {DnbThemeProviderWrapper} from 'common/react-vendor';
import {Provider} from 'common/redux-vendor';
import {store} from 'store';
import {
	AdvancedQueryContextProvider,
	IAdvancedQueryComponent,
} from './context/AdvancedQueryContext';
import {AdvancedQueryContainer} from './AdvancedQueryContainer';

const AdvancedQueryComponent = (
	props: IAdvancedQueryComponent
): React.ReactElement => {
	return (
		<DnbThemeProviderWrapper>
			<Provider store={store}>
				<AdvancedQueryContextProvider scope={props || {}}>
					<AdvancedQueryContainer />
				</AdvancedQueryContextProvider>
			</Provider>
		</DnbThemeProviderWrapper>
	);
};

export {AdvancedQueryComponent};
