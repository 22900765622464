import React from 'common/react-vendor';
import {DNBDialog, DNBDivider, DNBTypography} from 'common/dnb-uux-vendor';
import {clone} from 'lodash';
import {IChipsDialog} from '../ChipsTypes';
import {ChipsLeftSection} from './ChipsLeftSection';
import {ChipsRightSection} from './ChipsRightSection';
import {clearBulkEntryModal} from '../ChipsController';
import {useChipsContext} from '../useChipsContext';
import {ChipsCenterSection} from './ChipsCenterSection';
import styles from '../Chips.module.scss';

const ChipsSection = (): React.ReactElement => {
	const {context, setContext} = useChipsContext();
	const [isDialogUpdated, setIsDialogUpdated] = React.useState(false);
	const {chips, datasource} = context;
	const noDataSource = datasource.length === 0;
	const {setPreviousItems, setToBeAddedItems, setLastPastedValue} = setContext;
	React.useEffect(() => {
		setPreviousItems(clone(chips));
		setToBeAddedItems({});
		setLastPastedValue('');
	}, [chips, setPreviousItems, setToBeAddedItems, setLastPastedValue]);
	return (
		<DNBTypography component='div' className='ChipsDialogLarge'>
			<DNBTypography component='div' className={styles.ChipsContent}>
				{/* <!-- left section --> */}
				<ChipsLeftSection
					isDialogUpdated={isDialogUpdated}
					setIsDialogUpdated={setIsDialogUpdated}
				/>
				{!noDataSource && (
					<DNBDivider
						sx={{borderWidth: '1px', borderRadius: '4px'}}
						orientation='horizontal'
						variant='lightIncidental'
					/>
				)}
				{/* <!-- center section --> */}
				{!noDataSource && (
					<ChipsCenterSection
						isDialogUpdated={isDialogUpdated}
						setIsDialogUpdated={setIsDialogUpdated}
					/>
				)}
				<DNBDivider
					sx={{borderWidth: '1px', borderRadius: '4px'}}
					orientation='horizontal'
					variant='lightIncidental'
				/>
				{/* <!-- right section --> */}
				<ChipsRightSection
					isDialogUpdated={isDialogUpdated}
					setIsDialogUpdated={setIsDialogUpdated}
				/>
			</DNBTypography>
		</DNBTypography>
	);
};

const ChipsDialog = ({
	isDialogOpen,
	setIsDialogOpen,
}: IChipsDialog): React.ReactElement => {
	const {context, setContext} = useChipsContext();
	const {previousItems, toBeAddedItems, treeContext, CallBack} = context;
	const {setChips} = setContext;
	const shouldAllowBulkEntrySubmission = (): boolean => {
		return (
			Object.keys(toBeAddedItems).length + Object.keys(previousItems).length <=
			0
		);
	};
	const closeBulkEntryModal = (): void => {
		setIsDialogOpen(false);
		clearBulkEntryModal(setContext);
	};
	const submitBulkEntryModal = (): void => {
		const newChips = {
			...previousItems,
			...toBeAddedItems,
		};
		setChips(newChips);
		CallBack(newChips);
		closeBulkEntryModal();
	};
	const title = (
		<DNBTypography variant='h6' sx={{display: 'flex'}}>
			Add or Edit {treeContext.item?.DisplayName} Values
		</DNBTypography>
	);
	return (
		<DNBDialog
			// @ts-ignore, title.
			title={title}
			open={isDialogOpen}
			hasStickyFooter={false}
			hasCloseButton={true}
			// @ts-ignore, The parameter setting of the onClose method of DNBDialog is unreasonable, and MUI itself supports passing parameters here.
			onClose={(e: unknown) => {
				if (!e) {
					setIsDialogOpen(false);
				}
			}}
			secondaryCTA={() => {
				setIsDialogOpen(false);
			}}
			secondaryCTALabel='Cancel'
			disabledPrimaryCTA={shouldAllowBulkEntrySubmission()}
			primaryCTA={() => {
				submitBulkEntryModal();
				setIsDialogOpen(false);
			}}
			primaryCTALabel='Add'>
			<ChipsSection />
		</DNBDialog>
	);
};

export {ChipsDialog};
