import {FundamentalType} from 'common/app/utilities/FundamentalTypeUtility';
import {BucketCmp, Period} from 'common/components/datacloud/query/query.enums';
import {Bkt} from 'common/components/datacloud/query/query.types';
import {IStatsCube} from './CubeConst';

enum AttributeFlagNodeType {
	ARRAY = 'ARRAY',
	BINARY = 'BINARY',
	BOOLEAN = 'BOOLEAN',
	MISSING = 'MISSING',
	NULL = 'NULL',
	NUMBER = 'NUMBER',
	OBJECT = 'OBJECT',
	POJO = 'POJO',
	STRING = 'STRING',
}

interface IAttributeFlag {
	array: boolean;
	bigDecimal: boolean;
	bigInteger: boolean;
	binary: boolean;
	boolean: boolean;
	containerNode: boolean;
	double: boolean;
	empty: boolean;
	float: boolean;
	floatingPointNumber: boolean;
	int: boolean;
	integralNumber: boolean;
	long: boolean;
	missingNode: boolean;
	nodeType: AttributeFlagNodeType;
	null: boolean;
	number: boolean;
	object: boolean;
	pojo: boolean;
	short: boolean;
	textual: boolean;
	valueNode: boolean;
}

type IAttributeFlagsMap = Record<string, IAttributeFlag>;

enum LogicalDataType {
	Id = 'Id',
	InternalId = 'InternalId',
	Date = 'Date',
	Event = 'Event',
	StageName = 'StageName',
	Reference = 'Reference',
	RowId = 'RowId',
	Opportunity = 'Opportunity',
	Metric = 'Metric',
	Timestamp = 'Timestamp',
}

/**
 * Attribute Entity Type refers to BE BusinessEntity.java.
 */
enum AttributeEntityType {
	Account = 'Account',
	Contact = 'Contact',
	Campaign = 'Campaign',
	Product = 'Product',
	ProductHierarchy = 'ProductHierarchy',
	Transaction = 'Transaction',
	PeriodTransaction = 'PeriodTransaction',
	PurchaseHistory = 'PurchaseHistory',
	DepivotedPurchaseHistory = 'DepivotedPurchaseHistory',
	CuratedAccount = 'CuratedAccount',
	CuratedContact = 'CuratedContact',
	AnalyticPurchaseState = 'AnalyticPurchaseState',
	Rating = 'Rating',
	LatticeAccount = 'LatticeAccount',
	PrimeAccount = 'PrimeAccount',
	ActivityStream = 'ActivityStream',
	Catalog = 'Catalog',
	WebVisitProfile = 'WebVisitProfile',
	ContactWebVisitProfile = 'ContactWebVisitProfile',
	Opportunity = 'Opportunity',
	AccountMarketingActivity = 'AccountMarketingActivity',
	ContactMarketingActivity = 'ContactMarketingActivity',
	AccountSalesActivity = 'AccountSalesActivity',
	ContactSalesActivity = 'ContactSalesActivity',
	CustomIntent = 'CustomIntent',
	AccountCampaignLaunchHistory = 'AccountCampaignLaunchHistory',
	ContactCampaignLaunchHistory = 'ContactCampaignLaunchHistory',
	TPS = 'TPS',
	DUNSIP = 'DUNSIP',
	AccountSegmentMember = 'AccountSegmentMember',
	ContactSegmentMember = 'ContactSegmentMember',
	ActivitySegmentStats = 'ActivitySegmentStats',
	AccountSegmentStats = 'AccountSegmentStats',
	ContactSegmentStats = 'ContactSegmentStats',
	AccountSegmentCount = 'AccountSegmentCount',
	ContactSegmentCount = 'ContactSegmentCount',
	TimeLine = 'TimeLine',
	AccountTimeLineSubQuery = 'AccountTimeLineSubQuery',
	ContactTimeLineSubQuery = 'ContactTimeLineSubQuery',
}

/**
 * Time Series Entity Type refers to BE EntityType.java
 */
enum TimeSeriesEntityType {
	Opportunity = 'Opportunity',
	MarketingActivity = 'MarketingActivity',
	SalesActivityTask = 'SalesActivityTask',
	SalesActivityEvent = 'SalesActivityEvent',
	WebVisit = 'WebVisit',
	CustomIntent = 'CustomIntent',
}

const SubCategoryDict: Record<string, string> = {
	CustomIntent: 'D&B Buyer Intent',
};

enum RefreshFrequency {
	Release = 'Release',
	Week = 'Week',
}

enum State {
	Active,
	Inactive,
	Deprecated,
}

enum BktType {
	Boolean = 'Boolean',
	Numerical = 'Numerical',
	Enum = 'Enum',
	TimeSeries = 'TimeSeries',
	PercentChange = 'PercentChange',
	Date = 'Date',
}

enum ChgCompareType {
	AT_LEAST = 'AT_LEAST',
	AS_MUCH_AS = 'AS_MUCH_AS',
	BETWEEN = 'BETWEEN',
}

enum DirectionType {
	INC = 'INC',
	DEC = 'DEC',
}

interface IChange {
	Cmp: ChgCompareType;
	Direction: DirectionType;
	Vals: unknown[];
}

const DefaultDateTopbkt: Bkt = {
	Cnt: -1,
	Fltr: {
		Cmp: BucketCmp.EVER,
		Vals: [-1],
		Period: Period.Day,
	},
	Lbl: 'Ever',
	Id: -1,
};

const DefaultTopbkt: Bkt = {
	Cmp: BucketCmp.IS_NOT_NULL,
	Id: -1,
	Lbl: '*',
	Vals: [],
	Cnt: -1,
};

/**
 * Get default top bucket per fundamental type.
 * @param fundamentalType Fundamental type of attribute
 * @returns The default top bucket
 */
const getActivityDefaultTopbkt = (fundamentalType: string): Bkt => {
	return fundamentalType === FundamentalType[FundamentalType.date]
		? DefaultDateTopbkt
		: DefaultTopbkt;
};

const IsFundamentalType = (
	type: string,
	fundamentalType: FundamentalType
): boolean => {
	return type === FundamentalType[fundamentalType];
};

interface IBuckets {
	HasMore: boolean;
	List: Bkt[];
	Type: BktType;
}

interface IAttributeStats {
	Bkts: IBuckets;
	Cnt: number;
}

enum Tag {
	Internal = 'Internal',
	External = 'External',
	InternalTransform = 'InternalTransform',
	ExternalTransform = 'ExternalTransform',
}

/**
 * Attribute interface from BE response.
 * Pay attention to the difference between account/contact attribute
 * to time series attributes.
 * Refers to API pls/datacollection/attributes
 * Refers to API pls/datacollection/attributes/timeseries
 * @Category Category that attribute belongs to
 * Account/Contact - category from category api.
 * Mapped to legacyDisplayName from category metadata.
 * [NOTE] Time series - 'Default'
 * Set to @AttributeEntityType by @getEventEnrichments from data cloud service.
 * @Entity Attribute entity type
 * [NOTE] Time series does not have this field.
 * Set to @AttributeEntityType by @getEventEnrichments from data cloud service.
 */
interface IAttribute {
	ApprovedUsage: string[];
	AssociatedDataRules: string[];
	AttrName: string;
	AttributeFlagsMap: IAttributeFlagsMap;
	BitOffset: number;
	CanEnrich: boolean;
	CanInternalEnrich: boolean;
	CanModel: boolean;
	CanSegment: boolean;
	Category: string;
	ColumnId: string;
	ColumnName: string;
	DataLicense: string;
	DataType: string;
	Description: string;
	DiscretizationStrategy: string;
	DisplayName: string;
	Entity: AttributeEntityType;
	FilterTags: string[];
	FundamentalType: string;
	Groups: Record<string, boolean>;
	ImportanceOrdering: number;
	IsAdminDisabledForModel: boolean;
	IsCampaignDerivedField: boolean;
	IsCoveredByMandatoryRule: boolean;
	IsCoveredByOptionalRule: boolean;
	IsExportable: boolean;
	IsHiddenForRemodelingUI: boolean;
	IsHiddenInCategoryTile: boolean;
	IsPremium: boolean;
	JavaClass: string;
	LastDataRefresh: string;
	LogicalDataType: LogicalDataType;
	MatchDestination: string;
	NumBits: number;
	PhysicalName: string;
	PredictivePower: number;
	RefreshFrequency: RefreshFrequency;
	SecondaryDisplayName: string;
	ShouldDeprecate: number;
	State: State;
	StatisticalType: string;
	Stats?: IAttributeStats;
	Subcategory: string;
	SubcategoryAltName?: string;
	Tags: Tag[];
	// FE added fields.
	cube: IStatsCube;
	topbkt: Bkt;
}

export type {IAttribute, IAttributeStats, IBuckets, IChange};
export {
	AttributeEntityType,
	TimeSeriesEntityType,
	DefaultDateTopbkt,
	DefaultTopbkt,
	getActivityDefaultTopbkt,
	SubCategoryDict,
	IsFundamentalType,
};
