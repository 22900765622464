import React from 'common/react-vendor';
import {KeyboardEvent} from 'react';
import {
	AddIcon,
	CircularProgress,
	CloseIcon,
	DNBButton,
	DNBIconButton,
	DNBTextField,
	DNBTypography,
	ExpandLessIcon,
	ExpandMoreIcon,
	InputAdornment,
	SearchOutlinedIcon,
} from 'common/dnb-uux-vendor';
import {clone} from 'lodash';
import {VariableSizeList as List} from 'common/widgets/react-window';
import classNames from 'classnames';
import {
	addCustomValuesWithDelimiterCheck,
	chooseItem,
	getDisplayName,
	removeItem,
} from './ChipsController';
import {useChipsContext} from './useChipsContext';
import {IChip, altLabel} from './ChipsTypes';
import styles from './Chips.module.scss';
import {ChipsDialog} from './Dialog/ChipsDialog';

export const ChipsControllerContainer = (): React.ReactElement => {
	const {context, setContext} = useChipsContext();
	const {
		ID,
		chips,
		showHeader,
		showIcon,
		showloading,
		iconClass,
		stringPlaceholder,
		disabled,
		datasource,
		header,
		sortId: initSortId,
		sortReverse: initSortReverse,
		query,
		displayName,
		customVals,
		queryScope,
		treeContext,
		singleSelection,
	} = context;
	const {setQuery} = setContext;

	const [isDialogOpen, setIsDialogOpen] = React.useState(false);
	const [positionInQueryList, setPositionInQueryList] = React.useState(0);
	const [blur, setBlur] = React.useState(true);
	const [showQueryList, setShowQueryList] = React.useState(false);
	const [sortReverse, setSortReverse] = React.useState(initSortReverse);
	const [sortId, setSortId] = React.useState(initSortId);
	const [inputText, setInputText] = React.useState(query);
	const [filteredItems, setFilteredItems] = React.useState<IChip[]>([]);
	React.useEffect(() => {
		if (!showloading) {
			if (inputText) {
				const filteredItems = datasource.filter((item) => {
					return item[queryScope || displayName]
						?.toLocaleString()
						.toLowerCase()
						.includes(inputText.toLowerCase());
				});
				setFilteredItems(filteredItems);
			} else {
				setFilteredItems(clone(datasource));
			}
		}
	}, [datasource, queryScope, displayName, inputText, showloading]);
	const blockMouseEventPropagation = (
		e: React.MouseEvent<Element, MouseEvent>
	): void => {
		e.stopPropagation();
	};
	const getIconButton = (): React.ReactElement => {
		if (showloading) {
			return <CircularProgress size={16} />;
		}
		if (iconClass === 'plus') {
			return <AddIcon />;
		}
		return <SearchOutlinedIcon />;
	};
	const clearPositionInQueryList = (): void => {
		setPositionInQueryList(-1);
	};
	const hoverIn = (): void => {
		setShowQueryList(true);
		clearPositionInQueryList();
	};
	const hoverOut = (): void => {
		if (blur) {
			clearPositionInQueryList();
			setShowQueryList(false);
		}
	};
	const sort = (sortid: keyof IChip): void => {
		const reverse = sortid === sortId;
		setSortReverse(!sortReverse);
		setSortId(sortid);

		if (reverse) {
			setFilteredItems(filteredItems.reverse());
		} else {
			setFilteredItems(
				filteredItems.sort((objA: IChip, objB: IChip): number => {
					let a = objA[sortid];
					let b = objB[sortid];

					if (typeof a === 'string' && typeof b === 'string') {
						a = a.toLowerCase();
						b = b.toLowerCase();
						if (a > b) {
							return 1;
						}
						if (a < b) {
							return -1;
						}
					} else if (typeof a === 'number' && typeof b === 'number') {
						if (a > b) {
							return 1;
						}
						if (a < b) {
							return -1;
						}
					}
					return 0;
				})
			);
		}
	};
	const selectedItems = [...Object.keys(chips)];

	const isSelected = (name: string): boolean => {
		return selectedItems.includes(name);
	};

	const queryKeyPressed = (event: KeyboardEvent<HTMLInputElement>): void => {
		setBlur(false);
		const itemFromDropdown = filteredItems[positionInQueryList];

		switch (event.key) {
			case 'Backspace':
			case 'Delete':
				if (query.length === 0) {
					setBlur(true);
					clearPositionInQueryList();
					setShowQueryList(true);
				}
				break;
			case 'Escape':
				setPositionInQueryList(-1);
				break;
			case 'ArrowDown':
				event.preventDefault();
				setShowQueryList(true);
				if (positionInQueryList < filteredItems.length - 1) {
					setPositionInQueryList(positionInQueryList + 1);
				}
				break;
			case 'ArrowUp':
				event.preventDefault();
				setShowQueryList(true);
				if (positionInQueryList > 0) {
					setPositionInQueryList(positionInQueryList - 1);
				}
				break;
			case 'Enter':
				if (itemFromDropdown) {
					chooseItem(context, setContext, itemFromDropdown);
					if (singleSelection) {
						setShowQueryList(false);
					}
					setPositionInQueryList(-1);
				}

				if (!itemFromDropdown && customVals && query.length > 0) {
					addCustomValuesWithDelimiterCheck(context, setContext, query);
					setInputText('');
				}

				// event.target.focus();
				break;
		}
	};
	const showQueryResult = (override: boolean): void => {
		if (override || query.length > 0) {
			setShowQueryList(true);
		} else {
			setPositionInQueryList(0);
			showQueryResult(false);
		}
	};
	const focusInput = (): void => {
		setBlur(false);
		setPositionInQueryList(0);
		showQueryResult(true);
	};
	const blurText = (): void => {
		if (blur) {
			setShowQueryList(false);
		}
		setBlur(true);
	};
	const fundamentalType =
		treeContext.item?.FundamentalType === 'numeric' ? 'number' : 'text';
	return (
		<DNBTypography component='div' className={styles.controlsContainer}>
			<DNBTypography
				component='div'
				className={classNames({
					textContainer: true,
					dropDown: true,
					[styles.textContainer!]: true,
					[styles.dropDown!]: true,
					[styles.showDropdown!]: showQueryList,
					[styles.showHeader!]: showHeader,
				})}
				onMouseOver={blockMouseEventPropagation}
				onMouseDown={blockMouseEventPropagation}
				onMouseMove={blockMouseEventPropagation}>
				<DNBTypography component='div'>
					<DNBTextField
						onChange={(e) => {
							setQuery(e.target.value);
							setInputText(e.target.value);
						}}
						onKeyUp={queryKeyPressed}
						onFocus={focusInput}
						onBlur={blurText}
						name='query'
						type={fundamentalType}
						spellCheck={false}
						disabled={disabled}
						value={inputText}
						size='compact'
						placeholder={stringPlaceholder}
						sx={{width: '100%'}}
						InputProps={{
							autoComplete: 'off',
							startAdornment: (
								<InputAdornment position='start'>
									{showIcon &&
										(inputText ? (
											<DNBIconButton
												size='small'
												sx={{padding: 0, marginRight: 1}}
												onClick={() => {
													setInputText('');
												}}
												onMouseDown={(e) => e.preventDefault()}
												edge='end'>
												<CloseIcon />
											</DNBIconButton>
										) : (
											getIconButton()
										))}
								</InputAdornment>
							),
						}}
					/>
				</DNBTypography>
				<DNBTypography
					component='div'
					onMouseOver={hoverIn}
					onMouseLeave={hoverOut}>
					{showHeader && (filteredItems || []).length > 0 && (
						<DNBTypography
							component='div'
							className={classNames({
								[styles.dropdownContent!]: true,
								[styles.queryResultHeader!]: true,
								[styles.showDropdown!]: showQueryList,
								[styles.showHeader!]: showHeader,
							})}>
							<DNBTypography component='div' className={styles.dropdownHeader}>
								<DNBButton
									size='small'
									sx={{lineHeight: 1, padding: 0, cursor: 'pointer'}}
									variant='text'
									endIcon={
										sortId === ID &&
										(sortReverse ? <ExpandLessIcon /> : <ExpandMoreIcon />)
									}
									onClick={() => sort(ID)}>
									{header?.[0]}
								</DNBButton>
								{header && header.length > 1 && header[1] && (
									<DNBButton
										size='small'
										sx={{lineHeight: 1, padding: 0, cursor: 'pointer'}}
										variant='text'
										endIcon={
											sortId === altLabel &&
											(sortReverse ? <ExpandLessIcon /> : <ExpandMoreIcon />)
										}
										onClick={() => sort(altLabel)}>
										{header[1]}
									</DNBButton>
								)}
							</DNBTypography>
						</DNBTypography>
					)}
					{(filteredItems || []).length > 0 && (
						<DNBTypography
							component='div'
							className={classNames({
								[styles.dropdownContent!]: true,
								[styles.showDropdown!]: showQueryList,
							})}>
							<List
								height={
									filteredItems.length > 4 ? 200 : filteredItems.length * 40
								}
								width='100%'
								itemSize={() => 40}
								itemCount={filteredItems.length}>
								{({index, style}) => {
									const item = filteredItems[index] || {};
									const name = getDisplayName(displayName, item);
									const checked = isSelected(name);
									return (
										<DNBTypography
											component='div'
											key={name}
											style={style}
											className={classNames({
												[styles.queryResultRow!]: true,
												[styles.queryResultRowSelected!]: checked,
											})}
											onMouseDown={() => {
												if (checked) {
													removeItem(context, setContext, item);
												} else {
													chooseItem(context, setContext, item, true);
													if (singleSelection) {
														setShowQueryList(false);
													}
												}
											}}>
											<DNBTypography component='span' title={name}>
												{header && header?.length > 1 && header[1] && (
													<DNBTypography
														component='span'
														className='pull-right'>
														{item[altLabel]}
													</DNBTypography>
												)}
												{name}
											</DNBTypography>
										</DNBTypography>
									);
								}}
							</List>
						</DNBTypography>
					)}
					<DNBTypography component='div' sx={{clear: 'both'}} />
				</DNBTypography>
			</DNBTypography>
			<DNBButton
				size='small'
				sx={{lineHeight: 1, padding: 0, cursor: 'pointer'}}
				variant='text'
				className={styles.bulkEntryButton}
				onClick={() => setIsDialogOpen(true)}>
				Explore More Values
			</DNBButton>
			{isDialogOpen && (
				<ChipsDialog
					isDialogOpen={isDialogOpen}
					setIsDialogOpen={setIsDialogOpen}
				/>
			)}
		</DNBTypography>
	);
};
