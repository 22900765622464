import React from 'common/react-vendor';
import {
	designTokens,
	ApartmentIcon,
	ContactsIcon,
	DescriptionOutlinedIcon,
	SellOutlinedIcon,
} from 'common/dnb-uux-vendor';
import {
	FeatureFlags,
	isFeatureFlagEnabled,
	isTimeSeriesSegmentEnabled,
} from 'common/stores/tenantConfig';
import EventIcon from 'common/components/datacloud/attributes/Event';
import {
	CustomDocumentTypeGroup,
	DocumentType,
	DocumentTypeGroup,
	IDocumentGroupType,
	IDocumentTypeGroup,
	IDocumentTypeGroupIconConfig,
	IDocumentTypeGroupUIConfig,
	IDocumentTypeNode,
	IDocumentTypeNodeSystemMap,
	IIconConfig,
	ISubDocumentTypeMap,
} from './DocumentType';
import {DocumentTypeUIConfig} from './DocumentTypeUIConfig';
import {SystemType} from './SystemType';

const SubDocumentTypeMap: ISubDocumentTypeMap = {
	[DocumentType.MarketingActivity]: [
		DocumentType.VisitWebpage,
		DocumentType.FillOutForm,
		DocumentType.ClickLink,
		DocumentType.SendEmail,
		DocumentType.EmailDelivered,
		DocumentType.EmailBounced,
		DocumentType.InterestingMoment,
		DocumentType.OpenEmail,
		DocumentType.ClickEmail,
		DocumentType.NewLead,
		DocumentType.BounceBack,
		DocumentType.EmailClickThrough,
		DocumentType.EmailOpen,
		DocumentType.EmailSend,
		DocumentType.FormSubmit,
		DocumentType.PageView,
		DocumentType.Subscribe,
		DocumentType.Unsubscribe,
		DocumentType.ActivityWebVisit,
	],
	[DocumentType.SalesActivityType]: [
		DocumentType.SalesActivityTask,
		DocumentType.SalesActivityEvent,
	],
};

/**
 * To get main document type from one specific document type.
 * @param documentType The document type to query.
 * @returns The main document type or undefined.
 */
const getMainDocumentType = (
	documentType: DocumentType
): DocumentType | undefined => {
	const findDocumentType = Object.keys(SubDocumentTypeMap).filter((key) => {
		const mainDocumentType = key as DocumentType;
		return SubDocumentTypeMap[mainDocumentType]?.includes(documentType);
	})?.[0] as DocumentType | undefined;
	return findDocumentType;
};

const ActivityStreamSystemMap: IDocumentTypeNodeSystemMap = {
	[SystemType.Salesforce]: (): IDocumentTypeNode[] =>
		isFeatureFlagEnabled(FeatureFlags.HideSalesEventObjects)
			? [{main: DocumentType.Opportunity}]
			: [
					{main: DocumentType.SalesActivityEvent},
					{main: DocumentType.Opportunity},
					{main: DocumentType.SalesActivityTask},
			  ],
	[SystemType.Marketo]: (): IDocumentTypeNode[] => [
		{
			main: DocumentType.MarketingActivity,
			sub: [
				DocumentType.VisitWebpage,
				DocumentType.FillOutForm,
				DocumentType.ClickLink,
				DocumentType.SendEmail,
				DocumentType.EmailDelivered,
				DocumentType.EmailBounced,
				DocumentType.InterestingMoment,
				DocumentType.OpenEmail,
				DocumentType.ClickEmail,
				DocumentType.NewLead,
			],
		},
	],
	[SystemType.Eloqua]: (): IDocumentTypeNode[] => [
		{
			main: DocumentType.MarketingActivity,
			sub: [
				DocumentType.BounceBack,
				DocumentType.EmailClickThrough,
				DocumentType.EmailOpen,
				DocumentType.EmailSend,
				DocumentType.FormSubmit,
				DocumentType.PageView,
				DocumentType.Subscribe,
				DocumentType.Unsubscribe,
				DocumentType.ActivityWebVisit,
			],
		},
	],
	[SystemType.OneTime]: (): IDocumentTypeNode[] => {
		return isFeatureFlagEnabled(FeatureFlags.HideSalesEventObjects)
			? [
					{main: DocumentType.MarketingActivity},
					{main: DocumentType.Opportunity},
			  ]
			: [
					{main: DocumentType.MarketingActivity},
					{main: DocumentType.SalesActivityEvent},
					{main: DocumentType.SalesActivityTask},
					{main: DocumentType.Opportunity},
			  ];
	},
	[SystemType.AWS_S3]: (): IDocumentTypeNode[] => {
		return isFeatureFlagEnabled(FeatureFlags.HideSalesEventObjects)
			? [
					{main: DocumentType.MarketingActivity},
					{main: DocumentType.Opportunity},
			  ]
			: [
					{main: DocumentType.MarketingActivity},
					{main: DocumentType.SalesActivityEvent},
					{main: DocumentType.SalesActivityTask},
					{main: DocumentType.Opportunity},
			  ];
	},
	[SystemType.Salesforce_Marketing_Cloud]: (): IDocumentTypeNode[] => [
		{
			main: DocumentType.MarketingActivity,
		},
	],
	[SystemType.Microsoft_Dynamics365]: (): IDocumentTypeNode[] => [
		{
			main: DocumentType.Opportunity,
		},
	],
	[SystemType.Hub_Spot]: (): IDocumentTypeNode[] => [
		{main: DocumentType.SalesActivityEvent},
		{main: DocumentType.SalesActivityTask},
		{
			main: DocumentType.Opportunity,
		},
	],
};

const AccountSystemMap: IDocumentTypeNodeSystemMap = {
	[SystemType.Eloqua]: (): IDocumentTypeNode[] => [],
	[SystemType.Marketo]: (): IDocumentTypeNode[] => [],
	[SystemType.Salesforce_Marketing_Cloud]: (): IDocumentTypeNode[] => [],
};

const ContactSystemMap: IDocumentTypeNodeSystemMap = {
	[SystemType.Eloqua]: (): IDocumentTypeNode[] => [
		{main: DocumentType.Contact},
	],
	[SystemType.Marketo]: (): IDocumentTypeNode[] => [{main: DocumentType.Lead}],
	[SystemType.Salesforce_Marketing_Cloud]: (): IDocumentTypeNode[] => [
		{main: DocumentType.Contact},
	],
};

const ProductSystemMap: IDocumentTypeNodeSystemMap = {
	[SystemType.AWS_S3]: (): IDocumentTypeNode[] => [
		{main: DocumentType.ProductBundle},
		{main: DocumentType.ProductHierarchy},
		{main: DocumentType.ProductPurchase},
	],
};

const svgBasePath = '/atlas/assets/svgs/EIF/Icon/DocumentTypeGroup';

const DocumentGroupTypeConfig: IDocumentGroupType = {
	Account: (systemType: SystemType) =>
		AccountSystemMap[systemType]?.() || [{main: DocumentType.Account}],
	Contact: (systemType: SystemType) =>
		ContactSystemMap[systemType]?.() || [
			{main: DocumentType.Contact},
			{main: DocumentType.Lead},
		],
	Transaction: (systemType: SystemType) =>
		ProductSystemMap[systemType]?.() || [],
	ActivityStream: (systemType: SystemType) => {
		return (
			(isTimeSeriesSegmentEnabled() &&
				ActivityStreamSystemMap[systemType]?.()) ||
			[]
		);
	},
	Catalog: (systemType: SystemType) => {
		const hideCampaign =
			!isTimeSeriesSegmentEnabled() ||
			systemType === SystemType.Salesforce_Marketing_Cloud ||
			systemType === SystemType.Hub_Spot;
		return (!hideCampaign && [{main: DocumentType.Campaign}]) || [];
	},
};

const DocumentTypeGroupConfig: IDocumentTypeGroup = {
	Account: DocumentTypeGroup.Account,
	Contact: DocumentTypeGroup.Contact,
	Lead: DocumentTypeGroup.Contact,
	ProductPurchase: DocumentTypeGroup.Transaction,
	ProductBundle: DocumentTypeGroup.Transaction,
	ProductHierarchy: DocumentTypeGroup.Transaction,
	WebVisit: DocumentTypeGroup.ActivityStream,
	Opportunity: DocumentTypeGroup.ActivityStream,
	MarketingActivity: DocumentTypeGroup.ActivityStream,
	CustomIntent: DocumentTypeGroup.ActivityStream,
	G2Intent: DocumentTypeGroup.ActivityStream,
	TrustRadiusIntent: DocumentTypeGroup.ActivityStream,
	CampaignActivity: DocumentTypeGroup.ActivityStream,
	Impression: DocumentTypeGroup.ActivityStream,
	PerformanceReport: DocumentTypeGroup.ActivityStream,
	SalesActivityEvent: DocumentTypeGroup.ActivityStream,
	SalesActivityTask: DocumentTypeGroup.ActivityStream,
	LeadHistory: DocumentTypeGroup.ActivityStream,
	VisitWebpage: DocumentTypeGroup.ActivityStream,
	FillOutForm: DocumentTypeGroup.ActivityStream,
	ClickLink: DocumentTypeGroup.ActivityStream,
	SendEmail: DocumentTypeGroup.ActivityStream,
	EmailDelivered: DocumentTypeGroup.ActivityStream,
	EmailBounced: DocumentTypeGroup.ActivityStream,
	InterestingMoment: DocumentTypeGroup.ActivityStream,
	OpenEmail: DocumentTypeGroup.ActivityStream,
	ClickEmail: DocumentTypeGroup.ActivityStream,
	NewLead: DocumentTypeGroup.ActivityStream,
	BounceBack: DocumentTypeGroup.ActivityStream,
	EmailClickThrough: DocumentTypeGroup.ActivityStream,
	EmailOpen: DocumentTypeGroup.ActivityStream,
	EmailSend: DocumentTypeGroup.ActivityStream,
	FormSubmit: DocumentTypeGroup.ActivityStream,
	PageView: DocumentTypeGroup.ActivityStream,
	Subscribe: DocumentTypeGroup.ActivityStream,
	Unsubscribe: DocumentTypeGroup.ActivityStream,
	ActivityWebVisit: DocumentTypeGroup.ActivityStream,
	CustomEvent: DocumentTypeGroup.ActivityStream,
	WebVisitPathPattern: DocumentTypeGroup.Catalog,
	WebVisitSourceMedium: DocumentTypeGroup.Catalog,
	OpportunityStageName: DocumentTypeGroup.Catalog,
	OpportunityContactRole: DocumentTypeGroup.Catalog,
	MarketingActivityType: DocumentTypeGroup.Catalog,
	Campaign: DocumentTypeGroup.Catalog,
	Asset: DocumentTypeGroup.Catalog,
	AdGroup: DocumentTypeGroup.Catalog,
	SalesActivityType: DocumentTypeGroup.Catalog,
	SalesActivityEventType: DocumentTypeGroup.Catalog,
	SalesActivityTaskType: DocumentTypeGroup.Catalog,
	LatticeRecommendation: DocumentTypeGroup.Catalog,
	LeadCategory: DocumentTypeGroup.Catalog,
	User: DocumentTypeGroup.Catalog,
	Audience: DocumentTypeGroup.Catalog,
	CampaignBudget: DocumentTypeGroup.Catalog,
	CampaignElements: DocumentTypeGroup.Catalog,
	CustomObject: DocumentTypeGroup.Catalog,
};

/**
 * Primary icon config of document type group
 * @param color Document type group color
 * @returns IIconConfig
 */
const PrimaryIconConfig = (color: string): IIconConfig => ({
	color,
	fontSize: '16px',
});

/**
 * Secondary gray icon config of document type group
 */
const SecondaryIconConfig: IIconConfig = {
	color: designTokens.TokenColors.ColorGraySecondary,
	fontSize: '32px',
};

const DocumentTypeGroupIconConfig: IDocumentTypeGroupIconConfig = {
	Account: {
		color: designTokens.TokenColors.ColorPrimaryBlue,
		path: `${svgBasePath}/account.svg`,
		componentTag: (prop: IIconConfig) => <ApartmentIcon sx={{...prop}} />,
	},
	Contact: {
		color: designTokens.TokenColors.ColorSecondaryTealDark,
		path: `${svgBasePath}/contact.svg`,
		componentTag: (prop: IIconConfig) => <ContactsIcon sx={{...prop}} />,
	},
	ActivityStream: {
		color: designTokens.TokenColors.ColorSecondaryPurpleLight,
		path: `${svgBasePath}/events.svg`,
		componentTag: (prop: IIconConfig) => <EventIcon sx={{...prop}} />,
	},
	Catalog: {
		color: designTokens.TokenColors.ColorSecondaryBlueLight,
		path: `${svgBasePath}/general.svg`,
		componentTag: (prop: IIconConfig) => (
			<DescriptionOutlinedIcon sx={{...prop}} />
		),
	},
	Transaction: {
		color: designTokens.TokenColors.ColorSecondaryBlueLight,
		// Not using, leave it empty
		path: '',
		componentTag: (prop: IIconConfig) => <SellOutlinedIcon sx={{...prop}} />,
	},
};

const ReadyStateInitMessage = 'Initializing...';
const ActivityStreamDisabledMessage = (isAccountIncluded?: boolean): string =>
	`To load events data, please load ${
		isAccountIncluded ? 'accounts or ' : ''
	}contacts first. Otherwise, your events data may be lost.`;
const CatalogDisabledMessage =
	'To import campaign data, please import opportunity or marketing activity from this connection first.';

const DocumentTypeGroupUIConfig: IDocumentTypeGroupUIConfig = {
	Account: {
		title: 'Accounts',
	},
	Contact: {
		title: 'Contacts',
	},
	Transaction: {
		title: 'Products',
	},
	ActivityStream: {
		title: 'Activities',
		getReadyState: ({isFetching, isEventsReady, isAccountIncluded}) => {
			if (isFetching) {
				return {message: ReadyStateInitMessage};
			}
			return {
				message: isEventsReady
					? ''
					: ActivityStreamDisabledMessage(isAccountIncluded),
			};
		},
		getCustomDocumentTypeGroup: () => CustomDocumentTypeGroup.event,
	},
	Catalog: {
		title: 'General',
		getReadyState: ({isFetching, isCampaignReady}) => {
			if (isFetching) {
				return {message: ReadyStateInitMessage};
			}
			return {
				message: isCampaignReady ? '' : CatalogDisabledMessage,
			};
		},
		getCustomDocumentTypeGroup: () => CustomDocumentTypeGroup.object,
	},
};

/**
 * Supported document group list.
 */
const SupportedDocumentGroupList = [
	DocumentTypeGroup.Account,
	DocumentTypeGroup.Contact,
	DocumentTypeGroup.ActivityStream,
	DocumentTypeGroup.Transaction,
	DocumentTypeGroup.Catalog,
];

/**
 * Document type list that supporting unique id.
 */
const UniqueIdDocumentTypeList: string[] = [
	DocumentType.Account,
	DocumentType.Contact,
	DocumentType.Lead,
	DocumentType.ProductPurchase,
];
const isUniqueIdSupported = (
	cdpObject: string,
	documentType?: DocumentType
): boolean =>
	UniqueIdDocumentTypeList.includes(cdpObject) ||
	documentType === DocumentType.CustomObject;

/**
 * Document type list that not support delete.
 */
const UnDeletableDocumentTypeList = [
	DocumentType.WebVisit,
	DocumentType.CustomIntent,
];

/**
 * Systems that has multiple marketing activity document types.
 */
const MultipleMarketingActivityDocumentTypeList = [
	SystemType.Marketo,
	SystemType.Eloqua,
];

/**
 * Indicates whether the document type is supported or not.
 * Rule:
 * - Always show, for GA features.
 * [Account, Contact, Lead]
 * - Featured by feature flag, for dev features.
 * [MarketingActivity, Opportunities] from Event catalog.
 * [Campaigns] from General catalog.
 * - Always hide, for unsupported features.
 * All type excluded from above.
 * @param schemaType Usually refers to DocumentType enum, for CustomEvent this maybe any string.
 * @param documentType Refers to DocumentType enum.
 * @returns boolean indicating whether the document type is supported or not.
 */
const isDocumentTypeSupported = (
	schemaType: DocumentType,
	documentType: DocumentType
): boolean => {
	const supportedDocumentTypeList = [
		DocumentType.CustomEvent,
		DocumentType.CustomObject,
	];

	const supportedList = [
		DocumentType.Account,
		DocumentType.Contact,
		DocumentType.Lead,
		DocumentType.CustomIntent,
		DocumentType.WebVisit,
		DocumentType.ProductPurchase,
		DocumentType.ProductBundle,
		DocumentType.ProductHierarchy,
		DocumentType.G2Intent,
		DocumentType.TrustRadiusIntent,
	];
	const featuredList = [
		DocumentType.MarketingActivity,
		DocumentType.Opportunity,
		DocumentType.SalesActivityTask,
		DocumentType.SalesActivityEvent,
		DocumentType.Campaign,
	];
	return (
		supportedDocumentTypeList.includes(documentType) ||
		supportedList.includes(schemaType) ||
		(isFeatureFlagEnabled(FeatureFlags.ENABLE_TIME_SERIES_SEGMENT) &&
			featuredList.includes(schemaType))
	);
};

// Currently only `Event` group support custom schema type
const getDocumentTypeGroupByType = (cdpObjectType: string): DocumentTypeGroup =>
	DocumentTypeGroupConfig[cdpObjectType as DocumentType] ||
	DocumentTypeGroup.ActivityStream;

const isTimeSeriesDocumentType = (cdpObjectType: string): boolean =>
	getDocumentTypeGroupByType(cdpObjectType) ===
	DocumentTypeGroup.ActivityStream;

const uneditableDocumentTypeSet = new Set([
	DocumentType.CustomIntent,
	DocumentType.G2Intent,
	DocumentType.TrustRadiusIntent,
	DocumentType.ProductBundle,
	DocumentType.ProductHierarchy,
	DocumentType.ProductPurchase,
]);

const isDocumentTypeUneditable = (cdpObjectType: DocumentType): boolean =>
	uneditableDocumentTypeSet.has(cdpObjectType);

const intentDocumentTypeSet = new Set([
	DocumentType.CustomIntent,
	DocumentType.G2Intent,
	DocumentType.TrustRadiusIntent,
]);

const isIntentDocumentType = (cdpObjectType: DocumentType): boolean =>
	intentDocumentTypeSet.has(cdpObjectType);

const getDisplayNameByType = (cdpObjectType = ''): string =>
	DocumentTypeUIConfig[cdpObjectType as DocumentType]?.title || cdpObjectType;

export {
	SubDocumentTypeMap,
	DocumentGroupTypeConfig,
	DocumentTypeGroupConfig,
	DocumentTypeGroupIconConfig,
	DocumentTypeGroupUIConfig,
	DocumentTypeUIConfig,
	SupportedDocumentGroupList,
	UniqueIdDocumentTypeList,
	isUniqueIdSupported,
	UnDeletableDocumentTypeList,
	MultipleMarketingActivityDocumentTypeList,
	isDocumentTypeSupported,
	getMainDocumentType,
	isTimeSeriesDocumentType,
	PrimaryIconConfig,
	SecondaryIconConfig,
	isIntentDocumentType,
	isDocumentTypeUneditable,
	getDocumentTypeGroupByType,
	getDisplayNameByType,
};
