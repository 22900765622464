import React from 'common/react-vendor';
import {IAttribute} from '../schemaTypes';
import {renderRequiredText} from '../utils';
import styles from './AttributeOverview.module.scss';

export default function AttributeOverview({
	attribute: {
		screenName,
		displayName,
		cdpDataType,
		timeZone,
		dateFormat,
		approvedUsage,
		cdpMaxLength,
		cdpMinLength,
		nullable,
		description,
	},
}: {
	attribute: IAttribute;
}): React.ReactElement {
	return (
		<div>
			<p className={styles.title}>{displayName || screenName}</p>
			<table className={styles.attributeOverviewTable}>
				<tbody>
					<tr>
						<td>Data Type</td>
						<td>{cdpDataType}</td>
					</tr>
					{!!timeZone && (
						<tr>
							<td>Time Zone</td>
							<td>{timeZone}</td>
						</tr>
					)}
					{!!dateFormat && (
						<tr>
							<td>Date Format</td>
							<td>{dateFormat}</td>
						</tr>
					)}
					{!!approvedUsage?.length && (
						<tr>
							<td>Choices</td>
							<td>{approvedUsage.join()}</td>
						</tr>
					)}
					{!!(cdpMinLength ?? cdpMaxLength) && (
						<tr>
							<td>Length</td>
							<td>
								{cdpMinLength ?? ''} - {cdpMaxLength ?? ''}
							</td>
						</tr>
					)}
					<tr>
						<td>Required</td>
						<td>{renderRequiredText(nullable)}</td>
					</tr>
					{!!description && (
						<tr>
							<td>Description</td>
							<td>{description}</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}
