import _ from 'lodash';
import {Bkt} from 'common/components/datacloud/query/query.types';
import {IAttribute, SubCategoryDict} from 'atlas/data/AttributeConst';
import {OtherCategory} from 'atlas/data/CategoryConst';
import {IEntityMapping} from 'atlas/data/EntityConst';
import {
	IModalAttribute,
	AttributesHardCodedConfig,
} from 'atlas/modelsOld/modals/AddAttributeModal/AddAttributesModalConst';
import {EntityType} from 'atlas/data/RestrictionConst';
import {CategoryMetadata} from 'common/components/datacloud/datacloud.service.vanilla';
import {IDataController} from './DataController';

/**
 * Attribute sort function
 * @param param attribute lhs
 * @param param attribute rhs
 * @returns -1,0,1
 */
const AttributeSorter = (
	{
		DisplayName: lhsDisplayName,
		Subcategory: lhsSubCategory,
		AttrName: lhsAttrName,
	}: IAttribute,
	{
		DisplayName: rhsDisplayName,
		Subcategory: rhsSubCategory,
		AttrName: rhsAttrName,
	}: IAttribute
): number => {
	if (
		AttributesHardCodedConfig[lhsSubCategory] &&
		lhsSubCategory === rhsSubCategory
	) {
		const lhsConfig = AttributesHardCodedConfig[lhsSubCategory]!;
		const rhsConfig = AttributesHardCodedConfig[rhsSubCategory]!;
		return (
			(lhsConfig[lhsAttrName]?.sortOrder || 0) -
			(rhsConfig[rhsAttrName]?.sortOrder || 0)
		);
	}
	const subCategoryCompare = lhsSubCategory.localeCompare(rhsSubCategory);
	const displayNameCompare = lhsDisplayName.localeCompare(rhsDisplayName);
	return subCategoryCompare || displayNameCompare;
};

/**
 * Attribute filter function
 * @param param attribute data Refers to @IAttribute
 * @param isActivity True on time series attribute
 * @param entityType Entity type refers to @EntityType
 * @param entityMap Entity map refers to @IEntityMapping
 * @returns True if filtered
 */
const AttributeFilter = (
	{Entity, AttrName}: IAttribute,
	isActivity: boolean,
	entityType: EntityType,
	entityMap: IEntityMapping
): boolean => {
	const key = isActivity ? `${entityType}ActivityStream` : entityType;
	// Attribute without AttrName is not queryable
	if (!AttrName) {
		return false;
	}
	if (!_.isEmpty(entityMap)) {
		return (entityMap[key] || []).includes(Entity);
	}
	return isActivity || `${Entity}` === `${entityType}`;
};

/**
 * Account / Contact data controller
 * wrap raw data from attribute api
 * provide modal attribute data for display usage
 */
class AccountContactDataController implements IDataController {
	categoryMap: Record<string, string>;

	attribute: IAttribute;

	topBucket: Bkt;

	constructor(
		categoryMetadata: CategoryMetadata[],
		attribute: IAttribute,
		topBucket: Bkt
	) {
		this.categoryMap = this.getCategoryMap(categoryMetadata);
		this.attribute = attribute;
		this.topBucket = topBucket;
	}

	/**
	 * Get category map with key legacyDisplayName and value DisplayName
	 * @param categoryMetadata category meta data
	 */
	getCategoryMap = (
		categoryMetadata: CategoryMetadata[]
	): Record<string, string> => {
		return categoryMetadata.reduce((pre, {displayName, legacyDisplayName}) => {
			return {...pre, [legacyDisplayName]: displayName};
		}, {} as Record<string, string>);
	};

	/**
	 * Get attribute id key
	 * @returns The attribute id key
	 * For example:
	 * account/contact attribute: Account.Attribute_Name
	 */
	getKey = (): string => {
		const {
			attribute: {AttrName, Entity},
		} = this;
		return `${Entity}.${AttrName}`;
	};

	getCategory = (): string => {
		const {
			attribute: {Category},
		} = this;
		return Category;
	};

	isShowSubCategory = (): boolean => {
		const {
			attribute: {Subcategory},
		} = this;
		return Subcategory !== OtherCategory;
	};

	toModalAttribute = (): IModalAttribute => {
		const {
			attribute: {
				Category,
				Subcategory,
				DisplayName: name,
				Description,
				AttrName,
			},
		} = this;
		const topbkt = this.topBucket;
		const ShowSubcategory = this.isShowSubCategory();
		const Title = `${ShowSubcategory ? `${Subcategory} -> ` : ''}${name}`;
		return {
			Category: this.categoryMap[Category] || Category,
			Subcategory: SubCategoryDict?.[Subcategory] || Subcategory,
			ShowSubcategory,
			Title,
			AttrName: this.getKey(),
			name,
			Description:
				// activity attribute api do not return `Description` param
				AttributesHardCodedConfig[Subcategory]?.[AttrName]?.description ||
				Description,
			topbkt,
		};
	};
}

export {AttributeFilter, AttributeSorter, AccountContactDataController};
