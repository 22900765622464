import {react2angular} from 'common/react-vendor';
import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {store} from 'store';
import {
	getDataCloudProperty,
	getEnrichments,
	getEnrichmentsMap,
	setDataCloudPropertyValue,
	getEventEnrichments,
} from 'common/stores/datacloud';
import {
	Modes,
	setModeFromState,
	dispatchQueryPropertyValue,
	getQueryProperty,
	isSegmentationMode,
	isRulesBasedModelMode,
	setPublicProperty,
	dispatchSetAddBucketTreeRoot,
	setEntitiesProperty,
	dispatchSetCountLoading,
	setResourceTypeCount,
	setRestrictions,
} from 'common/stores/query';
import {getSubSegmentStatus} from '../../../../../atlas/app/segmentation/SegmentationUtility';
import {
	FeatureFlags,
	isFeatureFlagEnabled,
	isBuyerJourneyEnabled,
	isTimeSeriesSegmentEnabled,
	isSegmentationV2Enabled,
	isHorizontalNavigationEnabled,
	isEnableQueryBuilderRedesign,
} from 'common/stores/tenantConfig';
import {
	createOrUpdateSegment,
	removeEmptyBuckets,
	sanitizeSegmentRestriction,
	sanitizeSegment,
} from '../../segment/segment.helpers';
import {
	getAllActivityBuckets,
	getRuleAllBuckets,
	getRuleCount,
	refreshCounts,
	getEntitiesCounts,
} from '../query.helpers';
import {dispatchRatingsEnginePropertyValue} from 'atlas/stores/ratingsEngine';
import {
	saveRatingEngineRules,
	getCoverageMap,
	getBucketRuleCounts,
} from 'atlas/ratingsengine/ratingsengine.helpers';
import {SEGMENTS_EXPLORER} from '../../../../../atlas/app/navigation/header/components/page-navigation.utils';
import {getCategoryThemeColor} from '../../datacloud.service.vanilla';
import {
	StickyHeight,
	QueryButtonGroupHeigh,
} from '../../datacloud.constants';
import {AdvancedQueryComponent} from './AdvancedQueryComponent';

angular
	.module('common.datacloud.query.builder', [
		'common.datacloud.query.builder.queryButtonGroup',
		'common.datacloud.query.builder.block',
		'common.datacloud.query.builder.iconDirective',
		'common.datacloud.query.builder.list',
		'common.datacloud.query.builder.input',
		'common.datacloud.query.builder.tree',
	])
	.controller(
		'AdvancedQueryCtrl',
		function (
			$rootScope,
			$state,
			$stateParams,
			$timeout,
			Cube,
			RatingEngineModel,
			CurrentRatingEngine,
			CategoryMetadata,
			EnrichmentTopAttributes,
			EntityList,
		) {
			const vm = this;
			let CoverageMap;

			const segmentObj =
				getQueryProperty('segment') ||
				(CurrentRatingEngine ? CurrentRatingEngine.segment : {});

			const inRatingEngine = CurrentRatingEngine !== null;
			const isEditable = inRatingEngine
				? CurrentRatingEngine.viewOnly
				: segmentObj.viewOnly;

			angular.extend(vm, {
				inModel: $state.current.name.split('.')[1] === 'model',
				inRatingEngine,
				cube: Cube,
				history: getQueryProperty('history'),
				restriction: getQueryProperty('accountRestriction'),
				account_restriction: getQueryProperty('accountRestriction'),
				contact_restriction: getQueryProperty('contactRestriction'),
				enrichmentsMap: getEnrichmentsMap(),
				eventEnrichmentsMap: getDataCloudProperty('eventEnrichmentsMap'),
				droppedItem: null,
				draggedItem: null,
				items: [],
				enrichments: [],
				eventEnrichments: [],
				labelIncrementor: 0,
				bucket: getQueryProperty('selectedBucket'),
				buckets: [],
				bucketsMap: {A: 0, B: 1, C: 2, D: 3, E: 4, F: 5},
				bucketLabels: ['A', 'B', 'C', 'D', 'E', 'F'],
				default_bucket: 'A',
				rating_rule: {},
				coverage_map: {},
				rating_id: $stateParams.rating_id,
				treeMode: 'account',
				segment: $stateParams.segment,
				segmentObj,
				segmentInputTree: [],
				segmentInputTreeInit: [], // for undo
				segmentHistoryTree: [], // for undo save history
				rulesInputTree: [],
				accountRulesTree: [],
				contactRulesTree: [],
				mouseDownTimer: false,
				heights: {},
				canEdit:
					!isEditable && RBAC.hasAccess(RBACInterface.MODELS, RBACActions.EDIT),
				categoryMetadata: CategoryMetadata,
				enrichmentTopAttributes: EnrichmentTopAttributes,
				entityList: EntityList,
				segmentsList: getQueryProperty('segments'),
				showSegmentationV2: isSegmentationV2Enabled(),
				enableQueryBuilderRedesign: isEnableQueryBuilderRedesign(),
				isNewRedesign:
					isSegmentationV2Enabled() && isEnableQueryBuilderRedesign(),
				showTimeSeries: isTimeSeriesSegmentEnabled(),
				listSegmentMaterialization: isFeatureFlagEnabled(
					FeatureFlags.LIST_SEGMENT_MATERIALIZATION
				),
				showSegmentBlock:
					typeof segmentObj.isSubSegment === 'undefined' ||
					segmentObj.isSubSegment,
				isTAM: $stateParams.isTAM,
				isRules: isSegmentationMode(),
				stickyBar:
					($state.current.name.split('.')[1] === 'segment' ||
						$state.current.name ===
							'home.ratingsengine.createAccountFitModeling.createSegment' ||
						$state.current.name ===
							'home.ratingsengine.createAccountFitModeling.scoringSegment') &&
					isHorizontalNavigationEnabled(),
				customParentSegmentName: segmentObj.customParentSegmentName,
				isAccountFitModelingSuccess: $state.current.name.includes(
					'home.ratingsengine.createAccountFitModeling.successCriteria'
				),
				isBuyerJourneyEnabled: isBuyerJourneyEnabled(),
			});

			if (vm.showSegmentationV2) {
				angular.extend(vm, {
					member_restriction: getQueryProperty('memberRestriction'),
				});
				if (vm.segment !== 'Create') {
					const segment = vm.segmentsList.find(
						(item) => item.name === vm.segment
					);
					vm.showSegmentBlock = segment?.subSegments.length === 0;
				}
			}

			vm.init = function () {
				if (
					vm.showSegmentationV2 &&
					$stateParams.segment === 'Create' &&
					$stateParams.subSegment
				) {
					setPublicProperty('enableSaveSegmentButton', true);
				}
				setPublicProperty(
					'enableExportSegmentButton',
					!vm.segmentObj.status ||
						getSubSegmentStatus(vm.segmentObj.status) === 'Active'
				);

				// FIXME: The query builder should be given configuration from its
				// parent; not infer functionality based on its environment
				setModeFromState($state.current.name);
				// FIXME: `vm.mode` is still used in some of the templates.
				// `query store mode` should be the only source of truth
				vm.mode = getQueryProperty('mode');
				vm.isRules = isSegmentationMode();

				if (isRulesBasedModelMode()) {
					vm.ratingEngineModel = RatingEngineModel;
					vm.rating_rule = RatingEngineModel.rule.ratingRule;
					vm.rating_buckets = vm.rating_rule.bucketToRuleMap;
					vm.default_bucket = vm.rating_rule.defaultBucketName;

					dispatchRatingsEnginePropertyValue('rule', RatingEngineModel);

					vm.initCoverageMap();
					vm.getRatingsAndRecordCounts(
						angular.copy(RatingEngineModel),
						CurrentRatingEngine.segment.name
					);

					vm.setRulesTree();

					dispatchQueryPropertyValue(
						'accountBucketTreeRoot',
						vm.accountRulesTree[0]
					);
					dispatchQueryPropertyValue(
						'contactBucketTreeRoot',
						vm.contactRulesTree[0]
					);
				}

				getEnrichments().then(function (enrichments) {
					enrichments.forEach(({Entity, ColumnId}, i) => {
						vm.enrichmentsMap[`${Entity}.${ColumnId}`] = i;
					});
					vm.enrichments = enrichments;

					store.dispatch(
						setDataCloudPropertyValue('enrichmentsMap', vm.enrichmentsMap)
					);

					$timeout(function () {
						if (isRulesBasedModelMode()) {
							if (vm.showSegmentationV2 && vm.MemberRulesTree) {
								vm.rulesInputTree = {
									collapsed: false,
									logicalRestriction: {
										operator: 'AND',
										restrictions: [
											vm.accountRulesTree[0],
											vm.contactRulesTree[0],
											vm.MemberRulesTree[0],
										],
									},
								};
							} else {
								vm.rulesInputTree = {
									collapsed: false,
									logicalRestriction: {
										operator: 'AND',
										restrictions: [
											vm.accountRulesTree[0],
											vm.contactRulesTree[0],
										],
									},
								};
							}
							// this for refresh bucket label
							setPublicProperty('resetLabelIncrementor', true);
							vm.setTotalRuleCount();
							vm.setBucketLabel(vm.rulesInputTree);
						}

						vm.setCurrentSavedTree();

						// console.log('[AQB] Restriction:', angular.copy(vm.restriction));
						// console.log('[AQB] Items:', vm.items);
						// console.log('[AQB] Cube:', vm.cube);
					}, 1);
				});

				if (vm.showTimeSeries) {
					getEventEnrichments().then(function (eventEnrichments) {
						const newEventEnrichmentsMap = vm.eventEnrichmentsMap
							? {...vm.eventEnrichmentsMap}
							: {};

						eventEnrichments.forEach(({Entity, ColumnId}, i) => {
							newEventEnrichmentsMap[`${Entity}.${ColumnId}`] = i;
						});

						vm.eventEnrichments = eventEnrichments;
						vm.eventEnrichmentsMap = newEventEnrichmentsMap;

						store.dispatch(
							setDataCloudPropertyValue(
								'eventEnrichmentsMap',
								newEventEnrichmentsMap
							)
						);
					});
				}

				dispatchSetAddBucketTreeRoot(null);

				if (vm.isRules) {
					vm.segmentInputTree = {
						logicalRestriction: {
							operator: 'AND',
							canNotEditOperator: true,
							restrictions: [
								vm.account_restriction.restriction,
								vm.contact_restriction.restriction,
							],
						},
					};
					if (vm.showSegmentationV2) {
						const {compositeSegmentBlock, restrictions} =
							vm.member_restriction.restriction.logicalRestriction;
						const unionTree = restrictions.filter(
							(item) =>
								item.logicalRestriction &&
								item.logicalRestriction.operator === 'OR'
						)[0];
						const intersectionTree = restrictions.filter(
							(item) =>
								item.logicalRestriction &&
								item.logicalRestriction.operator === 'AND'
						)[0];

						const oldSegmentTree = [];
						if (!compositeSegmentBlock) {
							restrictions.filter((item, index, arr) => {
								if (!item.logicalRestriction) {
									oldSegmentTree.push(item);
									arr.splice(index, 1);
								}
							});
						}
						if (!unionTree) {
							const logicalRestriction = {
								logicalRestriction: {
									operator: 'OR',
									restrictions: [],
								},
							};
							restrictions[0] = logicalRestriction;
						}
						if (!intersectionTree) {
							const logicalRestriction = {
								logicalRestriction: {
									operator: 'AND',
									restrictions: [],
								},
							};
							restrictions[1] = logicalRestriction;
						}
						if (!compositeSegmentBlock) {
							restrictions[1].logicalRestriction.restrictions.push(
								...oldSegmentTree
							);
						}
						// PLS-26654
						vm.member_restriction.restriction.logicalRestriction.compositeSegmentBlock = true;
						vm.setSegmentInputTree();
					}
					vm.segmentInputTreeInit = angular.copy([
						vm.showSegmentationV2 ? vm.segmentHistoryTree : vm.segmentInputTree,
					]);

					refreshCounts();
				}
				if (!vm.showSegmentationV2) {
					const current = vm.isRules
						? angular.copy([vm.segmentInputTree])
						: angular.copy([vm.rulesInputTree]);
					const old = angular.copy(vm.history[vm.history.length - 1]) || [];
					if (vm.history.length !== 0 && !vm.compareTree(old, current)) {
						vm.history.push(current);
					}
				}
				vm.setTotalRuleCount();
			};

			vm.doesSegmentOrBlockShow = () => {
				return (
					vm.showSegmentationV2 &&
					vm.showSegmentBlock &&
					!vm.customParentSegmentName &&
					!$stateParams.subStageId &&
					!vm.isAccountFitModelingSuccess
				);
			};

			vm.initCoverageMap = function (map) {
				var n = map ? 0 : -1;

				vm.buckets = [];

				vm.bucketLabels.forEach(function (bucketName, index) {
					vm.buckets.push({bucket: bucketName, count: n});
				});

				if (map) {
					var segmentId = Object.keys(map.segmentIdModelRulesCoverageMap)[0];

					vm.coverage_map = map.segmentIdModelRulesCoverageMap[segmentId];

					if (vm.coverage_map) {
						vm.coverage_map.bucketCoverageCounts.forEach(function (bkt) {
							vm.buckets[vm.bucketsMap[bkt.bucket]].count = bkt.count;
						});
					}
				}

				return map;
			};

			vm.doesDisplayParentSegment = () => {
				const isQueryBuilderRoute = $state.current.name === SEGMENTS_EXPLORER;
				return (
					isBuyerJourneyEnabled() &&
					isQueryBuilderRoute &&
					!!vm.segmentObj.customParentSegmentName
				);
			};

			vm.navigateParentSegment = () => {
				$state.go(SEGMENTS_EXPLORER, {
					segment: vm.segmentObj.customParentSegmentName,
				});
			};

			vm.parentSegment = () => {
				const parentSegment = vm.segmentsList.find(
					(segment) => segment.name === vm.segmentObj.customParentSegmentName
				);
				return `Parent Segment: ${parentSegment?.display_name}`;
			};

			vm.getTree = function () {
				if (isRulesBasedModelMode()) return [vm.generateRulesTree()];
				return [vm.restriction.restriction];
			};

			vm.getAccountTree = function () {
				return [vm.account_restriction.restriction];
			};

			vm.getContactTree = function () {
				return [vm.contact_restriction.restriction];
			};

			vm.getSegmentUnionTree = function () {
				return vm.member_restriction.restriction.logicalRestriction.restrictions.slice(
					0,
					1
				);
			};

			vm.getSegmentIntersectionTree = function () {
				return vm.member_restriction.restriction.logicalRestriction.restrictions.slice(
					1,
					2
				);
			};

			vm.setRulesTree = function () {
				vm.accountRulesTree = [vm.generateRulesTree('Account')];
				vm.contactRulesTree = [vm.generateRulesTree('Contact')];

				dispatchQueryPropertyValue(
					'accountBucketTreeRoot',
					vm.accountRulesTree[0]
				);
				dispatchQueryPropertyValue(
					'contactBucketTreeRoot',
					vm.contactRulesTree[0]
				);
				if (vm.showSegmentationV2) {
					const memberRulesTree = vm.generateRulesTree('Member');
					if (memberRulesTree) {
						vm.MemberRulesTree = [memberRulesTree];
					}
				}
			};

			vm.setSegmentInputTree = function () {
				const segmentInputTree = [];
				const accountLen =
					vm.account_restriction.restriction.logicalRestriction.restrictions
						.length;
				const contactLen =
					vm.contact_restriction.restriction.logicalRestriction.restrictions
						.length;

				const {restrictions} =
					vm.member_restriction.restriction.logicalRestriction;

				const unionTree = restrictions[0] || [];
				const unionTreeLen =
					unionTree?.logicalRestriction?.restrictions.length || 0;
				const intersectionTree = restrictions[1] || [];
				const intersectionTreeLen =
					intersectionTree?.logicalRestriction?.restrictions.length || 0;
				/** （ ( (account) and (contact) ）or (union) ） and (intersection)
				 * 1 account
				 * 2 contact
				 * 3 union
				 * 4 intersection
				 * 5 account and contact
				 * 6 account or union
				 * 7 account and intersection
				 * 8 contact or union
				 * 9 contact and intersection
				 * 10 union and intersection
				 * 11 (account and contact) or union
				 * 12 account and contact and intersection
				 * 13 (account or union) and intersection
				 * 14 (contact or union) and intersection
				 * 15 ( (account) and (contact) ）or (union) ） and (intersection)
				 * 16 []
				 */
				const orTree = {
					logicalRestriction: {
						operator: 'OR',
						restrictions: [],
					},
				};
				const andTree = {
					logicalRestriction: {
						operator: 'AND',
						restrictions: [],
					},
				};
				if (
					accountLen &&
					!contactLen &&
					!unionTreeLen &&
					!intersectionTreeLen
				) {
					segmentInputTree.push(vm.account_restriction.restriction); // 1 account
				} else if (
					!accountLen &&
					contactLen &&
					!unionTreeLen &&
					!intersectionTreeLen
				) {
					segmentInputTree.push(vm.contact_restriction.restriction); // 2 contact
				} else if (
					!accountLen &&
					!contactLen &&
					unionTreeLen &&
					!intersectionTreeLen
				) {
					segmentInputTree.push(unionTree); // 3 union
				} else if (
					!accountLen &&
					!contactLen &&
					!unionTreeLen &&
					intersectionTreeLen
				) {
					segmentInputTree.push(intersectionTree); //  4 intersection
				} else if (
					accountLen &&
					contactLen &&
					!unionTreeLen &&
					!intersectionTreeLen
				) {
					andTree.logicalRestriction.restrictions.length = 0;
					andTree.logicalRestriction.canNotEditOperator = true;
					andTree.logicalRestriction.restrictions.push(
						vm.account_restriction.restriction
					);
					andTree.logicalRestriction.restrictions.push(
						vm.contact_restriction.restriction
					);
					segmentInputTree.push(andTree); //  5 account and contact
				} else if (
					accountLen &&
					!contactLen &&
					unionTreeLen &&
					!intersectionTreeLen
				) {
					orTree.logicalRestriction.restrictions.length = 0;
					orTree.logicalRestriction.restrictions.push(
						vm.account_restriction.restriction
					);
					orTree.logicalRestriction.restrictions.push(unionTree);
					segmentInputTree.push(orTree); // 6 account or union
				} else if (
					accountLen &&
					!contactLen &&
					!unionTreeLen &&
					intersectionTreeLen
				) {
					andTree.logicalRestriction.restrictions.length = 0;
					andTree.logicalRestriction.restrictions.push(
						vm.account_restriction.restriction
					);
					andTree.logicalRestriction.restrictions.push(intersectionTree);
					segmentInputTree.push(andTree); // 7 account and intersection
				} else if (
					!accountLen &&
					contactLen &&
					unionTreeLen &&
					!intersectionTreeLen
				) {
					orTree.logicalRestriction.restrictions.length = 0;
					orTree.logicalRestriction.restrictions.push(
						vm.contact_restriction.restriction
					);
					orTree.logicalRestriction.restrictions.push(unionTree);
					segmentInputTree.push(orTree); // 8 contact or union
				} else if (
					!accountLen &&
					contactLen &&
					!unionTreeLen &&
					intersectionTreeLen
				) {
					andTree.logicalRestriction.restrictions.length = 0;
					andTree.logicalRestriction.restrictions.push(
						vm.contact_restriction.restriction
					);
					andTree.logicalRestriction.restrictions.push(intersectionTree);
					segmentInputTree.push(andTree); // 9 contact and intersection
				} else if (
					!accountLen &&
					!contactLen &&
					unionTreeLen &&
					intersectionTreeLen
				) {
					segmentInputTree.push(vm.member_restriction.restriction); // 10 union and intersection
				} else if (
					accountLen &&
					contactLen &&
					unionTreeLen &&
					!intersectionTreeLen
				) {
					andTree.logicalRestriction.restrictions.length = 0;
					andTree.logicalRestriction.canNotEditOperator = true;
					andTree.logicalRestriction.restrictions.push(
						vm.account_restriction.restriction
					);
					andTree.logicalRestriction.restrictions.push(
						vm.contact_restriction.restriction
					);
					orTree.logicalRestriction.restrictions.length = 0;
					orTree.logicalRestriction.restrictions.push(andTree);
					orTree.logicalRestriction.restrictions.push(unionTree);
					segmentInputTree.push(orTree); // 11 (account and contact) or union
				} else if (
					accountLen &&
					contactLen &&
					!unionTreeLen &&
					intersectionTreeLen
				) {
					andTree.logicalRestriction.restrictions.length = 0;
					andTree.logicalRestriction.canNotEditOperator = true;
					andTree.logicalRestriction.restrictions.push(
						vm.account_restriction.restriction
					);
					andTree.logicalRestriction.restrictions.push(
						vm.contact_restriction.restriction
					);
					andTree.logicalRestriction.restrictions.push(intersectionTree);
					segmentInputTree.push(andTree); // 12 account and contact and intersection
				} else if (
					accountLen &&
					!contactLen &&
					unionTreeLen &&
					intersectionTreeLen
				) {
					orTree.logicalRestriction.restrictions.length = 0;
					orTree.logicalRestriction.restrictions.push(
						vm.account_restriction.restriction
					);
					orTree.logicalRestriction.restrictions.push(unionTree);
					andTree.logicalRestriction.restrictions.length = 0;
					andTree.logicalRestriction.restrictions.push(orTree);
					andTree.logicalRestriction.restrictions.push(intersectionTree);
					segmentInputTree.push(andTree); // 13 (account or union) and intersection
				} else if (
					!accountLen &&
					contactLen &&
					unionTreeLen &&
					intersectionTreeLen
				) {
					orTree.logicalRestriction.restrictions.length = 0;
					orTree.logicalRestriction.restrictions.push(
						vm.contact_restriction.restriction
					);
					orTree.logicalRestriction.restrictions.push(unionTree);
					andTree.logicalRestriction.restrictions.length = 0;
					andTree.logicalRestriction.restrictions.push(orTree);
					andTree.logicalRestriction.restrictions.push(intersectionTree);
					segmentInputTree.push(andTree); // 14 (contact or union) and intersection
				} else if (
					accountLen &&
					contactLen &&
					unionTreeLen &&
					intersectionTreeLen
				) {
					const allTree = {
						logicalRestriction: {
							operator: 'AND',
							restrictions: [
								{
									logicalRestriction: {
										operator: 'OR',
										restrictions: [
											{
												logicalRestriction: {
													operator: 'AND',
													canNotEditOperator: true,
													restrictions: [
														vm.account_restriction.restriction,
														vm.contact_restriction.restriction,
													],
												},
											},
											vm.member_restriction.restriction.logicalRestriction
												.restrictions[0],
										],
									},
								},
								vm.member_restriction.restriction.logicalRestriction
									.restrictions[1],
							],
						},
					};
					segmentInputTree.push(allTree); //15 ( (account) and (contact) ）or (union) ） and (intersection)
				} else {
					const nullTree = {
						logicalRestriction: {
							operator: 'AND',
							restrictions: [],
						},
					};
					segmentInputTree.push(nullTree);
				}
				vm.segmentInputTree = segmentInputTree[0];
				vm.segmentHistoryTree = {
					logicalRestriction: {
						operator: 'AND',
						restrictions: [
							vm.account_restriction.restriction,
							vm.contact_restriction.restriction,
							vm.member_restriction.restriction,
						],
					},
				};

				dispatchQueryPropertyValue(
					'accountRestriction',
					vm.account_restriction
				);
				dispatchQueryPropertyValue(
					'contactRestriction',
					vm.contact_restriction
				);
				dispatchQueryPropertyValue('memberRestriction', vm.member_restriction);
			};

			vm.getSegmentInputTree = function () {
				const accountLen =
					vm.account_restriction.restriction.logicalRestriction.restrictions
						.length;
				const contactLen =
					vm.contact_restriction.restriction.logicalRestriction.restrictions
						.length;
				const segmentInputTree = [];
				if (vm.showSegmentationV2) {
					segmentInputTree.push(vm.segmentInputTree);
				} else {
					if (accountLen && contactLen) {
						segmentInputTree.push(vm.segmentInputTree);
					} else if (contactLen) {
						segmentInputTree.push(vm.contact_restriction.restriction);
					} else {
						segmentInputTree.push(vm.account_restriction.restriction);
					}
				}
				return segmentInputTree;
			};

			vm.getRulesInputTree = function () {
				var accountAttrSelected = vm.checkAttributesSelected('account');
				var contactAttrSelected = vm.checkAttributesSelected('contact');

				if (vm.accountRulesTree[0] && vm.contactRulesTree[0]) {
					if (
						vm.accountRulesTree[0].logicalRestriction.restrictions.length !=
							0 &&
						vm.contactRulesTree[0].logicalRestriction.restrictions.length !=
							0 &&
						accountAttrSelected &&
						contactAttrSelected
					) {
						return [vm.rulesInputTree];
					} else if (
						(vm.accountRulesTree[0].logicalRestriction.restrictions.length &&
							vm.contactRulesTree[0].logicalRestriction.restrictions.length ==
								0) ||
						(accountAttrSelected && !contactAttrSelected)
					) {
						return vm.accountRulesTree;
					} else if (
						(vm.contactRulesTree[0].logicalRestriction.restrictions.length &&
							vm.accountRulesTree[0].logicalRestriction.restrictions.length ==
								0) ||
						(contactAttrSelected && !accountAttrSelected)
					) {
						return vm.contactRulesTree;
					}
				}
			};

			vm.checkAttributesSelected = function (entity) {
				const bucket = vm.buckets[vm.bucketsMap[vm.bucket]];
				const counts = vm.getRuleCount(bucket, true);

				return counts[entity] > 0;
			};

			vm.resetRulesInputTree = function () {
				vm.rulesInputTree = {
					collapsed: false,
					logicalRestriction: {
						operator: 'AND',
						restrictions: [vm.accountRulesTree[0], vm.contactRulesTree[0]],
					},
				};
				// this for refresh bucket label
				setPublicProperty('resetLabelIncrementor', true);
				vm.setTotalRuleCount();
				vm.setBucketLabel(vm.rulesInputTree);
			};

			vm.saveRules = function () {
				saveRatingEngineRules.bind($state)();

				vm.saved = true;
			};

			vm.generateRulesTree = function (entity) {
				if (vm.bucket) {
					const bucket = vm.rating_rule.bucketToRuleMap[vm.bucket];
					const rEntity = entity ? entity.toLowerCase() : vm.treeMode;
					return bucket[rEntity + '_restriction'];
				}
				return undefined;
			};

			/**
			 * TODO: A map with association Entity -> Bucket should be created
			 * Look at vm.updateBucketCount where we can use this map as well
			 * @param {*} item
			 * @param {*} entity
			 */
			vm.isMatching = function (itemEntity, entity) {
				if (itemEntity == entity) {
					return true;
				} else if (
					(itemEntity === 'PurchaseHistory' && entity === 'Account') ||
					(itemEntity === 'Rating' && entity === 'Account')
				) {
					return true;
				} else {
					return false;
				}
			};

			vm.pushItem = function (item, tree) {
				if (item) {
					var attributeEntity = item.Entity,
						cube = vm.cube[attributeEntity]?.Stats[item.ColumnId];

					item.cube = cube;
					item.topbkt = tree.bkt;

					vm.items.push(item);
				}
			};

			vm.setCurrentSavedTree = function () {
				dispatchQueryPropertyValue(
					'currentSavedTree',
					vm.isRules
						? angular.copy([vm.segmentInputTree])
						: angular.copy([vm.rulesInputTree])
				);
			};

			vm.setTotalRuleCount = function () {
				const segmentHistoryTree = vm.showSegmentationV2
					? vm.segmentHistoryTree
					: vm.segmentInputTree;
				const current = vm.isRules
					? angular.copy([segmentHistoryTree])
					: angular.copy([vm.rulesInputTree]);
				const allBuckets = getRuleAllBuckets(current);

				vm.totalRules = allBuckets.length || 0;

				return vm.totalRules;
			};

			vm.getBucketLabel = function (bucket, forceUpdate) {
				//console.log('getBucketLabel', vm.labelIncrementor, vm.totalRules, bucket.labelGlyph, bucket);
				if (
					getQueryProperty('public')['resetLabelIncrementor'] ||
					vm.labelIncrementor == vm.totalRules
				) {
					vm.labelIncrementor = 0;
					setPublicProperty('resetLabelIncrementor', false);
				}

				if (bucket && bucket.labelGlyph && !forceUpdate) {
					return bucket.labelGlyph;
				} else {
					vm.labelIncrementor += 1;
					if (vm.labelIncrementor === 0) {
						vm.labelIncrementor += 1;
					}
					bucket.labelGlyph = vm.labelIncrementor;
					return vm.labelIncrementor;
				}
			};

			vm.setBucketLabel = function (bucket) {
				if (bucket.activityRestriction) {
					bucket.activityRestriction.restriction.logicalRestriction.restrictions.forEach(
						(bucket) => {
							vm.setBucketLabel(bucket);
						}
					);
				} else if (
					bucket.bucketRestriction ||
					bucket.segmentMemberRestriction
				) {
					vm.getBucketLabel(bucket, true);
				} else if (bucket.logicalRestriction.restrictions) {
					bucket.logicalRestriction.restrictions.forEach((bucket) => {
						vm.setBucketLabel(bucket);
					});
				}
			};

			vm.saveState = function (noCount) {
				vm.labelIncrementor = 0;

				if (vm.showSegmentationV2) {
					vm.setSegmentInputTree();
					// this for refresh bucket label
					vm.setBucketLabel(
						vm.isRules ? vm.segmentInputTree : vm.rulesInputTree
					);
				}
				const segmentHistoryTree = vm.showSegmentationV2
					? vm.segmentHistoryTree
					: vm.segmentInputTree;

				const current = vm.isRules
					? angular.copy([segmentHistoryTree])
					: angular.copy([vm.rulesInputTree]);
				if (vm.history.length === 0) {
					vm.history.push(vm.segmentInputTreeInit);
				}
				const old = angular.copy(vm.history[vm.history.length - 1]) || [];

				if (!vm.compareTree(old, current)) {
					vm.history.push(current);
				}
				if (!noCount) {
					vm.updateCount();
				}
			};

			vm.changeDefaultBucket = function (bucket) {
				vm.updateCount();
			};

			vm.clickBucketTile = function (bucket) {
				vm.labelIncrementor = 0;
				vm.bucket = bucket.bucket;

				vm.setSelectedBucket(vm.bucket);
				vm.setRulesTree();
				vm.resetRulesInputTree();
			};

			vm.setSelectedBucket = function (bucket) {
				dispatchQueryPropertyValue('selectedBucket', bucket);
			};

			vm.getRuleCount = function (bkt, entity) {
				return getRuleCount(
					bkt,
					vm.rating_rule.bucketToRuleMap,
					vm.bucketLabels,
					entity
				);
			};

			vm.getRestrictionEntity = function (entity) {
				const isContact =
					entity === 'Contact' ||
					entity === 'ContactMarketingActivity' ||
					entity === 'CuratedContact';

				return isContact ? 'contact' : 'account';
			};

			vm.getRatingsAndRecordCounts = function (model, segmentName) {
				const rulesForCounts = vm.getRuleRecordCounts();

				getCoverageMap(model, segmentName, rulesForCounts)
					.then(function (result) {
						CoverageMap = vm.initCoverageMap(result);

						const buckets = result.segmentIdAndSingleRulesCoverageMap;
						if (buckets) {
							Object.keys(buckets).forEach(function (key) {
								if (vm.RuleRecordMap[key]) {
									if (vm.RuleRecordMap[key].bucketRestriction) {
										const label = vm.RuleRecordMap[key].bucketRestriction.attr,
											type = vm.getRestrictionEntity(label.split('.')[0]);

										vm.RuleRecordMap[key].bucketRestriction.bkt.Cnt =
											buckets[key][type + 'Count'];
									}
									if (vm.RuleRecordMap[key].activityRestriction) {
										vm.RuleRecordMap[key].activityRestriction.count =
											buckets[key]['accountCount'] +
											buckets[key]['contactCount'];
									}
								}
							});
						}
					})
					.finally(() => {
						$rootScope.$apply();
					});
			};

			vm.clickUndo = function () {
				let lastState;
				while ((lastState = vm.history.pop())) {
					if (vm.setState(lastState)) {
						vm.updateCount();

						refreshCounts();
						break;
					}
				}
				vm.setTotalRuleCount();
			};

			vm.setState = function (newState) {
				const segmentHistoryTree = vm.showSegmentationV2
					? vm.segmentHistoryTree
					: vm.segmentInputTree;
				const current = vm.isRules
					? angular.copy([segmentHistoryTree])
					: angular.copy([vm.rulesInputTree]);
				if (!vm.compareTree(newState, current)) {
					vm.labelIncrementor = 0;
					const restrictions = angular.copy(
						newState[0].logicalRestriction.restrictions
					);

					if (vm.showSegmentationV2) {
						setPublicProperty('enableSaveSegmentButton', true);

						setRestrictions({
							type: 'account',
							restriction: {restriction: restrictions[0]},
						});

						setRestrictions({
							type: 'contact',
							restriction: {restriction: restrictions[1]},
						});

						vm.account_restriction = {
							restriction: restrictions[0],
						};

						vm.contact_restriction = {
							restriction: restrictions[1],
						};

						setRestrictions({
							type: 'member',
							restriction: {restriction: restrictions[2]},
						});

						vm.member_restriction.restriction =
							getQueryProperty('memberRestriction').restriction;

						vm.setSegmentInputTree();
					} else {
						setRestrictions({
							type: 'account',
							restriction: {restriction: restrictions[0]},
						});

						setRestrictions({
							type: 'contact',
							restriction: {restriction: restrictions[1]},
						});

						vm.account_restriction = {
							restriction: restrictions[0],
						};

						vm.contact_restriction = {
							restriction: restrictions[1],
						};

						vm.segmentInputTree.logicalRestriction.restrictions[0] =
							restrictions[0];
						vm.segmentInputTree.logicalRestriction.restrictions[1] =
							restrictions[1];
					}

					return true;
				}

				return false;
			};

			vm.updateCount = function () {
				setPublicProperty('enableSaveSegmentButton', true);

				if (isRulesBasedModelMode()) {
					dispatchSetCountLoading([vm.treeMode + 's'], true);

					var RatingEngineCopy = angular.copy(RatingEngineModel),
						BucketMap = RatingEngineCopy.rule.ratingRule.bucketToRuleMap;

					vm.bucketLabels.forEach(function (bucketName, index) {
						vm.buckets[vm.bucketsMap[bucketName]].count = -1;

						const [accountRestriction, contactRestriction] = removeEmptyBuckets(
							[
								BucketMap[bucketName]['account_restriction'],
								BucketMap[bucketName]['contact_restriction'],
							]
						);

						const [sanitizeAccountRestriction, sanitizeContactRestriction] =
							sanitizeSegmentRestriction([
								accountRestriction,
								contactRestriction,
							]);

						BucketMap[bucketName]['account_restriction'] =
							sanitizeAccountRestriction;
						BucketMap[bucketName]['contact_restriction'] =
							sanitizeContactRestriction;

						if (vm.showSegmentationV2) {
							const [memberRestriction] = removeEmptyBuckets([
								BucketMap[bucketName]['member_restriction'],
							]);

							const [sanitizeMemberRestriction] = sanitizeSegmentRestriction([
								memberRestriction,
							]);

							BucketMap[bucketName]['member_restriction'] =
								sanitizeMemberRestriction;
						}
					});

					$timeout(function () {
						vm.getRatingsAndRecordCounts(
							RatingEngineCopy,
							CurrentRatingEngine.segment.name
						);
					}, 250);
				} else {
					if (
						$state.current.name.includes(
							'home.ratingsengine.createAccountFitModeling.successCriteria'
						)
					) {
						// Dark magic to be able to rerender the AccountFitModelingWizard with a loading spinner
						window.postMessage({isParsingQuery: true});
					}

					setEntitiesProperty('loading', true);
					$timeout(function () {
						var segment = {
							free_form_text_search: '',
							page_filter: {
								num_rows: 10,
								row_offset: 0,
							},
						};
						vm.labelIncrementor = 0;

						segment['account_restriction'] = angular.copy(
							getQueryProperty('accountRestriction')
						);

						segment['contact_restriction'] = angular.copy(
							getQueryProperty('contactRestriction')
						);

						if (vm.showSegmentationV2) {
							segment['member_restriction'] = angular.copy(
								getQueryProperty('memberRestriction')
							);
						}

						if (
							$state.current.name.includes(
								'home.ratingsengine.createAccountFitModeling.successCriteria'
							)
						) {
							// Dark magic to be able to persist the modelLabelQuery in the react component AccountFitModelingWizard
							window.postMessage({query: sanitizeSegment(segment)});
						} else {
							getEntitiesCounts(sanitizeSegment(segment))
								.then(function (result) {
									setResourceTypeCount('accounts', false, result['Account']);
									setResourceTypeCount('contacts', false, result['Contact']);
								})
								.finally(() => {
									$rootScope.$apply();
								});
						}
					}, 250);
				}
			};

			vm.getRuleRecordCounts = function () {
				const restrictions = vm.getAllBucketRestrictions(),
					segmentId = CurrentRatingEngine.segment.name;

				vm.RuleRecordMap = {};

				restrictions.forEach(function (bucket, index) {
					if (bucket.bucketRestriction) {
						bucket.bucketRestriction.bkt.Cnt = -1;

						vm.RuleRecordMap[bucket.bucketRestriction.attr + '_' + index] =
							bucket;
					} else if (bucket.activityRestriction) {
						bucket.activityRestriction.count = -1;

						vm.RuleRecordMap['activityRestriction_' + index] = bucket;
					}
				});

				return getBucketRuleCounts(angular.copy(restrictions), segmentId);
			};

			vm.getAllBucketRestrictions = function () {
				var RatingEngineCopy = RatingEngineModel,
					BucketMap = RatingEngineCopy.rule.ratingRule.bucketToRuleMap,
					restrictions = [];

				vm.bucketLabels.forEach(function (bucketName, index) {
					var accountRestriction = BucketMap[bucketName]['account_restriction'];
					var accountLogical = {
						logicalRestriction: {operator: 'AND', restrictions: []},
					};
					if (accountRestriction && accountRestriction != null) {
						accountLogical =
							BucketMap[bucketName]['account_restriction'].logicalRestriction;
					} else {
						accountLogical = {operator: 'AND', restrictions: []};
						BucketMap[bucketName]['account_restriction'] = {
							logicalRestriction: accountLogical,
						};
					}

					var contactRestriction = BucketMap[bucketName]['contact_restriction'];
					var contactLogical = {
						logicalRestriction: {operator: 'AND', restrictions: []},
					};
					if (contactRestriction && contactRestriction != null) {
						contactLogical =
							BucketMap[bucketName]['contact_restriction'].logicalRestriction;
					} else {
						contactLogical = {operator: 'AND', restrictions: []};
						BucketMap[bucketName]['contact_restriction'] = {
							logicalRestriction: contactLogical,
						};
					}

					restrictions = getAllActivityBuckets(
						accountLogical.restrictions,
						restrictions
					);
					restrictions = getAllActivityBuckets(
						contactLogical.restrictions,
						restrictions
					);
				});

				return restrictions;
			};

			vm.saveSegment = function () {
				var segment = getQueryProperty('segment'),
					restriction = getQueryProperty('accountRestriction');

				vm.labelIncrementor = 0;
				vm.saving = true;

				createOrUpdateSegment(segment, restriction).then(function () {
					vm.labelIncrementor = 0;
					vm.saving = false;
					vm.updateCount();
					vm.setCurrentSavedTree();
				});
			};

			vm.compareTree = function (old, current) {
				// remove AQB properties like labelGlyph/collapse
				const oldTree = sanitizeSegmentRestriction(angular.copy(old), true);
				const currentTree = sanitizeSegmentRestriction(
					angular.copy(current),
					true
				);

				return JSON.stringify(oldTree) === JSON.stringify(currentTree);
			};

			vm.checkDisableSave = function () {
				// FIXME: this stuff is disabled for now
				if (!getQueryProperty('currentSavedTree') || !vm.tree) {
					return true;
				}

				var old = angular.copy(getQueryProperty('currentSavedTree')),
					current = angular.copy(vm.tree);

				return vm.compareTree(old, current);
			};

			vm.goAttributes = () => {
				// FIXME: Either `goAttributes` or `nextState` should be passed in from
				// the parent. The query builder shouldn't change its functionality
				// based on the UIRouter state
				// TODO: The entire state path may not be necessary if a consistent
				// relative path can be provided.
				let nextState;
				if (getQueryProperty('mode') === Modes.RulesBasedModelDetails) {
					nextState =
						'home.ratingsengine.rulesprospects.segment.attributes.add';
				} else if (
					getQueryProperty('mode') === Modes.RulesBasedModelDashboard
				) {
					nextState = 'home.ratingsengine.dashboard.segment.attributes.add';
				} else if (
					$state.current.name ===
					'home.ratingsengine.createAccountFitModeling.createSegment'
				) {
					nextState =
						'home.ratingsengine.createAccountFitModeling.createSegment.attributes';
				} else if (
					$state.current.name ===
					'home.ratingsengine.createAccountFitModeling.successCriteria'
				) {
					nextState =
						'home.ratingsengine.createAccountFitModeling.successCriteria.attributes';
				} else if (
					$state.current.name ===
					'home.ratingsengine.createAccountFitModeling.scoringSegment'
				) {
					nextState =
						'home.ratingsengine.createAccountFitModeling.scoringSegment.attributes';
				} else {
					nextState = vm.inModel
						? 'home.model.analysis.explorer.attributes'
						: 'home.segment.explorer.attributes';
				}

				$state.go(nextState, {
					segment: $stateParams.segment,
				});
			};

			vm.mouseUp = function (event) {
				var dragged = vm.draggedItem,
					dropped = vm.droppedItem;

				if (
					dragged &&
					(!dropped || (dropped && dropped.uniqueId !== dragged.uniqueId))
				) {
					vm.droppedItem = vm;

					if (dropped) {
						this.saveState();
						vm.dropMoveItem(dragged, dropped);
					}
				}

				$timeout.cancel(vm.mouseDownTimer);
				vm.mouseDownTimer = false;

				vm.draggedItem = null;
				vm.droppedItem = null;

				if (vm.draggedClone) {
					vm.draggedClone.remove();
				}

				delete vm.droppedItemAppend;
				delete vm.draggedClone;
			};

			vm.dropMoveItem = function (dragged, dropped, endMove) {
				var items = dropped.parent
					? dropped.parent.logicalRestriction.restrictions
					: dropped.tree.logicalRestriction.restrictions;

				if (
					dropped.tree.logicalRestriction ||
					dropped.parent.logicalRestriction
				) {
					var draggedParent = dragged.parent.logicalRestriction.restrictions,
						droppedParent = dropped.parent
							? dropped.parent.logicalRestriction.restrictions
							: [],
						draggedIndex = draggedParent.indexOf(dragged.tree),
						droppedIndex = droppedParent.indexOf(dropped.tree),
						draggedItem = angular.copy(dragged.tree);

					if (dropped.tree.logicalRestriction) {
						var restrictions = dropped.tree.logicalRestriction.restrictions;

						if (vm.droppedItemAppend) {
							restrictions.push(draggedItem);
						} else {
							restrictions.splice(0, 0, draggedItem);
						}
					} else {
						var inc = vm.droppedItemAppend ? 1 : 0;

						droppedParent.splice(droppedIndex + inc, 0, draggedItem);
					}

					draggedParent.splice(draggedParent.indexOf(dragged.tree), 1);
				}
			};

			vm.clickTreeMode = function (value) {
				vm.treeMode = value;

				vm.restriction = getQueryProperty(value + 'Restriction');
				vm.tree = vm.getTree();

				vm.setCurrentSavedTree();
			};

			vm.categoryClass = function (category) {
				//console.log('[advanced]', category);
				var category =
					'category-' +
					category.toLowerCase().replace(/\s/g, '-').replace('&', 'and');
				return category;
			};

			vm.initKnownContactAttribute = function (tree) {
				let setDefaultKnownContactAttribute = true;
				let subType = 'Known';
				if (tree.logicalRestriction.restrictions.length > 0) {
					setDefaultKnownContactAttribute = false;
					const restrictions = tree.logicalRestriction.restrictions.filter(
						(item) => {
							if (!item || !item.bucketRestriction) {
								return false;
							}
							const [Entity, ColumnId] = item.bucketRestriction.attr.split('.');
							return Entity === 'CuratedContact' && ColumnId === 'KnownContact';
						}
					);
					if (restrictions.length === 1) {
						const {Cmp, Vals} = restrictions[0].bucketRestriction.bkt;
						if (Cmp === 'EQUAL') {
							if (Vals?.[0] === 'Yes') {
								subType = 'Known';
							} else if (Vals?.[0] === 'No') {
								subType = 'Unknown';
							}
						}
					} else {
						subType = 'All';
					}
				}
				if (setDefaultKnownContactAttribute) {
					const bucketRestriction = {
						attr: 'CuratedContact.KnownContact',
						bkt: {
							Lbl: '',
							Cmp: 'EQUAL',
							Vals: ['Yes'],
						},
						ignored: false,
					};
					tree.logicalRestriction.restrictions.push({bucketRestriction});
					vm.updateCount();
				}
				return subType;
			};

			vm.updateRestriction = function (restrictions, subType) {
				let completed = false;

				const index = restrictions.findIndex((item) => {
					const [Entity, ColumnId] = item?.bucketRestriction?.attr.split('.');
					return Entity === 'CuratedContact' && ColumnId === 'KnownContact';
				});

				if (index > -1) {
					completed = true;
					const item = restrictions[index];
					if (subType === 'Known') {
						item.bucketRestriction.bkt.Vals = ['Yes'];
					} else if (subType === 'Unknown') {
						item.bucketRestriction.bkt.Vals = ['No'];
					} else {
						restrictions.splice(index, 1);
					}
				}
				if (!completed && subType !== 'All') {
					const bucketRestriction = {
						attr: 'CuratedContact.KnownContact',
						bkt: {
							Lbl: '',
							Cmp: 'EQUAL',
							Vals: [subType === 'Known' ? 'Yes' : 'No'],
						},
						ignored: false,
					};
					restrictions.push({bucketRestriction: bucketRestriction});
				}
			};

			vm.hideRestrictions = function (
				item,
				enable_calculate_known_contact,
				entity
			) {
				if (enable_calculate_known_contact && entity === 'Contact' && item) {
					if (item.bucketRestriction) {
						const [Entity, ColumnId] = item.bucketRestriction.attr.split('.');
						if (Entity === 'CuratedContact' && ColumnId === 'KnownContact') {
							return true;
						}
					}
				}
				return false;
			};

			vm.getTreeClass = function (operator) {
				return operator ? 'query-section-type-' + operator.toLowerCase() : '';
			};

			vm.getCategoryThemeColor = (category, item) =>
				getCategoryThemeColor(category, item);

			vm.listItemClickCollapsed = (target) => {
				target.parent.collapsed = false;
				const treeRoot = target.root.restriction.restriction;
				treeRoot.collapsed = false;
				treeRoot.logicalRestriction.restrictions.forEach((restriction) => {
					if (restriction.logicalRestriction) {
						restriction.collapsed = false;
					}
					if (restriction.activityRestriction) {
						restriction.activityRestriction.restriction.collapsed = false;
						const activityRestriction =
							restriction.activityRestriction.restriction.logicalRestriction;
						if (activityRestriction) {
							activityRestriction.restrictions.forEach((restriction) => {
								if (restriction.logicalRestriction) {
									restriction.collapsed = false;
								}
							});
						}
					}
				});
				const id = vm.getBucketLabel(target.tree);
				const waiting = !document.getElementById('bucket-' + id);
				const scrollIntoView = (id) => {
					const algorithm =
						document.getElementsByClassName('query-algorithm')?.[0];
					if (algorithm) {
						let stickyHeight = algorithm?.offsetHeight + StickyHeight;
						if (vm.isBuyerJourneyEnabled) {
							stickyHeight += QueryButtonGroupHeigh;
						}
						const bucketElement = document.getElementById('bucket-' + id);
						bucketElement.setAttribute(
							'style',
							`padding-top: ${stickyHeight}px;margin-top: -${stickyHeight}px;`
						);
						bucketElement.scrollIntoView();
					}
				};
				if (vm.scroll_timer) {
					clearInterval(vm.scroll_timer);
				}
				if (waiting) {
					vm.scroll_timer = setInterval(function () {
						const elementExist = !document.getElementById('bucket-' + id);
						if (!elementExist) {
							scrollIntoView(id);
							clearInterval(vm.scroll_timer);
						}
					}, 200);
				} else {
					scrollIntoView(id);
				}
			};

			vm.listItemClickOperator = (logicalRestriction, operator) => {
				logicalRestriction.operator = operator;
				vm.saveState();
			};

			vm.clickDelete = (trees) => {
				if (!trees && trees.length > 0) {
					return;
				}
				trees[0].logicalRestriction.restrictions = [];
				vm.saveState();
				vm.setTotalRuleCount();
			};

			vm.init();
		}
	)
	.component(
		'advancedQuery',
		react2angular(
			AdvancedQueryComponent,
			[],
			[
				'$state',
				'$stateParams',
				'$rootScope',
			]
		)
	);
