import React from 'common/react-vendor';
import {DNBCard} from 'common/dnb-uux-vendor';
import {useSelector} from 'common/redux-vendor';
import {cloneDeep, isEqual} from 'lodash';
import {QueryState} from 'common/stores/query/types';
import classNames from 'classnames';
import {useAdvancedQuery} from './hook/useAdvancedQuery';
import {
	clickDelete,
	doesSegmentOrBlockShow,
	getAccountTree,
	getContactTree,
	GetRulesInputTree,
	getSegmentIntersectionTree,
	getSegmentUnionTree,
	getTrees,
	mouseUp,
} from './AdvancedQuery.helper';
import {QueryBlock} from './queryBlock/queryBlock';
import {IQueryBlockType, QueryBlockConfig} from './queryBlock/queryBlockTypes';
import {QueryTreeComponent} from './tree/QueryTreeComponent';
import {updatedRestriction} from './tree/QueryTree.helpers';

const QueryBuilderSection = (): React.ReactElement => {
	const [refreshCount, setRefreshCount] = React.useState(0);
	const refresh = useSelector(
		(state: {query: QueryState}) => state?.query?.public.refresh
	);
	const {context: advancedQuery, setContext} = useAdvancedQuery();
	const {segmentObj, items} = advancedQuery;
	const treeScope = {
		...advancedQuery,
		clickDeleteAll: (treeType: string, type: IQueryBlockType) =>
			clickDelete(advancedQuery, setContext, treeType, type),
		getRulesInputTree: () => GetRulesInputTree(advancedQuery),
		getAccountTree,
		getContactTree,
		getSegmentUnionTree,
		getSegmentIntersectionTree,
	};
	let countKey = 0;
	const treesRef = React.useRef({
		Account: getTrees(IQueryBlockType.Account, advancedQuery),
		Contact: getTrees(IQueryBlockType.Contact, advancedQuery),
		Union: getTrees(IQueryBlockType.Union, advancedQuery),
		Intersection: getTrees(IQueryBlockType.Intersection, advancedQuery),
	});
	const QueryBuilderConfig = Object.entries(QueryBlockConfig).map(
		([type, config]) => {
			return {
				type,
				scope: treeScope,
				beforeOperator: config.beforeOperator,
				visible: ['Account', 'Contact'].includes(type)
					? config.visible
					: doesSegmentOrBlockShow(advancedQuery),
			};
		}
	);
	const oldTreesRef = cloneDeep(treesRef);
	const refreshTree = (): void => {
		setRefreshCount(refreshCount + 1);
	};
	React.useEffect(() => {
		if (refresh > -1) {
			Object.values(IQueryBlockType).forEach((type) => {
				const tree = getTrees(type, advancedQuery);
				// clear action need updated tree
				if (
					!isEqual(oldTreesRef.current[type], tree) &&
					(!isEqual(tree, treesRef.current[type]) ||
						tree?.[0]?.logicalRestriction?.restrictions?.length === 0)
				) {
					treesRef.current[type] = tree;
					// setForceUpdate(type);
					setRefreshCount(refreshCount + 1);
				}
			});
		}
	}, [advancedQuery, refresh, oldTreesRef, refreshCount]);
	return (
		<>
			{QueryBuilderConfig.map((config) => {
				const trees = treesRef.current[config?.type as IQueryBlockType];
				return (
					<React.Fragment key={config.type}>
						{config.visible && config.beforeOperator && (
							<div className='queryHelperTextOne'>
								<span>
									<strong>{config.beforeOperator}</strong>
								</span>
							</div>
						)}
						{config.visible && (
							<DNBCard
								className='querySectionBlock'
								hasBorder
								variant='white'
								sx={{overflow: 'unset'}}>
								<QueryBlock
									scope={config.scope}
									type={config.type as IQueryBlockType}
								/>
								<ul
									className='querySection queryTreeRoot'
									onMouseLeave={() =>
										mouseUp(advancedQuery, setContext, () => refreshTree())
									}>
									{trees.map((tree) => {
										countKey++;
										const updateTreeStore = (
											entity: string,
											forceUpdatedStore = true
										): void => {
											if (forceUpdatedStore) {
												updatedRestriction(tree, undefined, entity);
											}
											refreshTree();
										};
										return (
											<li
												key={`${config.type}-${countKey}`}
												className={classNames({
													querySectionItemBoxSubtree:
														tree.logicalRestriction?.operator,
												})}
												onMouseUp={() =>
													mouseUp(advancedQuery, setContext, () =>
														refreshTree()
													)
												}>
												<QueryTreeComponent
													key={`${config.type}-${countKey}`}
													items={items}
													root={advancedQuery}
													tree={tree}
													entity={config.type}
													segment={segmentObj}
													updateTree={updateTreeStore}
													memberType={config.type}
												/>
											</li>
										);
									})}
								</ul>
							</DNBCard>
						)}
					</React.Fragment>
				);
			})}
		</>
	);
};
export {QueryBuilderSection};
