// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-param-reassign */
/**
 * Disabling no param reassign since the old
 * methods were modifying the objects directly,
 * so leaving it like that to prevent introducing bugs
 */
import isEmpty from 'lodash/isEmpty';
import {store} from 'store';
// TODO: remove 'deleteAngularHashKey' when migration to React is complete
import {
	sanitizeRuleBuckets,
	deleteAngularHashKey,
	sanitizeSegmentRestriction,
} from 'common/components/datacloud/segment/segment.helpers';
import type {
	BucketToRuleMap,
	RatingModel,
} from 'common/components/datacloud/datacloud.types';
import type {Restriction} from 'common/components/datacloud/query/query.types';
import {Operator} from 'common/components/datacloud/query/query.enums';
import {decryptJSONData} from 'common/app/utilities/EncryptionUtility';
import type {Attribute} from 'common/components/datacloud/query/advanced/tree/types';
import {isSegmentationV2Enabled} from 'common/stores/tenantConfig';
import NgState from 'atlas/ng-state';
import type {
	RatingsEngineRule,
	TrainingSegment,
	CurrentRating,
	TrainingCounts,
	ModelTrainingOptions,
	ConfigFilters,
	Current,
	RatingsEngineValidation,
} from 'atlas/stores/ratingsEngine/types';
import type {RatingEnginesCoverageSegments} from 'atlas/playbook/content/metadataLiveramplaunch/types';
import {
	getRatingsEngineProperty,
	dispatchRatingsEnginePropertyValue,
	dispatchTrainingCountsPropertyValue,
	dispatchCurrentPropertyValue,
	dispatchValidationPropertyValue,
} from 'atlas/stores/ratingsEngine';
import {getJobsProperty, setJobsPropertyValue} from 'atlas/stores/jobs';
import type {JobStatusRecord} from 'atlas/stores/jobs';
import type {FieldDocument} from 'atlas/stores/import';
import {updatePublicRefresh} from 'common/stores/query';
import {
	saveRules,
	saveRating as saveRatingQuery,
	getRating as getRatingQuery,
	getProductCoverage as getProductCoverageQuery,
	getRatingsChartData,
	saveIteration as saveIterationQuery,
	launchModeling,
	validateModel,
	createAIModel,
	getTrainingCounts as getTrainingCountsQuery,
	updateRatingModel,
	getRatings as getRatingsQuery,
	deleteRating as deleteRatingQuery,
} from './ratingsengine.queries';
import type {
	RatingEngine,
	SaveRatingOpts,
	SaveRatingResponse,
	Product,
	CoverageResponse,
	CoverageRequest,
	RatingsBuckets,
	AIRatingRecord,
	UpdateRatingModelOpts,
} from './ratingsengine.types';
import type {ISegment} from '../data/SegmentConst';
import {saveFieldMappings} from '../import/import.helpers';
import type {Field} from '../import/ImportUtils.types';
import {
	nextLabelRules,
	wizardProgressItems,
} from './ratingsEngineWizard.constants';
import type {WizardItems} from './ratingsEngineWizard.types';
import type {
	IIteration,
	RatingsDashboard,
} from '../navigation/header/components/page-navigation.utils';

/**
 * I think they might be a circular dependency
 * or something within the UserValues utility.
 *
 * When I try to import that, I am not able to
 * get a working build.
 */
export const getUserEmail = (): string | undefined =>
	decryptJSONData(
		JSON.parse(`${window.localStorage.getItem('GriotClientSession')}`)
	)?.EmailAddress;

// #region saveRatingEngineRules
type StringRecord = Record<string, string>;

export interface StateService<T extends StringRecord> {
	go(name: string, params: StringRecord): void;
	params: T;
}

export type RatingsPageParams = {rating_id: string};

/**
 *
 * Inside the common project we don't have NgState
 * defined. That is why we need to bind it on that
 * project. In case we use this function inside atlas
 * we can freely skip the binding since NgState its
 * already defined :).
 *
 * @param this expected to be the bind of angular $state.
 * @param nextState name of the angular route
 */
export function saveRatingEngineRules(
	this: StateService<RatingsPageParams> | undefined,
	nextState?: string
): void {
	const current = getRatingsEngineProperty<RatingsEngineRule>('rule');

	if (current) {
		try {
			const {go, params} = this || NgState.getAngularState() || {};

			const {rating_id} = params;

			const opts = {
				rating_id,
				model_id: current?.rule?.id,
				model: {
					rule: deleteAngularHashKey(
						sanitizeRuleBuckets({...current?.rule}, true)
					),
				},
			};

			saveRules(opts)
				.then(() => {
					if (nextState) {
						go(nextState, {rating_id});
					}
				})
				.catch((error) => console.error(error));
		} catch (cause) {
			throw new Error("We couldn't find the ng state", {
				cause,
			});
		}
	}
}
// #endregion saveRatingEngineRules

export const saveRating = async (
	rating: Partial<RatingEngine>
): Promise<SaveRatingResponse | string> => {
	const {params: stateParams} = NgState.getAngularState() ?? {};

	const opts: SaveRatingOpts = {
		createdBy: rating.createdBy ?? getUserEmail()!,
		type: rating.type ?? 'RULE_BASED',
		segment:
			rating.segment ?? getRatingsEngineProperty<ISegment>('savedSegment')!,
		displayName: stateParams?.opts?.displayName ?? rating.displayName,
		status: stateParams?.opts?.status ?? rating.status,
		id: rating.id,
		note: stateParams?.opts?.note ?? rating.note,
		teamId: rating.teamId,
	};

	let params = {};

	if (rating?.type === 'CROSS_SELL') {
		opts.advancedRatingConfig = {
			cross_sell: {
				modelingStrategy:
					rating?.advancedRatingConfig?.cross_sell?.modelingStrategy,
			},
		};
	} else if (
		rating?.type === 'CUSTOM_EVENT' &&
		stateParams.rating_id &&
		getRatingsEngineProperty('customEventModelingType') === 'LPI'
	) {
		params = {
			'unlink-segment': true,
		};
	}

	return saveRatingQuery(opts, params).then((data) => data);
};

export const nextSaveRatingEngine = (nextState: string): void => {
	const {go} = NgState.getAngularState() ?? {};

	const currentRating = getRatingsEngineProperty<RatingEngine>('currentRating');
	const currentSegment = getRatingsEngineProperty<ISegment | null>(
		'savedSegment'
	);

	if (
		currentRating?.segment !== null &&
		currentSegment !== null &&
		currentRating?.segment?.name !== currentSegment?.name
	) {
		dispatchRatingsEnginePropertyValue('currentRating', {});
	}

	if (currentRating) {
		saveRating(currentRating)
			.then((rating) => {
				if (typeof rating !== 'string') {
					go(nextState, {rating_id: rating.id});
				}
			})
			.catch((error) => console.error(error));
	}
};

export const getRating = async (
	id?: string,
	refetch = false
): Promise<RatingEngine | string> => {
	if (!id) {
		return '';
	}
	const currentRating = getRatingsEngineProperty<RatingEngine>('currentRating');

	if (!isEmpty(currentRating) && currentRating.id === id && !refetch) {
		return currentRating;
	}

	return getRatingQuery(id).then((engine) => {
		dispatchRatingsEnginePropertyValue('currentRating', engine);

		return engine;
	});
};

export const formatTrainingAttributes = (type: string): string => {
	switch (type) {
		case 'DataCloud':
			return 'D&B Data Cloud';
		case 'CDL':
			return 'CDP';
		case 'CustomFileAttributes':
			return 'Training File';
		default:
			return '';
	}
};

export const getProductCoverage = async (
	ratingEngine: RatingEngine,
	productsList: Product[],
	purchasedBeforePeriod: number
): Promise<RatingEnginesCoverageSegments | string> => {
	const productIds = productsList.map(({ProductId}) => ProductId);

	return getProductCoverageQuery(
		ratingEngine,
		productIds,
		purchasedBeforePeriod
	).then((result) => result);
};

export const getCoverageMap = async (
	CurrentRatingsEngine: RatingModel,
	segmentId: string,
	CoverageMap: CoverageRequest
): Promise<CoverageResponse | string> => {
	const rule = deleteAngularHashKey(
		sanitizeRuleBuckets({...CurrentRatingsEngine.rule!})
	);

	const coverageMap = CoverageMap ?? {};

	coverageMap.restrictNotNullSalesforceId = false;
	coverageMap.segmentIdModelRules = [
		{
			segmentId,
			ratingRule: {
				bucketToRuleMap: rule.ratingRule.bucketToRuleMap,
				defaultBucketName: rule.ratingRule.defaultBucketName,
			},
		},
	];
	updatePublicRefresh();
	return getRatingsChartData(coverageMap).then((response) => response);
};

export const generateRatingsBuckets = (): RatingsBuckets => {
	const restriction: Restriction = {
		logicalRestriction: {
			operator: Operator.AND,
			restrictions: [],
		},
	};

	const template = {
		account_restriction: {...restriction},
		contact_restriction: {...restriction},
	};

	return {
		A: {...template},
		B: {...template},
		C: {...template},
		D: {...template},
		E: {...template},
		F: {...template},
	};
};

export const checkRatingsBuckets = (map: BucketToRuleMap): void => {
	const buckets: (keyof RatingsBuckets)[] = ['A', 'B', 'C', 'D', 'E', 'F'];
	const generated = generateRatingsBuckets();

	buckets.forEach((key) => {
		const bucket = map[key];

		if (!bucket) {
			map[key] = generated[key];
		}
	});
};

// #region saveIteration
interface SaveIterationReturn {
	applicationId?: string;
	result?: string | boolean;
	showProgress: boolean;
}

const launchSavedIteration = async (
	engineId: string,
	modelId: string,
	enrichments: Attribute[]
): Promise<SaveIterationReturn> =>
	launchModeling(engineId, modelId, enrichments).then((applicationId) => {
		dispatchRatingsEnginePropertyValue('applicationId', applicationId);

		store.dispatch(
			setJobsPropertyValue('inProgressModelJobs', {
				...getJobsProperty<JobStatusRecord>('inProgressModelJobs'),
				[engineId]: null,
			})
		);

		return {
			applicationId,
			showProgress: false,
		};
	});

const saveValidatedModel = async (
	engineId: string,
	iteration: AIRatingRecord,
	result: string | boolean,
	stateToValidate: keyof RatingsEngineValidation
): Promise<SaveIterationReturn | undefined> => {
	if (result === true) {
		// Save iteration
		return saveIterationQuery(engineId, iteration).then(async (result) => {
			const modelId = typeof result === 'object' ? result.AI.id : '';

			const enrichments = getRatingsEngineProperty<Attribute[]>(
				'iterationEnrichments'
			);

			if (enrichments) {
				return launchSavedIteration(engineId, modelId!, enrichments);
			}
		});
	}

	dispatchValidationPropertyValue(stateToValidate, !result);

	return {
		result,
		showProgress: false,
	};
};

export const saveIteration = async (
	stateToValidate: keyof RatingsEngineValidation
): Promise<SaveIterationReturn | undefined> => {
	const ratingEngine = getRatingsEngineProperty<RatingEngine>('rating')!;

	const engineId = ratingEngine?.id ?? '';

	const iteration = getRatingsEngineProperty<AIRatingRecord | null>(
		'remodelIteration'
	);

	const validationModelId = iteration?.id;

	const createdBy = getUserEmail();

	if (iteration) {
		iteration.AI.derived_from_rating_model = iteration.AI.id!;
		iteration.AI.createdBy = createdBy!;

		const configFilters =
			getRatingsEngineProperty<ConfigFilters>('configFilters');

		if (iteration.AI.advancedModelingConfig.cross_sell) {
			// save iteration data for saveIteration

			iteration.AI.trainingSegment =
				getRatingsEngineProperty<TrainingSegment>('trainingSegment')!;

			iteration.AI.advancedModelingConfig.cross_sell.filters = configFilters;

			// save ratingEngine.latest_iteration for validate
			ratingEngine.latest_iteration!.AI.advancedModelingConfig.cross_sell!.filters =
				configFilters;
		} else {
			iteration.AI.advancedModelingConfig.custom_event = configFilters;

			ratingEngine.latest_iteration!.AI.advancedModelingConfig.custom_event =
				configFilters;
		}

		// Sanitize iteration to remove data
		delete iteration.AI.pid;
		delete iteration.AI.id;
		delete iteration.AI.modelingJobId;
		delete iteration.AI.modelingJobStatus;
		delete iteration.AI.modelSummaryId;
	}

	dispatchValidationPropertyValue(stateToValidate, false);

	return validateModel(engineId, validationModelId, ratingEngine).then(
		async (result) =>
			saveValidatedModel(engineId, iteration!, result, stateToValidate)
	);
};
// #endregion saveIteration

export const nextSaveRatingEngineAI = async (
	nextState: string
): Promise<void> => {
	const {params: stateParams, go} = NgState.getAngularState() ?? {};
	const ratingId = stateParams.rating_id;

	const engineType = getRatingsEngineProperty<string>('modelingStrategy')!;

	const opts: Partial<RatingEngine> = {
		type: 'CROSS_SELL',
		advancedRatingConfig: {
			cross_sell: {
				modelingStrategy: engineType,
			},
		},
	};

	if (ratingId) {
		opts.id = ratingId;
	}

	return saveRating(opts).then((rating) => {
		if (typeof rating === 'object') {
			go(nextState, {rating_id: rating.id});
		}
	});
};

export const getProductsSelectedIds = (): string[] =>
	Object.keys(
		getRatingsEngineProperty<Record<string, string>>('productsSelected')!
	);

export const selectProduct = (id: string, name: string): void => {
	const productsSelected = {
		...getRatingsEngineProperty<Record<string, string>>('productsSelected')!,
	};

	if (productsSelected[id]) {
		delete productsSelected[id];
	} else {
		productsSelected[id] = name;
	}

	dispatchRatingsEnginePropertyValue('productsSelected', productsSelected);
};

export const selectAllProducts = (allProducts: Product[]): Product[] => {
	return allProducts.map((product) => {
		if (product.Selected === false || product.Selected === undefined) {
			selectProduct(product.ProductId, product.ProductName);
		}

		return {
			...product,
			Selected: true,
		};
	});
};

export const nextLaunchAIModel = async (
	nextState: string,
	model: AIRatingRecord
): Promise<void> => {
	const {go} = NgState.getAngularState() ?? {};

	const currentRating =
		getRatingsEngineProperty<CurrentRating>('currentRating');
	const obj = model.AI;

	if (currentRating && obj) {
		return createAIModel(currentRating.id!, obj.id!).then((applicationId) => {
			dispatchRatingsEnginePropertyValue('applicationId', applicationId);

			store.dispatch(
				setJobsPropertyValue('inProgressModelJobs', {
					...getJobsProperty<JobStatusRecord>('inProgressModelJobs'),
					[currentRating.id!]: null,
				})
			);

			if (nextState) {
				go(nextState, {ai_model_job_id: applicationId});
			}
		});
	}
};

// #region getBucketRuleCounts
const findBuckets = (
	tree: Restriction[],
	restrictions: Restriction[] = []
): Restriction[] => {
	for (const branch of tree) {
		if (branch?.bucketRestriction) {
			restrictions.push(branch);
			break;
		}

		if (branch?.logicalRestriction) {
			findBuckets(branch.logicalRestriction.restrictions, restrictions);
		}
	}

	return restrictions;
};

interface Bucket {
	segmentId: string;
	responseKeyId: string;
	account_restriction?: Restriction;
	contact_restriction?: Restriction;
}

export interface BucketRuleCounts {
	restrictNotNullSalesforceId: boolean;
	segmentIdAndSingleRules: Bucket[];
}

export const getBucketRuleCounts = (
	restrictions: Restriction[],
	segmentId: string
): BucketRuleCounts => {
	const buckets = restrictions.map(function (bucket, index) {
		let bucketTmp = {...bucket};

		if (bucket.activityRestriction) {
			const allActivityBuckets: Restriction[] = [];

			findBuckets(
				bucket.activityRestriction.restriction.logicalRestriction
					?.restrictions ?? [],
				allActivityBuckets
			);

			if (allActivityBuckets.length > 0) {
				bucketTmp = {...allActivityBuckets[0]};
			}
		}

		if (!bucketTmp.bucketRestriction) {
			return undefined;
		}

		const label = bucketTmp.bucketRestriction?.attr;

		const entity = label.split('.')[0];

		const isContact =
			entity === 'Contact' ||
			entity === 'ContactMarketingActivity' ||
			entity === 'CuratedContact';

		const type = isContact ? 'contact' : 'account';

		const object: Bucket = {
			segmentId,
			responseKeyId: bucket.activityRestriction
				? `activityRestriction_${index}`
				: `${label}_${index}`,
		};

		const [sanitizeBucket] = sanitizeSegmentRestriction([bucket]);

		object[`${type}_restriction`] = sanitizeBucket!;

		return object;
	});

	return {
		restrictNotNullSalesforceId: false,
		segmentIdAndSingleRules: buckets.filter((item) => item) as Bucket[],
	};
};
// #endregion getBucketRuleCounts

export const getTrainingCounts = async (
	engineId: string,
	modelId: string,
	ratingEngine: RatingEngine,
	queryType: string
): Promise<string | number> =>
	getTrainingCountsQuery(engineId, modelId, ratingEngine, queryType).then(
		(result) => {
			if (typeof result === 'number') {
				if (queryType === 'EVENT') {
					if (result < 50) {
						dispatchTrainingCountsPropertyValue('valid', false);
					} else if (result >= 50) {
						dispatchTrainingCountsPropertyValue('valid', true);
					}

					dispatchTrainingCountsPropertyValue('purchasesCount', result);
					dispatchTrainingCountsPropertyValue('purchasesCountReturned', true);
				} else if (queryType === 'TRAINING') {
					dispatchTrainingCountsPropertyValue('recordsCount', result);
					dispatchTrainingCountsPropertyValue('recordsCountReturned', true);
				}

				// This conditional is in a discrete block because the parent function
				// is called multiple times with different params, so we must validate
				// using the persisted trainingCounts validity.
				const trainingCounts =
					getRatingsEngineProperty<TrainingCounts>('trainingCounts');

				if (typeof trainingCounts?.valid !== 'undefined') {
					dispatchValidationPropertyValue('training', trainingCounts.valid);
				} else {
					dispatchValidationPropertyValue('training', true);
				}
			}

			return result;
		}
	);

export const saveRatingStatus = async (
	id: string,
	status: string,
	action: string
): Promise<string | SaveRatingResponse> =>
	saveRatingQuery(
		{
			id,
			status,
		},
		{},
		action
	).then((data) => data);

// #region nextSaveAIRatingModel
interface AIRatingModelPromiseParams {
	rating: RatingEngine;
	nextState: string;
	opts: NextSaveAIRatingModelOpts;
	model: string | AIRatingRecord;
	aiModel: UpdateRatingModelOpts;
}

const validateAIRatingCounts = async ({
	rating,
	nextState,
	opts,
	model,
	aiModel,
}: AIRatingModelPromiseParams): Promise<void> => {
	const {launchAIModel, validate} = opts;

	if (getRatingsEngineProperty<TrainingCounts>('trainingCounts')?.valid) {
		const {params: stateParams, go} = NgState.getAngularState() ?? {};

		const ratingId = stateParams.rating_id;

		return validateModel(ratingId, aiModel.AI.id, rating).then((result) => {
			const success = result === true;

			if (success) {
				if (launchAIModel) {
					nextLaunchAIModel(nextState, model as AIRatingRecord);
				} else if (nextState) {
					go(nextState, {rating_id: ratingId});
				}
			} else {
				dispatchValidationPropertyValue(
					validate as keyof RatingsEngineValidation,
					true
				);
			}
		});
	}

	dispatchValidationPropertyValue(
		validate as keyof RatingsEngineValidation,
		true
	);
};

const getAIRatingModelTrainingCounts = async ({
	rating,
	nextState,
	opts,
	model,
	aiModel,
}: AIRatingModelPromiseParams): Promise<void> => {
	const {validate, launchAIModel} = opts;

	const {params: stateParams, go} = NgState.getAngularState() ?? {};

	const ratingId = stateParams.rating_id;

	if (validate && typeof model === 'object') {
		rating.latest_iteration = model;

		getTrainingCounts(ratingId, aiModel.AI.id!, rating, 'TRAINING');

		return getTrainingCounts(ratingId, aiModel.AI.id!, rating, 'EVENT').then(
			() =>
				validateAIRatingCounts({
					rating,
					nextState,
					opts,
					model,
					aiModel,
				})
		);
	}

	if (launchAIModel && typeof model === 'object') {
		nextLaunchAIModel(nextState, model);
	} else if (nextState) {
		go(nextState, {rating_id: ratingId});
	}
};

const updateAIRatingModel = async (
	rating: string | RatingEngine,
	nextState: string,
	opts: NextSaveAIRatingModelOpts
): Promise<void> => {
	if (typeof rating === 'object' && rating.latest_iteration) {
		const {params: stateParams} = NgState.getAngularState() ?? {};

		const ratingId = stateParams.rating_id;

		const model = rating.latest_iteration.AI;

		const targetProducts =
			model.targetProducts?.length === 0 ? [] : getProductsSelectedIds();

		const aiModel = {
			AI: {
				id: model.id,
				predictionType: getRatingsEngineProperty<string>('predictionType'),
				trainingSegment:
					getRatingsEngineProperty<TrainingSegment>('trainingSegment'),
				advancedModelingConfig: {
					cross_sell: {
						targetProducts,
						trainingProducts:
							getRatingsEngineProperty<string[]>('trainingProducts') ?? [],
						modelingStrategy: stateParams.engineType,
						filters: getRatingsEngineProperty<ConfigFilters>('configFilters'),
					},
				},
			},
		};

		return updateRatingModel(ratingId, aiModel.AI.id!, aiModel).then((model) =>
			getAIRatingModelTrainingCounts({rating, nextState, opts, model, aiModel})
		);
	}
};

interface NextSaveAIRatingModelOpts {
	validate?: string;
	launchAIModel?: boolean;
}

export const nextSaveAIRatingModel = async (
	nextState: string,
	opts: NextSaveAIRatingModelOpts = {}
): Promise<void> => {
	const {params: stateParams} = NgState.getAngularState() ?? {};

	dispatchTrainingCountsPropertyValue('recordsCountReturned', false);
	dispatchTrainingCountsPropertyValue('purchasesCountReturned', false);

	if (opts?.validate) {
		dispatchValidationPropertyValue(
			opts.validate as keyof RatingsEngineValidation,
			false
		);
	}

	const ratingId = stateParams.rating_id;

	return getRating(ratingId).then((rating) =>
		updateAIRatingModel(rating, nextState, opts)
	);
};
// #endregion nextSaveAIRatingModel

export const nextSaveSummary = async (
	nextState: string,
	opts: NextSaveAIRatingModelOpts = {}
): Promise<void> => {
	const {go} = NgState.getAngularState() ?? {};

	const rating = getRatingsEngineProperty<RatingEngine>('currentRating') ?? {};

	return saveRating(rating).then(() => {
		if (opts.launchAIModel) {
			nextSaveAIRatingModel(nextState, opts);
		} else if ('id' in rating) {
			go(nextState, {rating_id: rating.id}, {reload: true});
		}
	});
};

// #region nextSaveCustomEventRatingModel
interface UpdateCustomEventRatingModelParams {
	rating: string | RatingEngine;
	nextState: string;
	ratingId: string;
}

const updateCustomEventRatingModel = async ({
	rating,
	nextState,
	ratingId,
}: UpdateCustomEventRatingModelParams): Promise<void> => {
	if (typeof rating === 'object') {
		const model = rating.latest_iteration;

		const predictionType = getRatingsEngineProperty<string>('predictionType');

		const customEventModelingType = getRatingsEngineProperty<string>(
			'customEventModelingType'
		);

		const dataStores =
			customEventModelingType === 'LPI'
				? ['CustomFileAttributes', 'DataCloud']
				: ['CDL', 'DataCloud'];

		const modelTrainingOptions = getRatingsEngineProperty<ModelTrainingOptions>(
			'modelTrainingOptions'
		);

		const ratingModel = {
			AI: {
				id: model?.AI.id ?? '',
				predictionType,
				advancedModelingConfig: {
					custom_event: {
						customEventModelingType,
						dataStores,
						sourceFileName: getRatingsEngineProperty<string>('fileName'),
						sourceFileDisplayName:
							getRatingsEngineProperty<string>('displayFileName'),
						deduplicationType: modelTrainingOptions?.deduplicationType,
						excludePublicDomains: modelTrainingOptions?.excludePublicDomains,
						transformationGroup: modelTrainingOptions?.transformationGroup,
					},
				},
			},
		};

		return updateRatingModel(ratingId, ratingModel.AI.id, ratingModel).then(
			() => {
				const {go} = NgState.getAngularState() ?? {};

				go(nextState, {rating_id: ratingId});
			}
		);
	}
};

export const nextSaveCustomEventRatingModel = async (
	nextState: string
): Promise<void> => {
	const {params: stateParams} = NgState.getAngularState() ?? {};
	const ratingId = stateParams.rating_id;

	return getRating(ratingId).then(async (rating) =>
		updateCustomEventRatingModel({
			rating,
			nextState,
			ratingId,
		})
	);
};
// #endregion nextSaveCustomEventRatingModel

// #region saveFieldMapping
const isUnmappedField = (fieldMapping: Field): boolean =>
	!fieldMapping.mappedToLatticeField ||
	getRatingsEngineProperty<string[]>('availableFields')!.includes(
		fieldMapping.userField!
	);

const getSavedRatingFieldMapping = async (nextState: string): Promise<void> => {
	const {params: stateParams} = NgState.getAngularState() ?? {};

	const ratingId = stateParams.rating_id;

	return getRating(ratingId).then((rating) => {
		if (typeof rating === 'object') {
			nextLaunchAIModel(nextState, rating.latest_iteration!);
		}
	});
};

export const saveFieldMapping = async (nextState: string): Promise<void> => {
	const FieldDocument =
		getRatingsEngineProperty<FieldDocument>('FieldDocument')!;

	FieldDocument.fieldMappings.forEach(function (fieldMapping) {
		if (fieldMapping.ignored) {
			FieldDocument.ignoredFields!.push(fieldMapping.userField!);
			delete fieldMapping.ignored;
		} else if (isUnmappedField(fieldMapping)) {
			const customEventModelingType = getRatingsEngineProperty<string>(
				'customEventModelingType'
			);
			const dataStores =
				customEventModelingType === 'LPI'
					? ['CustomFileAttributes', 'DataCloud']
					: ['CDL', 'DataCloud'];

			if (dataStores.indexOf('CustomFileAttributes') < 0) {
				fieldMapping.mappedToLatticeField = false;
				fieldMapping.mappedField = fieldMapping.userField;
				fieldMapping.ignored = true;
				FieldDocument.ignoredFields!.push(fieldMapping.mappedField!);
			}
		}
	});

	return saveFieldMappings(
		getRatingsEngineProperty<string>('fileName')!,
		FieldDocument,
		{
			excludeCustomFileAttributes:
				getRatingsEngineProperty<string>('customEventModelingType') === 'CDL',
		},
		true
	).then(async () => getSavedRatingFieldMapping(nextState));
};
// #endregion saveFieldMapping

export const getWizardProgressItems = (step: string): WizardItems => {
	const items = wizardProgressItems()[step || 'rulesprospects']!;

	return items.filter((item) => {
		if (isSegmentationV2Enabled() && step === 'rulesprospects') {
			if (item.label === 'Segment') {
				item.nextLabel = nextLabelRules;
			}

			if (item.label === 'Attributes') {
				return false;
			}
		}

		return !item.disabled;
	});
};

export const setRatings = (
	ratings: RatingEngine[],
	ignoreGetCoverage?: boolean
): void => {
	dispatchCurrentPropertyValue('ratings', ratings);

	dispatchRatingsEnginePropertyValue('ratingsSet', true);

	if (!ignoreGetCoverage) {
		ratings.forEach((rating) => {
			const {id} = rating;

			const {tileStates, bucketCountMap} =
				getRatingsEngineProperty<Current>('current')!;

			dispatchCurrentPropertyValue('tileStates', {
				...tileStates,
				[id]: {
					showCustomMenu: false,
					editRating: false,
					saveEnabled: false,
				},
			});

			const coverage = [];
			for (const bucket in rating.coverage) {
				if (bucket in rating.coverage) {
					coverage.push({bucket, count: rating.coverage[bucket]});
				}
			}

			dispatchCurrentPropertyValue('bucketCountMap', {
				...bucketCountMap,
				[id]: {
					accountCount: rating.accountsInSegment,
					contactCount: rating.contactsInSegment,
					bucketCoverageCounts: coverage,
				},
			});
		});
	}
};

export const getRatings = async (
	active: boolean,
	cacheOnly: boolean
): Promise<string | RatingEngine[]> => {
	dispatchRatingsEnginePropertyValue('ratingsSet', false);

	const current = getRatingsEngineProperty<Current>('current');

	if (current && current.ratings.length > 0 && cacheOnly) {
		return current.ratings;
	}

	return getRatingsQuery(active).then((data) => {
		if (typeof data === 'object') {
			setRatings(data);
		}

		return data;
	});
};

export const deleteRating = async (
	ratingId: string
): Promise<string | boolean> =>
	deleteRatingQuery(ratingId).then((result) => {
		if (result === true) {
			// immediately remove deleted rating from ratinglist
			setRatings(
				getRatingsEngineProperty<Current>('current')!.ratings.filter(
					(rating) => rating.id !== ratingId
				),
				true
			);
		}

		return result;
	});

export const getIterationFromDashboard = (
	ratingEngineDashboard: RatingsDashboard,
	iterationId?: string
): null | IIteration => {
	const dashboardIterations = ratingEngineDashboard.iterations ?? [];

	const validIterations = dashboardIterations.filter(
		(iteration) => iteration.modelingJobStatus === 'Completed'
	);

	const filterValid = validIterations.filter(({id}) => id === iterationId);

	if (validIterations.length === 0) {
		return null;
	}

	if (filterValid.length === 0) {
		const lastValidIndex = validIterations.length - 1;

		return validIterations[lastValidIndex]!;
	}

	return filterValid[0]!;
};
