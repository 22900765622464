import React from 'common/react-vendor';
import {DNBTypography} from 'common/dnb-uux-vendor';
import {isEqual} from 'lodash';
import {QueryTreeContainerOperator} from './QueryTreeContainerOperator';
import {QueryTreeAttribute} from './QueryTreeAttribute';
import {QueryTreeBucketRestriction} from './QueryTreeBucketRestriction';
import {doesBucketExist} from './QueryTree.helpers';
import {useQueryTree} from './hook/useQueryTree';
import {QueryTreeDragItemOutline} from './QueryTreeDragItemOutline';
import {IQueryTreeContext} from './context/QueryTreeContext';

const QueryTreeContainer = (): React.ReactElement => {
	const [refreshTree, setRefreshTree] = React.useState(0);
	const {context} = useQueryTree();
	const {tree, entity, root} = context;
	const entityLowerCase = entity.toLocaleLowerCase();
	const droppedItem = root.droppedItem?.current as IQueryTreeContext;
	const draggedItem = root.draggedItem?.current;
	const showDragItemOutlineBefore =
		!tree.logicalRestriction &&
		root.droppedItemAppend?.current === false &&
		(isEqual(droppedItem, context) ||
			(isEqual(draggedItem?.tree, tree) &&
				(isEqual(droppedItem?.tree, tree) || !droppedItem)));
	const showDragItemOutlineAfter =
		!tree.logicalRestriction &&
		root.droppedItemAppend?.current === true &&
		(isEqual(droppedItem, context) ||
			(isEqual(draggedItem?.tree, tree) &&
				(isEqual(droppedItem?.tree, tree) || !droppedItem)));
	const showBucketRestriction =
		doesBucketExist(tree) &&
		!isEqual(draggedItem?.tree, tree) &&
		entityLowerCase !== 'member';
	const showSegmentMemberRestriction =
		tree.segmentMemberRestriction &&
		!isEqual(draggedItem?.tree, tree) &&
		entityLowerCase !== 'member';
	const callBack = (): void => {
		setRefreshTree(refreshTree + 1);
	};
	React.useEffect(() => {
		if (isEqual(droppedItem?.tree, tree)) {
			setRefreshTree(refreshTree + 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [droppedItem]);
	return (
		<DNBTypography
			component='div'
			className={
				tree?.logicalRestriction
					? `query-section-type-${tree?.logicalRestriction?.operator.toLowerCase()}`
					: ''
			}>
			{/* -- AND/OR operator container -- */}
			{tree?.logicalRestriction && <QueryTreeContainerOperator />}
			{/* -- Drag and Drop item outline (insert before item) -- */}
			{showDragItemOutlineBefore && (
				<QueryTreeDragItemOutline dashedTop={true} />
			)}
			{/* -- bucketRestriction Attribute item container -*/}
			{showBucketRestriction && (
				<QueryTreeBucketRestriction callBack={callBack} />
			)}
			{/* -- segmentMemberRestriction Attribute item container -- */}
			{showSegmentMemberRestriction && (
				<QueryTreeBucketRestriction callBack={callBack} />
			)}
			{/* -- Drag and Drop item outline (append after item) -- */}
			{showDragItemOutlineAfter && <QueryTreeDragItemOutline />}
			{/* -- this section for account/contact attribute start -- */}
			{tree.logicalRestriction && !tree?.collapsed && <QueryTreeAttribute />}
		</DNBTypography>
	);
};

export {QueryTreeContainer};
