import React from 'common/react-vendor';
import {
	CloseIcon,
	DNBButton,
	DNBCheckbox,
	DNBDivider,
	DNBIconButton,
	DNBTextField,
	DNBTypography,
	InputAdornment,
	SearchOutlinedIcon,
} from 'common/dnb-uux-vendor';
import {VariableSizeList as List} from 'common/widgets/react-window';
import styles from '../Chips.module.scss';
import {
	getDisplayName,
	getPotentialValuesCount,
	limitCheck,
} from '../ChipsController';
import {useChipsContext} from '../useChipsContext';
import {IChip, IDialogUpdated, countText} from '../ChipsTypes';

const ChipsCenterSection = (props: IDialogUpdated): React.ReactElement => {
	const {context, setContext} = useChipsContext();
	const {
		ID,
		datasource,
		queryScope,
		displayName,
		previousItems,
		toBeAddedItems,
		treeContext,
	} = context;
	const {setToBeAddedItems, setPreviousItems} = setContext;
	const [searchText, setSearchText] = React.useState('');
	const [filteredItems, setFilteredItems] = React.useState(datasource);
	const {isDialogUpdated, setIsDialogUpdated} = props;

	React.useEffect(() => {
		if (searchText) {
			const filteredItems = datasource.filter((item) => {
				return item[queryScope || displayName]
					?.toLocaleString()
					.toLowerCase()
					.includes(searchText.toLowerCase());
			});
			setFilteredItems(filteredItems);
		} else {
			setFilteredItems(datasource);
		}
	}, [datasource, queryScope, displayName, searchText]);

	const selectedItems = [
		...Object.keys(previousItems),
		...Object.keys(toBeAddedItems),
	];

	const isSelected = (name: string): boolean => {
		return selectedItems.includes(name);
	};
	const getHighest = (): number => {
		const counts = filteredItems.map((item) => {
			return item.Cnt || 0;
		});
		return Math.max(...counts);
	};
	const highest = getHighest();
	const percentage = (count = 0): string => {
		const percent = (count / highest) * 100;
		return `${percent > 0.1 ? percent : '0.1'}%`;
	};
	const chooseItem = (item: IChip): void => {
		if (item) {
			const key = item[ID]?.toLocaleString();
			if (key !== undefined && toBeAddedItems[key] === undefined) {
				const newToBeAddedItems = {
					...toBeAddedItems,
					...{
						[key]: item,
					},
				};
				if (!limitCheck(selectedItems.length, treeContext, false, true)) {
					return;
				}
				setToBeAddedItems(newToBeAddedItems);
			}
			setIsDialogUpdated(!isDialogUpdated);
		}
	};
	const removeItem = (item: IChip): void => {
		if (Object.hasOwn(toBeAddedItems, item[ID] as string)) {
			delete toBeAddedItems[item[ID] as string];
			setToBeAddedItems(toBeAddedItems);
		} else if (Object.hasOwn(previousItems, item[ID] as string)) {
			delete previousItems[item[ID] as string];
			setPreviousItems(previousItems);
		}
		setIsDialogUpdated(!isDialogUpdated);
	};
	const counts = getPotentialValuesCount(filteredItems);
	const names = counts > 1 ? `${countText}S` : countText;
	return (
		<DNBTypography component='div' className={styles.ChipsCenterSection}>
			<div className={styles.searchSection}>
				<DNBTypography component='span' variant='compact-body'>
					Explore values in the platform
				</DNBTypography>
				<DNBTypography
					component='div'
					sx={{
						display: 'flex',
					}}>
					<DNBTextField
						onChange={(e) => {
							setSearchText(e.currentTarget.value);
						}}
						value={searchText}
						size='compact'
						placeholder={`Search ${treeContext.item?.DisplayName} Values`}
						sx={{width: '100%'}}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									{searchText ? (
										<DNBIconButton
											size='small'
											sx={{padding: 0, marginRight: 1}}
											onClick={() => {
												setSearchText('');
											}}
											onMouseDown={(e) => e.preventDefault()}
											edge='end'>
											<CloseIcon />
										</DNBIconButton>
									) : (
										<SearchOutlinedIcon />
									)}
								</InputAdornment>
							),
							sx: {
								placeSelf: 'normal',
							},
						}}
					/>
					<DNBButton
						variant='text'
						size='compact'
						disabled={!searchText}
						onClick={() => {
							setSearchText('');
						}}>
						Clear Search
					</DNBButton>
				</DNBTypography>
			</div>
			<DNBTypography
				component='div'
				variant='overline'
				sx={{padding: '10px 12px', fontWeight: 850}}>
				{`${counts} ${names}`}
			</DNBTypography>
			<DNBDivider
				sx={{height: '1px', borderRadius: '4px'}}
				orientation='vertical'
				variant='incidental'
			/>
			<List
				height={566}
				width='100%'
				itemSize={() => 64}
				itemCount={filteredItems.length}>
				{({index, style}) => {
					const item = filteredItems[index] || {};
					const name = getDisplayName(displayName, item);
					const checked = isSelected(name);
					return (
						<DNBTypography component='div' key={name} style={style}>
							<DNBCheckbox
								name={name}
								size='small'
								id={name}
								label={
									<DNBTypography
										component='div'
										sx={{
											marginTop: '-20px',
											marginLeft: 1,
										}}>
										<DNBTypography
											component='div'
											sx={{
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}>
											<DNBTypography component='span' variant='compact-body'>
												{name}
											</DNBTypography>
										</DNBTypography>
										{!treeContext.isActivity && (
											<DNBTypography
												component='div'
												sx={{display: 'flex', alignItems: 'center'}}>
												<DNBTypography
													component='div'
													sx={{
														height: 16,
														width: `${percentage(item.Cnt)}`,
														background: (theme) =>
															theme.colors.ColorSecondaryBlueLight,
													}}
												/>
												<DNBTypography
													component='span'
													variant='caption-bold'
													sx={{
														marginLeft: 1,
														whiteSpace: 'nowrap',
													}}>
													{`${item.Cnt?.toLocaleString('en-US')} Records`}
												</DNBTypography>
											</DNBTypography>
										)}
									</DNBTypography>
								}
								labelProps={{
									sx: {
										'width': '98%',
										'height': 56,
										'margin': '4px',
										':hover': {
											margin: '4px',
										},
									},
								}}
								title={name}
								checked={checked}
								onChange={() => {
									if (checked) {
										removeItem(item);
									} else {
										chooseItem(item);
									}
								}}
							/>
							<DNBDivider />
						</DNBTypography>
					);
				}}
			</List>
		</DNBTypography>
	);
};

export {ChipsCenterSection};
