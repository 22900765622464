import React from 'common/react-vendor';
import {DNBCard} from 'common/dnb-uux-vendor';
import classNames from 'classnames';
import {useQueryTree} from './hook/useQueryTree';
import {getBucketLabel} from '../AdvancedQuery.helper';
import {
	getBackgroundColor,
	mouseOver,
	mouseMove,
	mouseOverHighlight,
	mouseOutHighlight,
} from './QueryTree.helpers';
import {useAdvancedQuery} from '../hook/useAdvancedQuery';

const QueryTreeDragItemOutline = ({
	dashedTop = false,
}: {
	dashedTop?: boolean;
}): React.ReactElement => {
	const {context: queryTree} = useQueryTree();
	const {context: advancedQuery, setContext: setAdvancedQuery} =
		useAdvancedQuery();
	const {root, editing, item} = queryTree;
	const draggedItem = root.draggedItem?.current;
	const tree = draggedItem?.tree || queryTree.tree;
	return (
		<DNBCard
			className={classNames({
				dashed: true,
				dashedTop,
				querySectionBlock: true,
				querySectionItem: true,
				canEdit: root.canEdit,
			})}
			style={{
				borderLeftColor: getBackgroundColor(tree, root, item),
			}}
			hasBorder
			variant='white'
			onMouseOver={(e) => {
				mouseOver(e, editing, queryTree, advancedQuery);
				if (draggedItem?.tree) {
					mouseOverHighlight(getBucketLabel(draggedItem.tree));
				}
			}}
			onMouseMove={(e) => {
				mouseMove(e, editing, queryTree, advancedQuery, setAdvancedQuery, true);
			}}
			onMouseOut={() => {
				if (draggedItem?.tree) {
					mouseOutHighlight(getBucketLabel(draggedItem.tree));
				}
			}}>
			<div
				style={{position: 'relative'}}
				id={`bucket-${getBucketLabel(tree)}`}
			/>
			<div className='querySectionItemLabel'>
				<strong style={{backgroundColor: getBackgroundColor(tree, root, item)}}>
					{getBucketLabel(tree) || '-'}
				</strong>
			</div>
		</DNBCard>
	);
};

export {QueryTreeDragItemOutline};
