import React from 'common/react-vendor';
import {
	DNBButton,
	DNBSelect,
	DNBSelectOption,
	DNBTypography,
} from 'common/dnb-uux-vendor';
import classnames from 'classnames';
import {isRulesBasedModelMode, setPublicProperty} from 'common/stores/query';
import {isNumericalRangeValid} from 'components/datacloud/query/advanced/tree/edit/NumericalRange/numericalRange.validations';
import {BucketCmp} from 'common/components/datacloud/query/query.enums';
import {
	InputRangeConfig,
	ActivityRestriction,
	InputChangeData,
	LogicalRestriction,
} from 'common/components/datacloud/query/query.types';
import {changeActivityNumber} from '../../tree.helpers';
import {NumericalRange} from '../NumericalRange/NumericalRange';
import {
	setNumericalRangeInitialState,
	updateNumericalRange,
} from './treeActivityEdit.helpers';

const {useState, useEffect, useMemo} = React;

type RootScope = {
	updateCount(): void;
};

export type TreeScope = {
	numerical_operations: Record<string, string>;
	editing: boolean;
	activityRestriction: Record<'activityRestriction', ActivityRestriction>;
	operation: BucketCmp;
	root: RootScope;
	rangeConfig: InputRangeConfig;
	vals: number[];
	parent: LogicalRestriction;
	tree: LogicalRestriction;
	isValidRange: boolean;
};

export type ScopeChange = (key: keyof TreeScope, value: unknown) => void;

interface TreeActivityEditProps {
	scope: TreeScope;
	onScopeChange: ScopeChange;
}

export const TreeActivityEdit = ({
	scope,
	onScopeChange,
}: TreeActivityEditProps): React.ReactElement => {
	const [showFromNumerical, setShowFromNumerical] = useState<boolean>(false);
	const [showToNumerical, setShowToNumerical] = useState<boolean>(false);

	const [operation, setOperation] = useState(
		scope.activityRestriction.activityRestriction.cmp
	);

	const [rangeConfig, setRangeConfig] = useState<InputRangeConfig>();

	const {rangeConfig: initialRangeConfig} = scope;

	const numericalOperationsActivity = useMemo(() => {
		const operations = {...scope.numerical_operations};

		if (operations.IS_NULL) {
			delete operations.IS_NULL;
		}

		if (operations.IS_NOT_NULL) {
			delete operations.IS_NOT_NULL;
		}

		return operations;
	}, [scope.numerical_operations]);

	useEffect(() => {
		setNumericalRangeInitialState({
			operation,
			activityRestriction: scope.activityRestriction.activityRestriction,
			onScopeChange,
			setShowFromNumerical,
			setShowToNumerical,
			setRangeConfig,
			initialRangeConfig,
		});
		onScopeChange(
			'vals',
			scope?.activityRestriction?.activityRestriction?.cnt || []
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isValid = (): boolean => {
		const isValidRange = isNumericalRangeValid({
			vals: scope.activityRestriction.activityRestriction.cnt,
			rangeConfig,
			showFrom: showFromNumerical,
			showTo: showToNumerical,
		});

		setPublicProperty('enableSaveSegmentButton', isValidRange);

		return isValidRange;
	};
	useEffect(() => {
		const isValidRange = isNumericalRangeValid({
			vals: scope.vals,
			rangeConfig,
			showFrom: showFromNumerical,
			showTo: showToNumerical,
		});
		onScopeChange('isValidRange', isValidRange);
	}, [
		onScopeChange,
		rangeConfig,
		scope.vals,
		showFromNumerical,
		showToNumerical,
	]);

	const updateActivityRestriction = (newOperation?: BucketCmp): void => {
		if (scope.activityRestriction) {
			onScopeChange('activityRestriction', {
				activityRestriction: {
					...scope.activityRestriction.activityRestriction,
					cmp: newOperation ?? operation,
					cnt: scope.vals,
				},
			});
		}
	};

	const updateParentActivityRestriction = <T,>(
		key: keyof ActivityRestriction,
		value: T
	): void => {
		const {logicalRestriction, activityRestriction: activity} = scope.parent;

		if (logicalRestriction) {
			const activityRestrictionIndex =
				logicalRestriction.restrictions.findIndex(
					(item) =>
						item.activityRestriction &&
						item.activityRestriction.restriction === scope.tree
				);

			if (activityRestrictionIndex !== -1) {
				const activityRestriction =
					logicalRestriction.restrictions[activityRestrictionIndex];

				if (activityRestriction?.activityRestriction) {
					activityRestriction.activityRestriction[key] = value as string &
						BucketCmp &
						number[] &
						number &
						LogicalRestriction;
				}
			}
		} else if (activity?.activityRestriction) {
			activity.activityRestriction[key] = value as string &
				BucketCmp &
				number[] &
				number &
				LogicalRestriction;
		}
	};

	const handleCmpChange = (newCmp: BucketCmp): void => {
		// eslint-disable-next-line no-param-reassign
		scope.activityRestriction.activityRestriction.cmp = newCmp;

		updateParentActivityRestriction<BucketCmp>('cmp', newCmp);

		setOperation(newCmp);

		onScopeChange('operation', newCmp);

		if (rangeConfig) {
			const newRangeConfig = {
				...rangeConfig,
				from: {
					...rangeConfig.from,
					value: undefined,
				},
				to: {
					...rangeConfig.to,
					value: undefined,
				},
			};

			setRangeConfig(newRangeConfig);
			onScopeChange('rangeConfig', newRangeConfig);
		}

		updateNumericalRange({
			operation: newCmp,
			onScopeChange,
			setShowFromNumerical,
			setShowToNumerical,
		});

		updateActivityRestriction(newCmp);
	};

	const handleNumericalRangeChange = ({
		index,
		value,
	}: InputChangeData): void => {
		updateParentActivityRestriction('cnt', scope.vals);

		changeActivityNumber({
			activityRestriction: scope.activityRestriction.activityRestriction,
			value,
			index,
		});

		updateActivityRestriction();

		if (rangeConfig) {
			const rangeKey = index === 0 ? 'from' : 'to';

			const newRangeConfig = {
				...rangeConfig,
				[rangeKey]: {
					...rangeConfig[rangeKey],
					value,
				},
			};

			setRangeConfig(newRangeConfig);
			onScopeChange('rangeConfig', newRangeConfig);
		}
	};

	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	): void => {
		event.preventDefault();
		event.stopPropagation();

		onScopeChange('editing', false);
		scope.root.updateCount();
	};

	return (
		<form>
			<div
				className={classnames('editing-container', {
					'le-grid': scope.editing,
				})}>
				<div className='le-row'>
					<div className='le-col-span-8'>
						<div className='flex-container'>
							<div className='num-edit-row'>
								<DNBTypography component='span' variant='compact-body'>
									The number of activities
								</DNBTypography>
								<DNBSelect<BucketCmp>
									value={operation}
									size='compact'
									onChange={(_, value) => {
										if (value !== null) handleCmpChange(value);
									}}>
									{Object.entries<string>(numericalOperationsActivity).map(
										([key, label]) => (
											<DNBSelectOption key={key} value={key}>
												{label}
											</DNBSelectOption>
										)
									)}
								</DNBSelect>
								{rangeConfig !== undefined &&
									(showFromNumerical || showToNumerical) && (
										<NumericalRange
											config={rangeConfig}
											showFrom={showFromNumerical}
											showTo={showToNumerical}
											onChange={handleNumericalRangeChange}
										/>
									)}
							</div>
						</div>
					</div>

					{scope.editing && (
						<div className='le-col-span-2'>
							<div className='flexContainerController'>
								<DNBButton
									variant='primary'
									size='compact'
									className={classnames({
										'button blue-button set': true,
										'disabled': !isValid(),
									})}
									disabled={!isValid()}
									onClick={handleClick}>
									SET
								</DNBButton>
								{isRulesBasedModelMode() && (
									<DNBButton
										variant='secondary'
										size='compact'
										className='button gray-button unset'
										onClick={handleClick}>
										UNSET
									</DNBButton>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</form>
	);
};

/**
 * TODO: Angular directive components can't use camelCase attributes...
 * Since we are migrating everything to React I feel that ideally
 * we should keep using camelCases for props, that is why I am creating this temporary
 * wrapper to pass the angular attributes to the React component...
 *
 * DONT use this component!
 * Use TreeActivityEdit if you are refactoring an angular component to React.
 * This component purpose is only to be use in the temporary
 * tree-activity-edit.component react2angular
 *
 * TODO: DELETE ME Once all instances of <query-activity-edit-directive />
 * are removed...
 */

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const TreeActivityEditWithAngularProps = ({
	vm,
	onScopeChange,
}: Record<string, any>): React.ReactElement => (
	<TreeActivityEdit scope={vm} onScopeChange={onScopeChange} />
);
