import {ECdpDataType} from 'atlas/connectors/EIF/schema/schemaTypes';
import {ObjectType} from '../../ConnectionType';
import {isDocumentTypeSupported} from '../../DocumentConst';
import {DocumentType} from '../../DocumentType';
import {IImportSourceResponse} from '../FE/ImportSourceResponse';
import {IGetSourcePerConnectionItem, toImportSourceById} from './GetSourceById';

interface ICdpAttribute extends ObjectType {
	fieldId: string;
	fieldName: string;
	fieldType: ECdpDataType;
	sourceSchemaType?: string;
}

interface ICdpAttributeMapping {
	sourceName: string;
	displayName: string;
	cdpAttributeName: string;
	isUniqueId: boolean;
	isMatchId: boolean;
	dateFormat: string;
	sourceSchemaType: DocumentType;
	timeFormat: string;
	timeZone: string;
}

interface ICdpMappingConfig {
	uniqueId?: string;
	matchIds?: string[];
	cdpAttributeMappings: ICdpAttributeMapping[];
}

interface IGetSourcePerConnection extends ObjectType {
	Errors?: string[];
	Success: boolean;
	Result: IGetSourcePerConnectionItem[];
}

const toImportSourceResponse = (
	response: IGetSourcePerConnection
): IImportSourceResponse[] => {
	const {Result = []} = response;
	return Result.filter(({schemaType, documentType}) =>
		isDocumentTypeSupported(schemaType, documentType)
	).map((item) => {
		return toImportSourceById(item);
	});
};

export {toImportSourceResponse};
export type {
	ICdpAttribute,
	ICdpAttributeMapping,
	ICdpMappingConfig,
	IGetSourcePerConnection,
};
