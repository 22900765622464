import {Attribute} from '../datacloud/query/advanced/tree/types';
import {Bkt, BucketRestriction} from '../datacloud/query/query.types';

const altLabel = 'Cnt';
const countText = 'VALUE';

enum RightSection {
	NEWLY = 'newly',
	PREVIOUSLY = 'previously',
}

enum DelimiterType {
	default = '',
	comma = 'Comma',
	tab = 'Tab',
	newline = 'Newline',
}
const delimiterLiterals = {
	[DelimiterType.default]: ' ',
	[DelimiterType.comma]: ',',
	[DelimiterType.tab]: '\t',
	[DelimiterType.newline]: '\n',
};

interface IChip extends Bkt {
	value?: Bkt | string;
	custom?: boolean;
	id?: keyof Bkt;
}

interface IChips {
	[displayName: string]: IChip;
}

interface IChipsController {
	query: string;
	customVals: boolean;
	addCustomValuesWithDelimiterCheck: (query: string) => void;
}

interface TreeContext {
	isActivity: boolean;
	enableQueryBuilderRedesign: boolean;
	tree: {
		bucketRestriction?: BucketRestriction;
	};
	item?: Attribute;
	ChipsController?: IChipsController;
}

interface IChipsComponent {
	id?: keyof IChip;
	displayname: keyof IChip;
	header?: string[];
	sortreverse?: boolean;
	sortid?: keyof IChip;
	queryscope?: keyof IChip;
	datasource: IChip[];
	showicon: boolean;
	inputdelimiter: string;
	allowcustomvalues: boolean;
	enableremoverow?: boolean;
	chipsposition?: string;
	callback: (args: IChip[]) => void;
	context: TreeContext;
	usepagination: boolean;
	chips?: IChips;
	showAll?: boolean;
	disabled?: boolean;
	initialvalue: IChip[] | {name: string};
	singleSelection: boolean;
	showloading?: boolean;
	icon: string;
	dontrandomizeid: boolean;
	placeholder: string;
	updatedContext?: (key: string, val: unknown) => void;
}

interface IChipsDialog {
	isDialogOpen: boolean;
	setIsDialogOpen: (open: boolean) => void;
}

interface IDialogUpdated {
	isDialogUpdated: boolean;
	setIsDialogUpdated: (updated: boolean) => void;
}

export type {
	IChip,
	IChips,
	IChipsComponent,
	IChipsDialog,
	TreeContext,
	IDialogUpdated,
	IChipsController,
};

export {delimiterLiterals, altLabel, DelimiterType, RightSection, countText};
