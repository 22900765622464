import NoticeService from '../notice/NoticeService';
import {IChipsContext, IChipsContextUpdate} from './ChipsContext';
import {IChip, IChips, TreeContext} from './ChipsTypes';

const shouldShowPagination = (
	usePagination: boolean,
	chips: IChips
): boolean => {
	return usePagination && Object.keys(chips).length >= 10;
};

const getDisplayName = (displayName: keyof IChip, item: IChip): string =>
	item?.[displayName]?.toString() || '';

const removeItem = (
	context: IChipsContext,
	setContext: IChipsContextUpdate,
	item: IChip
): void => {
	const {CallBack, ID, chips} = context;
	const {setChips} = setContext;
	if (Object.hasOwn(chips, item[ID] as string)) {
		delete chips[item[ID] as string];
	}
	setChips(chips);
	CallBack();
};

const addCustomValue = (id: keyof IChip, value: string | IChip): IChips => {
	if (!value) {
		return {};
	}

	if (typeof value === 'string') {
		return {
			[value]: {
				[id]: value as IChip,
			},
		};
	}
	return {
		[value[id] as string]: value,
	};
};

const getEmptyObject = (ID: keyof IChip, displayName: keyof IChip): IChip => {
	return {
		[displayName]: undefined,
		[ID]: undefined,
		value: undefined,
	};
};

/**
 * Currently, the backend supports no more than 10,000 attribute values per query restriction.
 *
 * There is also a limitation in one of the libraries used in Query Builder (QueryDSL) that causes an StackOverflowError
 * while trying to build a query with more that 2,000 activity attribute values.
 */
const getMaxNumChips = (treeContext: TreeContext): number => {
	if (!treeContext) {
		return 10_000;
	}

	if (treeContext.isActivity) {
		return 2_000;
	}

	const chipsOperations = ['CONTAINS', 'NOT_CONTAINS'];
	const {Cmp = 'IS_NULL'} = treeContext.tree?.bucketRestriction?.bkt || {
		Cmp: 'IS_NULL',
	};

	return chipsOperations.includes(Cmp) ? 250 : 10_000;
};

const limitCheck = (
	selectedItemsCount: number,
	treeContext: TreeContext,
	showWarning = false,
	needApply = false
): boolean => {
	if (selectedItemsCount >= getMaxNumChips(treeContext) || showWarning) {
		NoticeService.warning({
			title: 'Too Many Values',
			message: `Please use no more than ${getMaxNumChips(
				treeContext
			)} delimiter separated entries.`,
			needApply,
		});
		return false;
	}
	return true;
};

const chooseItem = (
	context: IChipsContext,
	setContext: IChipsContextUpdate,
	item: IChip,
	callCallback?: boolean
): void => {
	if (item) {
		const {ID, chips, treeContext, singleSelection, CallBack} = context;
		const {setChips, setQuery} = setContext;
		if (!limitCheck(Object.keys(chips).length, treeContext)) {
			return;
		}
		if (singleSelection) {
			setChips({});
		}
		const key = item[ID]?.toLocaleString();
		if (key !== undefined && chips[key] === undefined) {
			const newChips = {
				...chips,
				...{
					[key]: item,
				},
			};
			setChips(newChips);
			if (callCallback) {
				CallBack(newChips);
			}
		}
		if (singleSelection) {
			setQuery('');
		}
	}
};

/**
 * The backend may provide a list in datasource of valid possible values.
 *
 * If so, we validate that the incoming item matches one such value.
 *
 * If no datasource was provided, no matching validation is necessary, any string is valid.
 */
const getEmptyMatchingItem = (
	key: keyof IChip,
	item: IChip | string,
	dontrandomizeid = false
): IChip => {
	return {
		[key]: dontrandomizeid ? item : `${Math.random()}_${item}`,
		value: item,
		custom: true,
	};
};

const getSplitQuery = (
	queryString: string,
	bulkEntryDelimiterStringLiteral: string
): string[] => {
	return queryString
		.split(bulkEntryDelimiterStringLiteral)
		.map((s) => s.trim())
		.filter((s) => s.length > 0);
};

const getChipsCount = (chips: IChips): number => {
	return Object.keys(chips).length;
};
const getPotentialValuesCount = (datasource: IChip[]): number => {
	return datasource.length;
};

const clearBulkEntryModal = (
	setContext: IChipsContextUpdate,
	removeLastPastedValue = true
): void => {
	const {setLastPastedValue, setToBeAddedItems} = setContext;
	if (removeLastPastedValue) {
		setLastPastedValue('');
	}
	setToBeAddedItems({});
};

const addCustomValuesWithDelimiterCheck = (
	context: IChipsContext,
	setContext: IChipsContextUpdate,
	query: string,
	shouldCallCallback = false
): void => {
	const {
		chips,
		ID,
		inputDelimiter,
		dontrandomizeid,
		treeContext,
		displayName,
		CallBack,
	} = context;
	const {setChips} = setContext;
	// negative lookbehind not supported by safari, but this reverse-negative-lookahead workaround is supported. inspired by https://stackoverflow.com/a/11347100
	const negativeLookaheadSplit = (
		string: string,
		negativeLookaheadRegex: RegExp
	): string[] => {
		const reverseString = (string: string): string =>
			string.split('').reverse().join('');
		const reversed = reverseString(string);
		const reversedMatches = reversed.split(negativeLookaheadRegex);
		// we need to reverse the order of the strings in the array and the order of their chars
		return reversedMatches
			.reverse()
			.filter((str) => str)
			.map(reverseString);
	};

	const splitByUnescapedDelimitersRegExp = new RegExp(
		`${inputDelimiter}(?!([\\\\]))`,
		'g'
	);
	const split = inputDelimiter
		? negativeLookaheadSplit(query, splitByUnescapedDelimitersRegExp)
		: [query];

	if (!limitCheck(split.length + Object.keys(chips).length, treeContext)) {
		return;
	}
	let obj = getEmptyObject(ID, displayName);
	let newChips = {...chips};
	split.forEach((value) => {
		const replaceEscapedWithUnescapedDelimiterRegExp = new RegExp(
			`\\\\${inputDelimiter}`,
			'g'
		);

		const sanitizedValue = inputDelimiter
			? value
					.replace(replaceEscapedWithUnescapedDelimiterRegExp, inputDelimiter)
					.trim()
			: value.trim();

		const maybeRandomizedId = dontrandomizeid
			? sanitizedValue
			: `${Math.random()}_${sanitizedValue}`;

		obj = {
			...obj,
			[displayName]: sanitizedValue,
			value: sanitizedValue,
			custom: true,
			[ID]: maybeRandomizedId,
		};
		newChips = {
			...newChips,
			...addCustomValue(ID || displayName, obj),
		};
	});
	setChips(newChips);
	if (shouldCallCallback) {
		CallBack(newChips);
	}
};

export {
	removeItem,
	shouldShowPagination,
	getDisplayName,
	addCustomValue,
	chooseItem,
	getEmptyMatchingItem,
	getMaxNumChips,
	getSplitQuery,
	getChipsCount,
	getPotentialValuesCount,
	clearBulkEntryModal,
	addCustomValuesWithDelimiterCheck,
	limitCheck,
};
