import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {react2angular} from 'common/react-vendor';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {loadCategoryMetadata} from 'common/components/datacloud/datacloud.service.vanilla';
import {Banner} from 'common/app/services/BannerService';
import {
	getCube,
	getEntityList,
	getEventEnrichments,
} from 'common/stores/datacloud';
import {
	isTimeSeriesSegmentEnabled,
	isSegmentationV2Enabled,
	isQueryBuilderRefactorEnable,
} from 'common/stores/tenantConfig';
import {dispatchQueryPropertyValue} from 'common/stores/query';
import {setMultipleStates} from 'common/app/utilities/AdminPageUtility';
import {getPrefixedKey} from 'common/app/utilities/persistance';
import advancedTemplate from './advanced/advanced.component.html';
import Account360IframeTemplate from '../account360/account360.html';
import {Account360Path} from '../account360/account360.constants';
import './results/rebuild/index.ng';
import {queryBuilderExit} from './queryBuilder.utilities';
import {
	getSegmentByName,
	getSegmentDependenciesModelView,
} from '../segment/segment.queries';
import {getCategories} from '../datacloud.helpers';
import StateHistory from '../../../../atlas/app/StateHistory';
import {setBackButtonStates} from '../../../../atlas/app/navigation/header/back/BackButton';
import {getMyDataState} from '../../../../atlas/app/navigation/sidebar/sidebar.helpers';
import {getEntitiesCounts, resetRestrictions} from './query.helpers';
import {API as JourneyAPI} from '../../../../atlas/app/JourneyStage/ApiCalls';
import {
	API,
	getJourneyStageOverview,
} from '../../../../atlas/app/segmentation/SegmentDashboard/ApiCalls';
import {
	SubstageAccount,
	SubstageContact,
} from '../../../../atlas/app/segmentation/JourneyEntity/SubstageEntity';
import {StageSummary} from './advanced/stage/StageSummary';
import {
	ACCOUNTS_PAGE_VIEW_KEY,
	CONTACTS_PAGE_VIEW_KEY,
} from './results/rebuild';

angular.module('common.datacloud.query').config(($stateProvider) => {
	$stateProvider.states = setMultipleStates;

	const enableQueryBuilderRefactor = isQueryBuilderRefactorEnable();
	const showSegmentationV2 = isSegmentationV2Enabled();
	const advancedQueryOption = enableQueryBuilderRefactor && showSegmentationV2
		? {
			component: 'advancedQuery',
		 }
		: {
				controller: 'AdvancedQueryCtrl',
				controllerAs: 'vm',
				template: `<div ng-if="vm.isBuyerJourneyEnabled" class="query-button-group">
						<query-button-group><query-button-group />
					</div>
					${advancedTemplate}
					`,
		 };

	const account360Routes = [
		'home.segment.accounts.contacts',
		'home.segment.journeyaccounts.contacts',
	];

		$stateProvider
			.state('home.segment.explorer.builder', {
				url: '/builder',
				// Please also update ISegmentCreateParam when new param is added.
				params: {
					pageIcon: 'ico-analysis',
					pageTitle: 'Query Builder',
					subSegment: '',
					customParentSegmentName: '',
					ssviCardName: '',
					newAccountRestriction: null,
					isTAM: false,
				},
				onEnter: [
					'$state',
					'$stateParams',
					async ($state, $stateParams) => {
						if (
							!RBAC.hasAccess(RBACInterface.ATTRIBUTES_TAB, RBACActions.VIEW) &&
							!RBAC.hasAccess(RBACInterface.SEGMENTS, RBACActions.VIEW)
						) {
							$state.go(
								getMyDataState(),
								{
									...$stateParams,
									pageTitle: 'Accounts',
								},
								{
									reload: true,
								}
							);
						}
						const name = $stateParams.segment;
						const lastFrom = StateHistory.lastTo()
							? StateHistory.lastFrom().name
							: '';

						if (lastFrom === 'home.playlistchannels') {
							setBackButtonStates({
								backLabel: 'Campaign Playbook',
								backName: 'home.playlistchannels',
								currentState: 'home.segment.explorer.builder',
								hide: false,
							});
						} else {
							const isCreateState = name === 'Create';

							if (!isCreateState) {
								await getSegmentByName(name).then((result) => {
									setBackButtonStates({
										backLabel: result.display_name,
										backName: 'home.segments_new',
										currentState: 'home.segment.explorer.builder',
										hide: false,
									});
								});
							}
						}

						if (
							lastFrom !== 'home.segment.explorer.attributes' &&
							name &&
							name !== 'Create'
						) {
							getSegmentDependenciesModelView(name);
						}
						dispatchQueryPropertyValue('cancelUpdateBucketCalls', false);
					},
				],
				onExit: ($state, $q) => {
					Banner.reset();
					dispatchQueryPropertyValue('cancelUpdateBucketCalls', true);
					const queryBuilderConfig = {
						$state,
					};
					const deferred = $q.defer();
					queryBuilderExit(queryBuilderConfig).then((exit) => {
						if (exit) {
							deferred.resolve(true);
						} else {
							deferred.reject('user cancelled action');
							HideSpinner();
						}
					});
					return deferred.promise;
				},
				resolve: {
					Cube: [
						async () => {
							return getCube();
						},
					],
					RatingEngineModel: [() => null],
					CurrentRatingEngine: [() => null],
					CategoryMetadata: [() => loadCategoryMetadata()],
					EnrichmentTopAttributes: [
						async function () {
							return getCategories();
						},
					],

					EventEnrichmentTopAttributes: [
						'$q',
						function ($q) {
							const showTimeSeries = isTimeSeriesSegmentEnabled();
							if (showTimeSeries) {
								const deferred = $q.defer();

								getEventEnrichments()
									.then(function (result) {
										deferred.resolve(result || {});
									})
									.catch(() => {});

								return deferred.promise;
							}
						},
					],
					EntityList: [
						'$q',
						function ($q) {
							const showSegmentationV2 = isSegmentationV2Enabled();
							if (showSegmentationV2) {
								const deferred = $q.defer();

								getEntityList()
									.then(function (result) {
										deferred.resolve(result || {});
									})
									.catch(() => {});

								return deferred.promise;
							}
						},
					],
				},
				views: {
					'main@': advancedQueryOption,
					'header.back@': 'backNav',
				},
			})
			.state('home.segment.accounts', {
				url: '/accounts',
				params: {
					pageIcon: 'ico-analysis',
					pageTitle: 'Accounts',
				},
				onEnter: async ($state, $stateParams) => {
					if (!RBAC.hasAccess(RBACInterface.MY_DATA, RBACActions.VIEW)) {
						$state.go('home');
					}

					const isCreateState = $stateParams.segment === 'Create';

					if (!isCreateState) {
						window.ShowSpinner();

						await getSegmentByName($stateParams.segment).then((result) => {
							setBackButtonStates({
								backLabel: result.display_name,
								backName: 'home.segments_new',
								currentState: 'home.segment.accounts',
								hide: false,
							});
						});
					}
				},
				onExit: ($state, $q) => {
					sessionStorage.removeItem(getPrefixedKey(ACCOUNTS_PAGE_VIEW_KEY));
					Banner.reset();
					getEntitiesCounts();
					dispatchQueryPropertyValue('cancelUpdateBucketCalls', true);
					const queryBuilderConfig = {
						$state,
					};
					const deferred = $q.defer();
					queryBuilderExit(queryBuilderConfig).then((exit) => {
						if (exit) {
							deferred.resolve(true);
						} else {
							deferred.reject('user cancelled action');
							HideSpinner();
						}
					});
					return deferred.promise;
				},
				views: {
					'main@': {
						template: `<results-page entity-type="'accounts'"></results-page>`,
					},
					'header.back@': 'backNav',
				},
			})
			.state('home.segment.contacts', {
				url: '/contacts',
				params: {
					pageIcon: 'ico-analysis',
					pageTitle: 'Contacts',
				},
				onEnter: async ($state, $stateParams) => {
					if (!RBAC.hasAccess(RBACInterface.MY_DATA, RBACActions.VIEW)) {
						$state.go('home');
					}

					const isCreateState = $stateParams.segment === 'Create';

					if (!isCreateState) {
						window.ShowSpinner();

						await getSegmentByName($stateParams.segment).then((result) => {
							setBackButtonStates({
								backLabel: result.display_name,
								backName: 'home.segments_new',
								currentState: 'home.segment.contacts',
								hide: false,
							});
						});
					}
				},
				onExit: ($state, $q) => {
					sessionStorage.removeItem(getPrefixedKey(CONTACTS_PAGE_VIEW_KEY));
					Banner.reset();
					getEntitiesCounts();
					const queryBuilderConfig = {
						$state,
					};
					const deferred = $q.defer();
					queryBuilderExit(queryBuilderConfig).then((exit) => {
						if (exit) {
							deferred.resolve(true);
						} else {
							deferred.reject('user cancelled action');
							HideSpinner();
						}
					});
					return deferred.promise;
				},
				views: {
					'main@': {
						template: `<results-page entity-type="'contacts'"></results-page>`,
					},
					'header.back@': 'backNav',
				},
			})
			.states(account360Routes, {
				url: `${Account360Path}?a360_accountId&a360_contactId&a360_campaignLaunchId&a360_source`,
				params: {
					pageIcon: '',
					pageTitle: 'Account 360',
				},
				onExit: [
					'$state',
					'$q',
					($state, $q) => {
						Banner.reset();
						const queryBuilderConfig = {
							$state,
						};
						const deferred = $q.defer();
						queryBuilderExit(queryBuilderConfig).then((exit) => {
							if (exit) {
								deferred.resolve(true);
							} else {
								deferred.reject('user cancelled action');
								HideSpinner();
							}
						});
						return deferred.promise;
					},
				],
				views: {
					'subsummary@': null,
					'main@': {
						controllerAs: 'vm',
						controller: 'AC360Ctrl',
						template: Account360IframeTemplate,
					},
				},
			})
			.state('home.segment.stage', {
				url: '/stage/:stageId',
				params: {
					stageId: '',
				},
				resolve: {
					JourneyStage: [
						'$stateParams',
						async ($stateParams) => {
							const {stageId} = $stateParams;
							if (!stageId) return;
							const data = await JourneyAPI.getJourneyStage(stageId);
							return data;
						},
					],
					JourneyStageOverview: [
						'$stateParams',
						async ($stateParams) => {
							const {segment, stageId} = $stateParams;
							if (!stageId) return;
							const data = await getJourneyStageOverview(segment, stageId);
							return data;
						},
					],
				},
				redirectTo: 'home.segment.stage.substage',
			})
			.state('home.segment.stage.substage', {
				url: '/substage/:subStageId',
				params: {
					subStageId: 'Create',
					subStageName: '',
				},
				resolve: {
					Cube: [
						async () => {
							return getCube();
						},
					],
					RatingEngineModel: [() => null],
					CurrentRatingEngine: [() => null],
					CategoryMetadata: [() => loadCategoryMetadata()],
					EnrichmentTopAttributes: [
						async function () {
							return getCategories();
						},
					],
					EventEnrichmentTopAttributes: [
						'$q',
						function ($q) {
							const showTimeSeries = isTimeSeriesSegmentEnabled();
							if (showTimeSeries) {
								const deferred = $q.defer();

								getEventEnrichments()
									.then(function (result) {
										deferred.resolve(result || {});
									})
									.catch(() => {});

								return deferred.promise;
							}
						},
					],
					EntityList: [
						'$q',
						function ($q) {
							const showSegmentationV2 = isSegmentationV2Enabled();
							if (showSegmentationV2) {
								const deferred = $q.defer();

								getEntityList()
									.then(function (result) {
										deferred.resolve(result || {});
									})
									.catch(() => {});

								return deferred.promise;
							}
						},
					],
					SubJourneyStage: [
						'$stateParams',
						async ($stateParams) => {
							const {subStageId} = $stateParams;
							if (!subStageId || subStageId === 'Create') {
								resetRestrictions();
								return;
							}
							const {data} = await API.getSubJourneyStage(subStageId);
							const {accountRestriction, contactRestriction} = data;
							resetRestrictions({
								account_restriction:
									accountRestriction && JSON.parse(accountRestriction),
								contact_restriction:
									contactRestriction && JSON.parse(contactRestriction),
							});
							return data;
						},
					],
				},
				views: {
					'summary@': 'stageSummary',
					'main@': {
						controller(
							$controller,
							$rootScope,
							$state,
							$stateParams,
							$timeout,
							Cube,
							RatingEngineModel,
							CurrentRatingEngine,
							CategoryMetadata,
							EnrichmentTopAttributes,
							EntityList,
							JourneyStage,
							SubJourneyStage
						) {
							const vm = this;
							vm.JourneyStage = JourneyStage;
							vm.SubJourneyStage = SubJourneyStage;
							vm.baseController = $controller('AdvancedQueryCtrl', {
								$rootScope,
								$state,
								$stateParams,
								$timeout,
								Cube,
								RatingEngineModel,
								CurrentRatingEngine,
								CategoryMetadata,
								EnrichmentTopAttributes,
								EntityList,
							});
							angular.extend(vm, vm.baseController);
						},
						controllerAs: 'vm',
						template: `<div ng-if="vm.isBuyerJourneyEnabled">
											<substage-button-group stage="vm.JourneyStage" sub-stage="vm.SubJourneyStage"><substage-button-group />
										</div>
										<div><parent-stage-container stage="vm.JourneyStage"></parent-stage-container></div>
										${advancedTemplate}
										`,
					},
				},
			})
			.state('home.segment.stage.substage.accounts', {
				url: '/accounts/?{stages:string}',
				params: {
					stages: '',
				},
				views: {
					'summary@': 'stageSummary',
					'main@': 'substageAccount',
				},
			})
			.state('home.segment.stage.substage.contacts', {
				url: '/contacts/?{stages:string}',
				params: {
					stages: '',
				},
				views: {
					'summary@': 'stageSummary',
					'main@': 'substageContact',
				},
			});
	})
	.component(
		'stageSummary',
		react2angular(StageSummary, [], ['$state', '$scope', '$stateParams'])
	)
	.component(
		'substageAccount',
		react2angular(SubstageAccount, [], ['$stateParams'])
	)
	.component(
		'substageContact',
		react2angular(SubstageContact, [], ['$stateParams'])
	);
