import React from 'common/react-vendor';
import {IChipsComponent} from './ChipsTypes';
import {ChipsTextContainer} from './ChipsTextContainer';
import {ChipsContextProvider} from './ChipsContext';
import {ChipsControllerContainer} from './ChipsControllerContainer';
import styles from './Chips.module.scss';

const ChipsComponent = (scope: IChipsComponent): React.ReactElement => {
	return (
		<ChipsContextProvider scope={scope}>
			<div className={styles.chipsQueryContainer}>
				<ChipsControllerContainer />
				<ChipsTextContainer />
			</div>
		</ChipsContextProvider>
	);
};

export {ChipsComponent};
