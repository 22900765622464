import {clone} from 'lodash';
import {
	CounterMap,
	IAttributeValues,
} from 'atlas/modelsOld/modals/AddAttributeModal/Helper/CounterMap';
import {Bkt, Restriction} from 'common/components/datacloud/query/query.types';
import {
	IModalAttribute,
	IModalAttributeMap,
	IModalCategory,
	IModalCategoryMap,
} from '../AddAttributesModalConst';

/**
 * Modal controller interface.
 * @param getTitle Get modal title
 * @param searchPlaceHolder Search placeholder
 * @param getCounterMap Get attribute select counter map
 * @param getSelectedAttributes Get attribute selected key list
 * @param getSelectedCountText Get attribute selected count text
 * @param isAttributeDisabled True if attribute is disabled
 * @param toAttributeKey Get key of attribute
 * @param toRestriction Get restriction from attribute key
 * @param needUpdateRestrictionCount True if update restriction count is needed
 * @param name Controller name
 */
interface IModalController {
	getTitle: () => string;
	searchPlaceHolder: () => string;
	getCounterMap: () => CounterMap;
	getSelectedAttributes: () => IAttributeValues[];
	getSelectedCountText: () => string;
	isAttributeDisabled: (id: string) => boolean;
	toAttributeKey: (restriction: Restriction) => string;
	toRestriction: (
		id: string,
		inRulesBasedModal: boolean,
		inner?: Bkt
	) => Restriction;
	needUpdateRestrictionCount: () => boolean;
	name: () => string;
}

const searchPlaceHolder = (name: string): string => {
	return `Search ${name}s`;
};

const getSelectedCountText = (name: string, selectCount: number): string => {
	const names = selectCount > 1 ? `${name}s` : name;
	return `${selectCount} ${names} selected`;
};

const toModalAttributeMap = (
	modalAttributeList: IModalAttribute[]
): IModalAttributeMap => {
	const newModalAttributeMap: IModalAttributeMap = {};
	modalAttributeList.forEach((modalAttribute) => {
		const {AttrName} = modalAttribute;
		newModalAttributeMap[AttrName] = modalAttribute;
	});
	return newModalAttributeMap;
};

const toModalCategoryList = (
	modalAttributeList: IModalAttribute[]
): IModalCategory[] => {
	const ModalCategorySorter = (
		{name: lhs}: IModalCategory,
		{name: rhs}: IModalCategory
	): number => {
		return lhs.localeCompare(rhs);
	};
	const modalCategoryMap = modalAttributeList.reduce((pre, modalAttribute) => {
		const categories = {...pre};
		const {Category, Subcategory, AttrName} = modalAttribute;
		if (!categories.hasOwnProperty(Category)) {
			categories[Category] = {
				name: Category,
				subCategories: [],
				showSubCategories: false,
				colIds: {},
				filterColIds: {},
				expanded: false,
				curExpendedSub: Subcategory,
			};
		}
		const curSubcategories = categories[Category]!.subCategories;
		if (!curSubcategories.includes(Subcategory)) {
			curSubcategories.push(Subcategory);
		}
		categories[Category]!.colIds = {
			...categories[Category]!.colIds,
			[Subcategory]: categories[Category]!.colIds[Subcategory] || [],
		};
		categories[Category]!.colIds[Subcategory]?.push(AttrName);
		categories[Category]!.filterColIds = clone(categories[Category]!.colIds);
		if (curSubcategories && curSubcategories.length > 1) {
			categories[Category]!.showSubCategories = true;
		}
		return categories;
	}, {} as IModalCategoryMap);
	return Object.values(modalCategoryMap).sort(ModalCategorySorter);
};

export type {IModalController};
export {
	searchPlaceHolder,
	getSelectedCountText,
	toModalAttributeMap,
	toModalCategoryList,
};
