import React from 'common/react-vendor';
import {
	FetcherQueryTreeItemEditContext,
	IQueryTreeItemEditContext,
} from '../context/QueryTreeItemEditContext';

const useQueryTreeItemEdit = (): IQueryTreeItemEditContext => {
	const {data, setData} = React.useContext(FetcherQueryTreeItemEditContext);
	return {data, setData};
};

export {useQueryTreeItemEdit};
