import React from 'common/react-vendor';
import {GridColDef} from 'common/node_modules/@mui/x-data-grid';
import {DNBTooltip, GridRenderCellParams} from 'common/dnb-uux-vendor';
import storageUtil from 'common/widgets/utilities/app-storage.utility';
import AttributeOverview from './components/AttributeOverview';
import {renderRequiredText} from './utils';
import {MAX_LENGTH_DIC} from './consts';
import {useReorder} from '../Hook/useReorder';
import {IOption} from '../Data/FilterOptionType';
import {ICommonMultipleSelectGroup} from '../AddConnection/Component/Common/CommonMultipleSelectGroup';
import {ECdpDataType} from './schemaTypes';

export const columns: Array<GridColDef> = [
	{
		headerName: 'Attributes',
		field: 'screenName',
		sortable: true,
		width: 200,
		renderCell: ({row, value}) => (
			<DNBTooltip
				arrow
				placement='right'
				content={<AttributeOverview attribute={row} />}>
				<span>{row.displayName || value}</span>
			</DNBTooltip>
		),
	},
	{
		headerName: 'Type',
		field: 'cdpDataType',
	},
	{
		headerName: 'Time Zone',
		field: 'timeZone',
	},
	{
		headerName: 'Date Format',
		field: 'dateFormat',
		width: 150,
	},
	{
		headerName: 'Min Length',
		field: 'cdpMinLength',
		type: 'number',
	},
	{
		headerName: 'Max Length',
		field: 'cdpMaxLength',
		type: 'number',
		valueGetter: ({row, value}: GridRenderCellParams): number => {
			return value || MAX_LENGTH_DIC[row.cdpDataType as ECdpDataType];
		},
	},
	{
		headerName: 'Required',
		field: 'nullable',
		valueFormatter: ({value}) => renderRequiredText(value),
	},
	{
		headerName: 'Description',
		field: 'description',
		flex: 1,
		maxWidth: 200,
	},
];

const defaultColumnOptions = columns.reduce(
	(prev, {headerName, field}) => ({
		...prev,
		[field]: {value: field, label: headerName, checked: true},
	}),
	{}
);
const defaultColumnSequence = columns.map(({field}) => field);
const storageNamespace = 'customizedCDPSchemaColumns@v2';

export const useCustomizedColumns = (): [
	Array<GridColDef>,
	ICommonMultipleSelectGroup
] => {
	const storedOptionList = storageUtil.get(storageNamespace);
	const [optionList, onOptionChanged] = React.useState<Record<string, IOption>>(
		() => storedOptionList || defaultColumnOptions
	);
	const [columnSequence, setColumnSequence] = React.useState<string[]>(() =>
		storedOptionList ? Object.keys(storedOptionList) : defaultColumnSequence
	);
	const customizedColumns = React.useMemo(
		() =>
			columns
				.filter(({field}) => optionList[field]?.checked)
				.sort(
					(prev, next) =>
						columnSequence.indexOf(prev.field) -
						columnSequence.indexOf(next.field)
				),
		[optionList, columnSequence]
	);

	const {onDragStart, onDragOver, onDragEnd} = useReorder({
		orderList: columnSequence,
		onReorder: (newSequence) => {
			setColumnSequence(newSequence);
			onOptionChanged((prevOptionList) =>
				newSequence.reduce(
					(prev, field) => ({...prev, [field]: prevOptionList[field]}),
					{}
				)
			);
		},
	});

	React.useEffect(() => {
		storageUtil.set(storageNamespace, optionList);
	}, [optionList]);

	return [
		customizedColumns,
		{
			optionList,
			onOptionChanged,
			onDragStart,
			onDragEnd,
			onDragOver,
		},
	];
};
