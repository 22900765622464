import {axios} from 'common/network.vendor';
import {Query} from 'common/components/datacloud/datacloud.types';
import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import {isCalculateKnownContactEnabled} from 'common/stores/tenantConfig';
// TODO: remove 'deleteAngularHashKey' when migration to React is complete
import {
	deleteAngularHashKey,
	sanitizeSegment,
} from 'common/components/datacloud/segment/segment.helpers';
import {getSessionSegmentState} from 'common/components/datacloud/query/results/rebuild/segment.helpers';
import {updatePublicRefresh} from 'common/stores/query';
import {getCurrentSegmentName} from './query.helpers';

interface DataCollectionStatusResponse {
	AccountCount: number;
	ActivityStreamLastRefresh: Record<string, number>;
	ApsRollingPeriod: string;
	activityPartitionKeyMigrated?: boolean;
	builtProductTxnS3CacheStore: boolean;
	ContactCount: number;
	CreationTime: number;
	DataCloudBuildNumber: string;
	DateMap: Record<string, number>;
	DimensionMetadataSignature: string;
	EvaluationDate: string;
	EvaluationDateMap: Record<string, number>;
	MinTxnDate: number;
	MaxTxnDate: number;
	OrphanContactCount: number;
	OrphanTransactionCount: number;
	ProductCount: number;
	pathPatternMap: Record<string, Array<string>>;
	RedshiftPartition: string;
	ServingStoreVersion: number;
	TopVisitedUrls: Record<string, number>;
	TransactionCount: number;
	timelineRebuildFlag: boolean;
	timelineVersionMap: Record<string, string>;
	UnmatchedAccountCount: number;
	UpdateTime: number;
}

interface EntitiesCountsResponse {
	'Account': number;
	'Contact': number;
	'Known Contact': number;
	'UnKnown Contact': number;
}

const getSanitizedQuery = (query: Query): Query =>
	deleteAngularHashKey<Query>(sanitizeSegment(query));

const getDataByQuery = async (
	resourceType = 'accounts',
	query: Query
): Promise<Query> => {
	const sanitizedQuery = getSanitizedQuery(query);

	return axiosInstance
		.post(`/pls/${resourceType}/data`, sanitizedQuery)
		.then((response) => response.data);
};

const getCollectionStatus = async (): Promise<DataCollectionStatusResponse> =>
	axiosInstance
		.get('/pls/datacollection/status')
		.then((response) => response.data);

// #region getEntitiesCounts
let source = new AbortController();

const getSegmentListCount = async (
	isAccount: boolean
): Promise<EntitiesCountsResponse> => {
	const segmentName = getCurrentSegmentName();

	if (!segmentName) {
		return Promise.resolve({
			'Account': 0,
			'Contact': 0,
			'Known Contact': 0,
			'UnKnown Contact': 0,
		});
	}

	source.abort();
	source = new AbortController();

	return axios
		.get<number>(`/pls/${isAccount ? 'accounts' : 'contacts'}/list/count`, {
			params: {
				segmentName,
			},
			signal: source.signal,
		})
		.then((response) => ({
			'Account': isAccount ? response?.data : 0,
			'Contact': isAccount ? 0 : response?.data,
			'Known Contact': 0,
			'UnKnown Contact': 0,
		}));
};
// https://developer.mozilla.org/en-US/docs/Web/API/AbortController
let controller = new AbortController();
const getEntitiesCounts = async (
	query: Query,
	refresh = true
): Promise<EntitiesCountsResponse> => {
	refresh && updatePublicRefresh();
	const sessionSegmentState = getSessionSegmentState();

	if (
		sessionSegmentState?.isCompanyList ||
		sessionSegmentState?.isContactList
	) {
		return getSegmentListCount(Boolean(sessionSegmentState.isCompanyList));
	}

	const url = `/pls/entities/counts${
		isCalculateKnownContactEnabled() ? '/v2' : ''
	}`;
	const sanitizedQuery = getSanitizedQuery(query);
	// Abort previous request
	controller.abort();
	// Create a new instance of the abort controller that will be attached to the new request, and would be aborted in case that called again
	controller = new AbortController();
	const config = {
		signal: controller.signal,
	};
	return axios.post(url, sanitizedQuery, config).then((response) => {
		return response.data;
	});
};
// #endregion getEntitiesCounts

const getCountByQuery = async (
	resourceType = 'accounts',
	query: Query
): Promise<number> => {
	const sanitizedQuery = getSanitizedQuery(query);

	return axiosInstance.post(`/pls/${resourceType}/count`, sanitizedQuery).then(
		(response) => {
			return response.data;
		},
		() => {
			return 0;
		}
	);
};

export {
	getSanitizedQuery,
	getDataByQuery,
	getCollectionStatus,
	getEntitiesCounts,
	getCountByQuery,
};

export type {DataCollectionStatusResponse, EntitiesCountsResponse};
