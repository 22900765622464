import React from 'common/react-vendor';
import {
	IQueryTreeContext,
	IQueryTreeContextUpdate,
	QueryTreeContext,
	QueryTreeContextUpdate,
} from '../context/QueryTreeContext';

const {useContext} = React;

interface IUseQueryTree {
	context: IQueryTreeContext;
	setContext: IQueryTreeContextUpdate;
}

const useQueryTree = (): IUseQueryTree => {
	const context = useContext<IQueryTreeContext>(QueryTreeContext);
	const setContext = useContext<IQueryTreeContextUpdate>(
		QueryTreeContextUpdate
	);
	return {context, setContext};
};

export {useQueryTree};
export type {IUseQueryTree};
