import React from 'common/react-vendor';
import {
	AddIcon,
	DeleteOutlinedIcon,
	DNBButton,
	DNBDialog,
	DNBIconButton,
	DNBTextField,
	DNBTypography,
} from 'common/dnb-uux-vendor';
import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import trim from 'lodash/trim';
import uniq from 'lodash/uniq';
import styles from './CustomCDPSchemaDialog.module.scss';
import {CustomDocumentTypeGroup, DocumentType} from '../../Data/DocumentType';
import {DocumentTypeUIConfig} from '../../Data/DocumentTypeUIConfig';
import {useCustomSchema} from '../context/CustomSchemaContext';

interface ICustomCDPSchemaDialog {
	customType: CustomDocumentTypeGroup;
	open: boolean;
	onClose: () => void;
	title?: string;
}

const CustomSchemaConfig = {
	[CustomDocumentTypeGroup.event]: {
		description:
			'Custom Activities are used to store time series activity data related to your accounts or contacts.',
	},
	[CustomDocumentTypeGroup.object]: {
		description:
			'Custom Objects can be used to store custom data related to your accounts or contacts.',
	},
};

const IllegalReg = /[^0-9a-zA-Z ]/g;
const lowerCaseFn = (str: string): string => str.toLowerCase();
export const MAX_CUSTOM_COUNT = 5;
// Custom name cannot be entities or their displayname, case-insensitive.
const CONST_NAMES = uniq(
	[
		...Object.values(DocumentType),
		...Object.values(DocumentTypeUIConfig)
			.map(({title}) => title)
			.filter(Boolean),
	].map(lowerCaseFn)
);

const inputStyles = {width: '249px', margin: '0 4px 4px 0'};

export default function CustomCDPSchemaDialog({
	title,
	customType,
	open,
	onClose,
}: ICustomCDPSchemaDialog): JSX.Element {
	const isCustomEvent = customType === CustomDocumentTypeGroup.event;
	const {customEvents, customObjects, setCustomEvents, setCustomObjects} =
		useCustomSchema();
	const customizedNames = isCustomEvent ? customEvents : customObjects;
	const setCustomizedNames = isCustomEvent ? setCustomEvents : setCustomObjects;
	const [names, setNames] = React.useState(['']);
	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
	const [deletingName, setDeletingName] = React.useState('');
	const [isDeleting, setIsDeleting] = React.useState(false);
	const customNamesLength = customizedNames.length + names.length;
	const lowerCasedExistingNames = React.useMemo(
		() => [...customEvents, ...customObjects].map(lowerCaseFn),
		[customEvents, customObjects]
	);
	const lowerCasedNames = names.map(lowerCaseFn);
	const customTypeText = isCustomEvent ? 'Activity' : 'Object';
	const lowerCasedCustomTypeText = customTypeText.toLowerCase();

	const checkDuplicateNames = (name: string, index: number): boolean =>
		[...CONST_NAMES, ...lowerCasedExistingNames].includes(name) ||
		lowerCasedNames.some((value, i) => value && value === name && index !== i);

	const isSaveDisabled = (namesToUse = lowerCasedNames): boolean => {
		if (isSubmitting || names.every((value) => !value)) {
			return true;
		}
		const allNames = [
			...CONST_NAMES,
			...lowerCasedExistingNames,
			...namesToUse,
		];
		return new Set(allNames).size !== allNames.length;
	};

	const onDeleteButtonClick = (name: string): void => {
		setDeletingName(name);
		setDeleteDialogOpen(true);
	};

	React.useEffect(() => {
		if (customizedNames.length === MAX_CUSTOM_COUNT && names.length) {
			setNames([]);
		}
	}, [customizedNames.length, names.length]);

	return (
		<DNBDialog
			title={title || 'How would you like to name your data?'}
			secondaryCTALabel='Cancel'
			primaryCTALabel='Save'
			size='compact'
			hasCloseButton
			open={open}
			disabledPrimaryCTA={isSaveDisabled()}
			onClose={onClose}
			primaryCTA={async () => {
				const trimmedNames = names.map(trim).filter(Boolean);
				if (isSaveDisabled(trimmedNames.map(lowerCaseFn))) {
					setNames(trimmedNames);
				} else if (!isSubmitting) {
					setIsSubmitting(true);
					const response = await axiosInstance.post(
						`/pls/cdp/newCustom${customType}s`,
						trimmedNames
					);
					setIsSubmitting(false);
					if (response.status === 200) {
						setCustomizedNames((prevNames) => [...prevNames, ...trimmedNames]);
						setNames(customNamesLength >= MAX_CUSTOM_COUNT ? [] : ['']);
					}
					onClose();
				}
			}}
			secondaryCTA={onClose}>
			<div className={styles.customSchema}>
				<DNBDialog
					open={deleteDialogOpen}
					title={`Delete ${deletingName}`}
					variant='destructive'
					onClose={() => setDeleteDialogOpen(false)}
					disabledPrimaryCTA={isDeleting}
					secondaryCTALabel='Cancel'
					secondaryCTA={() => setDeleteDialogOpen(false)}
					primaryCTALabel='Yes, Delete it'
					primaryCTA={async (): Promise<void> => {
						setIsDeleting(true);
						const response = await axiosInstance
							.post(
								`/pls/cdp/deleteCustom${isCustomEvent ? 'Events' : 'Objects'}`,
								[deletingName]
							)
							.catch((e) => e.response);
						if (
							response.status === 200 &&
							(response.data.Success ||
								response.data.UIAction?.status === 'Success')
						) {
							setCustomizedNames((prevNames) =>
								prevNames.filter((name) => name !== deletingName)
							);
						}
						setIsDeleting(false);
						setDeleteDialogOpen(false);
					}}>
					{`Deleting a Custom ${customTypeText} will also delete all data imported for it. Do you still want to delete this ${lowerCasedCustomTypeText}?`}
				</DNBDialog>
				<DNBTypography variant='compact-medium'>
					{CustomSchemaConfig[customType].description}
				</DNBTypography>
				<ul>
					{customizedNames.map((name) => (
						<li key={`customizedName-${name}`}>
							<DNBTextField
								variant='outlined'
								sx={inputStyles}
								id={`customizedName-${name}`}
								size='compact'
								value={name}
								disabled
							/>
							<DNBIconButton
								size='compact'
								onClick={() => onDeleteButtonClick(name)}>
								<DeleteOutlinedIcon />
							</DNBIconButton>
						</li>
					))}
					{names.map((name, index) => (
						<li
							// eslint-disable-next-line react/no-array-index-key
							key={index}>
							<DNBTextField
								variant='outlined'
								placeholder={`Custom ${customTypeText} Name`}
								sx={inputStyles}
								id={`customName-${index}`}
								size='compact'
								autoFocus={index === names.length - 1}
								helperText={
									checkDuplicateNames(name.toLowerCase(), index) ? (
										<DNBTypography
											variant='caption'
											sx={{
												color: (theme) => theme.colors.ColorNotificationError,
											}}>
											Name already exist, please change another name
										</DNBTypography>
									) : undefined
								}
								value={name}
								disabled={customNamesLength > MAX_CUSTOM_COUNT}
								onChange={(e) => {
									names[index] = e.target.value.replace(IllegalReg, '');
									setNames([...names]);
								}}
							/>
							<DNBIconButton
								size='compact'
								onClick={() => {
									names.splice(index, 1);
									setNames([...names]);
								}}>
								<DeleteOutlinedIcon />
							</DNBIconButton>
						</li>
					))}
				</ul>
				<DNBTypography variant='caption' className={styles.caption}>
					{`${customNamesLength}/${MAX_CUSTOM_COUNT} custom ${
						isCustomEvent ? 'activities' : 'objects'
					} have been created`}
				</DNBTypography>
				<DNBButton
					variant='text'
					startIcon={<AddIcon />}
					disabled={customNamesLength >= MAX_CUSTOM_COUNT}
					className={styles.noDecoration}
					onClick={() => setNames([...names, ''])}>
					{`Another ${customTypeText}`}
				</DNBButton>
			</div>
		</DNBDialog>
	);
}
