import React from 'common/react-vendor';
import {DNBCard, DNBTypography} from 'common/dnb-uux-vendor';
import {isEqual} from 'lodash';
import classNames from 'classnames';
import {Operator} from 'atlas/data/RestrictionConst';
import {isSegmentationMode} from 'common/stores/query';
import {IQueryTreeContext} from './context/QueryTreeContext';
import {
	TreeActivityEdit,
	TreeScope,
} from './edit/TreeActivityEdit/TreeActivityEdit';
import {useQueryTree} from './hook/useQueryTree';
import {UpdateCount, getBucketLabel} from '../AdvancedQuery.helper';
import {
	mouseOver,
	mouseMove,
	mouseOverHighlight,
	getOperationLabel,
	getOperationValue,
	showTo,
} from './QueryTree.helpers';
import {useAdvancedQuery} from '../hook/useAdvancedQuery';
import {defaultRangeConfig} from './edit/TreeActivityEdit/treeActivityEdit.constants';

const QueryTreeActivity = (): React.ReactElement => {
	const {context: queryTree} = useQueryTree();
	const {context: advancedQuery, setContext: setAdvancedQuery} =
		useAdvancedQuery();
	const {
		tree,
		root,
		activityRestriction,
		numerical_operations,
		parent,
		entity,
		two_inputs,
		updateTree,
	} = queryTree;
	const [editing, setEditing] = React.useState(false);
	const draggedItem = root.draggedItem?.current;
	const draggedItemTree = draggedItem?.tree || draggedItem?.tree;
	const droppedItem = root.droppedItem?.current as IQueryTreeContext;
	const droppedItemTree = droppedItem?.tree || droppedItem?.tree;
	// const tree = treeRef.current || initTree;
	const dragging = isEqual(draggedItemTree, tree);
	const dropped = isEqual(droppedItemTree, tree);
	const ignored = tree?.segmentMemberRestriction
		? tree.segmentMemberRestriction?.ignored
		: tree?.bucketRestriction?.ignored;
	const scope = {
		numerical_operations,
		editing: true,
		activityRestriction,
		operation: tree.bucketRestriction?.bkt?.Cmp,
		root: {
			updateCount: () => UpdateCount(advancedQuery, setAdvancedQuery, false),
		},
		rangeConfig: defaultRangeConfig,
		vals: activityRestriction?.activityRestriction?.cnt,
		tree,
		parent,
		isValidRange: true,
	} as TreeScope;
	const onScopeChange = (key: string, _: unknown): void => {
		if (key === 'editing') {
			setEditing(false);
			updateTree && updateTree(entity);
		}
	};
	return (
		<>
			<DNBCard
				style={{
					minHeight: '36px',
				}}
				className={classNames({
					querySection: true,
					querySectionItemActivity: true,
					querySectionAll: tree.logicalRestriction?.operator === Operator.AND,
					querySectionAny: tree.logicalRestriction?.operator === Operator.OR,
					canEdit: root.canEdit,
				})}
				variant='white'
				onMouseOver={(e) => {
					mouseOver(e, editing, queryTree, advancedQuery);
					if (draggedItemTree) {
						mouseOverHighlight(getBucketLabel(draggedItemTree));
					}
				}}
				onMouseMove={(e) => {
					mouseMove(
						e,
						editing,
						queryTree,
						advancedQuery,
						setAdvancedQuery,
						true
					);
				}}>
				<div
					onClick={() => setEditing(true)}
					className={classNames({
						querySectionLabel: true,
						dragging,
						dropped,
						canEdit: root.canEdit,
					})}>
					{/* View mode */}
					{!editing && (isSegmentationMode() || !ignored) && (
						<span className='itemBucketData'>
							<span className='querySectionItemOperation'>
								<span className='querySectionItemOperation'>
									The number of activities{` `}
									<span>
										<strong>{getOperationLabel(activityRestriction)}</strong>
									</span>
								</span>
								<span className='querySectionItemValue'>
									{` `}
									<strong>{getOperationValue(activityRestriction, 0)}</strong>
									{showTo(activityRestriction, two_inputs) && (
										<>
											<span> - </span>
											<strong>
												{getOperationValue(activityRestriction, 1)}
											</strong>
										</>
									)}
								</span>
							</span>
						</span>
					)}
					{/* -- Editing Mode -- */}
					{editing && (
						<DNBTypography
							component='span'
							variant='compact-body'
							className='itemEditBucketData'>
							<TreeActivityEdit scope={scope} onScopeChange={onScopeChange} />
						</DNBTypography>
					)}
				</div>
			</DNBCard>
		</>
	);
};

export {QueryTreeActivity};
