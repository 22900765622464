import {Connection} from 'atlas/playbook/content/metadataLiveramplaunch/types';
import {
	FeatureFlags,
	isActivationHubSpotEnabled,
	isActivationMicrosoftDynamicsEnabled,
	isActivationSalesLoftEnabled,
	isFeatureFlagEnabled,
	isG2ConnectionEnabled,
	isGenericDSPEnabled,
	isImportMicrosoftDynamicsEnabled,
	isHubSpotConnectionEnabled,
	isSalesforceMarketingCloudEnabled,
	isImportConnectionSFMCEnabled,
} from 'common/stores/tenantConfig';
import {ConnectionType, ObjectType} from './ConnectionType';
import {DocumentType} from './DocumentType';
import {FieldType} from './FieldType';
import {
	ExternalSystemType,
	SystemCategory,
	SystemLabelType,
	SystemType,
} from './SystemType';

const assetBasePath = '/atlas/assets/images';
const svgBasePath = '/atlas/assets/svgs';

/**
 * System Config Interface.
 * @icon Icon
 * @label Label refers to SystemLabelType
 * @category Category refers to SystemCategory
 * @beta Beta or not
 * @locked Locked or not
 * @displayName display name
 * @enable enabled or not
 * @cdpObjectType Default cdp oject type
 */
interface ISystemConfig {
	icon?: string;
	label: SystemLabelType[];
	category: SystemCategory;
	beta?: boolean;
	locked?: boolean;
	displayName?: string;
	enable?: boolean;
	cdpObjectType?: DocumentType;
	testId?: string;
}

type ISystemConfigMap = {
	[key in SystemType]: ISystemConfig;
};

const ImportSystemCreationList = (): SystemType[] => {
	const creationList = [
		SystemType.Salesforce,
		SystemType.Marketo,
		SystemType.Eloqua,
		SystemType.Custom,
		SystemType.TrustRadius,
	];
	if (isImportConnectionSFMCEnabled()) {
		creationList.splice(1, 0, SystemType.Salesforce_Marketing_Cloud);
	}
	if (isG2ConnectionEnabled()) {
		creationList.push(SystemType.G2);
	}
	if (isImportMicrosoftDynamicsEnabled()) {
		creationList.push(SystemType.Microsoft_Dynamics365);
	}
	if (isHubSpotConnectionEnabled()) {
		creationList.push(SystemType.Hub_Spot);
	}

	return creationList;
};

const ImportSystemEnabledList = (): SystemType[] => {
	const enableList = [
		SystemType.Salesforce,
		SystemType.Marketo,
		SystemType.Eloqua,
		SystemType.AWS_S3,
		SystemType.OneTime,
		SystemType.DataVision,
		SystemType.TrustRadius,
	];
	if (isImportConnectionSFMCEnabled()) {
		enableList.splice(1, 0, SystemType.Salesforce_Marketing_Cloud);
	}
	if (isG2ConnectionEnabled()) {
		enableList.push(SystemType.G2);
	}
	if (isImportMicrosoftDynamicsEnabled()) {
		enableList.push(SystemType.Microsoft_Dynamics365);
	}
	if (isHubSpotConnectionEnabled()) {
		enableList.push(SystemType.Hub_Spot);
	}

	return enableList;
};

const disableSourceCreationConnections = [
	SystemType.G2,
	SystemType.TrustRadius,
	SystemType.DataVision,
];

const metadataRefactorConnections = [SystemType.LinkedIn];

const DSPConnections = [SystemType.GenericDSP];

const trayAuthenticationConnections = [
	SystemType.Salesforce_Marketing_Cloud,
	SystemType.Hub_Spot,
	SystemType.Sales_Loft,
];

const trayPopUpAuthenticationConnections = [SystemType.Hub_Spot];

const marketingAutomationAuthDialogConnections = [SystemType.Eloqua];

const notNeedAuthenticationConnections = [SystemType.GenericDSP];

const ActivationSystemEnabledList = (): SystemType[] => {
	const enabledSystemList = [SystemType.Eloqua];

	enabledSystemList.push(SystemType.Salesforce);

	if (
		isFeatureFlagEnabled(
			FeatureFlags.ENABLE_METADATA_DRIVEN_CONNECTION_REFACTOR
		)
	) {
		enabledSystemList.push(...metadataRefactorConnections);
	}

	if (isSalesforceMarketingCloudEnabled()) {
		enabledSystemList.push(SystemType.Salesforce_Marketing_Cloud);
	}

	if (isGenericDSPEnabled()) {
		enabledSystemList.push(SystemType.GenericDSP);
	}

	if (isActivationMicrosoftDynamicsEnabled()) {
		enabledSystemList.push(SystemType.Microsoft_Dynamics365);
	}

	if (isActivationHubSpotEnabled()) {
		enabledSystemList.push(SystemType.Hub_Spot);
	}

	if (isActivationSalesLoftEnabled()) {
		enabledSystemList.push(SystemType.Sales_Loft);
	}

	return enabledSystemList;
};

/**
 * Filtered connection list under FF
 * @param connections @Connection array
 * @returns Filtered connection list
 */
const filteredConnectionList = (connections: Connection[]): Connection[] => {
	return connections.filter(({lookupIdMap}) =>
		!isActivationMicrosoftDynamicsEnabled()
			? `${lookupIdMap?.externalSystemName}` !==
			  SystemType.Microsoft_Dynamics365
			: true
	);
};

const SystemEnabledListConfig = {
	[ConnectionType.Import]: ImportSystemEnabledList,
	[ConnectionType.Activation]: ActivationSystemEnabledList,
};

/*
	Used by Add Connection Page.
*/
const SystemCreationListConfig = {
	[ConnectionType.Import]: ImportSystemCreationList,
	[ConnectionType.Activation]: ActivationSystemEnabledList,
};

const SystemConfigMap: ISystemConfigMap = {
	[SystemType.Salesforce]: {
		icon: `${svgBasePath}/logo_salesforce_icon.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.SalesEnablement,
	},
	[SystemType.Salesforce_Marketing_Cloud]: {
		icon: `${svgBasePath}/logo_SFMC_icon.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.MarketingAutomation,
		displayName: 'Salesforce Marketing Cloud',
		cdpObjectType: DocumentType.Contact,
		testId: 'SFMC',
	},
	[SystemType.Eloqua]: {
		icon: `${svgBasePath}/logo_eloqua_icon.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.MarketingAutomation,
		cdpObjectType: DocumentType.Contact,
	},
	[SystemType.AWS_S3]: {
		icon: `${svgBasePath}/logo_custom.svg`,
		label: [SystemLabelType.S3],
		category: SystemCategory.File,
		displayName: 'AWS S3',
	},
	[SystemType.Marketo]: {
		icon: `${svgBasePath}/logo_marketo_icon.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.MarketingAutomation,
		cdpObjectType: DocumentType.Lead,
	},
	[SystemType.Pardot]: {
		icon: `${svgBasePath}/logo_pardot_logo.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.DisplayAds,
		cdpObjectType: DocumentType.Contact,
	},
	[SystemType.Custom]: {
		icon: `${svgBasePath}/logo_custom.svg`,
		label: [SystemLabelType.File],
		category: SystemCategory.Other,
		displayName: 'Custom Connection',
		enable: true,
	},
	[SystemType.OneTime]: {
		icon: `${svgBasePath}/logo_custom.svg`,
		label: [SystemLabelType.File],
		category: SystemCategory.Custom,
		displayName: 'Custom',
	},
	[SystemType.DataVision]: {
		icon: `${svgBasePath}/logo-dnb.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.Other,
		displayName: 'Explore Module',
	},
	[SystemType.G2]: {
		icon: `${svgBasePath}/logo_G2.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.Intent,
		displayName: 'G2',
	},
	[SystemType.TrustRadius]: {
		icon: `${svgBasePath}/logo_TrustRadius.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.Intent,
	},
	// NOTE: The following system types are going to be added in the future.
	[SystemType.LinkedIn]: {
		icon: `${svgBasePath}/logo_linkedin_icon.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.SocialAds,
	},
	[SystemType.Facebook]: {
		icon: `${svgBasePath}/logo_marketo_icon.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.MarketingAutomation,
	},
	[SystemType.Outreach]: {
		icon: `${svgBasePath}/logo_outreach_icon.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.MarketingAutomation,
	},
	[SystemType.GoogleAds]: {
		icon: `${svgBasePath}/logo_google_ads_icon.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.SocialAds,
	},
	[SystemType.Google_Display_N_Video_360]: {
		icon: `${svgBasePath}/logo_google_dv_icon.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.DisplayAds,
	},
	[SystemType.AppNexus]: {
		icon: `${svgBasePath}/logo_xandr_icon.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.DisplayAds,
	},
	[SystemType.MediaMath]: {
		icon: `${assetBasePath}/logo_infillion_icon.png`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.DisplayAds,
	},
	[SystemType.Adobe_Audience_Mgr]: {
		icon: `${assetBasePath}/logo_adobe_audience_manager_icon.png`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.DisplayAds,
	},
	[SystemType.Verizon_Media]: {
		icon: `${svgBasePath}/logo_yahoo.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.DisplayAds,
	},
	[SystemType.TradeDesk]: {
		icon: `${svgBasePath}/logo_trade_desk_icon.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.DisplayAds,
	},
	[SystemType.GenericDSP]: {
		displayName: 'Generic DSP Connector',
		label: [],
		category: SystemCategory.ProgrammaticAds,
	},
	[SystemType.Microsoft_Dynamics365]: {
		icon: `${svgBasePath}/logo_microsoft_dynamics_icon.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.SalesEnablement,
		displayName: 'Microsoft Dynamics 365',
	},
	[SystemType.Hub_Spot]: {
		icon: `${svgBasePath}/logo_hubspot_icon.svg`,
		label: [SystemLabelType.Auto],
		category: SystemCategory.SalesEnablement,
		displayName: 'HubSpot',
	},
	[SystemType.Sales_Loft]: {
		icon: `${svgBasePath}/logo_salesloft_icon.svg`,
		label: [],
		category: SystemCategory.SalesEnablement,
		displayName: 'Salesloft',
	},
};

const getConnectionIcon = (systemType: SystemType, pid?: string): string => {
	if (systemType === SystemType.GenericDSP && pid)
		return `/pls/generic-dsp/dsp/icon?pid=${pid}`;
	return SystemConfigMap[systemType].icon || '';
};

interface ISystemConfigPerConnection {
	description?: string;
	beta?: boolean;
	locked?: boolean;
}

type ISystemConfigPerConnectionMap = {
	[type in SystemType]?: ISystemConfigPerConnection;
};

const ImportSystemConfig: ISystemConfigPerConnectionMap = {
	[SystemType.Salesforce]: {
		description: 'Import your customers and prospects from CRM.',
	},
	[SystemType.Salesforce_Marketing_Cloud]: {
		description: '',
	},
	[SystemType.Eloqua]: {
		description:
			'Import leads and marketing engagement data that is related to your customers and prosepcts.',
	},
	[SystemType.Marketo]: {
		description:
			'Import leads and marketing engagement data that is related to your customers and prosepcts.',
	},
	[SystemType.Pardot]: {
		description:
			'Import leads and marketing engagement data that is related to your customers and prosepcts.',
	},
	[SystemType.AWS_S3]: {
		description: 'Use S3 to import data from any system.',
	},
	[SystemType.OneTime]: {
		description: 'Do a one time import',
	},
	[SystemType.Custom]: {
		description: 'Create a custom connection to import data from any system.',
	},
	[SystemType.Microsoft_Dynamics365]: {
		description:
			'Create a connection to send records to Microsoft Dynamics 365',
	},
	[SystemType.Hub_Spot]: {
		description: 'Create a connection to send records to HubSpot.',
	},
};

const ActivationSystemConfig: ISystemConfigPerConnectionMap = {
	[SystemType.Salesforce]: {
		description: 'Create a connection to send records to Salesforce',
	},
	[SystemType.Salesforce_Marketing_Cloud]: {
		description:
			'Create a connection to activate updated Data Extensions in Salesforce Marketing Cloud',
		beta: true,
	},
	[SystemType.Eloqua]: {
		description: 'Create a connection to activate audience segments in Eloqua.',
	},
	[SystemType.LinkedIn]: {
		description:
			'Create a connection to send audiences of companies or contacts to Linkedin.',
	},
	[SystemType.GenericDSP]: {
		beta: true,
	},
	[SystemType.Microsoft_Dynamics365]: {
		description:
			'Create a connection to send records to Microsoft Dynamics 365.',
		beta: true,
	},
	[SystemType.Hub_Spot]: {
		description: 'Create a connection to send records to HubSpot.',
		beta: true,
	},
	[SystemType.Sales_Loft]: {
		description: 'Create a connection to send records to Salesloft.',
		beta: true,
	},
};

type ISystemConfigConnectionMap = {
	[key in ConnectionType]: ISystemConfigPerConnectionMap;
};

const SystemConfigPerConnectionMap: ISystemConfigConnectionMap = {
	[ConnectionType.Import]: ImportSystemConfig,
	[ConnectionType.Activation]: ActivationSystemConfig,
};

const SystemCategoryConfig = {
	[SystemCategory.DisplayAds]: [
		SystemType.Google_Display_N_Video_360,
		SystemType.MediaMath,
		SystemType.AppNexus,
		SystemType.Adobe_Audience_Mgr,
		SystemType.Verizon_Media,
		SystemType.TradeDesk,
		SystemType.Custom,
	],
	[SystemCategory.SocialAds]: [
		SystemType.LinkedIn,
		SystemType.GoogleAds,
		SystemType.Facebook,
	],
	[SystemCategory.MarketingAutomation]: [
		SystemType.Eloqua,
		SystemType.Outreach,
		SystemType.Salesforce_Marketing_Cloud,
		SystemType.Hub_Spot,
	],
	[SystemCategory.SalesEnablement]: [
		SystemType.Salesforce,
		SystemType.AWS_S3,
		SystemType.Marketo,
		SystemType.Pardot,
		SystemType.Microsoft_Dynamics365,
	],
};

/**
 * Map from SystemCategory to ExternalSystemType
 * @param category @SystemCategory
 * @remarks Please correct here if any relationship is wrong per PM.
 */
const getSystemCategoryToExternalSystemType = (
	category: SystemCategory
): ExternalSystemType => {
	switch (category) {
		case SystemCategory.SocialAds:
		case SystemCategory.DisplayAds:
			return 'ADS';
		case SystemCategory.ProgrammaticAds:
			return 'DSP';
		case SystemCategory.MarketingAutomation:
			return 'MAP';
		case SystemCategory.SalesEnablement:
			return 'CRM';
		case SystemCategory.Custom:
		case SystemCategory.File:
			return 'FILE_SYSTEM';
		case SystemCategory.Intent:
			return 'ERP';
		case SystemCategory.Other:
			return 'OTHER';
		default:
			return 'OTHER';
	}
};

const SystemLabelConfig = {
	[SystemLabelType.Auto]: [
		SystemType.Adobe_Audience_Mgr,
		SystemType.AppNexus,
		SystemType.Eloqua,
		SystemType.Facebook,
		SystemType.GoogleAds,
		SystemType.Google_Display_N_Video_360,
		SystemType.LinkedIn,
		SystemType.Marketo,
		SystemType.MediaMath,
		SystemType.Outreach,
		SystemType.Pardot,
		SystemType.Salesforce,
		SystemType.Salesforce_Marketing_Cloud,
		SystemType.TradeDesk,
		SystemType.Verizon_Media,
		SystemType.G2,
		SystemType.TrustRadius,
		SystemType.Hub_Spot,
		SystemType.Microsoft_Dynamics365,
	],
	[SystemLabelType.S3]: [SystemType.AWS_S3],
	[SystemLabelType.UI]: [SystemType.Custom],
};

interface ISystemType extends ObjectType {
	[FieldType.SystemType]: SystemType;
	[FieldType.Beta]?: boolean;
	[FieldType.Locked]?: boolean;
}

/**
 * System list for add connection page from inbound.
 * @returns System list.
 */
const ImportSystemList: ISystemType[] = Object.keys(SystemConfigMap).map(
	(key) => {
		const systemType = key as SystemType;
		const {beta = false, locked = false} = ImportSystemConfig[systemType] || {};
		return {
			[FieldType.SystemType]: systemType,
			[FieldType.Beta]: beta,
			[FieldType.Locked]: locked,
		};
	}
);

/**
 * System list for add connection page from outbound.
 * @returns System list.
 */
const ActivationSystemList: ISystemType[] = Object.keys(SystemConfigMap).map(
	(key) => {
		const systemType = key as SystemType;
		const {beta = false, locked = false} =
			ActivationSystemConfig[systemType] || {};
		return {
			[FieldType.SystemType]: systemType,
			[FieldType.Beta]: beta,
			[FieldType.Locked]: locked,
		};
	}
);

const showConnectionSchedule = (systemType: SystemType): boolean =>
	systemType !== SystemType.DataVision;

export type {
	ISystemConfig,
	ISystemConfigMap,
	ISystemConfigPerConnection,
	ISystemConfigPerConnectionMap,
	ISystemConfigConnectionMap,
	ISystemType,
};
export {
	SystemConfigMap,
	SystemCategoryConfig,
	SystemLabelConfig,
	ImportSystemCreationList,
	ImportSystemEnabledList,
	ActivationSystemEnabledList,
	SystemEnabledListConfig,
	SystemCreationListConfig,
	ImportSystemConfig,
	ActivationSystemConfig,
	SystemConfigPerConnectionMap,
	ImportSystemList,
	ActivationSystemList,
	metadataRefactorConnections,
	DSPConnections,
	trayAuthenticationConnections,
	trayPopUpAuthenticationConnections,
	notNeedAuthenticationConnections,
	marketingAutomationAuthDialogConnections,
	disableSourceCreationConnections,
	showConnectionSchedule,
	getConnectionIcon,
	getSystemCategoryToExternalSystemType,
	filteredConnectionList,
};
