import React from 'common/react-vendor';
import {
	ChipsContext,
	ChipsContextUpdate,
	IChipsContext,
	IChipsContextUpdate,
} from './ChipsContext';

const {useContext} = React;

interface IUseChipsContext {
	context: IChipsContext;
	setContext: IChipsContextUpdate;
}

const useChipsContext = (): IUseChipsContext => {
	const context = useContext<IChipsContext>(ChipsContext);
	const setContext = useContext<IChipsContextUpdate>(ChipsContextUpdate);
	return {context, setContext};
};

export {useChipsContext};
export type {IUseChipsContext};
