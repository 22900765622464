import {ATTRIBUTES_DEFAULT_GROUP} from 'atlas/connectors/connectors.service';
import UserValues from 'common/widgets/utilities/user-values.utility';
import {
	isMetadataConnection,
	isSocialAds,
	isTrayAuthenticationConnection,
} from 'atlas/connectors/EIF/AddConnection/addConnectionComponent.utilities';
import {DefaultBeta, DefaultLocked, FieldType} from '../../FieldType';
import {IActivationConnectionResponse} from '../FE/ActivationResponse';
import {ExternalSystemType, SystemType} from '../../SystemType';
import {IActivationAuthList, IActivationAuthResponse} from './ActivationAuth';
import {ConnectionType, ObjectType} from '../../ConnectionType';
import {
	ActivationSystemEnabledList,
	getSystemCategoryToExternalSystemType,
	SystemConfigMap,
} from '../../SystemConst';

interface IActivationConnectionRequest extends ObjectType {
	configId: string;
	orgName: string;
	orgId: string;
	externalSystemName: SystemType;
	connectionType: string;
	isRegistered: boolean;
	description: string;
	contactId: string;
	accountId: string;
	attributeSetName: string;
	externalAuthentication: IActivationAuthList;
	brokerAuthentication: {name: string; externalId?: string};
	exportFieldMappings: IFieldMappingsResponse[];
	externalSystemType: ExternalSystemType;
	configValues: ObjectType;
}

interface ISystemFieldsResponse extends ObjectType {
	Attribute: string;
	DisplayName: string;
}

interface IGroupAttributesResponse extends ObjectType {
	AttrName: string;
	Category: string;
	ColumnId: string;
	DisplayName: string;
	Entity: string;
	IsAdminDisabledForModel: boolean;
	IsCampaignDerivedField: boolean;
	IsCoveredByMandatoryRule: boolean;
	IsCoveredByOptionalRule: boolean;
	Subcategory: string;
}

interface IAttributeGroupsResponse extends ObjectType {
	attributesMap: ObjectType;
	created: number;
	createdBy: string;
	description: string;
	displayName: string;
	name: string;
	updated: number;
	updatedBy: string;
}

interface IFields {
	fieldMapping: IFieldMappingsResponse;
}

interface IMappedFields {
	[id: string]: IFields;
}

interface IFieldMappingsResponse {
	sourceField: string;
	destinationField: string;
	sourceFieldDisplayName: string;
	destinationFieldDisplayName: string;
	entity: string;
	audienceType?: string;
	operation?: string;
	overwriteValue: boolean;
	invalid?: boolean;
	pid?: number;

	fieldType?: string;
}

interface IActivationConnection extends ObjectType {
	configId: string;
	orgName: string;
	orgId: string;
	externalSystemName: SystemType;
	connectionType: string;
	isRegistered: boolean;
	description: string;
	contactId: string;
	accountId: string;
	accountName?: string;
	leadId?: string;
	teamId?: string;
	attributeSetName: string;
	created: number;
	createdBy: string;
	updated: number;
	updatedBy: string;
	trayEnabled: boolean;
	externalAuthentication: IActivationAuthList;
	brokerAuthentication: IActivationAuthResponse;
	exportFieldMappings: IFieldMappingsResponse[];
	externalSystemType: ExternalSystemType;
	configValues: ObjectType;
}

const isValidConnection = ({
	externalSystemName: systemType,
}: IActivationConnection): boolean => {
	return ActivationSystemEnabledList().includes(systemType);
};

const toActivationConnectionResponse = (
	data: IActivationConnection
): IActivationConnectionResponse => {
	const {
		configId,
		orgName,
		orgId,
		externalSystemName,
		isRegistered,
		contactId,
		accountId,
		accountName,
		leadId,
		teamId,
		description,
		attributeSetName,
		created,
		createdBy,
		updated,
		updatedBy,
		exportFieldMappings,
		externalAuthentication,
		brokerAuthentication,
		trayEnabled,
		externalSystemType,
		configValues,
	} = data;
	const {updated: expirationDate, authId: authenticationName} =
		externalAuthentication || {};
	return {
		[FieldType.SystemId]: configId,
		[FieldType.SystemName]: orgName,
		[FieldType.LastImportTime]: updated,
		[FieldType.OrgId]: orgId,
		[FieldType.SystemType]: externalSystemName,
		[FieldType.Enabled]: isRegistered,
		[FieldType.Status]: isRegistered ? 'Connected' : 'Disconnected',
		[FieldType.ContactId]: contactId || '',
		[FieldType.AccountId]: accountId || '',
		[FieldType.AccountName]: accountName || '',
		[FieldType.LeadId]: leadId || '',
		[FieldType.TeamId]: teamId || '',
		[FieldType.Description]: description || '',
		[FieldType.AttributeSetName]: attributeSetName || ATTRIBUTES_DEFAULT_GROUP,
		[FieldType.Created]: created,
		[FieldType.CreatedBy]: createdBy || '',
		[FieldType.LastUpdated]: updated,
		[FieldType.LastUpdatedBy]: updatedBy || '',
		[FieldType.exportFieldMappings]: exportFieldMappings,
		[FieldType.ExpirationDate]: expirationDate,
		[FieldType.AuthenticationName]: authenticationName,
		[FieldType.Beta]: DefaultBeta,
		[FieldType.Locked]: DefaultLocked,
		[FieldType.TrayEnabled]: trayEnabled,
		[FieldType.ExternalAuthentication]: externalAuthentication,
		[FieldType.BrokerAuthentication]: brokerAuthentication,
		[FieldType.ExternalSystemType]: externalSystemType,
		[FieldType.GDSPId]: (configValues?.gdspId || '') as string,
		[FieldType.GDSPName]: (configValues?.gdspName || '') as string,
		UserAccessToken: '',
		ConnectionType: ConnectionType.Activation,
	};
};

const getDefaultActivationOrgId = (OrgId?: string): string => {
	return OrgId || Math.random().toString(36);
};

const getActivationConfigValues = (
	systemType: SystemType,
	OrgId?: string,
	GDSPId?: string,
	GDSPName?: string
): Record<string, unknown> => {
	if (isMetadataConnection(systemType) && isSocialAds(systemType)) {
		return {
			external_ad_account: OrgId,
		};
	}

	if (systemType === SystemType.GenericDSP) {
		return {gdspId: GDSPId, gdspName: GDSPName};
	}

	return {};
};

const getDefaultActivationBrokerAuthentication = (
	SystemType: SystemType,
	AuthenticationName: string,
	BrokerAuthentication: IActivationAuthResponse
): IActivationConnectionRequest['brokerAuthentication'] => {
	if (isTrayAuthenticationConnection(SystemType)) {
		return {
			name: AuthenticationName,
			externalId: BrokerAuthentication.externalId,
		};
	}

	return {name: AuthenticationName};
};

const getDefaultActivationExternalAuthentication = (
	SystemType: SystemType,
	ExternalAuthentication: IActivationAuthList
): IActivationConnectionRequest['externalAuthentication'] => {
	if (isTrayAuthenticationConnection(SystemType)) {
		return {
			...ExternalAuthentication,
			trayWorkflowEnabled: true,
		};
	}

	return {} as IActivationAuthList;
};

const mapExportFieldMappings = (
	exportFieldMappings: IFieldMappingsResponse[] = [],
	emailMapping?: IFieldMappingsResponse
): IFieldMappingsResponse[] => {
	if (emailMapping) {
		return [...exportFieldMappings, emailMapping];
	}

	return [...exportFieldMappings];
};

const toActivationConnectionRequest = ({
	SystemId,
	OrgId,
	SystemName,
	Description,
	ContactId,
	AccountId,
	LeadId,
	ExternalSystemType,
	AttributeSetName,
	SystemType,
	exportFieldMappings,
	BrokerAuthentication,
	ExternalAuthentication,
	AuthenticationName,
	AccountName,
	TeamId,
	emailMapping,
	GDSPId,
	GDSPName,
}: IActivationConnectionResponse): IActivationConnectionRequest => {
	const auth = {
		...BrokerAuthentication,
		brokerAuthInfo: {},
	};

	return {
		orgId: getDefaultActivationOrgId(OrgId),
		orgName: SystemName,
		description: Description,
		contactId: ContactId,
		accountId: AccountId,
		accountName: AccountName,
		leadId: LeadId,
		teamId: TeamId,
		externalSystemType:
			ExternalSystemType ||
			getSystemCategoryToExternalSystemType(
				SystemConfigMap[SystemType].category
			),
		attributeSetName: AttributeSetName,
		externalSystemName: SystemType,
		connectionType: SystemType,
		configValues: getActivationConfigValues(
			SystemType,
			OrgId,
			GDSPId,
			GDSPName
		),
		exportFieldMappings: mapExportFieldMappings(
			exportFieldMappings,
			emailMapping
		),
		externalAuthentication: SystemId
			? ExternalAuthentication
			: getDefaultActivationExternalAuthentication(
					SystemType,
					ExternalAuthentication
			  ),
		brokerAuthentication: SystemId
			? auth
			: getDefaultActivationBrokerAuthentication(
					SystemType,
					AuthenticationName,
					BrokerAuthentication
			  ),
		configId: SystemId,
		endDestinationId: OrgId,
		isRegistered: true,
	};
};

interface IActivationConnectionList extends ObjectType {
	data: IActivationConnection[];
}

const toActivationConnectionListResponse = (
	result: IActivationConnectionList
): IActivationConnectionResponse[] => {
	let data: IActivationConnection[] = [];
	for (const val of Object.values(result)) {
		data = data.concat(val as IActivationConnection);
	}
	return data.filter(isValidConnection).map(toActivationConnectionResponse);
};

const AvailableLookupIdsUrl = '/pls/lookup-id-mapping/available-lookup-ids';
const getAvailableLookupIdsByAudienceType = (audienceType: string): string => {
	return `${AvailableLookupIdsUrl}?audienceType=${audienceType}`;
};

const AttributeSetUrl = '/pls/attrconfig/attributeset';

const SystemFieldsUrl = '/pls/externalsystemdata';
const getSystemFields = (
	SystemType: string,
	authId: string,
	audienceType?: string
): string => {
	return `${SystemFieldsUrl}/${SystemType}/fields?trayAuthId=${authId}${
		audienceType ? `&audienceType=${audienceType}` : ''
	}`;
};

const CustomObjectsUrl = '/pls/externalsystemdata/eloqua/customObjects';
const getCustomObjectsUrl = (authId: string): string => {
	return `${CustomObjectsUrl}/?trayAuthId=${authId}`;
};

const GroupAttributesUrl = '/pls/datacollection/attributes';
const getGroupAttributes = (
	attributeSetName: string,
	externalSystemName: string
): string => {
	return `${GroupAttributesUrl}/${attributeSetName}?externalSystemName=${externalSystemName}`;
};

// The getTenantID method returns the current tenant name
const tenantIdentifier = UserValues.getTenantId();

const PlsTrayUserDocument = `/pls/tray/userDocument?customerSpace=${tenantIdentifier}`;

const ActivationConnectionListUrl = '/pls/lookup-id-mapping';
const getActivationConnectionListUrl = (status: boolean): string => {
	return `${ActivationConnectionListUrl}?betaConnector=${status}`;
};
const getActivationConnectionUrlById = (id: string): string => {
	return `${ActivationConnectionListUrl}/config/${id}`;
};
const ActivationConnectionUrl =
	'/pls/lookup-id-mapping/registerWithBackendAndTray';

export {
	toActivationConnectionResponse,
	toActivationConnectionRequest,
	toActivationConnectionListResponse,
	ActivationConnectionUrl,
	getActivationConnectionListUrl,
	getActivationConnectionUrlById,
	isValidConnection,
	AvailableLookupIdsUrl,
	getAvailableLookupIdsByAudienceType,
	AttributeSetUrl,
	getSystemFields,
	getCustomObjectsUrl,
	getGroupAttributes,
	PlsTrayUserDocument,
};
export type {
	IActivationConnection,
	IActivationConnectionList,
	IFieldMappingsResponse,
	IMappedFields,
	IFields,
	IAttributeGroupsResponse,
	IGroupAttributesResponse,
	ISystemFieldsResponse,
	IActivationConnectionRequest,
};
