import {axios} from 'common/network.vendor';
import {
	AxiosPromise,
	AxiosRequestConfig,
	AxiosResponse,
} from 'common/node_modules/axios';
import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import {
	IAttributeData,
	IDateTimeFormatAPIResponse,
	ISchemaRequestData,
	ISchemaResponseData,
	TSchemaType,
} from '../schemaTypes';
import {GeneralResponse} from '../../AddSource/api/apiCalls';
import {ICreateOrUpdateConnection} from '../../Data/API/BE/CreateOrUpdateConnection';
import {CustomDocumentTypeGroup} from '../../Data/DocumentType';

export function getSchemaData(
	schemaType: string
): AxiosPromise<ISchemaResponseData> {
	return axiosInstance.get(`/pls/cdp/${schemaType}`);
}

export function setSchemaData(
	data: ISchemaRequestData & {revisedFieldName?: string}
): AxiosPromise<void> {
	return axiosInstance.post('/pls/cdp/save', data);
}

export function getDateTimeFormats(): AxiosPromise<IDateTimeFormatAPIResponse> {
	return axiosInstance.get('/pls/models/uploadfile/dateformat');
}

export function uploadSchemaTemplate(
	schemaType: TSchemaType,
	data: FormData,
	config: AxiosRequestConfig
): AxiosPromise<GeneralResponse<Array<IAttributeData>>> {
	return axios
		.post(`/pls/cdp/${schemaType}/import`, data, config)
		.then((res: AxiosResponse<Array<IAttributeData>>) => {
			return {
				...res,
				data: {
					Errors: [],
					Success: true,
					Result: res.data,
				},
			} as AxiosResponse<GeneralResponse<Array<IAttributeData>>>;
		});
}

export function getAllConnections(): AxiosPromise<ICreateOrUpdateConnection[]> {
	return axiosInstance('/pls/inboundchannel/connection');
}

export function getCustomSchemaList(
	customType: CustomDocumentTypeGroup
): AxiosPromise<string[]> {
	return axiosInstance(`/pls/cdp/listCustom${customType}s`);
}
