import React from 'common/react-vendor';
import {DNBSelect, DNBSelectOption} from 'common/dnb-uux-vendor';
import {
	BucketCmp,
	Direction,
} from 'common/components/datacloud/query/query.enums';
import {BucketRestriction, InputChangeData} from '../../../../query.types';
import {
	changeCompare,
	changeValue,
	getChangeValue,
	getCompareList,
	getDirection,
	getDirectionList,
	getNumericalPeriodConfig,
	setDirection,
} from '../percentStore.helpers';
import {NumericalRange} from '../../edit/NumericalRange/NumericalRange';

const PercentEdit = ({
	bucketRestriction,
	onBucketRestrictionChange,
}: {
	bucketRestriction: BucketRestriction;
	onBucketRestrictionChange(newBucketRestriction: BucketRestriction): void;
}): React.ReactElement => {
	const directionList = getDirectionList();
	const periodList = getCompareList();

	const [directionModel, setDirectionModel] = React.useState(
		bucketRestriction?.bkt
			? getDirection(bucketRestriction?.bkt)
			: Direction.DEC
	);
	const [periodModel, setPeriodModel] = React.useState(
		bucketRestriction?.bkt?.Chg?.Cmp || BucketCmp.EMPTY
	);
	const [periodChanging, setPeriodChanging] = React.useState(true);
	const [showFromPeriod, setShowFromPeriod] = React.useState(false);
	const [showToPeriod, setShowToPeriod] = React.useState(false);
	const [numericalConfig, setNumericalConfig] = React.useState(
		getNumericalPeriodConfig()
	);

	const changeNumericalConfig = (val: BucketCmp): void => {
		if (bucketRestriction?.bkt) {
			setPeriodChanging(true);
			const fromTmp = getChangeValue({
				compareType: val,
				bucket: bucketRestriction.bkt,
				index: 0,
			});
			const toTmp = getChangeValue({
				compareType: val,
				bucket: bucketRestriction.bkt,
				index: 1,
			});
			numericalConfig.from.value =
				fromTmp != null ? Number(fromTmp) : undefined;
			numericalConfig.to.value = toTmp != null ? Number(toTmp) : undefined;
			setNumericalConfig(numericalConfig);
			setPeriodChanging(false);
		}
	};

	const changePeriodView = (val: BucketCmp): void => {
		switch (val) {
			case 'AS_MUCH_AS': {
				setShowFromPeriod(true);
				setShowToPeriod(false);
				break;
			}
			case 'AT_LEAST': {
				setShowFromPeriod(true);
				setShowToPeriod(false);
				break;
			}
			case 'BETWEEN': {
				setShowFromPeriod(true);
				setShowToPeriod(true);
				break;
			}
			default: {
				setShowFromPeriod(false);
				setShowToPeriod(false);
			}
		}
		changeNumericalConfig(val);
	};

	const changeDirection = (val: Direction | null): void => {
		if (val && bucketRestriction?.bkt) {
			setDirection(bucketRestriction?.bkt, val);
			onBucketRestrictionChange(bucketRestriction);
			setDirectionModel(val);
		}
	};
	const changePeriod = (val: BucketCmp | null): void => {
		if (val && bucketRestriction.bkt) {
			const bkt = changeCompare(bucketRestriction.bkt, val);
			const newBucketRestriction = {
				...bucketRestriction,
				bkt,
			};
			onBucketRestrictionChange(newBucketRestriction);
			changePeriodView(val);
			setPeriodModel(val);
		}
	};

	const callbackChangedValue = ({index, value}: InputChangeData): void => {
		const Vals = changeValue({
			compareType: periodModel,
			index,
			values: bucketRestriction?.bkt?.Chg?.Vals as number[],
			value: value as number,
		});
		const newBucketRestriction = {
			...bucketRestriction,
			bkt: {
				...bucketRestriction?.bkt,
				Chg: {
					...bucketRestriction?.bkt?.Chg,
					Vals,
					Cmp: periodModel,
				},
			},
		};
		onBucketRestrictionChange(newBucketRestriction);
	};

	React.useEffect(() => {
		changePeriodView(periodModel);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className='numEditRow'>
			<DNBSelect<Direction>
				value={directionModel}
				size='compact'
				onChange={(_, val) => changeDirection(val)}>
				{directionList.map((list) => (
					<DNBSelectOption key={list.name} value={list.name}>
						{list.displayName}
					</DNBSelectOption>
				))}
			</DNBSelect>
			<DNBSelect<BucketCmp>
				value={periodModel}
				size='compact'
				onChange={(_, val) => changePeriod(val)}>
				{periodList.map((list) => (
					<DNBSelectOption key={list.name} value={list.name}>
						{list.displayName}
					</DNBSelectOption>
				))}
			</DNBSelect>
			{periodChanging === false && (
				<NumericalRange
					config={numericalConfig}
					showMessage={true}
					showFrom={showFromPeriod}
					showTo={showToPeriod}
					bucketRestriction={bucketRestriction}
					onChange={callbackChangedValue}
				/>
			)}
			{(showFromPeriod || showToPeriod) && <span>%</span>}
		</div>
	);
};

export {PercentEdit};
