import React from 'common/react-vendor';
import {useDispatch, useSelector} from 'common/node_modules/react-redux';
import {
	Grid,
	GridColDef,
	useGridApiRef,
	DNBTypography,
	DNBDataTable,
	DNBPopover,
	DNBButton,
	DNBIconButton,
	DNBDialog,
	DNBDialogProps,
	DeleteOutlineOutlinedIcon,
	ViewColumnOutlinedIcon,
	AddIcon,
	GridRowSelectionModel,
	FileDownloadOutlinedIcon,
	gridSortedRowIdsSelector,
} from 'common/dnb-uux-vendor';
import {SearchInput} from 'common/local-uux-components/search/SearchInput';
import {FilterButton} from 'common/composite-uux-components/filter-drawer';
import {Spinner} from 'widgets/spinner';
import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import Drawer from './Drawer';
import Snackbar from './Snackbar';
import ColumnActions from './ColumnActions';
import {useCustomizedColumns} from '../columns';
import AttributesCount from './AttributesCount';
import {
	checkForDuplicatedAttributes,
	renderRequiredText,
	autoLimitCDPMaxLength,
} from '../utils';

import {
	addSchemaData,
	deleteData,
	editSchemaData,
	getSchemaData,
} from '../services/schemaService';
import {setDrawerVisibility} from '../slices/schemaSlice';
import {changeScene, initData} from '../slices/attributeSlice';
import {
	attributeNamespace,
	CDP_SCHEMA_FIELD,
	schemaNamespace,
	schemaTabConfig,
} from '../consts';
import styles from './AttributeList.module.scss';
import {
	AttributeFormScene,
	ECdpDataType,
	IAttribute,
	IAttributeData,
	IAttributeUpdateData,
	IStoreState,
	TSchemaType,
} from '../schemaTypes';
import {SchemaFilter} from './SchemaFilter';
import {SourceListCustomizeColumn} from '../../Component/ContextMenu/SourceListCustomizeColumn';
import {DocumentType} from '../../Data/DocumentType';
import {isDocumentTypeUneditable} from '../../Data/DocumentConst';

type ModalProps = Omit<
	DNBDialogProps & {children?: React.ReactNode},
	'onClose'
>;

const {useEffect, useState, useRef, useCallback, useMemo, Suspense, lazy} =
	React;
const SchemaForm = lazy(() => import('./SchemaForm'));
const getRowId = (rowData: IAttribute): string => rowData.fieldName!;
const filterConfig = {
	cdpDataType: {
		label: 'Data Type',
	},
	nullable: {
		label: 'Required',
		valueFormatter: renderRequiredText,
	},
};
const schemaType2LabelMap = Object.values(schemaTabConfig)
	.flat()
	.reduce((map, {value, label}) => map.set(value, label), new Map());

export default function AttributeList({
	schemaType,
}: {
	schemaType: TSchemaType;
}): React.ReactElement {
	const dispatch = useDispatch();
	const apiRef = useGridApiRef();
	const {
		loading: isTableLoading,
		drawerOpened,
		dataSource,
	} = useSelector((state: IStoreState) => state[schemaNamespace]);
	const {
		scene: schemaFormScene,
		submitting,
		uploadedData,
	} = useSelector((state: IStoreState) => state[attributeNamespace]);
	const [selectionModel, setSelectionModel] =
		React.useState<GridRowSelectionModel>([]);
	const [displayData, setDisplayData] = useState<IAttributeData[]>([]);
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const [modalProps, setModalProps] = useState<ModalProps>({open: false});
	const [showCustomColumn, setShowCustomColumn] = useState(false);
	const [searchString, setSearchString] = useState('');
	const [isFiltered, setIsFiltered] = useState(false);
	const [filterParams, setFilterParams] = useState<{
		cdpDataType?: string[];
		nullable?: string[];
	}>({});
	const [customizedColumns, customizeColumnConfig] = useCustomizedColumns();
	const tableRef = useRef<HTMLDivElement>(null);
	const schemaFormRef = useRef<HTMLFormElement>(null);
	const customColumnPopoverAnchorRef = useRef<SVGSVGElement>(null);
	const availableViewHeightForTable =
		document.body.offsetHeight - (tableRef.current?.offsetTop ?? 0);
	// Store data in ref to avoid rerender of this component
	const attributesInForm = useRef<IAttributeUpdateData[]>([]);
	const deleteRows = useCallback((rows: IAttributeData[]): void => {
		if (!rows.length) {
			return;
		}
		openModal({
			title: 'Warning',
			children: (
				<DNBTypography variant='compact-body'>
					If you delete this attribute, all the related segments and models
					using this attribute will be impacted.
					<br />
					Do you still want to delete this attribute?
				</DNBTypography>
			),
			primaryCTALabel: 'Yes, Delete it',
			primaryCTA: async () => {
				setModalProps((preState) => ({
					...preState,
					secondaryCTA: undefined,
					disabledPrimaryCTA: true,
				}));
				const deleteDataResponse = await deleteData(rows);
				closeModal();
				if (!deleteDataResponse) {
					Snackbar.success(
						`Attribute "${rows
							.map(({screenName}) => screenName)
							.join()}" deleted successfully.`
					);
				} else {
					Snackbar.error(deleteDataResponse.errorMsg, 10000);
				}
			},
		});
	}, []);

	const columnsWithActions = useMemo(
		() => [
			...customizedColumns,
			{
				type: 'actions',
				field: 'actions',
				headerName: 'Actions',
				sortable: false,
				disableReorder: false,
				align: 'left',
				renderCell: ({row}) =>
					isDocumentTypeUneditable(schemaType as DocumentType) ? (
						'Uneditable'
					) : (
						<ColumnActions
							data={row}
							onClickEditAction={() => {
								attributesInForm.current = [row];
								dispatch(changeScene(AttributeFormScene.Edit));
								dispatch(setDrawerVisibility(true));
							}}
							onClickDeleteAction={() => {
								deleteRows([row]);
							}}
						/>
					),
			} as GridColDef,
		],
		[customizedColumns, deleteRows, dispatch, schemaType]
	);

	useEffect(() => {
		let ignorePrevRequest = false;

		getSchemaData(schemaType, () => ignorePrevRequest);

		return () => {
			ignorePrevRequest = true;
		};
	}, [schemaType]);

	useEffect(() => {
		const lowerCasedSearchString = searchString.toLowerCase();
		setDisplayData(
			dataSource
				.filter(
					({screenName = '', displayName = ''}) =>
						screenName.toLowerCase().includes(lowerCasedSearchString) ||
						displayName.toLowerCase().includes(lowerCasedSearchString)
				)
				.filter(
					({cdpDataType, nullable}) =>
						(!filterParams.cdpDataType ||
							filterParams.cdpDataType.includes(cdpDataType!)) &&
						(!filterParams.nullable ||
							filterParams.nullable.includes(renderRequiredText(nullable)))
				)
		);
	}, [dataSource, searchString, filterParams]);

	const isAddAttributeScene = schemaFormScene === AttributeFormScene.Add;
	const onFilterChange = useCallback(
		(args: typeof filterParams, allSelected: boolean) => {
			setFilterParams(args);
			setIsFiltered(!allSelected);
		},
		[]
	);

	function openModal(config: Omit<ModalProps, 'open'> = {}): void {
		setModalProps({
			...config,
			open: true,
		});
	}

	function closeModal(): void {
		setModalProps({
			open: false,
		});
	}

	function closeDrawer(): void {
		dispatch(setDrawerVisibility(false));
	}

	async function submitAddData(
		newAttributes: Array<IAttributeUpdateData>
	): Promise<void> {
		// if all added data are duplicated, `newAttributes` will be empty, then no-op
		if (!newAttributes.length) {
			closeDrawer();
			return;
		}
		const apiResponse = await addSchemaData(schemaType, newAttributes);
		if (!apiResponse) {
			Snackbar.success(
				`${newAttributes.length} new ${
					newAttributes.length <= 1 ? 'attribute has' : 'attributes have'
				} been added to the CDP Schema successfully.`
			);
		} else {
			Snackbar.error(apiResponse.errorMsg, 10000);
		}
	}

	async function submitEditData(data: IAttributeUpdateData): Promise<void> {
		const apiResponse = await editSchemaData(schemaType, data).catch((e) => e);
		if (!apiResponse) {
			Snackbar.success(`Attribute "${data.screenName}" updated successfully.`);
		} else {
			Snackbar.error(apiResponse.errorMsg, 10000);
		}
	}

	function onSubmitData(): void {
		const fileData = autoLimitCDPMaxLength(Object.values(uploadedData).flat());

		// do not validate form if user has uploaded files
		// Let's treat those attributes which has screenName as user want them, then we need validation
		if (
			(!fileData.length ||
				attributesInForm.current.some(({screenName}) => !!screenName)) &&
			!schemaFormRef.current!.reportValidity()
		) {
			return;
		}
		if (isAddAttributeScene) {
			const {duplicatedAttributes, uniqAttributes} =
				checkForDuplicatedAttributes(
					dataSource,
					attributesInForm.current
						.filter(({screenName}) => !!screenName)
						.concat(fileData)
				);
			if (duplicatedAttributes.length) {
				openModal({
					title: 'Warning',
					children: (
						<>
							<DNBTypography variant='compact-body' component='p'>
								The following attributes already exist. Please review them and
								make some changes, otherwise, we will reject the duplicated
								ones.
							</DNBTypography>
							<DNBTypography variant='compact-bold' component='p'>
								{duplicatedAttributes
									.map(({screenName}) => screenName)
									.join(', ')}
							</DNBTypography>
						</>
					),
					primaryCTALabel: 'Ok, Reject Them',
					secondaryCTALabel: 'Back to Modify',
					primaryCTA: () => {
						closeModal();
						submitAddData(uniqAttributes);
					},
				});
			} else {
				submitAddData(uniqAttributes);
			}
		} else {
			openModal({
				title: 'Warning',
				children: (
					<DNBTypography variant='compact-body'>
						If you change the settings of this attribute, all the related
						segments and modals (if any) will be impacted. Do you still want to
						change the settings of this attribute?
					</DNBTypography>
				),
				primaryCTALabel: 'Yes, Change it',
				primaryCTA: () => {
					closeModal();
					submitEditData(attributesInForm.current[0]!);
				},
			});
		}
	}

	const showCreateButton = RBAC.hasAccess(
		RBACInterface.EIF_CDP_SCHEMA,
		RBACActions.CREATE
	);
	return (
		<Grid container spacing={1} sx={{marginTop: '24px'}}>
			<Drawer
				// The filter's z-index is 1200
				maskStyle={{zIndex: 1200}}
				title={isAddAttributeScene ? 'Add Attributes' : 'Edit Attribute'}
				open={drawerOpened}
				onCancel={() => closeDrawer()}
				onOk={() => onSubmitData()}
				confirmLoading={submitting}
				okText={isAddAttributeScene ? 'Add' : 'Update'}>
				<Suspense fallback={<Spinner />}>
					{drawerOpened && (
						<SchemaForm
							ref={schemaFormRef}
							schemaType={schemaType}
							data={attributesInForm.current}
							setData={(newData) => {
								attributesInForm.current = newData;
							}}
						/>
					)}
				</Suspense>
			</Drawer>
			<DNBDialog
				onClose={() => closeModal()}
				secondaryCTA={() => closeModal()}
				{...modalProps}
			/>

			<Grid item xs={isFilterOpen ? 3 : 0}>
				<SchemaFilter
					isFilterOpen={isFilterOpen}
					dataSource={dataSource}
					filterConfig={filterConfig}
					onFilter={onFilterChange}
				/>
			</Grid>

			<Grid item xs={isFilterOpen ? 9 : true}>
				<div className={styles.toolbar}>
					<div className={styles.filterBar}>
						<FilterButton
							id='attribute-list'
							hasActiveFilters={isFiltered}
							doesFilterOpen={isFilterOpen}
							onClick={() => setIsFilterOpen((opened) => !opened)}
						/>

						<SearchInput
							onChange={({target: {value}}) => setSearchString(value)}
							onClear={() => setSearchString('')}
							value={searchString}
						/>

						<AttributesCount count={dataSource.length} />
					</div>
					<div>
						<DNBIconButton
							size='small'
							onClick={() =>
								apiRef.current.exportDataAsCsv({
									utf8WithBom: true,
									fileName: `${document.title}_${
										schemaType2LabelMap.get(schemaType) || schemaType
									}`,
									getRowsToExport: ({apiRef}) =>
										gridSortedRowIdsSelector(apiRef),
								})
							}>
							<FileDownloadOutlinedIcon />
						</DNBIconButton>
						<DNBIconButton
							size='small'
							disabled={!selectionModel.length}
							onClick={() => {
								deleteRows(
									dataSource.filter(
										({fieldName}) =>
											fieldName && selectionModel.includes(fieldName)
									)
								);
							}}>
							<DeleteOutlineOutlinedIcon />
						</DNBIconButton>
						<DNBIconButton
							size='small'
							sx={
								showCustomColumn
									? (theme) => ({
											backgroundColor: theme.colors.ColorGraySecondaryAlpha1,
									  })
									: undefined
							}
							onClick={() => setShowCustomColumn(true)}>
							<ViewColumnOutlinedIcon ref={customColumnPopoverAnchorRef} />
						</DNBIconButton>
						<DNBPopover
							sx={{width: '270px', pb: 4}}
							open={showCustomColumn}
							anchorEl={customColumnPopoverAnchorRef.current}
							placement='bottom'
							hasArrow={false}
							hasCloseButton={false}
							closeButtonOnClick={() => setShowCustomColumn(false)}>
							<SourceListCustomizeColumn
								isDraggable
								{...customizeColumnConfig}
							/>
						</DNBPopover>
						{showCreateButton &&
							!isDocumentTypeUneditable(schemaType as DocumentType) && (
								<DNBButton
									size='compact'
									startIcon={<AddIcon />}
									sx={{marginLeft: '16px'}}
									onClick={() => {
										attributesInForm.current = [
											{
												cdpDataType: ECdpDataType.Text,
												defaultValue: null,
												nullable: true,
											},
										];
										dispatch(initData());
										dispatch(setDrawerVisibility(true));
									}}>
									CDP Attributes
								</DNBButton>
							)}
					</div>
				</div>
				{(isFiltered || searchString) && (
					<DNBTypography variant='compact-body'>
						Showing {displayData.length} out of {dataSource.length} results
					</DNBTypography>
				)}
				<div
					className={styles.tableContainer}
					style={{
						height: availableViewHeightForTable,
					}}
					ref={tableRef}>
					<DNBDataTable
						apiRef={apiRef}
						checkboxSelection
						pageSize={100}
						columns={columnsWithActions}
						rows={displayData}
						loading={isTableLoading}
						autoHeight={false}
						disableVirtualization={false}
						getRowId={getRowId}
						onRowSelectionModelChange={(newSelectionModel) => {
							setSelectionModel(newSelectionModel);
						}}
						rowSelectionModel={selectionModel}
						isRowSelectable={({row}) => row.property === CDP_SCHEMA_FIELD}
					/>
				</div>
			</Grid>
		</Grid>
	);
}
