import type {StateService} from '@uirouter/angularjs';
import {
	Bkt,
	BucketRestriction,
} from 'common/components/datacloud/query/query.types';
import {isNil} from 'lodash';
import {Entity} from 'common/components/datacloud/query/query.enums';
import {Query} from 'common/components/datacloud/datacloud.types';
import messageService from 'common/app/utilities/message-service';
import Message, {NOTIFICATION, SUCCESS} from 'common/app/utilities/message';
import DeprecatedModalService from 'common/components/modal/DeprecatedModalService';
import {
	setPublicProperty,
	getPublicProperty,
	getQueryProperty,
	dispatchSetIsSavingTrainingSegment,
} from 'common/stores/query';
import {createOrUpdateSegment} from '../segment/segment.queries';
import getSanitizedSegmentByConfig from '../tabs/subheader/SubheaderUtility';

const CreateMsg =
	'You have just created a new segment. Are you sure you want to leave without saving this segment?';
const updateMsg =
	'You have just made changes to an existing segment. Are you sure you want to leave without saving the changes?';

const queryBuilderRoutes = [
	'home.segment.accounts',
	'home.segment.accounts.contacts',
	'home.segment.contacts',
	'home.segment.explorer.builder',
	'home.segment.explorer.attributes',
	'home.segment.explorer.enumpicker',
	'home.ratingsengine.createAccountFitModeling.createSegment',
	'home.ratingsengine.createAccountFitModeling.createSegment.attributes',
	'home.ratingsengine.createAccountFitModeling.createSegment.picker',
	'home.ratingsengine.createAccountFitModeling.successCriteria',
	'home.ratingsengine.createAccountFitModeling.successCriteria.attributes',
	'home.ratingsengine.createAccountFitModeling.successCriteria.picker',
	'home.ratingsengine.createAccountFitModeling.scoringSegment',
	'home.ratingsengine.createAccountFitModeling.scoringSegment.attributes',
	'home.ratingsengine.createAccountFitModeling.scoringSegment.picker',
	'home.ratingsengine.rulesprospects.segment.attributes',
];

interface IQueryBuilderConfig {
	$state: StateService;
}

interface GetBucketRestrictionParams {
	columnName: string;
	objectType: Entity;
	range: number | null;
	attr: string;
	bkt: Bkt;
}

const saveSegment = (): Promise<void> => {
	dispatchSetIsSavingTrainingSegment(true);

	const segment = getQueryProperty<Query>('segment');
	if (segment) {
		delete segment.forFrontEnd;
	}

	const sanitizedSegment = getSanitizedSegmentByConfig(
		segment
			? {
					...segment,
					name: segment?.name === 'Create' ? '' : segment?.name,
			  }
			: undefined
	);

	return createOrUpdateSegment(sanitizedSegment).then((result) => {
		// Dark magic to be able to persist the segment in the react component AccountFitModelingWizard
		window.postMessage({segment: result.data});

		messageService.sendMessage(
			new Message(
				null,
				NOTIFICATION,
				SUCCESS,
				'The segment was saved successfully',
				''
			)
		);
	});
};

const sameSegmentTabTransitionCheck = ($state: StateService): boolean => {
	return (
		queryBuilderRoutes.includes($state.transition.to().name || '') &&
		queryBuilderRoutes.includes($state.current.name || '')
	);
};

const removeEventListener = (): void => {
	window.onbeforeunload = null;
};

const queryBuilderExit = (props: IQueryBuilderConfig): Promise<boolean> => {
	const {$state} = props;

	const enableSaveSegmentButton = getPublicProperty('enableSaveSegmentButton');

	if (
		($state.transition && sameSegmentTabTransitionCheck($state)) ||
		!enableSaveSegmentButton
	) {
		removeEventListener();
		return Promise.resolve(true);
	}

	const segment = getQueryProperty<Query>('segment');
	const display_name = segment?.display_name;
	const returnMsg = !display_name ? CreateMsg : updateMsg;
	return new Promise((resolve) => {
		DeprecatedModalService.warning(
			{
				title: 'Warning',
				message: returnMsg,
				dischargetext: 'Leave',
				confirmtext: 'Save Segment',
			},
			function ({action, data}) {
				const eventData = (data as Event)?.target as Element;
				if (eventData?.className?.includes('close')) {
					resolve(false);
					setPublicProperty('enableSaveSegmentButton', true);
					return false;
				}
				if (action === 'ok') {
					saveSegment();
				}
				setPublicProperty('enableSaveSegmentButton', false);
				removeEventListener();
				resolve(true);
				return true;
			}
		);
	});
};

const queryBuilderUnload = (): void => {
	const enableSaveSegmentButton = getPublicProperty('enableSaveSegmentButton');
	const segment = getQueryProperty<Query>('segment');
	const display_name = segment?.display_name;
	window.onbeforeunload = function (e) {
		const returnMsg = !display_name ? CreateMsg : updateMsg;
		e.returnValue = returnMsg;
		return returnMsg;
	};
	if (!enableSaveSegmentButton) {
		removeEventListener();
	}
};

const getBucketRestriction = ({
	columnName,
	objectType,
	range = null,
	attr,
	bkt,
}: GetBucketRestrictionParams): BucketRestriction => ({
	attr: attr || `${objectType}.${columnName}`,
	bkt: isNil(bkt) ? ({} as Bkt) : bkt,
	range,
	ignored: false,
});

export {
	queryBuilderExit,
	queryBuilderUnload,
	queryBuilderRoutes,
	getBucketRestriction,
	sameSegmentTabTransitionCheck,
};
