import {Operator} from '../../query.enums';
import {Restriction} from '../../query.types';
import {IQueryBuilder} from '../QueryBuilderType';

interface IQueryBuilderExtends extends IQueryBuilder {
	clickDelete?: (trees: Restriction[]) => void;
	clickDeleteAll?: (treeType: string, type: IQueryBlockType) => void;
	getRulesInputTree: () => Restriction[];
	getAccountTree: () => Restriction[];
	getContactTree: () => Restriction[];
	getSegmentUnionTree: () => Restriction[];
	getSegmentIntersectionTree: () => Restriction[];
}

interface IQueryBlock {
	scope: IQueryBuilderExtends;
	type: IQueryBlockType;
}

enum IQueryBlockType {
	Account = 'Account',
	Contact = 'Contact',
	Union = 'Union',
	Intersection = 'Intersection',
}

type IQueryBlockConfig = {
	[key in IQueryBlockType]: {
		beforeOperator?: Operator;
		visible?: boolean;
	};
};

const QueryBlockConfig: IQueryBlockConfig = {
	[IQueryBlockType.Account]: {
		beforeOperator: undefined,
		visible: true,
	},
	[IQueryBlockType.Contact]: {
		beforeOperator: Operator.AND,
		visible: true,
	},
	[IQueryBlockType.Union]: {
		beforeOperator: Operator.OR,
		visible: false,
	},
	[IQueryBlockType.Intersection]: {
		beforeOperator: Operator.AND,
		visible: false,
	},
};

export type {IQueryBlock, IQueryBlockConfig};
export {QueryBlockConfig, IQueryBlockType};
