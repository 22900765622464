import {OtherCategory} from 'atlas/data/CategoryConst';
import {ISegment, SegmentType, ListSystemType} from 'atlas/data/SegmentConst';
import {IModalAttribute} from 'atlas/modelsOld/modals/AddAttributeModal/AddAttributesModalConst';
import {getEnabledSegmentList} from 'atlas/modelsOld/modals/AddAttributeModal/AddAttributesModalWrapper';
import {SegmentStatus} from '../../../../segmentation/segmentation.constants';
import {IDataController} from './DataController';

/**
 * Segment filter function
 * @param param Segment data
 * @returns True if filtered
 */
const SegmentFilter = ({
	type,
	isSubSegment,
	listSegment,
	status,
}: ISegment): boolean => {
	const entityTypeList = getEnabledSegmentList();
	const isEntityEnabled = entityTypeList.includes(type);
	const isListSegmentCanCreateSub =
		listSegment?.systemType &&
		![ListSystemType.ContactList, ListSystemType.DataVision].includes(
			listSegment?.systemType
		);
	const isSegmentCanCreateSub = listSegment?.systemType
		? !!isListSegmentCanCreateSub
		: true;

	return (
		isEntityEnabled &&
		!isSubSegment &&
		isSegmentCanCreateSub &&
		status === SegmentStatus.ACTIVE
	);
};

/**
 * Segment sort function
 * @param param segment lhs
 * @param param segment rhs
 * @returns -1,0,1
 */
const SegmentSorter = (
	{display_name: lhs}: ISegment,
	{display_name: rhs}: ISegment
): number => {
	return lhs.localeCompare(rhs);
};

/**
 * Segment data controller
 * wrap raw segment data
 * provide modal attribute data for display usage
 */
class SegmentDataController implements IDataController {
	segment: ISegment;

	relation: string;

	constructor(segment: ISegment, relation: string) {
		this.segment = segment;
		this.relation = relation;
	}

	getKey = (): string => {
		const {
			segment: {name},
		} = this;
		return name;
	};

	getCategory = (): string => {
		const {
			segment: {dataType},
		} = this;
		const isListSegment = this.isListSegment();
		return isListSegment ? `${dataType}` : `${dataType} Segments`;
	};

	isShowSubCategory = (): boolean => {
		return false;
	};

	toModalAttribute = (): IModalAttribute => {
		const {
			segment: {type, display_name: name, description: Description, accounts},
			relation,
		} = this;
		const Category = this.getCategory();
		return {
			Category,
			Subcategory: OtherCategory,
			ShowSubcategory: false,
			Title: name,
			AttrName: this.getKey(),
			name,
			Description,
			type,
			bucketCount: accounts,
			relation,
		};
	};

	isListSegment = (): boolean => {
		const {
			segment: {type},
		} = this;
		return type === SegmentType.List;
	};
}

export {SegmentFilter, SegmentSorter, SegmentDataController};
