import React from 'common/react-vendor';
import {DNBTypography} from 'common/dnb-uux-vendor';
import {BucketRestriction} from '../../../../query.types';
import {
	getCompareReadable,
	getDirectionReadable,
	getValuesFormatted,
} from '../percentStore.helpers';

const PercentItem = ({
	bucketRestriction,
}: {
	bucketRestriction: BucketRestriction;
}): React.ReactElement => {
	return (
		<span>
			<DNBTypography
				component='span'
				variant='compact-body'
				className='querySectionItemValue'>
				has
			</DNBTypography>
			<span className='querySectionItemValue'>
				<strong>{getDirectionReadable(bucketRestriction)}</strong>
			</span>
			<span className='querySectionItemValue'>
				<strong>{getCompareReadable(bucketRestriction)}</strong>
			</span>
			<span className='querySectionItemValue'>
				<strong>{getValuesFormatted(bucketRestriction)}</strong>
			</span>
			<DNBTypography
				component='span'
				variant='compact-body'
				className='querySectionItemValue'>
				%
			</DNBTypography>
		</span>
	);
};

export {PercentItem};
