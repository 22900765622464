import React from 'common/react-vendor';
import {
	IAdvancedQueryContext,
	IAdvancedQueryContextUpdate,
	AdvancedQueryContext,
	AdvancedQueryContextUpdate,
} from '../context/AdvancedQueryContext';

const {useContext} = React;

interface IUseAdvancedQuery {
	context: IAdvancedQueryContext;
	setContext: IAdvancedQueryContextUpdate;
}

const useAdvancedQuery = (): IUseAdvancedQuery => {
	const context = useContext<IAdvancedQueryContext>(AdvancedQueryContext);
	const setContext = useContext<IAdvancedQueryContextUpdate>(
		AdvancedQueryContextUpdate
	);
	return {context, setContext};
};

export {useAdvancedQuery};
export type {IUseAdvancedQuery};
