import React from 'common/react-vendor';
import {
	DNBButton,
	DNBTag,
	DNBTooltip,
	DNBTypography,
} from 'common/dnb-uux-vendor';
import classNames from 'classnames';
import {
	getDisplayName,
	removeItem,
	shouldShowPagination,
} from './ChipsController';
import {useChipsContext} from './useChipsContext';
import styles from './Chips.module.scss';

export const ChipsTextContainer = (): React.ReactElement => {
	const {context, setContext} = useChipsContext();
	const {chips, disabled, chipsposition, displayName, usePagination} = context;
	const [showAll, setShowAll] = React.useState(!usePagination);
	const allChips = Object.entries(chips);
	return (
		<DNBTypography component='div'>
			<DNBTypography
				component='div'
				className={classNames({
					[styles.chipsContainer!]: true,
					[styles.scrollable!]: shouldShowPagination(usePagination, chips),
					[styles.expanded!]: showAll,
					[styles.bottom!]: chipsposition === 'bottom',
				})}>
				{allChips
					.filter((_, i) => i < (showAll ? allChips.length : 100))
					.map(([key, item]) => {
						const name = getDisplayName(displayName, item);
						return (
							<DNBTag
								type='default'
								key={key}
								className={styles.chip}
								sx={{
									color: (theme) => theme.colors.ColorGraySecondary,
								}}
								onDelete={() => removeItem(context, setContext, item)}
								disabled={disabled}
								label={
									<DNBTypography
										component='div'
										variant='caption'
										className={styles.chipContainer}
										sx={{display: 'flex', alignItems: 'center'}}>
										<DNBTooltip content={name} placement='top' arrow>
											<div className={styles.text}>
												<span>{name}</span>
											</div>
										</DNBTooltip>
									</DNBTypography>
								}
							/>
						);
					})}
			</DNBTypography>
			{shouldShowPagination(usePagination, chips) && (
				<DNBTypography
					component='div'
					className={classNames({
						[styles.chipsContainerPagination!]: true,
						[styles.bottom!]: chipsposition === 'bottom',
					})}>
					<DNBButton
						variant='text'
						size='compact'
						onClick={() => setShowAll(!showAll)}>
						{`${showAll ? 'Show Less' : 'Show All'}`}
					</DNBButton>
				</DNBTypography>
			)}
		</DNBTypography>
	);
};
