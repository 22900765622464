import type {CmpMap} from './tree.types';
import {BucketCmp} from '../../query.enums';

const twoInputs: BucketCmp[] = [
	BucketCmp.GT_AND_LT,
	BucketCmp.GT_AND_LTE,
	BucketCmp.GTE_AND_LT,
	BucketCmp.GTE_AND_LTE,
];

const noInputs: BucketCmp[] = [BucketCmp.IS_NULL, BucketCmp.IS_NOT_NULL];

const chipInputs: BucketCmp[] = [
	BucketCmp.IN_COLLECTION,
	BucketCmp.NOT_IN_COLLECTION,
];

// #region cmp labels
const IS = 'is';
const IS_NOT = 'is not';
const IS_EMPTY = 'is empty';
const IS_BETWEEN = 'is between';
const IS_EQUAL_TO = 'is equal to';
const BETWEEN = 'between';
const BETWEEN_LAST = 'between last';
const PREVIOUS = 'previous';
const ONLY_PRIOR_TO_LAST = 'only prior to last';
const EQUAL_TO = 'equal to';
const NOT_EQUAL_TO = 'not equal to';
// #endregion cmp labels

// #region presets
const equalMap = {
	[BucketCmp.EQUAL]: IS_EQUAL_TO,
};

const everMap = {
	[BucketCmp.EVER]: 'ever',
};

const inCurrentPeriodMap = {
	[BucketCmp.IN_CURRENT_PERIOD]: 'current',
};

const withinMap = {
	[BucketCmp.WITHIN]: PREVIOUS,
};

const priorOnlyMap = {
	[BucketCmp.PRIOR_ONLY]: ONLY_PRIOR_TO_LAST,
};

const betweenDateMap = {
	[BucketCmp.BETWEEN_DATE]: BETWEEN,
};

const beforeMap = {
	[BucketCmp.BEFORE]: 'before',
};

const afterMap = {
	[BucketCmp.AFTER]: 'after',
};

const inCollectionMap = {
	[BucketCmp.IN_COLLECTION]: EQUAL_TO,
};

const inCollectionMapIsEqual = {
	[BucketCmp.IN_COLLECTION]: IS_EQUAL_TO,
};

const notInCollectionMap = {
	[BucketCmp.NOT_IN_COLLECTION]: NOT_EQUAL_TO,
};

const notInCollectionMapIsNot = {
	[BucketCmp.NOT_IN_COLLECTION]: IS_NOT,
};

const isNullMap = {
	[BucketCmp.IS_NULL]: IS_EMPTY,
};

const isNotNullMap = {
	[BucketCmp.IS_NOT_NULL]: 'is present',
};

const startsWithMap = {
	[BucketCmp.STARTS_WITH]: 'starts with',
};

const endsWithMap = {
	[BucketCmp.ENDS_WITH]: 'ends with',
};

const containsMap = {
	[BucketCmp.CONTAINS]: 'contains',
};
// #endregion presets

const cmpMap: CmpMap = {
	[BucketCmp.YES]: IS,
	[BucketCmp.NO]: IS,
	[BucketCmp.EMPTY]: IS,
	[BucketCmp.IS]: IS,
	[BucketCmp.IS_EMPTY]: IS_EMPTY,
	[BucketCmp.LOW_CASE_BETWEEN]: IS_BETWEEN,
	...isNullMap,
	...isNotNullMap,
	...equalMap,
	[BucketCmp.NOT_EQUAL]: 'is not equal to',
	[BucketCmp.GREATER_THAN]: 'is greater than',
	[BucketCmp.GREATER_OR_EQUAL]: 'is greater than or equal to',
	[BucketCmp.LESS_THAN]: 'is less than',
	[BucketCmp.LESS_OR_EQUAL]: 'is less than or equal to',
	[BucketCmp.GTE_AND_LTE]: 'is greater than or equal and lesser than or equal',
	[BucketCmp.GTE_AND_LT]: IS_BETWEEN,
	[BucketCmp.GT_AND_LTE]: 'is greater than and lesser or equal',
	[BucketCmp.GT_AND_LT]: 'is greater than and less than',
	...inCollectionMapIsEqual,
	...notInCollectionMapIsNot,
	...containsMap,
	[BucketCmp.NOT_CONTAINS]: 'not contains',
	...startsWithMap,
	...inCurrentPeriodMap,
	...everMap,
	[BucketCmp.ANY]: 'any',
	...beforeMap,
	[BucketCmp.BETWEEN]: BETWEEN,
	...afterMap,
	[BucketCmp.BETWEEN_LT]: BETWEEN_LAST,
	[BucketCmp.PREVIOUS]: PREVIOUS,
	[BucketCmp.PRIOR_ONLY_LT]: ONLY_PRIOR_TO_LAST,
	...endsWithMap,
	...withinMap,
	...priorOnlyMap,
	...betweenDateMap,
};

const transactionMap: CmpMap = {
	...everMap,
	...inCurrentPeriodMap,
	...withinMap,
	...priorOnlyMap,
	[BucketCmp.BETWEEN]: BETWEEN_LAST,
	...betweenDateMap,
	...beforeMap,
	...afterMap,
};

const numericalOperations: CmpMap = {
	...inCollectionMap,
	...notInCollectionMap,
	[BucketCmp.GREATER_THAN]: 'greater than',
	[BucketCmp.GREATER_OR_EQUAL]: 'greater than or equal to',
	[BucketCmp.LESS_THAN]: 'less than',
	[BucketCmp.LESS_OR_EQUAL]: 'less than or equal to',
	[BucketCmp.GT_AND_LT]: 'between (value1, value2)',
	[BucketCmp.GTE_AND_LT]: 'between [value1, value2)',
	[BucketCmp.GT_AND_LTE]: 'between (value1, value2]',
	[BucketCmp.GTE_AND_LTE]: 'between [value1, value2]',
	...isNullMap,
	...isNotNullMap,
};

const enumOperations: CmpMap = {
	...equalMap,
	[BucketCmp.NOT_EQUAL]: IS_NOT,
	...inCollectionMapIsEqual,
	...notInCollectionMapIsNot,
};

const stringOperations: CmpMap = {
	...inCollectionMap,
	...notInCollectionMap,
	...startsWithMap,
	...endsWithMap,
	...containsMap,
	[BucketCmp.NOT_CONTAINS]: 'does not contain',
	...isNullMap,
	...isNotNullMap,
};

const numericalLabels: CmpMap = {
	[BucketCmp.EQUAL]: '=',
	[BucketCmp.NOT_EQUAL]: '!=',
	[BucketCmp.GREATER_THAN]: '>',
	[BucketCmp.GREATER_OR_EQUAL]: '>=',
	[BucketCmp.LESS_THAN]: '<',
	[BucketCmp.LESS_OR_EQUAL]: '<=',
	...isNullMap,
	...isNotNullMap,
	...inCollectionMapIsEqual,
};

const segmentRelationOptions = {
	INCLUDE: 'include',
	EXCLUDE: 'exclude',
};

enum SegmentMember {
	Account = 'account',
	Contact = 'contact',
}

const segmentEntityOptions = {
	AccountSegmentMember: SegmentMember.Account,
	ContactSegmentMember: SegmentMember.Contact,
};

const booleanOperation = {
	true: 'Yes',
	false: 'No',
	present: 'Present',
	empty: 'Empty',
};

const booleanOperationTransaction = {
	true: 'Yes',
	false: 'No',
};

export {
	twoInputs,
	noInputs,
	chipInputs,
	cmpMap,
	transactionMap,
	numericalOperations,
	enumOperations,
	stringOperations,
	numericalLabels,
	segmentRelationOptions,
	SegmentMember,
	segmentEntityOptions,
	booleanOperation,
	booleanOperationTransaction,
};
