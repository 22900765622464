import {isEmpty, isNil} from 'lodash';
import {
	Query,
	RatingModelRule,
	CreateOrUpdateSegmentResponse,
} from 'common/components/datacloud/datacloud.types';
import {
	LogicalRestriction,
	Restriction,
	Restrictions,
} from 'common/components/datacloud/query/query.types';
import {isSegmentationV2Enabled} from 'common/stores/tenantConfig';
import {createOrUpdateSegment as createOrUpdateSegmentQuery} from './segment.queries';

// TODO: Angular adds $$hashKey for internal use, this function removes
//  this property, but it will not be needed when the migration to React
//  is finished so it should be removed
const deleteAngularHashKey = <Type>(object: Type): Type => {
	return JSON.parse(
		JSON.stringify(object, function (key, value) {
			if (key === '$$hashKey') {
				return;
			}
			return value;
		})
	);
};

const isEmptyBucket = (bucket?: Restriction): boolean => {
	if (!bucket) {
		return false;
	}

	const hasEmptyId =
		bucket &&
		bucket.bucketRestriction &&
		(!bucket.bucketRestriction.bkt || !bucket.bucketRestriction.bkt.Id);

	const hasEmptyName =
		bucket &&
		bucket.segmentMemberRestriction &&
		!bucket.segmentMemberRestriction.segmentName;

	return hasEmptyId || hasEmptyName || false;
};

const removeEmptyBuckets = (restrictions: Restrictions): Restrictions => {
	const tree = [...restrictions];

	for (let i = 0; i < tree.length; i++) {
		const branch = tree[i];

		if (isEmptyBucket(branch)) {
			tree.splice(i, 1);
		}

		if (branch && branch.logicalRestriction) {
			removeEmptyBuckets(branch.logicalRestriction.restrictions);
		}
	}

	return tree;
};

/**
 * TODO: this function mutates and returns the same object that is
 * received due to conflicts with angular, once the services that
 * consumes this function gets refactor, change the function to
 * return a new object instead of reassigning the parameters
 */
const sanitizeSegmentRestriction = (
	tree: Restrictions,
	removeBkt = false
): Restrictions => {
	if (!isEmpty(tree)) {
		tree.forEach(function (branch) {
			if (!isNil(branch?.labelGlyph)) {
				// eslint-disable-next-line no-param-reassign
				delete branch.labelGlyph;
			}

			if (!isNil(branch?.collapsed)) {
				// eslint-disable-next-line no-param-reassign
				delete branch.collapsed;
			}

			if (removeBkt && !isNil(branch?.bucketRestriction?.bkt)) {
				// @ts-ignore
				// eslint-disable-next-line no-param-reassign
				delete branch.bucketRestriction.bkt;
			}

			if (branch?.activityRestriction) {
				if (!isNil(branch?.activityRestriction?.restriction?.collapsed)) {
					// eslint-disable-next-line no-param-reassign
					delete branch?.activityRestriction?.restriction?.collapsed;
				}
				sanitizeSegmentRestriction(
					branch?.activityRestriction?.restriction?.logicalRestriction
						?.restrictions || [],
					removeBkt
				);
			}

			if (branch?.logicalRestriction) {
				sanitizeSegmentRestriction(
					branch.logicalRestriction.restrictions,
					removeBkt
				);
			}
		});
	}

	return tree;
};

const sanitizeRuleBuckets = (
	rule: RatingModelRule,
	keepEmptyBuckets = false
): RatingModelRule => {
	const newRule = {...rule};
	const {bucketToRuleMap = {}} = newRule.ratingRule;

	Object.entries(bucketToRuleMap).forEach(function ([bucketName, bucket]) {
		if (bucket) {
			if (!keepEmptyBuckets) {
				const account =
					bucket?.account_restriction?.logicalRestriction?.restrictions;
				const contact =
					bucket?.contact_restriction?.logicalRestriction?.restrictions;

				if (isEmpty(account) && isEmpty(contact)) {
					delete bucketToRuleMap[bucketName];
				}

				if (bucket.account_restriction) {
					removeEmptyBuckets([bucket.account_restriction]);
				}

				if (bucket.contact_restriction) {
					removeEmptyBuckets([bucket.contact_restriction]);
				}
			}

			if (bucket.account_restriction) {
				sanitizeSegmentRestriction([bucket.account_restriction]);
			}

			if (bucket.contact_restriction) {
				sanitizeSegmentRestriction([bucket.contact_restriction]);
			}
		}
	});

	return newRule;
};

/**
 * TODO: this function mutates and returns the same object that is received
 * due to conflicts with angular and refactored typescript functions, once
 * the services that consumes this function gets refactor, change the function
 * to return a new object instead of reassigning the parameters
 */
const sanitizeSegment = (segment: Query): Query => {
	if (segment?.account_restriction?.restriction) {
		sanitizeSegmentRestriction([segment.account_restriction.restriction]);
	}

	if (segment?.contact_restriction?.restriction) {
		sanitizeSegmentRestriction([segment.contact_restriction.restriction]);
	}

	if (isSegmentationV2Enabled() && segment?.member_restriction?.restriction) {
		sanitizeSegmentRestriction([segment.member_restriction.restriction]);
	}

	return segment;
};

const createOrUpdateSegment = (
	segment: Query,
	restriction: Restriction
): Promise<CreateOrUpdateSegmentResponse> => {
	const currentTime = new Date().getTime();

	const newSegment = {
		name: segment ? segment.name : `segment${currentTime}`,
		display_name: segment ? segment.display_name : `segment${currentTime}`,
		restrict_without_sfdcid: false,
		account_restriction: segment
			? restriction || segment.account_restriction
			: restriction,
		page_filter: {
			row_offset: 0,
			num_rows: 10,
		},
	};

	return createOrUpdateSegmentQuery(sanitizeSegment(newSegment)).then(
		(response) => response.data
	);
};

const flattenRestriction = (
	restriction: Restriction,
	hasActivity = false
): Restrictions => {
	const newRestrictions: Restrictions = [];

	if (restriction.bucketRestriction || restriction.segmentMemberRestriction) {
		newRestrictions.push(restriction);
	}

	if (
		restriction.activityRestriction?.restriction.logicalRestriction &&
		hasActivity
	) {
		restriction.activityRestriction?.restriction.logicalRestriction.restrictions.forEach(
			function (logicalRestriction) {
				newRestrictions.push(
					...flattenRestriction(logicalRestriction, hasActivity)
				);
			}
		);
	}

	if (restriction.logicalRestriction) {
		restriction.logicalRestriction.restrictions.forEach(function (
			logicalRestriction
		) {
			newRestrictions.push(
				...flattenRestriction(logicalRestriction, hasActivity)
			);
		});
	}

	return newRestrictions;
};

const flattenLogicalRestrictions = (
	restrictions: Restrictions,
	hasActivity?: boolean
): Restrictions => {
	if (isEmpty(restrictions)) {
		return restrictions;
	}

	return restrictions.reduce((accumulator: Restrictions, restriction) => {
		return [...accumulator, ...flattenRestriction(restriction, hasActivity)];
	}, []);
};

const flattenSegmentRestrictions = (segment: Query): Restrictions => {
	const accountRestrictions = flattenLogicalRestrictions(
		segment?.account_restriction?.restriction?.logicalRestriction
			?.restrictions || []
	);

	const contactRestrictions = flattenLogicalRestrictions(
		segment?.contact_restriction?.restriction?.logicalRestriction
			?.restrictions || []
	);

	const memberRestrictions = isSegmentationV2Enabled()
		? flattenLogicalRestrictions(
				segment?.member_restriction?.restriction?.logicalRestriction
					?.restrictions || []
		  )
		: [];

	return [
		...accountRestrictions,
		...contactRestrictions,
		...memberRestrictions,
	];
};

const findAndUpdatedLogicalRestriction = (
	rootLogicalRestriction: LogicalRestriction | Restriction,
	search: LogicalRestriction | Restriction,
	updated: LogicalRestriction | Restriction
): LogicalRestriction | Restriction => {
	return JSON.parse(
		JSON.stringify(rootLogicalRestriction).replace(
			JSON.stringify(search),
			JSON.stringify(updated)
		)
	);
};

export {
	sanitizeRuleBuckets,
	sanitizeSegmentRestriction,
	isEmptyBucket,
	removeEmptyBuckets,
	sanitizeSegment,
	createOrUpdateSegment,
	flattenSegmentRestrictions,
	deleteAngularHashKey,
	flattenLogicalRestrictions,
	findAndUpdatedLogicalRestriction,
};
