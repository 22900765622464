import {
	Restriction,
	Restrictions,
} from 'common/components/datacloud/query/query.types';
import {AttributeEntity} from 'common/components/datacloud/query/advanced/tree/types';
import {ISegmentMemberRestrictionType} from 'atlas/data/RestrictionConst';
import {
	CounterMap,
	IAttributeValues,
} from 'atlas/modelsOld/modals/AddAttributeModal/Helper/CounterMap';
import {SegmentType} from 'atlas/data/SegmentConst';
import {IModalAttributeMap} from '../AddAttributesModalConst';
import {
	getSelectedCountText,
	IModalController,
	searchPlaceHolder,
} from './ModalControllerType';

/**
 * Segment modal controller
 * Used for segments display
 */
class SegmentModalController implements IModalController {
	private modalAttributeMap: IModalAttributeMap;

	private restrictions: Restrictions;

	private selectedCounterMap: CounterMap;

	constructor(
		modalAttributeMap: IModalAttributeMap,
		restrictions: Restrictions
	) {
		this.modalAttributeMap = modalAttributeMap;
		this.restrictions = restrictions;
		this.selectedCounterMap = new CounterMap(this.getSelectedAttributes());
	}

	name = (): string => {
		return 'segment';
	};

	getTitle = (): string => {
		return `Select a ${this.name()}`;
	};

	searchPlaceHolder = (): string => {
		return searchPlaceHolder(this.name());
	};

	getCounterMap = (): CounterMap => this.selectedCounterMap;

	getSelectedAttributes = (): IAttributeValues[] => {
		const {restrictions} = this;
		return restrictions
			.map((restriction) => restriction as ISegmentMemberRestrictionType)
			.filter(
				({segmentMemberRestriction}) => !!segmentMemberRestriction?.segmentName
			)
			.map(({segmentMemberRestriction: {segmentName}}) => {
				return {
					attrId: segmentName,
					bkt: undefined,
				};
			});
	};

	getSelectedCountText = (): string => {
		const selectCount = this.selectedCounterMap.totalCount();
		return getSelectedCountText(this.name(), selectCount);
	};

	isAttributeDisabled = (id: string): boolean => {
		const {modalAttributeMap, selectedCounterMap} = this;
		const isTAM = (id: string): boolean =>
			modalAttributeMap[id]?.type === SegmentType.TAM;
		/** the subsegment of TAM could only has one parent segment (PLS-26055) */
		const hasTam = selectedCounterMap.toKeyArray().filter(isTAM)?.length > 0;
		const isCurrentTam = isTAM(id);
		return (
			hasTam ||
			(!hasTam && selectedCounterMap.toKeyArray().length === 1 && isCurrentTam)
		);
	};

	toAttributeKey = (restriction: Restriction): string => {
		const {segmentMemberRestriction: {segmentName} = {segmentName: ''}} =
			restriction as ISegmentMemberRestrictionType;
		return segmentName;
	};

	toRestriction = (id: string, _inRulesBasedModal: boolean): Restriction => {
		const {modalAttributeMap} = this;
		const modalAttribute = modalAttributeMap[id]!;
		const {bucketCount, relation = 'INCLUDE'} = modalAttribute;
		return {
			segmentMemberRestriction: {
				entity: AttributeEntity.AccountSegmentMember,
				segmentName: id,
				ignored: false,
				bkt: {
					Cnt: bucketCount || -1,
				},
				relation,
			},
		};
	};

	needUpdateRestrictionCount = (): boolean => {
		return false;
	};
}

export {SegmentModalController};
