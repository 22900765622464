import _ from 'lodash';
import React from 'common/react-vendor';
import RBAC, {
	AccessGroups,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import LocalStorageUtility from 'common/widgets/utilities/local-storage.utility';
import LeItemBar from 'widgets/itembar/le-itembar';
import LeSearch from 'widgets/filters/search/le-search';
import {
	DNBButton,
	IosShareIcon,
	SaveOutlinedIcon,
	BackspaceOutlinedIcon,
	ContentCopyOutlinedIcon,
	RefreshIcon,
} from 'common/dnb-uux-vendor';
import LeDropdown from 'widgets/rebrand/dropdown/le-dropdown';
import {store} from 'store';
import {
	getDataCloudProperty,
	getDataCloudMetadataProperty,
	setDataCloudPropertyValue,
} from 'common/stores/datacloud';
import {
	isEntityMatchEnabled,
	isSegmentationV2Enabled,
} from 'common/stores/tenantConfig';
import {setPublicProperty, getQueryProperty} from 'common/stores/query';
import UserValues from 'common/widgets/utilities/user-values.utility';
import {
	ACCOUNTS_PAGE_VIEW_KEY,
	CONTACTS_PAGE_VIEW_KEY,
} from '../../query/results/rebuild';
import './subheader.component.scss';
import getSanitizedSegmentByConfig, {
	ViewType,
	showExportBanner,
} from './SubheaderUtility';
import {queryBuilderUnload} from '../../query/queryBuilder.utilities';
import {
	refreshCounts as refreshQueryCounts,
	resetRestrictions,
} from '../../query/query.helpers';
import {
	getSegmentByName,
	createOrUpdateSegment,
	createOrUpdateSegmentExport,
	updateSegmentCounts,
} from '../../segment/segment.queries';
import HealthService from '../../../../app/services/HealthService';
import ConfigurationAttributesService from '../../../../../atlas/app/configureattributes/ConfigurationAttributesService';
import NoticeService from '../../../notice/NoticeService';
import {getTopPredictorExport} from '../../../../../atlas/app/AppCommon/topPredictor/topPredictor.helpers';
import StateHistory from '../../../../../atlas/app/StateHistory';
import {useSelector} from 'react-redux';
import {SegmentNgState} from '../../../../../atlas/app/helpers/NavigateHelper';
import {getRatingsEngineProperty} from '../../../../../atlas/app/stores/ratingsEngine';
import {SaveSegmentDialog} from './SaveSegmentDialog/SaveSegmentDialog';
import {EntitySessionKeyMap} from '../../../../../atlas/app/segmentation/JourneyEntity/JourneyEntity';

const reduxPath = 'datacloud.tabs.mydatasubheader';
const {useState, useEffect} = React;

function MyDataSubheader({
	$scope,
	$state,
	$rootScope,
	$stateParams,
	$timeout,
	DuplicateSegmentModal,
	ExportSegmentModal,
	vmEnrichments,
	vmTeams,
}) {
	queryBuilderUnload();
	const vmSegment = getQueryProperty('segment');

	const currentRating = getRatingsEngineProperty('currentRating');

	let model =
		currentRating && Object.keys(currentRating).length ? currentRating : null;
	const clientSession = LocalStorageUtility.getClientSession();

	if ($stateParams.segment && !model) {
		model = vmSegment || null; // cheap fix for if this is a segment and not a model and there is no model
	}
	const queryStore = useSelector((state) => state?.query);

	const [enrichments, setEnrichments] = useState(vmEnrichments);
	const {segment, section} = $stateParams;
	const canSaveSegment =
		RBAC.userHasAccessLevel(AccessGroups.EXCLUDE_SALES) &&
		RBAC.hasAccess(RBACInterface.SEGMENTS, RBACActions.CREATE);
	const canEditSegment = model
		? !model.viewOnly &&
		  RBAC.hasAccess(RBACInterface.SEGMENTS, RBACActions.EDIT)
		: true;
	const canEditAIModel = $stateParams.aiModel
		? RBAC.hasAccess(RBACInterface.MODELS, RBACActions.EDIT)
		: false;
	const userHasTeams = clientSession.TeamIds && clientSession.TeamIds.length;
	const publicQueryStore = queryStore?.public || {};
	const [isSaving, setIsSaving] = useState(false);
	const [enableSaveSegmentMsg, setEnableSaveSegmentMsg] = useState(false);

	const checkStatusBeforeExport = (exportType, event) => {
		event.preventDefault();
		HealthService.checkSystemStatus().then(() => {
			toggleExportDropdown(true); // disable dropdown
			exportSegment(exportType);
		});
	};

	const counts = queryStore?.counts || {};

	const [header, setHeader] = useState({
		exportSegment: {
			class: 'white-button select-label',
			click: false,
			icon: 'fa fa-chevron-down',
			iconlabel: 'Export',
			iconclass: 'save button white-button select-more',
			iconrotate: true,
			icondisabled: false,
			showSpinner: false,
			items: [
				{
					label: 'Enriched Accounts',
					icon: 'fa fa-building-o',
					class: 'aptrinsic-export-enriched-accounts',
					click: function (event) {
						() => checkStatusBeforeExport('ACCOUNT', event);
					},
					disabledif: !counts.accounts.loading && !counts.accounts.value,
				},
				{
					label: 'Enriched Contacts with Account Attributes',
					icon: 'fa fa-briefcase',
					class: 'aptrinsic-export-enriched-contacts-accounts',
					click: function (event) {
						() => checkStatusBeforeExport('ACCOUNT_AND_CONTACT', event);
					},
					disabledif:
						!counts.accounts.loading &&
						!counts.contacts.loading &&
						(counts.accounts.value === 0 || counts.contacts.value === 0),
				},
				{
					label: 'Enriched Contacts (No Account Attributes)',
					icon: 'fa fa-users',
					class: 'aptrinsic-export-enriched-contacts',
					click: function (event) {
						() => checkStatusBeforeExport('CONTACT', event);
					},
					disabledif: !counts.contacts.loading && !counts.contacts.value,
				},
			],
		},
	});

	const [teams, setTeams] = useState(vmTeams);
	const [isRefreshing, setIsRefreshing] = useState(false);

	const checkState = (type) => {
		const state = $state.current.name;

		const map = {
			'home.segment.explorer.attributes': 'attributes',
			'home.segment.explorer.builder': 'builder',
			'home.segment.explorer.enumpicker': 'picker',
			'home.segment.accounts.contacts.noActivity': 'contacts.noActivity',
			'home.segment.accounts.contacts.list': 'contacts.list',
			'home.segment.accounts.contacts.activity': 'contacts.activity',
			'home.segment.accounts.contacts.dashboard': 'contacts.dashboard',
			'home.segment.accounts': 'accounts',
			'home.segment.contacts': 'contacts',
			[SegmentNgState.Dashboard]: 'dashboard',
			[SegmentNgState.JourneyAccounts]: 'accounts',
			[SegmentNgState.JourneyContacts]: 'contacts',
			'home.model.datacloud': 'model_iteration',
			'home.ratingsengine.createAccountFitModeling.createSegment':
				'account.modeling',
			'home.ratingsengine.createAccountFitModeling.scoringSegment':
				'account.modeling',
		};

		return map[state] === type;
	};
	const inAccountsOrContactsTab =
		checkState('accounts') || checkState('contacts');
	const inWizard = !!(
		section == 'wizard.ratingsengine_segment' && queryStore?.addBucketTreeRoot
	);

	const inEditTab =
		checkState('dashboard') ||
		checkState('builder') ||
		checkState('account.modeling');

	const [datacollectionPrecheck, setDatacollectionPrecheck] = useState(null);
	useEffect(() => {
		function getDatacollectionPrecheck() {
			ConfigurationAttributesService.getPrecheck().then(function (result) {
				setDatacollectionPrecheck(result);
			});
		}

		if (['segment.analysis'].indexOf(section) != -1) {
			getDatacollectionPrecheck();
		}
	}, [section]);

	useEffect(() => {
		if (!_.isEqual(vmTeams, teams)) {
			setTeams(vmTeams);
		}

		if (!_.isEqual(vmEnrichments, enrichments)) {
			setEnrichments(vmEnrichments);
		}
	}, [vmTeams, vmEnrichments]);

	/* Search Field */
	const getSearchConfig = () => {
		return {
			placeholder: 'Search Attributes',
			query: '',
			open: false,
			static: true,
		};
	};

	const [enableClearSegmentButton, setEnableClearSegmentButton] =
		useState(true);

	/** Admin dropdown functions
	 *  Each option currently has three parts.
	 *  Disable check, visibility check, and an action function.
	 *  NOTE: This will eventually be moved up to the navigation bar.
	 */
	const showJobs =
		RBAC.userHasAtlasAccess() &&
		RBAC.hasAccess(RBACInterface.JOBS, RBACActions.VIEW);
	const openJobs = () => {
		$state.go('home.jobs.data', {}, {reload: true});
	};

	const disableAttributeAdmin = !RBAC.hasAccess(
		RBACInterface.ATTR_ADMIN,
		RBACActions.VIEW
	);
	const showAttributeAdmin = () => {
		if (section == 'insight' || section == 'wizard.ratingsengine_segment') {
			return false;
		}

		return ['segment.analysis'].indexOf(section) != -1 && !inWizard;
	};
	const openAttributeAdmin = () => {
		$state.go('home.attributes', {}, {reload: true});
	};

	const disableImportData = !RBAC.hasAccess(
		RBACInterface.IMPORT_DATA,
		RBACActions.VIEW
	);
	const showImportData = () => {
		return (
			['segment.analysis'].indexOf(section) != -1 &&
			!inWizard &&
			RBAC.userHasAccessLevel(AccessGroups.ADMINS)
		);
	};
	const openImportData = () => {
		isEntityMatchEnabled()
			? $state.go('home.multipletemplates', {}, {reload: true})
			: $state.go('home.importtemplates', {}, {reload: true});
	};

	const disableConfigureAttributes =
		!datacollectionPrecheck ||
		!RBAC.hasAccess(RBACInterface.CONFIGURE_ATTR, RBACActions.VIEW) ||
		(datacollectionPrecheck && datacollectionPrecheck.disableAllCuratedMetrics);
	const showConfigureAttributes = () => {
		return ['segment.analysis'].indexOf(section) != -1 && !inWizard;
	};
	const openConfigureAttributes = () => {
		$state.go('home.attributes.configureattributes', {}, {reload: true});
	};

	const disableConfigureCalendar = !RBAC.hasAccess(
		RBACInterface.CONFIGURE_CALENDAR,
		RBACActions.VIEW
	);
	const showConfigureCalendar = () => {
		return showImportData();
	};
	const openConfigureCalendar = () => {
		$state.go('home.import.calendar', {}, {reload: true});
	};

	const disableDeleteData = !RBAC.hasAccess(
		RBACInterface.DELETE_DATA,
		RBACActions.VIEW
	);
	const showDeleteData = () => {
		return (
			['segment.analysis'].indexOf(section) != -1 &&
			!inWizard &&
			RBAC.userHasAccessLevel(AccessGroups.ADMINS)
		);
	};
	const openDeleteData = () => {
		isEntityMatchEnabled()
			? $state.go('home.deletenew', {}, {reload: true})
			: $state.go('home.delete', {}, {reload: true});
	};

	const adminDropdownItems = [
		{
			label: 'Jobs',
			disabled: false,
			visible: showJobs,
			open: openJobs,
		},
		{
			label: 'Attribute Admin',
			disabled: disableAttributeAdmin,
			visible: showAttributeAdmin(),
			open: openAttributeAdmin,
		},
		{
			label: 'Import Data',
			disabled: disableImportData,
			visible: showImportData(),
			open: openImportData,
		},
		{
			label: 'Product Spend Profile Attributes',
			disabled: disableConfigureAttributes,
			visible: showConfigureAttributes(),
			open: openConfigureAttributes,
		},
		{
			label: 'Configure Calendar',
			disabled: disableConfigureCalendar,
			visible: showConfigureCalendar(),
			open: openConfigureCalendar,
		},
		{
			label: 'Delete Data',
			disabled: disableDeleteData,
			visible: showDeleteData(),
			open: openDeleteData,
		},
	];

	const getAdminConfig = () => {
		return {
			label: 'Admin',
			classNames: ['blue-text-dropdown'],
			disabled: false,
			icon: 'fa fa-cog',
			items: [...adminDropdownItems],
		};
	};

	const clearSegment = () => {
		setPublicProperty('enableSaveSegmentButton', false);
		$rootScope.$broadcast('clearSegment');
		setEnableClearSegmentButton(!enableClearSegmentButton);
		resetRestrictions(undefined, () => $scope.$apply());
	};

	const [isSaveSegmentOpen, setIsSaveSegmentOpen] = useState(false);

	const saveSegment = () => {
		const segmentName = segment;
		const isNewSegment = segmentName === 'Create';

		if (isNewSegment) {
			setIsSaveSegmentOpen(true);
		} else {
			const xhrSaveSegment = (segmentData) => {
				const {name} = segmentData;
				const displayName = segmentData.display_name;

				if (!displayName) {
					setPublicProperty('enableSaveSegmentButton', true);
					return;
				}

				const {display_name: modifiedDisplayName} =
					queryStore?.segment ?? segmentData;

				const {description} = segmentData;
				const {teamId} = segmentData;

				const segmentConfig = {
					name,
					display_name:
						displayName === modifiedDisplayName
							? displayName
							: modifiedDisplayName,
					description,
					teamId,
					page_filter: {
						row_offset: 0,
						num_rows: 10,
					},
				};
				if ($stateParams.isTAM) {
					segmentConfig.type = 'TAM';
				}
				if (isSegmentationV2Enabled()) {
					segmentConfig.materialized = segmentData.materialized;
				}
				const sanitizedSegment = getSanitizedSegmentByConfig(segmentConfig);
				setPublicProperty('enableSaveSegmentButton', false);
				setIsSaving(true);
				createOrUpdateSegment(sanitizedSegment).then((response) => {
					// Dark magic to be able to persist the segment in the react component AccountFitModelingWizard
					window.postMessage({segment: response.data});

					NoticeService.success({
						title: 'The segment was saved successfully',
					});

					setEnableSaveSegmentMsg(true);
					$timeout(() => {
						setEnableSaveSegmentMsg(false);
					}, 3500);

					setIsSaving(false);
				});
			};

			setPublicProperty('enableSaveSegmentButton', false);

			const xhrGetSegmentResult = (result) => {
				xhrSaveSegment(result);
			};

			getSegmentByName(segmentName).then(xhrGetSegmentResult);
		}
	};

	const duplicateSegment = () => {
		DuplicateSegmentModal.show(vmSegment, teams);
	};

	const toggleExportDropdown = (bool) => {
		const newHeader = {...header};
		newHeader.exportSegment = {...newHeader.exportSegment, icondisabled: bool};
		newHeader.exportSegment = {...newHeader.exportSegment, showSpinner: bool};
		setHeader({...header, ...newHeader});
	};

	const exportSegment = (
		exportType,
		attributeSetName,
		exportTimestamp,
		visibleColumns
	) => {
		const segmentName = segment;
		setPublicProperty('resetLabelIncrementor', true);

		const onSuccess = attributeSetName
			? (success) => {
					if (success) showExportBanner();
					ExportSegmentModal.toggleLoadingState(false);
					ExportSegmentModal.hide();
			  }
			: (success) => {
					if (success) showExportBanner();
					toggleExportDropdown(false);
			  };

		if (segmentName === 'Create') {
			const segmentExportConfig = {
				type: exportType,
				addExportTimestamp: exportTimestamp,
				...(visibleColumns ? {visibleColumns} : {}),
			};
			const sanitizedSegment = getSanitizedSegmentByConfig(segmentExportConfig);

			createOrUpdateSegmentExport(sanitizedSegment, attributeSetName).then(
				onSuccess
			);
		} else {
			const getAtlasExportFilter = () => {
				const type = checkState('accounts')
					? 'accounts'
					: checkState('contacts')
					? 'contacts'
					: undefined;
				if (!type) return;
				const sessionKey = EntitySessionKeyMap[type];
				const stageIdListStr = sessionStorage.getItem(sessionKey);
				return stageIdListStr
					? {
							journeyStages: stageIdListStr
								.split(',')
								.map((id) => parseInt(id)),
							segmentName,
					  }
					: undefined;
			};
			getSegmentByName(segmentName).then((result) => {
				const segmentData = result;
				const segmentExportConfig = {
					segmentName,
					export_prefix: segmentData.display_name,
					type: exportType,
					addExportTimestamp: exportTimestamp,
					...(visibleColumns ? {visibleColumns} : {}),
					atlasExportFilter: getAtlasExportFilter(),
				};
				const sanitizedSegment =
					getSanitizedSegmentByConfig(segmentExportConfig);
				createOrUpdateSegmentExport(sanitizedSegment, attributeSetName).then(
					onSuccess
				);
			});
		}
	};

	const exportSegmentFromModal = (
		exportType,
		attributeSetName,
		exportTimestamp,
		visibleColumns
	) => {
		HealthService.checkSystemStatus().then(() => {
			LocalStorageUtility.set(
				`${segment}_last_attribute_set_exported`,
				attributeSetName
			);
			exportSegment(
				exportType,
				attributeSetName,
				exportTimestamp,
				visibleColumns
			);
		});
	};

	const toggleExportModal = () => {
		const accountsAvailable = !!counts.accounts.value;
		const contactsAvailable = !!counts.contacts.value;
		const defaultAttributeSetSelection =
			LocalStorageUtility.get(`${segment}_last_attribute_set_exported`) ||
			'Default_Group';

		const canExportSegments = accountsAvailable || contactsAvailable;

		const onClickExport = exportSegmentFromModal;

		if (!canExportSegments) {
			NoticeService.warning({
				message:
					'Cannot export segment: This segment contains no accounts or contacts.',
			});
			return;
		}

		let viewType = ViewType.Generic;
		const keyPrefix = UserValues.getLongFormTenantId();
		let currentAttributeGroupView = null;
		if (
			['home.segment.accounts', SegmentNgState.JourneyAccounts].includes(
				$state.current.name
			)
		) {
			viewType = ViewType.Account;
			const queryKey = `${keyPrefix}.${ACCOUNTS_PAGE_VIEW_KEY}`;
			currentAttributeGroupView = JSON.parse(
				sessionStorage.getItem(queryKey)
			)?.attributeGroup;
		}
		if (
			['home.segment.contacts', SegmentNgState.JourneyContacts].includes(
				$state.current.name
			)
		) {
			viewType = ViewType.Contact;
			const queryKey = `${keyPrefix}.${CONTACTS_PAGE_VIEW_KEY}`;
			currentAttributeGroupView = JSON.parse(
				sessionStorage.getItem(queryKey)
			)?.attributeGroup;
		}

		ExportSegmentModal.show(
			segment,
			accountsAvailable,
			contactsAvailable,
			defaultAttributeSetSelection,
			onClickExport,
			viewType,
			currentAttributeGroupView,
			vmEnrichments
		);
	};

	/* Contacts Page Functions, Not Currently Used */
	const getAccountName = () =>
		getDataCloudMetadataProperty('accountName') || $stateParams.AccountId;

	const clickContactsList = () => {
		$timeout(() => {
			$state.go('home.segment.accounts.contacts.list', {});
		}, 1);
	};

	/* Model_Iteration Page Functions, Not Currently Used */
	const clickIterationFilter = (type) => {
		store.dispatch(setDataCloudPropertyValue('ratingIterationFilter', type));

		$rootScope.$broadcast('iterationFilterChange', type);
	};
	const checkIterationFilter = (type) => {
		const filter = getDataCloudProperty('ratingIterationFilter');
		return filter === type;
	};
	const getIterationFilterNumber = (type) => {
		switch (type) {
			case 'all':
				return enrichments.length;
			case 'used':
				return enrichments.filter(
					(item) => item.ImportanceOrdering !== undefined
				).length;
			case 'warnings':
				return enrichments.filter((item) => item.HasWarnings).length;
			case 'disabled':
				return enrichments.filter((item) => item.ApprovedUsage[0] === 'None')
					.length;
			default:
				throw new Error(`No such filter: '${type}'`);
		}
	};

	/* QueryBuilder Page Picker, Not Currently Used */
	const clickPickerBack = () => {
		const state = StateHistory.lastFrom();
		const params = StateHistory.lastFromParams();

		$state.go(state.name, params);
	};

	const getPickerItem = () => getDataCloudProperty('pickerObject');

	/* Model_Iteration Export Button, Not Currently Used */
	const clickedExport = () => {
		const data = LocalStorageUtility.getObject('ModelStore.data'); // using ModelStore.getData() causes build error
		const csvRows = getTopPredictorExport(data);
		const lineArray = [];

		csvRows.forEach((infoArray) => {
			const line = infoArray.join(',');
			lineArray.push(line);
		});

		const csvContent = lineArray.join('\n');
		const element = document.createElement('a');

		element.setAttribute(
			'href',
			`data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`
		);
		element.setAttribute('download', 'attributes.csv');
		element.style.display = 'none';

		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
	};

	const changeSettings = () => {
		const iteration = getRatingsEngineProperty('remodelIteration');
		const modelId = iteration.modelSummaryId;
		const {rating_id: ratingId} = $stateParams;
		const {aiModel} = $stateParams;
		const url = 'home.ratingsengine.dashboard.training';

		$state.go(
			url,
			{
				rating_id: ratingId,
				modelId,
				aiModel,
			},
			{reload: true}
		);
	};

	const saveSegmentCounts = () => {
		updateSegmentCounts(segment);
	};

	const refreshCounts = () => {
		const segmentName = segment;
		if (segmentName === 'Create') {
			return;
		}

		setIsRefreshing(true);
		setPublicProperty('resetLabelIncrementor', true);

		refreshQueryCounts()
			.then(() => {
				saveSegmentCounts();
			})
			.finally(() => {
				setIsRefreshing(false);
			});
	};

	return (
		<>
			<SaveSegmentDialog
				open={isSaveSegmentOpen}
				ngState={$state}
				handleClose={() => setIsSaveSegmentOpen(false)}
			/>
			{inAccountsOrContactsTab ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						margin: '2em auto 1em',
					}}>
					<div>
						<LeItemBar
							store={reduxPath}
							namespace={`${reduxPath}.LeItemBar.Top`}>
							{canEditSegment && (
								<DNBButton
									size='compact'
									variant='secondary'
									startIcon={<IosShareIcon />}
									onClick={toggleExportModal}>
									Export
								</DNBButton>
							)}
							{userHasTeams &&
								canEditSegment &&
								(canSaveSegment || segment !== 'Create') && (
									<DNBButton
										disabled={!publicQueryStore.enableSaveSegmentButton}
										size='compact'
										startIcon={<SaveOutlinedIcon />}
										onClick={saveSegment}>
										Save Segment
									</DNBButton>
								)}
						</LeItemBar>
					</div>
				</div>
			) : (
				<div className='subheader-container container-myData'>
					{checkState('contacts.list') ||
					checkState('contacts.activity') ||
					checkState('contacts.dashboard') ? (
						<ul className='subheader-tabs-links'>
							<li onClick={clickContactsList}>{getAccountName()}</li>
						</ul>
					) : null}
					{checkState('model_iteration') ? (
						<ul className='subheader-tabs-links'>
							<li
								className={`${checkIterationFilter('all') ? 'active' : ''}`}
								onClick={() => clickIterationFilter('all')}>
								All {getIterationFilterNumber('all')}
							</li>
							<li>|</li>
							<li
								className={`${checkIterationFilter('used') ? 'active' : ''}`}
								onClick={() => clickIterationFilter('used')}>
								Used by Model ({getIterationFilterNumber('used')})
							</li>
							<li>|</li>
							<li
								className={`${
									checkIterationFilter('warnings') ? 'active' : ''
								}`}
								onClick={() => clickIterationFilter('warnings')}>
								Warnings ({getIterationFilterNumber('warnings')})
							</li>
							<li>|</li>
							<li
								className={`${
									checkIterationFilter('disabled') ? 'active' : ''
								}`}
								onClick={() => clickIterationFilter('disabled')}>
								Disabled ({getIterationFilterNumber('disabled')})
							</li>
						</ul>
					) : null}
					{checkState('accounts') ? (
						<ul className='subheader-tabs-links'>
							<li>Accounts</li>
						</ul>
					) : null}
					{checkState('contacts') ? (
						<ul className='subheader-tabs-links'>
							<li>Contacts</li>
						</ul>
					) : null}
					{checkState('picker') ? (
						<ul className='subheader-tabs-links'>
							<li
								className={`${checkState('builder') ? 'active' : ''}`}
								onClick={clickPickerBack()}>
								Back
							</li>
							{getPickerItem() ? <li>|</li> : null}
							<li>{getPickerItem().item.DisplayName}</li>
						</ul>
					) : null}
					{checkState('model_iteration') ? (
						<LeItemBar
							store={reduxPath}
							namespace={`${reduxPath}.LeItemBar.Top`}>
							{!inAccountsOrContactsTab && !checkState('builder') && (
								<LeSearch
									uglifytag='LeSearch'
									config={getSearchConfig()}
									namespace={`${reduxPath}.LeSearch`}
								/>
							)}
							<DNBButton
								disabled={!canEditSegment || !canEditAIModel}
								size='small'
								onClick={changeSettings}>
								Remodel
							</DNBButton>
						</LeItemBar>
					) : (
						<LeItemBar
							store={reduxPath}
							namespace={`${reduxPath}.LeItemBar.Top`}>
							{inEditTab &&
								// PLS-24778 : Requirement 1.1
								(checkState('attributes') ? (
									<LeDropdown
										uglifytag='RebrandLeDropdown'
										config={getAdminConfig()}
										containerClass='pull-right'
									/>
								) : null)}
							{canEditSegment && inEditTab && (
								<DNBButton
									disabled={!publicQueryStore.enableExportSegmentButton}
									size='compact'
									variant='secondary'
									startIcon={<IosShareIcon />}
									onClick={toggleExportModal}>
									Export
								</DNBButton>
							)}
							{segment !== 'Create' && checkState('builder') && (
								<DNBButton
									disabled={
										publicQueryStore.enableSaveSegmentButton || isRefreshing
									}
									size='compact'
									variant='secondary'
									isLoading={isRefreshing}
									startIcon={<RefreshIcon />}
									onClick={refreshCounts}>
									Refresh
								</DNBButton>
							)}
							{segment !== 'Create' &&
								userHasTeams &&
								canSaveSegment &&
								inEditTab && (
									<DNBButton
										size='compact'
										onClick={duplicateSegment}
										variant='secondary'
										startIcon={<ContentCopyOutlinedIcon />}>
										Duplicate
									</DNBButton>
								)}
							{segment == 'Create' && canSaveSegment && inEditTab && (
								<DNBButton
									size='compact'
									variant='secondary'
									onClick={clearSegment}
									startIcon={<BackspaceOutlinedIcon />}>
									Clear
								</DNBButton>
							)}
							{userHasTeams &&
								inEditTab &&
								canEditSegment &&
								(canSaveSegment || segment !== 'Create') && (
									<DNBButton
										disabled={!publicQueryStore.enableSaveSegmentButton}
										size='compact'
										startIcon={<SaveOutlinedIcon />}
										isLoading={isSaving}
										variant='primary'
										onClick={saveSegment}>
										Save Segment
									</DNBButton>
								)}
						</LeItemBar>
					)}
					{checkState('model_iteration') ? (
						<div>
							Up to 50 most predictive attributes are used by the model.
							<a className='dl-attributes' href='#' onClick={clickedExport}>
								<i className='fa fa-download' />
								Download All Attributes
							</a>
						</div>
					) : null}
				</div>
			)}
		</>
	);
}

export {MyDataSubheader};
