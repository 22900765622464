import React from 'common/react-vendor';
import {ISegment} from 'atlas/data/SegmentConst';
import {getQueryProperty} from 'common/stores/query';
import {isCalculateKnownContactEnabled} from 'common/stores/tenantConfig';
import {Restriction} from '../../../query.types';
import {
	DefaultAdvancedQueryContext,
	IAdvancedQueryContext,
} from '../../context/AdvancedQueryContext';
import {
	enumOperations,
	noInputs,
	numericalOperations,
	twoInputs,
} from '../tree.constants';
import {Attribute} from '../types';
import {BucketCmp, BucketType, TreeType} from '../../../query.enums';
import {GetBktValuesReturn} from '../tree.helpers';
import {InitQueryTreeItem} from '../QueryTree.helpers';

type IDataUpdate<T> = React.Dispatch<React.SetStateAction<T>>;
const {createContext, useMemo} = React;
// eslint-disable-next-line @typescript-eslint/no-empty-function
const DefaultDataUpdate = (): void => {};

interface IQueryTreeContext {
	editing: boolean;
	root: IAdvancedQueryContext;
	tree: Restriction;
	entity: string;
	segment: ISegment;
	isListSegment: boolean;
	records_updating: boolean;
	items?: Attribute[];
	item?: Attribute;
	parent?: Restriction;
	memberType?: string;
	type?: BucketType | TreeType | string;
	label?: string;
	enable_calculate_known_contact: boolean;
	subType?: string;
	uniqueId?: number;
	range?: GetBktValuesReturn;
	activityRestriction?: Restriction;
	updateTree?: (entity: string, forceUpdatedStore?: boolean) => void;
	numerical_operations: Partial<Record<BucketCmp, string>>;
	two_inputs: BucketCmp[];
}

const DefaultQueryTreeContext: IQueryTreeContext = {
	editing: false,
	root: DefaultAdvancedQueryContext,
	tree: {},
	entity: 'Account',
	segment: {} as ISegment,
	isListSegment: false,
	records_updating: false,
	enable_calculate_known_contact: false,
	numerical_operations: {},
	two_inputs: twoInputs,
};

interface IQueryTreeContextUpdate {
	setEditing: IDataUpdate<boolean>;
	setRecordsUpdating: IDataUpdate<boolean>;
}
const DefaultQueryTreeContextUpdate: IQueryTreeContextUpdate = {
	setEditing: DefaultDataUpdate,
	setRecordsUpdating: DefaultDataUpdate,
};

const QueryTreeContext = createContext(DefaultQueryTreeContext);
const QueryTreeContextUpdate = createContext(DefaultQueryTreeContextUpdate);

interface IQueryTreeComponent {
	root: IAdvancedQueryContext;
	tree: Restriction;
	parent?: Restriction;
	entity: string;
	segment: ISegment;
	memberType?: string;
	items?: Attribute[];
	updateTree?: (entity: string) => void;
}

interface IQueryTreeContextProvider {
	scope: IQueryTreeComponent;
	children: React.ReactNode;
}

const QueryTreeContextProvider = ({
	scope,
	children,
}: IQueryTreeContextProvider): React.ReactElement => {
	const {root, parent, entity, segment, memberType, updateTree} = scope;
	const [editing, setEditing] = React.useState(false);
	const [records_updating, setRecordsUpdating] = React.useState(false);
	const [uniqueId] = React.useState(Math.random() * (8 * Math.pow(2, 8)));
	const initValue = useMemo(() => {
		const {item, label, type, range, activityRestriction, tree} =
			InitQueryTreeItem(scope);
		const isListSegment =
			getQueryProperty<ISegment>('segment')?.type === 'List';
		const enable_calculate_known_contact = isCalculateKnownContactEnabled();
		return {
			// isLoading,
			root,
			tree,
			parent,
			entity,
			segment,
			memberType,
			isListSegment,
			records_updating,
			enable_calculate_known_contact,
			subType: 'Known',
			numerical_operations: numericalOperations,
			enum_operations: enumOperations,
			no_inputs: noInputs,
			two_inputs: twoInputs,
			uniqueId,
			item,
			label,
			type,
			range,
			editing,
			activityRestriction,
			updateTree,
		};
	}, [
		root,
		parent,
		entity,
		segment,
		memberType,
		editing,
		updateTree,
		uniqueId,
		records_updating,
		scope,
	]);
	const initAction = useMemo(
		() => ({
			setEditing,
			setRecordsUpdating,
		}),
		[]
	);
	return (
		<QueryTreeContext.Provider value={initValue}>
			<QueryTreeContextUpdate.Provider value={initAction}>
				{children}
			</QueryTreeContextUpdate.Provider>
		</QueryTreeContext.Provider>
	);
};

export {QueryTreeContext, QueryTreeContextProvider, QueryTreeContextUpdate};
export type {
	IQueryTreeComponent,
	IQueryTreeContextProvider,
	IQueryTreeContext,
	IQueryTreeContextUpdate,
};
