import {Bkt} from 'common/components/datacloud/query/query.types';
import {isEqual} from 'lodash';

/**
 * Attribute counter map
 * @id id refers to Attribute name
 * @counter Counter to attribute
 */
type IAttributeValues = {
	attrId: string;
	bkt?: Bkt;
};
type ICounterMap = {
	[key in string]: {
		count: number;
		bkt?: Bkt[];
	};
};

type IKeyValues = {
	attrId: string;
	count: number;
	bkt?: Bkt[];
};

/**
 * Key counter map
 * Construct map with specific string array or undefine value.
 * @add Add key to map
 * @delete Delete key from map if counter is equals to 0
 * @delete Delete the whole key from map
 * @has True if has key
 * @count Count for the key, -1 if not in the map
 * @totalCount Total count for all keys
 * @clear Clear up the map
 * @toArray Convert map to key array with duplicated case
 * ['key1': 1, 'key2': 2] => [key1, key2, key2]
 * @toKeyArray Convert map to key array
 * ['key1': 1, 'key2': 2] => [key1, key2]
 */
class CounterMap {
	private counterMap: ICounterMap = {};

	constructor(keys: IAttributeValues[] | undefined) {
		if (!keys) {
			return;
		}
		keys.forEach(({attrId, bkt}) => {
			this.add(attrId, bkt, true);
		});
	}

	has = (key: string): boolean => {
		return key in this.counterMap;
	};

	count = (key: string): number => {
		if (!this.has(key)) {
			return 0;
		}
		return this.counterMap[key]?.count || 0;
	};

	totalCount = (): number => {
		return Object.values(this.counterMap).reduce((pre, {count}) => {
			return pre + count;
		}, 0);
	};

	add = (key: string, bkt?: Bkt, isInit = false): void => {
		if (!this.counterMap[key]) {
			this.counterMap[key] = {
				...(bkt ? {bkt: [{...bkt, checked: !isInit}]} : {}),
				count: 0,
			};
		} else if (this.counterMap[key]?.bkt) {
			this.counterMap[key]?.bkt?.push({...bkt, checked: !isInit});
		}
		this.counterMap[key] = {
			...this.counterMap[key],
			count: (this.counterMap[key]?.count || 0) + 1,
		};
	};

	addValue = (key: string, bkt?: Bkt, addNewOneBkt?: boolean): void => {
		if (
			!this.counterMap[key] ||
			!this.counterMap[key]?.bkt?.find((inner) => inner.checked) ||
			addNewOneBkt
		) {
			const tmpBkt = {...bkt, checked: true};
			if (this.counterMap[key]?.bkt) {
				this.counterMap[key]?.bkt?.push(tmpBkt);
			} else {
				this.counterMap[key] = {
					bkt: [tmpBkt],
					count: this.counterMap[key]?.count || 0,
				};
			}
			this.counterMap[key] = {
				...this.counterMap[key],
				count: (this.counterMap[key]?.count || 0) + 1,
			};
		} else {
			const checked = this.counterMap[key]?.bkt?.find((inner) => inner.checked);
			checked?.Vals?.push(...(bkt?.Vals || []));
		}
	};

	delete = (key: string, bkt?: Bkt): void => {
		if (!this.counterMap[key]) {
			return;
		}
		if (bkt && this.counterMap[key]?.bkt) {
			const delIndex = this.counterMap[key]?.bkt?.findIndex((item: Bkt) =>
				isEqual(item, bkt)
			);
			if (typeof delIndex === 'number' && delIndex > -1) {
				this.counterMap[key]?.bkt?.splice(delIndex, 1);
			}
		}
		this.counterMap[key] = {
			...this.counterMap[key],
			count: (this.counterMap[key]?.count || 0) - 1,
		};
		if (this.counterMap[key]?.count === 0) {
			delete this.counterMap[key];
		}
	};

	deleteKey = (key: string, bkt?: Bkt): void => {
		if (!this.counterMap[key]) {
			return;
		}
		if (bkt) {
			const delIndex = (this.counterMap[key]?.bkt?.length || 1) - 1;
			this.counterMap[key]?.bkt?.splice(delIndex, 1);
			this.counterMap[key] = {
				...this.counterMap[key],
				count: (this.counterMap[key]?.count || 1) - 1,
			};
			if (this.counterMap[key]?.count === 0) {
				delete this.counterMap[key];
			}
		} else {
			delete this.counterMap[key];
		}
	};

	deleteValue = (key: string, bkt?: Bkt, addNewOneBkt?: boolean): void => {
		if (!this.counterMap[key]) {
			return;
		}
		if (bkt) {
			if (addNewOneBkt) {
				const comparedBkt = {...bkt, checked: false, Cnt: 0};
				let delIndex = this.counterMap[key]?.bkt?.findIndex((inner) => {
					const innerComparedBkt = {
						...inner,
						Cnt: 0,
						checked: false,
					};
					return isEqual(innerComparedBkt, comparedBkt);
				});
				if (delIndex === undefined || delIndex === -1) {
					delIndex = (this.counterMap[key]?.bkt?.length || 1) - 1;
				}
				this.counterMap[key]?.bkt?.splice(delIndex, 1);
				this.counterMap[key] = {
					...this.counterMap[key],
					count: (this.counterMap[key]?.count || 1) - 1,
				};
				if (this.counterMap[key]?.count === 0) {
					delete this.counterMap[key];
				}
			} else {
				this.counterMap[key]?.bkt?.forEach((inner) => {
					const delIndex = inner.Vals?.indexOf(bkt?.Vals?.[0]);
					if (delIndex !== undefined && delIndex > -1) {
						inner.Vals?.splice(delIndex, 1);
					}
				});
				const delIndex = this.counterMap[key]?.bkt?.findIndex(
					(inner) => inner.Vals?.length === 0
				);
				if (delIndex !== undefined && delIndex > -1) {
					this.counterMap[key]?.bkt?.splice(delIndex, 1);
					this.counterMap[key] = {
						...this.counterMap[key],
						count: (this.counterMap[key]?.count || 1) - 1,
					};
					if (this.counterMap[key]?.count === 0) {
						delete this.counterMap[key];
					}
				}
			}
		} else {
			delete this.counterMap[key];
		}
	};

	clear = (): void => {
		Object.keys(this.counterMap).forEach((key) => {
			delete this.counterMap[key];
		});
	};

	toArray = (): IAttributeValues[] => {
		return Object.keys(this.counterMap).flatMap((key) => {
			const counter = this.counterMap[key];
			return new Array(counter?.count).fill({count: 0}).map((_, index) => {
				return {
					attrId: key,
					bkt: counter?.bkt?.[index],
				};
			});
		});
	};

	toKeyArray = (): string[] => {
		return Object.keys(this.counterMap);
	};

	toKeyValueArray = (): IKeyValues[] => {
		return Object.keys(this.counterMap).flatMap((key) => {
			const counter = this.counterMap[key];
			return {
				attrId: key,
				count: counter?.count || 0,
				bkt: counter?.bkt,
			};
		});
	};
}

export type {ICounterMap, IAttributeValues, IKeyValues};
export {CounterMap};
