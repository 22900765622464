import React from 'common/react-vendor';
import {AddIcon, DNBButton, DNBCard, RemoveIcon} from 'common/dnb-uux-vendor';
import {isEqual} from 'lodash';
import classNames from 'classnames';
import {dispatchSetAddBucketTreeRoot} from 'common/stores/query';
import {useSelector} from 'react-redux';
import {QueryState} from 'common/stores/query/types';
import {
	IAddAttributeState,
	IRoot,
} from 'atlas/modelsOld/modals/AddAttributeModal/AddAttributeModalScopeConst';
import {EntityType} from 'atlas/data/RestrictionConst';
import {AddAttributesContext} from 'atlas/modelsOld/modals/AddAttributeModal/AddAttributesComponent';
import {IAttribute} from 'atlas/data/AttributeConst';
import {useQueryTree} from './hook/useQueryTree';
import {useAdvancedQuery} from '../hook/useAdvancedQuery';
import {QueryTreeComponent} from './QueryTreeComponent';
import {
	AddEventBucket,
	AddOperator,
	getBackgroundColor,
	getKey,
	hideRestrictions,
	mouseMove,
	mouseOutHighlight,
	mouseOver,
	mouseOverHighlight,
	shouldGrayOut,
} from './QueryTree.helpers';
import {
	mouseUp,
	SaveState,
	SetTotalRuleCount,
	getBucketLabel,
	goAttributes,
} from '../AdvancedQuery.helper';
import {LogicalRestrictionRecord, Restriction} from '../../query.types';
import {IQueryTreeContext} from './context/QueryTreeContext';
import {SegmentMember} from './tree.constants';

const DragOutlineSection = (): React.ReactElement => {
	const {context: queryTree} = useQueryTree();
	const {context: advancedQuery, setContext: setAdvancedQuery} =
		useAdvancedQuery();
	const {root, editing, item} = queryTree;
	const draggedItem = root.draggedItem?.current;
	const tree = draggedItem?.tree || queryTree.tree;
	return (
		<li className='querySectionItemBox'>
			<div
				className='dashed querySectionItem'
				style={{
					borderLeftColor: getBackgroundColor(tree, root, item),
				}}
				onMouseOver={(e) => {
					mouseOver(e, editing, queryTree, advancedQuery);
					mouseOverHighlight(getBucketLabel(tree));
				}}
				onMouseOut={() => {
					mouseOutHighlight(getBucketLabel(tree));
				}}
				onMouseMove={(e) => {
					mouseMove(
						e,
						editing,
						queryTree,
						advancedQuery,
						setAdvancedQuery,
						true
					);
				}}>
				<div
					style={{position: 'relative'}}
					id={`bucket-${getBucketLabel(tree)}`}
				/>
				<div className='querySectionItemLabel'>
					<strong
						style={{
							backgroundColor: getBackgroundColor(tree, root, item),
						}}>
						{getBucketLabel(tree) || '-'}
					</strong>
				</div>
			</div>
		</li>
	);
};

const QuerySectionItemAction = (): React.ReactElement => {
	const [isDialogOpen, setIsDialogOpen] = React.useState(false);
	const {context: queryTree} = useQueryTree();
	const {context: advancedQuery, setContext: setAdvancedQuery} =
		useAdvancedQuery();
	const {
		root,
		editing,
		// treeRef,
		numerical_operations,
		entity,
		isListSegment,
		activityRestriction,
		updateTree,
		tree,
	} = queryTree;
	const {
		showTimeSeries,
		showSegmentationV2,
		enrichments,
		eventEnrichments,
		canEdit,
	} = advancedQuery;
	// const draggedItem = root.draggedItem?.current;
	// const tree = draggedItem?.treeRef.current || queryTree.treeRef.current;
	const [type, setType] = React.useState(entity);
	const [relation, setRelation] = React.useState('');
	const entityLowerCase = entity.toLocaleLowerCase();
	const notSubSegment = Object.values(SegmentMember).includes(
		entityLowerCase as SegmentMember
	);
	const restrictionType = notSubSegment
		? entityLowerCase
		: EntityType.Member.toLocaleLowerCase();
	const scope = {
		entity: entity as EntityType,
		activityRestriction,
		eventEnrichments: eventEnrichments as unknown as IAttribute[],
		enrichments: enrichments as unknown as IAttribute[],
		root: {
			...root,
			setTotalRuleCount: () => SetTotalRuleCount(setAdvancedQuery),
			saveState: () => {
				updateTree && updateTree(entity);
				SaveState(advancedQuery, setAdvancedQuery);
			},
			goAttributes,
		} as unknown as IRoot,
		enableQueryBuilderRedesign: true,
		tree,
		numerical_operations,
		showSegmentationV2,
	} as IAddAttributeState;
	// const [scope, setScope] = React.useState(vm);
	const [attributeEntity, setAttributeEntity] = React.useState(
		entity as EntityType
	);
	const disableAllTreeRestrictions = useSelector(
		(state: {query: QueryState}) =>
			state?.query?.public.disableAllTreeRestrictions
	);
	const Buttonconfig = {
		addAttribute: {
			show: notSubSegment && !activityRestriction,
			className: 'addAttribute',
			parentClass: '',
			disabled: isListSegment,
			startIcon: <AddIcon />,
			onClick: () => {
				// add tree root
				setType(entity);
				dispatchSetAddBucketTreeRoot(
					tree as LogicalRestrictionRecord,
					entityLowerCase
				);
				if (showSegmentationV2) {
					setIsDialogOpen(true);
				} else {
					SaveState(advancedQuery, setAdvancedQuery);
					goAttributes(advancedQuery);
				}
			},
			buttonText: `${
				showTimeSeries && showSegmentationV2 ? entity : ''
			} Attribute`,
		},
		addEvent: {
			show: notSubSegment,
			className: 'addEvent',
			parentClass: '',
			disabled: isListSegment,
			startIcon: <AddIcon />,
			onClick: () => {
				// add tree root
				setType('Event');
				dispatchSetAddBucketTreeRoot(
					tree as LogicalRestrictionRecord,
					entityLowerCase
				);
				if (showSegmentationV2) {
					setIsDialogOpen(true);
				} else {
					SaveState(advancedQuery, setAdvancedQuery);
					goAttributes(advancedQuery);
				}
			},
			buttonText: 'Activity Attribute',
		},
		addAttributeOperator: {
			show: notSubSegment,
			className: 'AddIcon notActivity',
			parentClass: 'addOperator',
			disabled: isListSegment,
			startIcon: <AddIcon />,
			onClick: () =>
				AddOperator(advancedQuery, setAdvancedQuery, tree, restrictionType),
			buttonText: `${entity} Operator`,
		},
		addActivityOperator: {
			show: notSubSegment,
			className: 'AddIcon Activity',
			parentClass: 'addOperator',
			disabled: isListSegment,
			startIcon: <AddIcon />,
			onClick: () =>
				AddOperator(advancedQuery, setAdvancedQuery, tree, restrictionType),
			buttonText: 'Activity Operator',
		},
		addEventBlock: {
			show: notSubSegment && showTimeSeries && showSegmentationV2,
			className: 'AddIcon',
			parentClass: 'addEventBlock',
			disabled: isListSegment && !canEdit,
			startIcon: <AddIcon />,
			onClick: () =>
				AddEventBucket(advancedQuery, setAdvancedQuery, tree, restrictionType),
			buttonText: 'Activity Query',
		},
		Union: {
			show: entityLowerCase === 'union',
			className: 'addAttribute',
			parentClass: '',
			disabled: isListSegment && !canEdit,
			startIcon: <AddIcon />,
			onClick: () => {
				// add tree root
				setAttributeEntity(EntityType.Member);
				setType('Union');
				setRelation('INCLUDE');
				dispatchSetAddBucketTreeRoot(
					tree as LogicalRestrictionRecord,
					entityLowerCase
				);
				if (showSegmentationV2) {
					setIsDialogOpen(true);
				} else {
					SaveState(advancedQuery, setAdvancedQuery);
					goAttributes(advancedQuery);
				}
			},
			buttonText: 'Segment',
		},
		IntersectionInclude: {
			show: entityLowerCase === 'intersection',
			className: 'addAttribute',
			parentClass: '',
			disabled: isListSegment && !canEdit,
			startIcon: <AddIcon />,
			onClick: () => {
				setAttributeEntity(EntityType.Member);
				setType('Union');
				setRelation('INCLUDE');
				// add tree root
				dispatchSetAddBucketTreeRoot(
					tree as LogicalRestrictionRecord,
					entityLowerCase
				);
				if (showSegmentationV2) {
					setIsDialogOpen(true);
				} else {
					SaveState(advancedQuery, setAdvancedQuery);
					goAttributes(advancedQuery);
				}
			},
			buttonText: 'Include a Segment',
		},
		IntersectionExclude: {
			show: entityLowerCase === 'intersection',
			className: 'addAttribute',
			parentClass: '',
			disabled: isListSegment && !canEdit,
			startIcon: <RemoveIcon />,
			onClick: () => {
				setAttributeEntity(EntityType.Member);
				setType('Intersection');
				setRelation('EXCLUDE');
				// add tree root
				dispatchSetAddBucketTreeRoot(
					tree as LogicalRestrictionRecord,
					entityLowerCase
				);
				if (showSegmentationV2) {
					setIsDialogOpen(true);
				} else {
					SaveState(advancedQuery, setAdvancedQuery);
					goAttributes(advancedQuery);
				}
			},
			buttonText: 'Exclude a Segment',
		},
	};
	return (
		<li
			className={`querySectionItemBox querySectionItemEnd ${entityLowerCase}`}>
			<div className='querySectionTypeDotline buttonGroup' />
			{entityLowerCase === 'intersection' && (
				<div className='querySectionTypeDotline intersection' />
			)}
			<DNBCard
				className={classNames({
					querySectionItem: true,
					querySectionItemEnd: true,
					disabled: !advancedQuery.canEdit,
				})}
				onMouseOver={(e) => {
					mouseOver(e, editing, queryTree, advancedQuery);
				}}
				onMouseMove={(e) => {
					mouseMove(
						e,
						editing,
						queryTree,
						advancedQuery,
						setAdvancedQuery,
						true,
						true
					);
				}}>
				{!isEqual(tree, tree) && (
					<div className='querySectionTypeDotlineBottom operation' />
				)}
				<div>
					{isDialogOpen && (
						<AddAttributesContext
							isDialogOpen={isDialogOpen}
							setIsDialogOpen={setIsDialogOpen}
							vm={{...scope, entity: attributeEntity}}
							type={type}
							relation={relation}
						/>
					)}
					{Object.entries(Buttonconfig).map(([key, button]) => {
						const {
							parentClass,
							className,
							disabled,
							startIcon,
							onClick,
							buttonText,
						} = button;
						return (
							button.show && (
								<span className={parentClass} key={key}>
									<DNBButton
										size='small'
										sx={{lineHeight: 1, padding: 0, cursor: 'pointer'}}
										variant='text'
										className={className}
										disabled={disabled || disableAllTreeRestrictions}
										startIcon={startIcon}
										onClick={() => onClick()}>
										{buttonText}
									</DNBButton>
								</span>
							)
						);
					})}
				</div>
			</DNBCard>
		</li>
	);
};

const QueryTreeAttribute = (): React.ReactElement => {
	const {context} = useQueryTree();
	const {context: advancedQuery, setContext: setAdvancedQuery} =
		useAdvancedQuery();
	const {
		entity,
		root,
		updateTree,
		tree,
		memberType,
		enable_calculate_known_contact,
	} = context;
	const entityLowerCase = entity.toLocaleLowerCase();
	let keyCount = 0;
	const draggedItem = root.draggedItem?.current;
	const droppedItem = root.droppedItem?.current as IQueryTreeContext;
	const showDragOutline =
		isEqual(droppedItem?.tree, tree) ||
		(isEqual(draggedItem?.tree, tree) &&
			(isEqual(droppedItem?.tree, tree) || !droppedItem));
	const showRestriction =
		!(
			isEqual(draggedItem?.tree, tree) &&
			droppedItem &&
			!isEqual(droppedItem?.tree, tree)
		) && !(entityLowerCase === 'member' && tree?.logicalRestriction);
	return (
		<DNBCard hasBorder={false} variant='white' sx={{overflow: 'unset'}}>
			<ul>
				{/* -- Drag and Drop item outline directly before 'add new item/operator' */}
				{showDragOutline && <DragOutlineSection />}
				{showRestriction &&
					tree?.logicalRestriction?.restrictions?.map((item) => {
						const key = getKey(JSON.stringify(item));
						return (
							<li
								key={`${key}-${keyCount++}`}
								className={classNames({
									querySectionItemBox: true,
									querySectionItemBoxSubtree: true,
									querySectionItemBoxActivity: !!item.activityRestriction,
									itemIgnored: shouldGrayOut(item, advancedQuery.canEdit),
									[`labelGlyph-${item.labelGlyph}`]: item?.labelGlyph,
								})}
								style={{
									minHeight: '72px',
									display: hideRestrictions(
										item,
										enable_calculate_known_contact,
										entity
									)
										? 'none'
										: 'block',
								}}
								onMouseUp={() =>
									mouseUp(
										advancedQuery,
										setAdvancedQuery,
										() => updateTree && updateTree(entity, true)
									)
								}>
								<QueryTreeComponent
									items={undefined}
									root={advancedQuery}
									tree={
										item?.activityRestriction
											? (item.activityRestriction?.restriction as Restriction)
											: item
									}
									parent={tree}
									entity={entity}
									segment={advancedQuery.segmentObj}
									updateTree={updateTree}
									memberType={memberType}
								/>
							</li>
						);
					})}
				<QuerySectionItemAction />
			</ul>
		</DNBCard>
	);
};

export {QueryTreeAttribute};
