import React from 'common/react-vendor';
import {cloneDeep, filter} from 'lodash';
import {MutableRefObject} from 'react';
import {IChipsController} from 'common/components/chips/ChipsTypes';
import {isRulesBasedModelMode} from 'common/stores/query';
import {
	Bucket,
	InputRangeConfig,
	Restriction,
	Vals,
} from '../../../query.types';
import {IAdvancedQueryContext} from '../../context/AdvancedQueryContext';
import {stringOperations} from '../tree.constants';
import {Attribute, AttributeEntity} from '../types';
import {BucketCmp} from '../../../query.enums';
import {showItem} from '../item/QueryTreeItem.helpers';
import {CmpMap} from '../tree.types';
import {useAdvancedQuery} from '../../hook/useAdvancedQuery';
import {useQueryTree} from '../hook/useQueryTree';

const {useMemo} = React;

interface IQueryTreeItemEditData {
	loading: boolean;
	tree: Restriction;
	item?: Attribute;
	isTransactionEditValid: boolean;
	isDateAttributeValid: boolean;
	booleanChanged: boolean;
	showFromNumerical: boolean;
	showToNumerical: boolean;
	vals: Vals;
	buckets: Bucket[];
	header: string[];
	chipsOperations: BucketCmp[];
	isSegment: boolean;
	isActivity: boolean;
	stringPlaceholder: string;
	string_operations: CmpMap;
	booleanValue: string;
	operation: BucketCmp;
	showSegmentationV2: boolean;
	root: IAdvancedQueryContext;
	entity: AttributeEntity;
	editMode: string;
	relation?: string;
	type?: string;
	clear: boolean;
	rangeConfig?: InputRangeConfig;
	enableQueryBuilderRedesign: boolean;
	DisplayName?: string;
	ChipsController?: IChipsController;
	presetOperation?: string;
}

const DefaultQueryTreeItemEditContext: IQueryTreeItemEditData = {
	loading: true,
	tree: {},
	isTransactionEditValid: true,
	isDateAttributeValid: true,
	booleanChanged: false,
	showFromNumerical: false,
	showToNumerical: false,
	vals: [],
	buckets: [],
	header: ['VALUE', 'RECORDS'],
	chipsOperations: [
		BucketCmp.EQUAL,
		BucketCmp.IN_COLLECTION,
		BucketCmp.NOT_EQUAL,
		BucketCmp.NOT_IN_COLLECTION,
		BucketCmp.CONTAINS,
		BucketCmp.NOT_CONTAINS,
	],
	isSegment: false,
	isActivity: false,
	stringPlaceholder: 'Add new value',
	string_operations: stringOperations,
	booleanValue: '',
	operation: BucketCmp.IS_NULL,
	showSegmentationV2: false,
	root: {} as IAdvancedQueryContext,
	entity: AttributeEntity.Account,
	editMode: 'Custom',
	type: '',
	clear: false,
	enableQueryBuilderRedesign: true,
};

interface IQueryTreeItemEditContext {
	data: MutableRefObject<IQueryTreeItemEditData>;
	setData: (key: string, val: unknown) => void;
}

const DefaultContext = {
	data: {current: {}},
} as IQueryTreeItemEditContext;

const FetcherQueryTreeItemEditContext =
	React.createContext<IQueryTreeItemEditContext>(DefaultContext);

interface IQueryTreeItemEdit {
	tree: Restriction;
	root: IAdvancedQueryContext;
	item: Attribute | undefined;
	type: string | undefined;
	memberType: string | undefined;
}

interface IQueryTreeItemEditContextProvider {
	scope: IQueryTreeItemEdit;
	children: React.ReactNode;
}

const QueryTreeItemEditContextProvider = ({
	scope,
	children,
}: IQueryTreeItemEditContextProvider): React.ReactElement => {
	const {root, tree, item, type} = scope;
	const {context: advancedQuery} = useAdvancedQuery();
	const {showSegmentationV2} = advancedQuery;
	const {context: queryTree} = useQueryTree();
	const {entity, editing, range, label} = queryTree;

	const initValue = useMemo(() => {
		const isSegment = tree.hasOwnProperty('segmentMemberRestriction');
		const isActivity =
			root.showTimeSeries &&
			!!tree?.bucketRestriction?.entityType &&
			showItem(tree, type, 'String');
		const stringPlaceholder = isActivity
			? 'Search for attributes'
			: 'Add new value';
		let relation;
		let DisplayName;
		let itemReturn = cloneDeep(item) as Attribute;
		let labelReturn = cloneDeep(label);
		let rangeReturn = cloneDeep(range);
		let treeReturn: Restriction = cloneDeep(tree);
		if (
			!editing &&
			(type === 'Date' ||
				type === 'Boolean' ||
				type === 'Numerical' ||
				type === 'Enum' ||
				type === 'TimeSeries' ||
				type === 'String' ||
				type === 'PercentChange' ||
				type === 'Member')
		) {
			// In a rules based model, starting to edit a bucket needs to set
			// `bkt` because the initial restrictions sent by the server and
			// the bkt set when pressing the unset button may cause errors to
			// be thrown in the indivudual bucket services
			// (QueryTreeAccountEntityService and QueryTreePurchaseHistoryService)
			if (isRulesBasedModelMode()) {
				if (tree?.bucketRestriction?.ignored) {
					if (type !== 'String' && item?.Stats?.Bkts?.List?.[0]) {
						itemReturn = {
							...item,
							topbkt: cloneDeep(item.Stats.Bkts.List[0]),
						};
						treeReturn = {
							...tree,
							bucketRestriction: {
								...tree.bucketRestriction,
								bkt: cloneDeep(item.Stats.Bkts.List[0]),
							},
						};
					} else if (item?.topbkt) {
						treeReturn = {
							...tree,
							bucketRestriction: {
								...tree.bucketRestriction,
								bkt: {
									...cloneDeep(item.topbkt),
									Id: cloneDeep(item.topbkt.Id || -1),
								},
							},
						};
					} else {
						const Cnt = item?.cube?.Cnt || -1;
						const bkt = {
							Lbl: ' ',
							Cmp: BucketCmp.IS_NOT_NULL,
							Id: -1,
							Cnt,
							Vals: [''],
						};
						itemReturn = {
							...itemReturn,
							topbkt: bkt,
						};
						treeReturn = {
							...tree,
							bucketRestriction: {
								...tree.bucketRestriction,
								bkt,
							},
						};
					}
					labelReturn = tree.bucketRestriction.bkt?.Lbl;
					rangeReturn = tree.bucketRestriction.bkt?.Vals;
				}
			}
			// SaveState(value, action, true);
			// setEditing(true);
		}
		if (tree?.segmentMemberRestriction && showItem(tree, type, 'Member')) {
			relation = tree.segmentMemberRestriction.relation;
			const [segment] = filter(root.segmentsList, {
				name: tree.segmentMemberRestriction.segmentName,
			});
			DisplayName = segment?.display_name;
		}
		return {
			...DefaultQueryTreeItemEditContext,
			isSegment,
			isActivity,
			stringPlaceholder,
			tree: treeReturn,
			item: itemReturn,
			type,
			string_operations: stringOperations,
			showSegmentationV2,
			root,
			relation,
			DisplayName,
			enableQueryBuilderRedesign: true,
			entity: entity as AttributeEntity,
			label: labelReturn,
			range: rangeReturn,
		};
	}, [
		root,
		tree,
		item,
		type,
		showSegmentationV2,
		entity,
		editing,
		label,
		range,
	]);
	const data = React.useRef<IQueryTreeItemEditData>(initValue);
	const setData = (key: string, val: unknown): void => {
		data.current = {
			...data.current,
			[key]: val,
		};
	};
	return (
		<FetcherQueryTreeItemEditContext.Provider
			value={{
				data,
				setData,
			}}>
			{children}
		</FetcherQueryTreeItemEditContext.Provider>
	);
};

export {QueryTreeItemEditContextProvider, FetcherQueryTreeItemEditContext};
export type {
	IQueryTreeItemEdit,
	IQueryTreeItemEditContextProvider,
	IQueryTreeItemEditContext,
	IQueryTreeItemEditData,
};
