import React from 'common/react-vendor';
import _ from 'lodash';
import {
	DNBTooltip,
	DNBTypography,
	InfoIcon,
	InputAdornment,
	SearchOutlinedIcon,
	DNBIconButton,
	DNBTextField,
	CloseIcon,
	DNBButton,
	DNBDivider,
	styled,
	Box,
} from 'common/dnb-uux-vendor';
import {
	TreeActivityEdit,
	TreeScope,
} from 'common/components/datacloud/query/advanced/tree/edit/TreeActivityEdit/TreeActivityEdit';
import {defaultRangeConfig} from 'common/components/datacloud/query/advanced/tree/edit/TreeActivityEdit/treeActivityEdit.constants';
import {Entity} from 'common/components/datacloud/query/query.enums';
import {
	DialogButton,
	FreeStyleDialog,
	FreeStyleDialogContent,
	FreeStyleBetweenFooter,
} from 'common/composite-uux-components/dialog-drawer/DialogDrawer';
import {Spinner} from 'common/widgets/spinner';
import {
	IAddAttributesDialog,
	IAddAttributeModalScope,
} from '../AddAttributeModalScopeConst';
import {AddAttributesLeftSection} from './AddAttributesLeftSection';
import {AddAttributesCenterSection} from './AddAttributesCenterSection';
import {AddAttributesRightSection} from './AddAttributesRightSection';

const AddAttributeDialog = styled(FreeStyleDialog)(() => ({
	'.MuiDialog-paper': {
		maxWidth: '1142px',
		width: '1142px',
		minHeight: '782px',
		height: 'auto',
	},
}));

const AddAttributeDialogContent = styled(FreeStyleDialogContent)(() => ({
	paddingBottom: '3px',
}));

const AddAttributes = ({
	displayValues,
	setDisplayValues,
	displaySearchResult,
	setDisplaySearchResult,
	searchAttributesText,
	setSearchAttributesText,
	data,
	refresh,
	setIsValidRange,
}: {
	data: IAddAttributeModalScope;
	refresh: React.Dispatch<React.SetStateAction<number>>;
	displayValues: boolean;
	setDisplayValues: (displayValues: boolean) => void;
	displaySearchResult: boolean;
	searchAttributesText: string;
	setDisplaySearchResult: (display: boolean) => void;
	setSearchAttributesText: (val: string) => void;
	setIsValidRange: (val: boolean) => void;
}): React.ReactElement => {
	const [status, setStatus] = React.useState(false);
	const [curAttribute, setCurAttribute] = React.useState('');
	const [curEntity, setCurEntity] = React.useState<Entity>(Entity.Account);
	const [modalScope] = React.useState(data);
	const {
		isActivity,
		activityRestriction,
		clearSearchFilter,
		searchText,
		searchFilter,
		setSearchText,
	} = modalScope;
	const [vals, setVals] = React.useState<number[]>([]);
	const handleScopeChange = (key: string, value: boolean | number[]): void => {
		if (key === 'isValidRange' && typeof value === 'boolean') {
			setIsValidRange(value);
		}
		if (key === 'activityRestriction') {
			refresh((key) => -key);
		}
		if (typeof value === 'object') {
			if (key === 'vals') {
				setVals(value);
			}
		}
	};
	if (!displayValues && !searchText && searchAttributesText) {
		setSearchText(searchAttributesText);
	}
	const newSearchText = !displayValues ? searchAttributesText : searchText;
	return (
		<div className='AddAttributesLarge modal-dialog'>
			<div className='modal-content'>
				<div className='addAttributesSearchBar'>
					<div className='addAttributesSearchInput'>
						<DNBTextField
							onChange={(e) => {
								setSearchText(e.currentTarget.value);
								setDisplaySearchResult(!displayValues);
								if (!displayValues) {
									setSearchAttributesText(e.currentTarget.value);
									searchFilter();
								}
								setStatus(!status);
							}}
							value={newSearchText}
							size='compact'
							placeholder='What are you looking for?'
							sx={{width: '100%'}}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										{newSearchText ? (
											<DNBIconButton
												size='small'
												sx={{padding: 0, marginRight: 1}}
												onClick={() => {
													setSearchText('');
													setDisplaySearchResult(false);
													if (!displayValues) {
														setSearchAttributesText('');
														clearSearchFilter();
													}
													setStatus(!status);
												}}
												onMouseDown={(e) => e.preventDefault()}
												edge='end'>
												<CloseIcon />
											</DNBIconButton>
										) : (
											<SearchOutlinedIcon />
										)}
									</InputAdornment>
								),
							}}
						/>
					</div>
					<DNBButton
						variant='text'
						size='compact'
						disabled={!newSearchText}
						onClick={() => {
							if (newSearchText) {
								setSearchText('');
								setDisplaySearchResult(false);
								if (!displayValues) {
									setSearchAttributesText('');
									clearSearchFilter();
								}
								setStatus(!status);
							}
						}}>
						Clear Search
					</DNBButton>
				</div>
				{isActivity && activityRestriction && (
					<div className='addAttributes-eventHeader'>
						<div className='addAttributesEventTitle'>
							<span>
								Please define the number of activities that must meet the
								criteria.
							</span>
						</div>
						<div className='addAttributesEventOperator'>
							<TreeActivityEdit
								scope={
									{
										...data,
										rangeConfig: defaultRangeConfig,
										vals,
									} as unknown as TreeScope
								}
								onScopeChange={(key, value) =>
									handleScopeChange(key, value as boolean)
								}
							/>
						</div>
					</div>
				)}
				<div className='modal-body addAttributesContent'>
					<div className='row addAttributesEditor'>
						{/* <!-- left section --> */}
						<AddAttributesLeftSection
							data={modalScope}
							status={status}
							setStatus={setStatus}
							displayValues={displayValues}
							setDisplayValues={setDisplayValues}
							refresh={refresh}
							setDisplaySearchResult={setDisplaySearchResult}
							searchAttributesText={searchAttributesText}
						/>
						<DNBDivider
							sx={{borderWidth: '1px', borderRadius: '4px'}}
							orientation='horizontal'
							variant='lightIncidental'
						/>
						{/* <!-- center section --> */}
						<AddAttributesCenterSection
							data={modalScope}
							status={status}
							setStatus={setStatus}
							displayValues={displayValues}
							setDisplayValues={setDisplayValues}
							curAttribute={curAttribute}
							setCurAttribute={setCurAttribute}
							curEntity={curEntity}
							setCurEntity={setCurEntity}
							refresh={refresh}
							displaySearchResult={displaySearchResult}
							setDisplaySearchResult={setDisplaySearchResult}
							searchAttributesText={searchAttributesText}
						/>
						<DNBDivider
							sx={{borderWidth: '1px', borderRadius: '4px'}}
							orientation='horizontal'
							variant='lightIncidental'
						/>
						{/* <!-- right section --> */}
						<AddAttributesRightSection
							data={modalScope}
							status={status}
							setStatus={setStatus}
							refresh={refresh}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const AddAttributesDialog = ({
	modalScope,
	isDialogOpen,
	setIsDialogOpen,
	entity,
}: IAddAttributesDialog): React.ReactElement => {
	const [displayValues, setDisplayValues] = React.useState(false);
	const [displaySearchResult, setDisplaySearchResult] = React.useState(false);
	const [searchAttributesText, setSearchAttributesText] = React.useState('');
	const [isValidRange, setIsValidRange] = React.useState(true);
	const [, refresh] = React.useState(0);
	const isModalReady = React.useMemo(
		() => !_.isEmpty(modalScope),
		[modalScope]
	);
	const primaryButtonText = React.useMemo(() => {
		if (!modalScope?.parent?.wizard) return DialogButton.Add;
		return modalScope?.parent?.wizard.isFinalStep
			? DialogButton.Save
			: DialogButton.Continue;
	}, [modalScope?.parent?.wizard]);
	React.useEffect(() => refresh((key) => key + 1), [entity]);
	return (
		<AddAttributeDialog
			open={isDialogOpen}
			onClose={() => setIsDialogOpen?.(false)}>
			<AddAttributeDialogContent>
				{!isModalReady ? (
					<Spinner />
				) : (
					<>
						<DNBTypography
							variant='h6'
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}>
							<DNBTypography component='span' sx={{display: 'flex'}}>
								{modalScope.title}
								{modalScope.entity === 'Member' && (
									<DNBTooltip
										arrow
										placement='bottom'
										content='Currently only CDP segments can be used as attributes. Sub-segments can not be used as attributes.'>
										<InfoIcon
											sx={{width: '24px', marginLeft: 2}}
											className='show-tooltip addAttributes-info'
										/>
									</DNBTooltip>
								)}
							</DNBTypography>
							<div>
								<DNBIconButton
									sx={{
										position: 'relative',
										top: '-16px',
										right: '-24px',
									}}
									onClick={() => setIsDialogOpen?.(false)}>
									<CloseIcon />
								</DNBIconButton>
							</div>
						</DNBTypography>
						{modalScope.parent.subTitle && (
							<DNBTypography variant='caption'>
								{modalScope.parent.subTitle}
							</DNBTypography>
						)}
						<AddAttributes
							data={modalScope}
							displayValues={displayValues}
							setDisplayValues={setDisplayValues}
							displaySearchResult={displaySearchResult}
							setDisplaySearchResult={setDisplaySearchResult}
							searchAttributesText={searchAttributesText}
							setSearchAttributesText={setSearchAttributesText}
							refresh={refresh}
							setIsValidRange={setIsValidRange}
						/>
					</>
				)}
			</AddAttributeDialogContent>
			<FreeStyleBetweenFooter>
				<DNBButton
					variant='text'
					size='compact'
					disabled={!isModalReady}
					onClick={() => {
						setIsDialogOpen(false);
						modalScope.onCancelClick();
					}}>
					{DialogButton.Cancel}
				</DNBButton>
				<Box display='flex' gap={3}>
					{(modalScope?.parent?.wizard || displayValues) && (
						<DNBButton
							variant='secondary'
							size='compact'
							onClick={() => {
								if (modalScope.parent.wizard) {
									return modalScope.parent.onBackClick?.(
										modalScope?.selectedAttributes()
									);
								}
								if (displayValues) {
									setDisplayValues(false);
									if (searchAttributesText) {
										setDisplaySearchResult(true);
									}
									modalScope.setSearchText('');
								}
							}}>
							{DialogButton.Back}
						</DNBButton>
					)}
					<DNBButton
						variant='primary'
						size='compact'
						disabled={
							!isModalReady ||
							modalScope.isSaveButtonDisabled() ||
							!isValidRange
						}
						onClick={() => {
							setIsDialogOpen?.(false);
							modalScope.onSaveClick({} as MouseEvent);
						}}>
						{primaryButtonText}
					</DNBButton>
				</Box>
			</FreeStyleBetweenFooter>
		</AddAttributeDialog>
	);
};

export {AddAttributesDialog};
