import React from 'common/react-vendor';
import {AddIcon, DNBButton, RemoveIcon} from 'common/dnb-uux-vendor';
import {dispatchSetAddBucketTreeRoot} from 'common/stores/query';
import {
	IAddAttributesComponent,
	IAddAttributesContext,
} from './AddAttributeModalScopeConst';
import {AddAttributesDialog} from './Dialog/AddAttributesDialog';
import {AttributesStore} from './Helper/AttributesStore';

const AddAttributesContext = ({
	vm,
	type,
	relation,
	parentScope,
	isDialogOpen,
	setIsDialogOpen,
}: IAddAttributesContext): React.ReactElement => {
	const subscribe = (callback: () => void): (() => void) => {
		return AttributesStore.subscribe(callback, {
			vm,
			type,
			relation,
			parentScope,
		});
	};
	const modalScope = React.useSyncExternalStore(subscribe, () =>
		AttributesStore.getSnapShot()
	);
	return (
		<AddAttributesDialog
			isDialogOpen={isDialogOpen}
			setIsDialogOpen={setIsDialogOpen}
			modalScope={modalScope}
			entity={vm.entity}
		/>
	);
};
const AddAttributesComponent = ({
	vm,
	type,
	relation,
	disabled,
	showButton,
	buttonText,
}: IAddAttributesComponent): React.ReactElement => {
	const [isDialogOpen, setIsDialogOpen] = React.useState(false);
	return (
		<>
			{isDialogOpen && (
				<AddAttributesContext
					isDialogOpen={isDialogOpen}
					setIsDialogOpen={setIsDialogOpen}
					vm={vm}
					type={type}
					relation={relation}
				/>
			)}
			{showButton && (
				<DNBButton
					size='small'
					sx={{lineHeight: 1, padding: 0, cursor: 'pointer'}}
					variant='text'
					className={`${type === 'Event' ? 'add-event' : 'add-attribute'}`}
					disabled={disabled}
					startIcon={relation === 'EXCLUDE' ? <RemoveIcon /> : <AddIcon />}
					onClick={() => {
						// add tree root
						dispatchSetAddBucketTreeRoot(vm?.tree, vm.entity.toLowerCase());
						if (vm.showSegmentationV2) {
							setIsDialogOpen(true);
						} else {
							vm.root.saveState();
							vm.root.goAttributes();
						}
					}}>
					{buttonText}
				</DNBButton>
			)}
		</>
	);
};

export {AddAttributesContext, AddAttributesComponent};
