import {getDataCloudProperty} from 'common/stores/datacloud';
import {clone, cloneDeep, filter, isEqual} from 'lodash';
import md5 from 'crypto-js/md5';
import {
	AttributeEntityType,
	TimeSeriesEntityType,
} from 'atlas/data/AttributeConst';
import {
	dispatchQueryPropertyValue,
	getPublicProperty,
	getQueryProperty,
	isSegmentationMode,
	updatePublicRefresh,
} from 'common/stores/query';
import {findAndUpdatedLogicalRestriction} from 'common/components/datacloud/segment/segment.helpers';
import {QueryPublic, RestrictionType} from 'common/stores/query/types';
import {IRootRestriction} from 'atlas/data/RestrictionConst';
import {
	CateGoryThemeColor,
	CategoryMetadata,
	getCategoryThemeColor,
	getMetadataForCategory,
} from 'common/components/datacloud/datacloud.service.vanilla';
import {EnrichmentsMap} from 'common/stores/datacloud/types';
import NoticeService from 'common/components/notice/NoticeService';
import {
	IAdvancedQueryContext,
	IAdvancedQueryContextUpdate,
} from '../context/AdvancedQueryContext';
import {
	IQueryTreeComponent,
	IQueryTreeContext,
	IQueryTreeContextUpdate,
} from './context/QueryTreeContext';
import {Attribute, CubeMap} from './types';
import {
	SaveState,
	SetTotalRuleCount,
	UpdateRestriction,
} from '../AdvancedQuery.helper';
import {
	GetBktValuesReturn,
	getBktValues,
	updateBucketCount,
} from './tree.helpers';
import {BucketCmp, BucketType, Operator, TreeType} from '../../query.enums';
import {
	ActivityRestriction,
	BucketRestriction,
	Restriction,
	SegmentMemberRestriction,
	Val,
} from '../../query.types';
import {cmpMap} from './tree.constants';
import {
	getListRestrictionsWithTooManyValuesError,
	getRuleAllBuckets,
} from '../../query.helpers';
import {IQueryBlockType} from '../queryBlock/queryBlockTypes';

const fundamentalTypeToBucketType = {
	alpha: 'String',
	boolean: 'Boolean',
	numeric: 'Numerical',
	date: 'Date',
};

const OperatorLabel = {
	[Operator.AND]: 'ALL of these conditions (AND)',
	[Operator.OR]: 'ANY of these conditions (OR)',
};

const pushItem = (
	items: Attribute[],
	item: Attribute,
	tree: BucketRestriction | SegmentMemberRestriction,
	cube: CubeMap
): Attribute[] | undefined => {
	let newItems;
	if (item) {
		const attributeEntity = item.Entity;
		const cubeStats = cube[attributeEntity]?.Stats[item.ColumnId];
		const newItem: Attribute = {
			...item,
			Stats: cubeStats,
			topbkt: tree?.bkt || {},
		};
		newItems = clone(items);
		newItems.push(newItem);
	}
	return newItems;
};

const InitQueryTreeItem = (
	scope: IQueryTreeComponent
): {
	item: Attribute | undefined;
	label: string | undefined;
	range: string | Val[] | undefined;
	type: string | undefined;
	activityRestriction: Restriction | undefined;
	tree: Restriction;
} => {
	const {tree, root, entity, items = [], parent} = scope;
	let itemReturn: Attribute | undefined;
	let typeString;
	const itemsReturn = clone(items);
	let typeReturn: BucketType | TreeType | undefined;
	let label;
	let range: GetBktValuesReturn;
	let activityRestrictionReturn;
	let treeReturn: Restriction = tree;
	if (root.showTimeSeries) {
		if (parent) {
			const activityRestriction =
				parent.logicalRestriction?.restrictions.filter(
					(item) =>
						item.activityRestriction &&
						isEqual(item.activityRestriction.restriction, tree)
				);
			if (activityRestriction && activityRestriction.length > 0) {
				activityRestrictionReturn = activityRestriction?.[0];
			}
		}
	}
	if (tree.bucketRestriction) {
		const isActivity = root.showTimeSeries && tree.bucketRestriction.entityType;
		if (tree.bucketRestriction.ignored === undefined) {
			treeReturn = {
				...tree,
				bucketRestriction: {
					...tree.bucketRestriction,
					ignored: false,
				},
			};
		}
		let entityListKey = entity;
		let {enrichments} = root;
		const {entityType, attr} = tree.bucketRestriction;
		if (isActivity) {
			entityListKey = `${entity}ActivityStream`;
			enrichments = root.eventEnrichments;
		}
		const [Entity, ColumnId] = attr.split('.');
		const type = entityType || Entity;
		const hasList =
			Entity &&
			root?.entityList?.[entityListKey]?.includes(
				type as AttributeEntityType | TimeSeriesEntityType
			);
		if (hasList) {
			const [item] = filter(enrichments, {
				Entity: type,
				ColumnId,
			}) as Attribute[];
			if (item) {
				const newItems = pushItem(
					items,
					item,
					tree.bucketRestriction,
					root?.cube
				);
				if (newItems) {
					itemReturn = newItems[newItems?.length - 1];
					itemsReturn.push(itemReturn!);
				}
				if (isActivity) {
					const types = Object.entries(fundamentalTypeToBucketType).find(
						([type]) => type === itemReturn?.FundamentalType
					);
					if (types) {
						typeString = types?.[1];
					}
				} else {
					// FIXME: if there is no Bkts, it is most likely a non-bucketable text field (YSong, Jan-2018)
					typeReturn = TreeType.String;
					if (itemReturn?.Stats?.Bkts) {
						typeReturn = itemReturn.Stats?.Bkts?.Type;
					}
				}
			}
			if (isActivity) {
				label = '';
			} else {
				label = tree.bucketRestriction?.bkt?.Lbl || '';
				range = getBktValues(tree.bucketRestriction, typeReturn);
			}
		}
	}
	if (tree.segmentMemberRestriction) {
		const {entity, segmentName} = tree.segmentMemberRestriction;
		const [segment] = filter(root.segmentsList, {name: segmentName});
		const item = {
			Entity: entity,
			ColumnId: segmentName,
			DisplayName: segment?.display_name || '',
			// name: segment?.name,
			Category: `${segment?.dataType} ${
				segment?.type === 'List' ? '' : 'Segments'
			}`,
		};
		treeReturn = {
			...tree,
			segmentMemberRestriction: {
				...tree.segmentMemberRestriction,
				bkt: {Cnt: segment?.accounts},
			},
		};
		if (item) {
			const newItems = pushItem(
				items,
				item as Attribute,
				tree.segmentMemberRestriction,
				root?.cube
			);
			if (newItems) {
				itemReturn = newItems[newItems?.length - 1];
				itemsReturn.push(itemReturn!);
			}
			typeString = 'Member';
		}
		label = '';
	}
	return {
		item: itemReturn,
		label,
		range,
		type: typeReturn || typeString,
		activityRestriction: activityRestrictionReturn,
		tree: treeReturn,
	};
};

const getRootRestrictionByEntity = (
	entity: string
): {
	RootRestriction: IRootRestriction;
	restrictionKey: RestrictionType;
} => {
	let restrictionKey =
		`${entity.toLocaleLowerCase()}Restriction` as RestrictionType;
	if (
		[IQueryBlockType.Union, IQueryBlockType.Intersection].includes(
			entity as IQueryBlockType
		)
	) {
		restrictionKey = 'memberRestriction';
	}
	return {
		RootRestriction: getQueryProperty<IRootRestriction>(restrictionKey),
		restrictionKey,
	};
};

const ClickCollapsed = (context: IQueryTreeContext): void => {
	const {tree, updateTree, entity} = context;
	const {RootRestriction, restrictionKey} = getRootRestrictionByEntity(entity);
	const updated = {
		...tree,
		collapsed: !tree.collapsed,
	};
	const newRestriction = {
		...RootRestriction,
		restriction: findAndUpdatedLogicalRestriction(
			RootRestriction.restriction,
			tree,
			updated
		),
	};
	dispatchQueryPropertyValue(restrictionKey, newRestriction);
	updatePublicRefresh();
	updateTree && updateTree(entity, false);
};

const ClickSubType = (
	value: IQueryTreeContext,
	setAdvancedQuery: IAdvancedQueryContextUpdate
): boolean => {
	const {root, subType} = value;
	if (!root.canEdit) {
		return false;
	}
	if (root.contact_restriction) {
		const restrictions =
			root.contact_restriction.restriction.logicalRestriction?.restrictions;
		if (restrictions) {
			UpdateRestriction(restrictions, subType);
		}
		setTimeout(() => {
			SaveState(root, setAdvancedQuery);
		}, 50);
	}
	return true;
};

const ClickOperator = (
	operator: Operator,
	value: IQueryTreeContext,
	setAdvancedQuery: IAdvancedQueryContextUpdate
): boolean => {
	const {root, tree, entity, updateTree} = value;
	if (!root.canEdit || !tree?.logicalRestriction) {
		return false;
	}
	const {RootRestriction, restrictionKey} = getRootRestrictionByEntity(entity);
	const updated: Restriction = {
		...tree,
		logicalRestriction: {
			...tree.logicalRestriction,
			operator,
		},
	};
	const newRestriction = {
		...RootRestriction,
		restriction: findAndUpdatedLogicalRestriction(
			RootRestriction.restriction,
			tree,
			updated
		),
	};
	dispatchQueryPropertyValue(restrictionKey, newRestriction);
	setTimeout(() => {
		SaveState(root, setAdvancedQuery);
	}, 50);
	updateTree && updateTree(entity, false);
	return true;
};

const updatedRestriction = (
	tree: Restriction | undefined,
	oldTree: Restriction | undefined,
	type: string
): void => {
	const {RootRestriction, restrictionKey} = getRootRestrictionByEntity(type);
	if (tree && oldTree && !isEqual(tree, oldTree)) {
		const newRestriction = {
			...RootRestriction,
			restriction: findAndUpdatedLogicalRestriction(
				RootRestriction.restriction,
				oldTree,
				tree
			),
		};
		dispatchQueryPropertyValue(restrictionKey, newRestriction);
	} else if (!oldTree && tree) {
		let newRestriction;
		if (type === IQueryBlockType.Union) {
			newRestriction = {
				...RootRestriction,
				restriction: {
					...RootRestriction.restriction,
					logicalRestriction: {
						...RootRestriction.restriction.logicalRestriction,
						restrictions: [
							tree,
							RootRestriction.restriction.logicalRestriction?.restrictions?.[1],
						],
					},
				},
			};
		} else if (type === IQueryBlockType.Intersection) {
			newRestriction = {
				...RootRestriction,
				restriction: {
					...RootRestriction.restriction,
					logicalRestriction: {
						...RootRestriction.restriction.logicalRestriction,
						restrictions: [
							RootRestriction.restriction.logicalRestriction?.restrictions?.[0],
							tree,
						],
					},
				},
			};
		} else {
			newRestriction = {
				...RootRestriction,
				restriction: tree,
			};
		}
		dispatchQueryPropertyValue(restrictionKey, newRestriction);
	}
};

const AddOperator = (
	advancedQuery: IAdvancedQueryContext,
	setAdvancedQuery: IAdvancedQueryContextUpdate,
	tree: Restriction,
	restrictionType: string
): void => {
	const {canEdit} = advancedQuery;
	if (!canEdit) {
		return;
	}
	const operator =
		tree.logicalRestriction?.operator === Operator.AND
			? Operator.OR
			: Operator.AND;
	if (tree.logicalRestriction) {
		const oldTree = cloneDeep(tree);
		tree.logicalRestriction.restrictions.push({
			logicalRestriction: {
				operator,
				restrictions: [],
			},
		});
		updatedRestriction(tree, oldTree, restrictionType);
		SaveState(advancedQuery, setAdvancedQuery);
	}
};

const AddEventBucket = (
	advancedQuery: IAdvancedQueryContext,
	setAdvancedQuery: IAdvancedQueryContextUpdate,
	tree: Restriction,
	restrictionType: string
): void => {
	const {canEdit} = advancedQuery;
	if (!canEdit) {
		return;
	}
	if (tree.logicalRestriction) {
		const oldTree = cloneDeep(tree);
		const activityRestriction = {
			cnt: [1],
			cmp: BucketCmp.GREATER_OR_EQUAL,
			count: -1,
			restriction: {
				logicalRestriction: {
					operator: Operator.AND,
					restrictions: [],
				},
			},
		} as unknown as ActivityRestriction;
		tree.logicalRestriction.restrictions.push({
			activityRestriction,
		});
		updatedRestriction(tree, oldTree, restrictionType);
		SaveState(advancedQuery, setAdvancedQuery);
	}
};

const clickDelete = (
	value: IQueryTreeContext,
	setAdvancedQuery: IAdvancedQueryContextUpdate
): boolean => {
	const {root, parent, tree, entity, updateTree} = value;
	if (!root.canEdit) {
		return false;
	}

	const oldParent = cloneDeep(parent);
	parent?.logicalRestriction?.restrictions.forEach((item, index) => {
		if (isEqual(item, tree)) {
			if (parent?.bucketRestriction || parent?.logicalRestriction) {
				parent?.logicalRestriction?.restrictions.splice(index, 1);
			}
		} else if (item?.labelGlyph && item.labelGlyph === tree.labelGlyph) {
			// this for delete segment
			if (parent?.logicalRestriction) {
				parent?.logicalRestriction?.restrictions.splice(index, 1);
			}
		}
		if (root.showTimeSeries) {
			if (
				item.activityRestriction &&
				isEqual(item.activityRestriction.restriction, tree)
			) {
				if (parent?.bucketRestriction || parent?.logicalRestriction) {
					parent?.logicalRestriction?.restrictions.splice(index, 1);
				}
			}
		}
	});
	const type = ['Account', 'Contact'].includes(entity)
		? entity.toLocaleLowerCase()
		: 'member';
	updatedRestriction(parent, oldParent, type);
	parent && oldParent && updateTree && updateTree(entity);
	SaveState(root, setAdvancedQuery);
	SetTotalRuleCount(setAdvancedQuery);
	return true;
};

const showToggleIgnored = (canEdit: boolean): boolean =>
	canEdit && isSegmentationMode();

// The 'itemIgnored' class grays out ignored items, but it should only
// be added if a user can use the toggle button to change whether an
// item is ignored. Else, we're in a rules based model, and ignored is
// controled by the SET/UNSET buttons
const shouldGrayOut = (item: Restriction, canEdit: boolean): boolean => {
	if (item.bucketRestriction) {
		return item.bucketRestriction?.ignored && showToggleIgnored(canEdit);
	}
	if (item.segmentMemberRestriction) {
		return item.segmentMemberRestriction?.ignored && showToggleIgnored(canEdit);
	}
	return false;
};

const getCategoryMetadata = (
	category: string,
	root: IAdvancedQueryContext
): CategoryMetadata | undefined =>
	getMetadataForCategory(root.categoryMetadata, category);

const getBackgroundColor = (
	tree: Restriction,
	root: IAdvancedQueryContext,
	item?: Attribute
): string => {
	const {canEdit} = root;
	if (shouldGrayOut(tree, canEdit)) {
		return '#aaa';
	}
	if (item?.Category) {
		const categoryMetadata = getCategoryMetadata(item.Category, root);
		return getCategoryThemeColor(categoryMetadata, item);
	}
	return CateGoryThemeColor.Segment;
};

const doesBucketExist = (tree: Restriction): boolean => {
	const bucket = tree.bucketRestriction;
	if (!bucket) {
		return false;
	}
	const {attr, entityType} = bucket;
	const isActivity = !!entityType;
	const key = isActivity ? `${entityType}.${attr.split('.')?.[1]}` : attr;
	const map = isActivity
		? getDataCloudProperty<EnrichmentsMap>('eventEnrichmentsMap')
		: getDataCloudProperty<EnrichmentsMap>('enrichmentsMap');
	return key in map;
};

const getKey = (message: string): string => {
	return md5(message).toString();
};

const editBucket = (
	queryTree: IQueryTreeContext,
	setQueryTree: IQueryTreeContextUpdate
): void => {
	const {root, records_updating} = queryTree;
	if (!root.canEdit) {
		return;
	}
	const vm = {}; // TODO
	const draggedItem = root.draggedItem?.current;
	if (
		isEqual(draggedItem, vm) ||
		getPublicProperty('disableAllTreeRestrictions') ||
		records_updating
	) {
		return;
	}
	const {setEditing} = setQueryTree;
	setEditing(true);
};

const showSubCategory = (
	tree: Restriction,
	item: Attribute | undefined
): boolean => {
	if (!tree.bucketRestriction || !item) {
		return false;
	}
	const {entityType} = tree.bucketRestriction;
	const {Subcategory} = item;
	const isActivity = !!entityType;
	return isActivity || Subcategory !== 'Other';
};

/** isolate activity and accont/contact
 *  1 activity include under css 'querySectionItemBoxActivity'
 *  2 root css 'queryTreeRoot'
 *  3 activity has 'entityType' attr
 * */
function isActivityAttribute(target: HTMLElement): boolean {
	let node = target.cloneNode(true) as HTMLElement;
	while (node && !node.className.includes('queryTreeRoot')) {
		node = node?.parentElement as HTMLElement;
		if (node?.className.includes('querySectionItemBoxActivity')) {
			return true;
		}
	}
	return false;
}

const mouseMove = (
	event: React.MouseEvent<HTMLElement, MouseEvent>,
	editing: boolean,
	queryTree: IQueryTreeContext,
	advancedQuery: IAdvancedQueryContext,
	setAdvancedQuery: IAdvancedQueryContextUpdate,
	dashedItem = false,
	append = false
): void => {
	const {canEdit, draggedClone, droppedItemAppend} = advancedQuery;
	if (!canEdit || editing) {
		return;
	}
	const {root} = queryTree;
	const {setDraggedContainer} = setAdvancedQuery;
	const dragged = root.draggedItem?.current;
	let draggedContainer = root.draggedContainer?.cloneNode(true) as HTMLElement;
	let curDraggedClone = root.draggedClone?.current?.cloneNode(
		true
	) as HTMLElement;
	if (dragged) {
		let target = event.currentTarget || event.target;
		while (target) {
			if (target?.classList?.contains('querySectionItem')) {
				break;
			}
			target = target.parentNode as HTMLElement;
		}
		const rectCurrent = target?.getBoundingClientRect();
		const offsetY = event.clientY - rectCurrent?.top;
		let DroppedItemAppend = false;
		if (!dashedItem) {
			DroppedItemAppend = offsetY / rectCurrent?.height >= 0.5;
			if (droppedItemAppend) {
				droppedItemAppend.current = DroppedItemAppend;
			}
		} else if (append) {
			DroppedItemAppend = append || false;
			if (droppedItemAppend) {
				droppedItemAppend.current = DroppedItemAppend;
			}
		}
		if (!curDraggedClone || !root.draggedContainer) {
			draggedContainer = document.getElementsByClassName(
				'advancedQueryBuilder'
			)?.[0] as HTMLElement;
			curDraggedClone = target.parentNode?.cloneNode(false) as HTMLElement;
			curDraggedClone?.append(target.cloneNode(true));
			if (curDraggedClone) {
				draggedContainer?.append(curDraggedClone);
				curDraggedClone.className = 'querySection dragging';
			}
			setDraggedContainer(draggedContainer);
		}
		if (root.draggedContainer) {
			const rect = root.draggedContainer?.getBoundingClientRect();
			const x = event.clientX - rect.left + 10;
			const y = event.clientY - rect.top - 51;
			const t = `translate(${x}px, ${y}px) scale(0.8, 0.8)`;
			curDraggedClone.style.transform = t;
			if (draggedClone) {
				draggedClone.current = curDraggedClone;
				const dragging = document.getElementsByClassName(
					'querySection dragging'
				)?.[0] as HTMLElement;
				dragging.style.transform = t;
			}
		}
	}
};

const mouseDown = (
	event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	editing: boolean,
	queryTree: IQueryTreeContext,
	advancedQuery: IAdvancedQueryContext,
	setAdvancedQuery: IAdvancedQueryContextUpdate
): void => {
	const {canEdit, draggedItem, mouseDownTimer} = advancedQuery;
	if (!canEdit || editing) {
		return;
	}

	if (draggedItem?.current) {
		draggedItem.current = undefined;
	}
	const timer = setTimeout(() => {
		if (draggedItem) {
			draggedItem.current = queryTree;
		}
		clearTimeout(timer);
		mouseMove(event, editing, queryTree, advancedQuery, setAdvancedQuery);
	}, 350);
	if (mouseDownTimer) {
		mouseDownTimer.current = timer;
	}
};

const mouseOver = (
	event: React.MouseEvent<HTMLDivElement | HTMLSpanElement, MouseEvent>,
	editing: boolean,
	queryTree: IQueryTreeContext,
	advancedQuery: IAdvancedQueryContext,
	callBack?: () => void
): void => {
	const {canEdit, showTimeSeries, droppedItem} = advancedQuery;
	if (!canEdit || editing) {
		return;
	}
	const {root, tree} = queryTree;
	const dragged = root.draggedItem?.current;
	const dropped = root.droppedItem?.current;
	if (
		dragged &&
		(!dropped ||
			(dropped &&
				(dropped as IQueryTreeContext)?.tree?.labelGlyph !== tree.labelGlyph))
	) {
		if (showTimeSeries) {
			const entityType = dragged.tree.bucketRestriction?.entityType;
			const isActivity = isActivityAttribute(event.currentTarget);
			if ((!entityType && isActivity) || (entityType && !isActivity)) {
				return;
			}
		}
		if (droppedItem) {
			if (droppedItem.current) {
				// delete last droppedItem
				const attributeClassList =
					'.querySectionItemBox.querySectionItemBoxSubtree';
				const item = droppedItem.current as IQueryTreeContext;
				const droppedId = item?.tree.labelGlyph;
				const dashedItem = document.querySelector(
					`${attributeClassList}.labelGlyph-${droppedId} .dashed`
				) as HTMLElement;
				if (dashedItem) {
					const display = dashedItem.style.display === 'none' ? '' : 'none';
					dashedItem.style.display = display;
				}
				if (dragged.tree.labelGlyph === droppedId) {
					const draggedId = dragged.tree.labelGlyph;
					const dashedItemParent = document.querySelector(
						`${attributeClassList}.labelGlyph-${draggedId}`
					) as HTMLElement;
					if (dashedItemParent) {
						dashedItemParent.style.display = 'none';
					}
				}
			}
			droppedItem.current = queryTree;
			callBack && callBack();
		}
	}
};

const mouseOverHighlight = (id: number): void => {
	if (document.getElementById(`input-${id}`)) {
		document.getElementById(`input-${id}`)?.classList.add('active-link');
	}
};

const mouseOutHighlight = (id: number): void => {
	if (document.getElementById(`input-${id}`)) {
		document.getElementById(`input-${id}`)?.classList.remove('active-link');
	}
};

const hideRestrictions = (
	item: Restriction,
	enable_calculate_known_contact: boolean,
	entity: string
): boolean => {
	if (enable_calculate_known_contact && entity === 'Contact' && item) {
		if (item.bucketRestriction) {
			const [Entity, ColumnId] = item.bucketRestriction.attr.split('.');
			if (Entity === 'CuratedContact' && ColumnId === 'KnownContact') {
				return true;
			}
		}
	}
	return false;
};

const getOperationLabel = (
	activityRestriction: Restriction | undefined
): string | undefined => {
	if (!activityRestriction?.activityRestriction) {
		return BucketCmp.GREATER_THAN;
	}
	const {cmp} = activityRestriction.activityRestriction;
	return cmpMap[cmp];
};

const getOperationValue = (
	activityRestriction: Restriction | undefined,
	position: number
): string | undefined => {
	if (!activityRestriction?.activityRestriction) {
		return '';
	}
	const {cnt = ['', '']} = activityRestriction.activityRestriction;
	return cnt[position]?.toLocaleString();
};

const showTo = (
	activityRestriction: Restriction | undefined,
	two_inputs: BucketCmp[]
): boolean => {
	if (!activityRestriction?.activityRestriction) {
		return false;
	}
	const {cmp} = activityRestriction.activityRestriction;
	return Object.values(two_inputs).includes(cmp);
};

const disableAllRestrictions = (): boolean => {
	return getPublicProperty('disableAllTreeRestrictions') as boolean;
};

const getNextLabelGlyph = (): number => {
	const queryPublic: QueryPublic = getQueryProperty('public');
	const {labelIncrementor} = queryPublic;
	let nextLabelGlyph = labelIncrementor;
	const current = getQueryProperty<Restriction>('segmentHistoryTree');
	const allBuckets = getRuleAllBuckets([current]);
	allBuckets.forEach((bucket) => {
		nextLabelGlyph = Math.max(nextLabelGlyph, bucket.labelGlyph || 0);
	});
	return nextLabelGlyph + 1;
};

const clickClone = (
	queryTree: IQueryTreeContext,
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate
): void => {
	const {root, tree, parent, entity} = queryTree;
	if (!root.canEdit) {
		return;
	}
	if (parent?.logicalRestriction) {
		const {restrictions} = parent?.logicalRestriction;
		const restrictionToCloneIndex = restrictions?.findIndex(
			(restriction) => restriction.labelGlyph === tree.labelGlyph
		);
		if (restrictionToCloneIndex > -1) {
			let clonedRestriction = cloneDeep<Restriction>(
				restrictions[restrictionToCloneIndex] || {}
			);
			if (!root.isRules) {
				// model need update totalRules
				SetTotalRuleCount(action);
			}
			const nextLabelGlyph = getNextLabelGlyph();
			if (nextLabelGlyph) {
				clonedRestriction = {
					...clonedRestriction,
					labelGlyph: nextLabelGlyph,
				};
			}
			const error =
				getListRestrictionsWithTooManyValuesError(clonedRestriction);
			if (error) {
				NoticeService.warning(error);
				return;
			}
			/**
			 * Splice clonedRestriction in at adjacent index of the original restriction,
			 * so that the clonedRestriction will be right after the original restriction in the UI.
			 */
			const oldParent = cloneDeep(parent);
			restrictions.splice(restrictionToCloneIndex, 0, clonedRestriction);
			updatedRestriction(parent, oldParent, entity.toLocaleLowerCase());
			// UpdateCount(value, action);
			SaveState(value, action);
			SetTotalRuleCount(action);
		}
	}
};

const updateBucketCountAndRecords = async (
	tree: Restriction,
	setQueryTree: IQueryTreeContextUpdate
): Promise<void> => {
	if (isSegmentationMode()) {
		const {setRecordsUpdating} = setQueryTree;
		setRecordsUpdating(true);

		if (tree.bucketRestriction) {
			const data = await updateBucketCount(cloneDeep(tree.bucketRestriction));

			if (typeof data == 'number') {
				if (tree.bucketRestriction?.bkt) {
					// eslint-disable-next-line no-param-reassign
					tree.bucketRestriction.bkt.Cnt = data;
				}
			}
			setRecordsUpdating(false);
		}
	}
};

const setIgnored = (
	queryTree: IQueryTreeContext,
	setQueryTree: IQueryTreeContextUpdate,
	value: IAdvancedQueryContext,
	action: IAdvancedQueryContextUpdate,
	isIgnored: boolean,
	callBack: (tree: Restriction) => void
): void => {
	const {root, tree} = queryTree;
	if (!root.canEdit) return;

	const {showSegmentationV2} = value;

	// If a user tries to enable a restriction, we need to run validation
	if (!isIgnored && tree.bucketRestriction) {
		const validationError = getListRestrictionsWithTooManyValuesError(tree);
		if (validationError) {
			NoticeService.warning(validationError);
			return; // Early return for no-op if validation fails
		}
	}
	if (tree.bucketRestriction) {
		tree.bucketRestriction.ignored = isIgnored;
		const {entityType} = tree.bucketRestriction;
		if (!entityType) {
			updateBucketCountAndRecords(tree, setQueryTree);
		}
	}
	if (tree.segmentMemberRestriction && showSegmentationV2) {
		tree.segmentMemberRestriction.ignored = isIgnored;
	}
	callBack(tree);
	SaveState(value, action);
};

export {
	InitQueryTreeItem,
	ClickCollapsed,
	ClickSubType,
	ClickOperator,
	clickDelete,
	OperatorLabel,
	getBackgroundColor,
	doesBucketExist,
	getKey,
	editBucket,
	getCategoryMetadata,
	showSubCategory,
	mouseOver,
	mouseMove,
	mouseOverHighlight,
	mouseOutHighlight,
	mouseDown,
	shouldGrayOut,
	hideRestrictions,
	AddOperator,
	AddEventBucket,
	getOperationLabel,
	getOperationValue,
	showTo,
	updatedRestriction,
	disableAllRestrictions,
	clickClone,
	showToggleIgnored,
	setIgnored,
	getNextLabelGlyph,
	updateBucketCountAndRecords,
	getRootRestrictionByEntity,
};
