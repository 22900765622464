import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {
	FeatureFlags,
	isCalculateKnownContactEnabled,
	isFeatureFlagEnabled,
	isHorizontalNavigationEnabled,
	isSegmentationV2Enabled,
	isBuyerJourneyEnabled,
	isBuyerJourneyAnalyticsEnabled,
	isEIFInboundEnabled,
	isAC360NewDesignEnabled,
	isQueryBuilderRefactorEnable,
} from 'common/stores/tenantConfig';
import {getEnrichments, getDataCloudStore} from 'common/stores/datacloud';
import {
	setPublicProperty,
	dispatchQueryPropertyValue,
	getQueryProperty,
} from 'common/stores/query';
import '../subheader/my-data-subheader.component.ng';
import '../segmentheader/segment.header.ng';
import '../tabs/segment.tabs.ng';
import '../segmentheader/segment.headerx.ng';
import '../../query/advanced/buttongroup/query.button.group.ng';
import '../../query/advanced/parentcontainer/parent.container.ng';
import '../segmentheader/ac360.header.ng';
import {getTeams} from '../../segment/segment.queries';
import StateHistory from '../../../../../atlas/app/StateHistory';
import {getAllBuckets} from '../../query/query.helpers';
import {
	SegmentNgState,
	getEntityRoute,
} from '../../../../../atlas/app/helpers/NavigateHelper';
import {getSessionSegmentState} from '../../query/results/rebuild/segment.helpers';
import {
	SEGMENTS_ACCOUNTS360,
	SEGMENTS_JOURNEYACCOUNTS360,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT,
	MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT,
	MODELS_CREATE_ACCOUNT_FIT_MODELING,
} from '../../../../../atlas/app/navigation/header/components/page-navigation.utils';

angular
	.module('common.datacloud.tabs.mydata', [
		'common.datacloud.tabs.mydatasubheader',
		'common.datacloud.tabs.segmenteditheader',
		'common.datacloud.tabs.segmenteditheaderx',
		'common.datacloud.tabs.ac360NewHeader',
		'common.datacloud.tabs.tabs',
	])
	.controller(
		'MyDataTabsController',
		function ($scope, $state, $stateParams, Segment) {
			const vm = this;
			const segmentObj = getQueryProperty('segment');
			const isListSegment = segmentObj?.type === 'List';

			const {isCompanyList, isContactList} = getSessionSegmentState() ?? {};

			angular.extend(vm, {
				DataCloudStore: getDataCloudStore(),
				stateParams: $stateParams,
				segment: $stateParams.segment,
				Segment,
				isListSegment,
				section: $stateParams.section,
				show_lattice_insights: isFeatureFlagEnabled(
					FeatureFlags.LATTICE_INSIGHTS
				),
				enable_calculate_known_contact: isCalculateKnownContactEnabled(),
				showSegmentationV2: isSegmentationV2Enabled(),
				enableHorizontalNavigation:
					isHorizontalNavigationEnabled() &&
					// We don't want to display the new redesigns on the ai model or segment pages yet
					!$stateParams.aiModel &&
					$stateParams.segment === 'Create',

				accountRestriction: getQueryProperty('accountRestriction') || null,
				contactRestriction: getQueryProperty('contactRestriction') || null,
				counts: isListSegment
					? {
							accounts: {value: segmentObj.accounts},
							contacts: {value: segmentObj.contacts},
					  }
					: getQueryProperty('counts'),
				teams: [],
				enrichments: [],
				segmentObj,
				showQueryBuilder: !isCompanyList && !isContactList,
				showAccounts: !isContactList,
				showContacts: !isCompanyList,
				isAccountFitModelingEnv:
					$state.current.name.includes(
						MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT
					) ||
					$state.current.name.includes(
						MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT
					),
				isBuyerJourneyEnabled: isBuyerJourneyEnabled(),
				enableQueryBuilderRefactor: isQueryBuilderRefactorEnable(),
			});

			if (vm.showSegmentationV2) {
				angular.extend(vm, {
					memberRestriction: getQueryProperty('memberRestriction') || null,
				});
			}

			/**
			 * Check if dashboard tab is display or not.
			 * #1 Create mode
			 * #2 either isBuyerJourneyEnabled or isBuyerJourneyAnalyticsEnabled
			 * #3 isSubSegment
			 * @returns True on display.
			 */
			vm.showDashboard = () => {
				const isCreateState = $stateParams.segment === 'Create';
				return (
					(isBuyerJourneyEnabled() || isBuyerJourneyAnalyticsEnabled()) &&
					!isCreateState &&
					!vm.segmentObj?.isSubSegment
				);
			};

			vm.getSegmentName = () => {
				return vm.Segment?.display_name || 'Segments';
			};

			vm.attributeTabEnabled =
				RBAC.hasAccess(RBACInterface.ATTRIBUTES_TAB, RBACActions.VIEW) &&
				!vm.showSegmentationV2;

			vm.init = function () {
				const subNavBar = document.getElementById('mainSummaryView');

				if (subNavBar) {
					subNavBar.style.display = 'contents';
				}

				dispatchQueryPropertyValue('history', []);

				const attributesUrl = `home.segment.explorer.attributes({segment:'${vm.segment}'})`;

				vm.attributes = vm.ifInModel(
					'home.model.analysis.explorer',
					attributesUrl
				);
				vm.accounts = vm.ifInModel(
					'home.model.analysis.accounts',
					'home.segment.accounts'
				);
				vm.contacts = vm.ifInModel(
					'home.model.analysis.contacts',
					'home.segment.contacts'
				);

				getEnrichments().then((result) => {
					vm.enrichments = result;
				});

				getTeams().then((response) => {
					vm.teams = response;
				});
			};

			vm.clickTab = function (tab, count) {
				if (count && count[tab].value < 0) {
					return;
				}

				const stateParamsSegment = tab === 'builder' ? 'builder' : 'Create';

				let route =
					tab === 'attributes'
						? 'explorer.attributes'
						: tab === 'builder'
						? 'explorer.builder'
						: tab;

				// route to journey account / contact if journey stage in enabled.
				if (vm.showDashboard()) {
					const journeyRoutes = ['accounts', 'contacts'];
					route = journeyRoutes.includes(route) ? `journey${route}` : route;
				}

				$state.dispose(); // transition issue

				if (vm.isAccountFitModelingEnv) {
					const accountFitModelingState = $state.current.name.includes(
						MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT
					)
						? MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT
						: MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT;

					$state.go(accountFitModelingState, {
						tab: `createSegment${
							route === 'explorer.builder' || route === 'explorer.attributes'
								? ''
								: route.charAt(0).toUpperCase() + route.slice(1)
						}`,
					});

					return;
				}

				// reloading home.segment only as it holds the main subheader component
				$state.go(
					`home.segment.${route}`,
					{
						segment: vm.segment ?? stateParamsSegment,
					},
					{reload: 'home.segment'}
				);
			};

			vm.getRuleCount = function () {
				let all = [];

				['accountRestriction', 'contactRestriction'].forEach(function (source) {
					const sourceRestriction = getQueryProperty(source);

					if (sourceRestriction.restriction) {
						const buckets = getAllBuckets(
							sourceRestriction.restriction.logicalRestriction.restrictions
						);
						all = [].concat(all, buckets);
					}
				});
				if (vm.showSegmentationV2) {
					const memberRestriction = getQueryProperty('memberRestriction');

					if (memberRestriction.restriction) {
						var buckets = getAllBuckets(
							memberRestriction.restriction.logicalRestriction.restrictions
						);
						all = [].concat(all, buckets);
					}
				}

				return all.length || 0;
			};

			vm.checkState = function (type) {
				const map = {
					'home.segment.explorer.attributes': 'attributes',
					'home.segment.explorer.enumpicker': 'attributes',
					'home.segment.explorer.builder': 'builder',
					'home.segment.accounts': 'accounts',
					'home.segment.contacts': 'contacts',
					// Journey Stage newly added routes.
					[SegmentNgState.Dashboard]: 'dashboard',
					[SegmentNgState.Subsegments]: 'subsegments',
					[SegmentNgState.JourneyAccounts]: 'accounts',
					[SegmentNgState.JourneyContacts]: 'contacts',
				};

				if (
					StateHistory.lastTo().name.includes(
						MODELS_CREATE_ACCOUNT_FIT_MODELING_CREATE_SEGMENT
					) ||
					StateHistory.lastTo().name.includes(
						MODELS_CREATE_ACCOUNT_FIT_MODELING_SCORING_SEGMENT
					)
				) {
					switch ($stateParams.tab) {
						case 'createSegment':
							return 'builder' === type;
						case 'attributes':
						case 'picker':
							return 'attributes' === type;
						case 'createSegmentAccounts':
							return 'accounts' === type;
						case 'createSegmentContacts':
							return 'contacts' === type;
					}
				}

				if (
					[SEGMENTS_JOURNEYACCOUNTS360, SEGMENTS_ACCOUNTS360].includes(
						StateHistory.lastTo().name
					)
				) {
					return map[StateHistory.lastFrom().name] === type;
				}

				return map[StateHistory.lastTo().name] == type;
			};

			vm.getEntityCounts = function (type) {
				const counts = getQueryProperty('counts');
				return counts[type].value.toLocaleString();
			};

			vm.isCountsLoading = function (type) {
				const counts = getQueryProperty('counts');
				return counts[type].loading;
			};

			vm.setStateParams = function (section) {
				let goHome = false;

				if (section && section == vm.section && section) {
					goHome = true;
				}

				vm.section = section;

				const params = {
					section: vm.section,
				};

				if (goHome) {
					params.category = '';
					params.subcategory = '';
				}

				const state = vm.ifInModel('home.model.analysis', 'home.segment');

				$state.go(state, params, {notify: true});
			};

			vm.inModel = function () {
				const name = $state.current.name.split('.');
				return name[1] == 'model';
			};

			vm.ifInModel = function (model, not) {
				return vm.inModel() ? model : not;
			};

			vm.go = function (state) {
				$state.go(state, {}, {reload: true});
			};

			vm.onChange = function (name) {
				let enableSave = true;
				if (!name) {
					enableSave = false;
				} else {
					const segment = {
						...vm.Segment,
						display_name: name,
					};
					vm.Segment.display_name = name;
					dispatchQueryPropertyValue('segment', segment);
				}
				setPublicProperty('enableSaveSegmentButton', enableSave);
			};

			vm.showSubHeader = () => {
				return (
					!isBuyerJourneyEnabled() ||
					($state.current.name !== SegmentNgState.Dashboard &&
						$state.current.name !== SegmentNgState.Subsegments &&
						$state.current.name !== SegmentNgState.QueryBuilder &&
						$state.current.name !== SegmentNgState.JourneyAccounts &&
						$state.current.name !== SegmentNgState.JourneyContacts &&
						$state.current.name !== SegmentNgState.Accounts &&
						$state.current.name !== SegmentNgState.Contacts &&
						!$state.current.name.includes(MODELS_CREATE_ACCOUNT_FIT_MODELING))
				);
			};

			vm.showAC360Header = () =>
				isEIFInboundEnabled() &&
				isAC360NewDesignEnabled() &&
				$state.current.name === `${getEntityRoute('accounts')}.contacts`;

			vm.init();
		}
	);
