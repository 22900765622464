import React from 'common/react-vendor';
import {
	DNBCheckbox,
	DNBDivider,
	DNBIconButton,
	DNBTooltip,
	DNBTypography,
	InfoOutlinedIcon,
	NavigateNextIcon,
} from 'common/dnb-uux-vendor';
import {isEqual} from 'lodash';
import {BucketCmp, Entity} from 'common/components/datacloud/query/query.enums';
import {Bkt} from 'common/components/datacloud/query/query.types';
import classNames from 'classnames';
import {VariableSizeList as List} from 'common/widgets/react-window';
import {
	CommonBreadcrumbs,
	ICommonBreadcrumbs,
} from 'atlas/connectors/EIF/Component/CommonBreadcrumbs';
import {IAddAttributeModalScope} from '../AddAttributeModalScopeConst';
import {IModalCategory} from '../AddAttributesModalConst';

const showColIds = (
	category: IModalCategory,
	colIds: string[] | undefined,
	data: IAddAttributeModalScope,
	status: boolean,
	setStatus: (status: boolean) => void,
	setDisplayValues: (displayValues: boolean) => void,
	setCurAttribute: (attrId: string) => void,
	setCurEntity: (entity: Entity) => void,
	refresh: React.Dispatch<React.SetStateAction<number>>,
	setDisplaySearchResult: (display: boolean) => void
): React.ReactElement => {
	if (!colIds) return <></>;
	const {
		filteredCategories,
		activeCategory,
		attributes,
		parent,
		searchText,
		selectedAttributes,
		attributeCheckboxDisabled,
		setSelectedAttributes,
		filterColIds,
		setSearchText,
		scrollTo,
	} = data;
	const gotoValues = (
		sourceEntity: string | undefined,
		sourceAttrId: string
	): void => {
		if (searchText) {
			setSearchText('');
			// searchFilter();
			const attrId = `${sourceEntity}.${sourceAttrId}`;
			const curExpendedSub = attributes[attrId]?.Subcategory;
			const category = filteredCategories.find((category) => {
				return category.name === attributes[attrId]?.Category;
			});
			if (
				category &&
				(category?.name !== activeCategory?.name ||
					curExpendedSub !== activeCategory?.curExpendedSub)
			) {
				scrollTo(category, curExpendedSub || category.curExpendedSub);
			}
		}
		setCurEntity(sourceEntity as Entity);
		setCurAttribute(sourceAttrId);
		setDisplayValues(true);
		setDisplaySearchResult(false);
	};
	return (
		<List
			key='attributesList'
			height={566}
			width='100%'
			itemSize={() => 64}
			itemCount={colIds.length}>
			{({index, style}) => {
				const colId = colIds[index] || '';
				const attribute = attributes?.[colId];
				const [sourceEntity, sourceAttrId = ''] = colId.split('.');
				const Stats = parent?.root?.cube?.[sourceEntity as Entity]?.Stats;
				const Bkts = Stats?.[sourceAttrId];
				return (
					<li key={colId} style={style}>
						<DNBCheckbox
							name={colId}
							size='small'
							id={colId}
							label={
								<DNBTypography
									component='div'
									sx={{
										display: 'inline-flex',
										justifyContent: 'space-between',
										marginTop: '-20px',
										marginLeft: 1,
										width: '99%',
									}}>
									<DNBTypography
										component='div'
										variant='compact-body'
										sx={{
											display: 'flex',
											alignItems: 'center',
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}>
										<span className='addAttributesText'>{attribute?.name}</span>
										{attributes?.[colId]?.Description?.trim() && (
											<DNBTooltip
												arrow
												placement='right'
												sx={{display: 'flex'}}
												content={attributes?.[colId]?.Description}>
												<InfoOutlinedIcon
													fontSize='small'
													sx={{
														cursor: 'pointer',
														marginLeft: 2,
														color: (theme) => theme.colors.ColorGraySecondary,
														fontSize: (theme) => theme.spacing(5),
													}}
												/>
											</DNBTooltip>
										)}
									</DNBTypography>
									<DNBTypography component='div' variant='compact-body'>
										{filterColIds(colId).length > 1 && (
											<span className='addAttributes-count'>
												({filterColIds(colId).length})
											</span>
										)}
										{Bkts?.Bkts?.List && (
											<DNBIconButton
												sx={{padding: '6px'}}
												size='small'
												onClick={() => gotoValues(sourceEntity, sourceAttrId)}>
												<NavigateNextIcon />
											</DNBIconButton>
										)}
									</DNBTypography>
								</DNBTypography>
							}
							labelProps={{
								sx: {
									'width': '98%',
									'height': 56,
									'margin': '4px',
									':hover': {
										margin: '4px',
									},
								},
							}}
							title={attributes?.[colId]?.Title}
							checked={
								selectedAttributes().findIndex(
									(values) => values.attrId === colId
								) > -1
							}
							disabled={attributeCheckboxDisabled(colId)}
							onChange={(e) => {
								refresh((key) => -key);
								setSelectedAttributes(
									e as unknown as MouseEvent,
									colId,
									category
								);
								setStatus(!status);
							}}
							className={`addAttributes-checkbox ${
								selectedAttributes().findIndex(
									(values) => values.attrId === colId
								) > -1
									? 'checked'
									: ''
							}`}
						/>
						<DNBDivider />
					</li>
				);
			}}
		</List>
	);
};

const showValues = (
	colIds: string[] | undefined,
	data: IAddAttributeModalScope,
	status: boolean,
	setStatus: (status: boolean) => void,
	curEntity: Entity,
	curAttribute: string,
	refresh: React.Dispatch<React.SetStateAction<number>>
): React.ReactElement => {
	if (!colIds || !curEntity) return <></>;
	const {
		parent,
		searchText,
		selectedAttributes,
		attributeCheckboxDisabled,
		setSelectedAttributeValues,
	} = data;
	const Stats = parent?.root?.cube?.[curEntity]?.Stats;
	const Bkts = Stats?.[curAttribute];
	const list =
		Bkts?.Bkts?.List.filter((bkt) =>
			bkt.Lbl?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase())
		).sort((a, b) => (b?.Cnt || 0) - (a?.Cnt || 0)) || [];
	if (!list.length) return <li>No Values</li>;
	const getHighest = (): number => {
		return Bkts?.Cnt || 0;
	};
	const addNewOneBkt =
		Bkts?.Bkts.Type !== 'Enum' || Bkts?.Bkts?.List.length <= 5;
	const highest = getHighest();
	const percentage = (count = 0): string => {
		const percent = (count / highest) * 100;
		return `${percent > 0.1 ? percent : '0.1'}%`;
	};
	const attrId = `${curEntity}.${curAttribute}`;
	const selectedAttributeValues = (bkt: Bkt | undefined): boolean => {
		if (addNewOneBkt) {
			const selectedAttribute = selectedAttributes()
				.filter((values) => values.attrId === attrId)
				.flatMap((inner) => {
					return [inner.bkt];
				});
			const comparedBkt = {...bkt, checked: false, Cnt: 0};
			return bkt && selectedAttribute
				? selectedAttribute.findIndex((inner) => {
						let innerComparedBkt = {
							...inner,
							checked: false,
							Cnt: 0,
						};
						if (inner?.Cmp === BucketCmp.IN_COLLECTION) {
							innerComparedBkt = {
								...innerComparedBkt,
								Cmp: BucketCmp.EQUAL,
							};
						}
						return isEqual(innerComparedBkt, comparedBkt);
				  }) > -1
				: false;
		}
		let selected = false;
		selectedAttributes().forEach(({attrId: id, bkt: ValuesBkt}) => {
			if (id === attrId && ValuesBkt?.Vals?.includes(bkt?.Vals?.[0])) {
				selected = true;
			}
		});
		return selected;
	};
	return (
		<List
			key='attributesValuesList'
			height={566}
			width='100%'
			itemSize={() => 64}
			itemCount={list.length}>
			{({index, style}) => {
				const colId = list[index]?.Lbl;
				const cnt = list[index]?.Cnt;
				return (
					<li key={colId} style={style}>
						<DNBCheckbox
							name={colId}
							size='small'
							id={colId}
							label={
								<DNBTypography
									component='div'
									sx={{
										marginTop: '-20px',
										marginLeft: 1,
										width: '99%',
									}}>
									<DNBTypography
										component='div'
										variant='compact-body'
										sx={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
										}}>
										<span>{colId}</span>
									</DNBTypography>
									<DNBTypography
										component='div'
										variant='caption-bold'
										sx={{display: 'flex', alignItems: 'center'}}>
										<DNBTypography
											component='div'
											sx={{
												height: 16,
												width: `${percentage(cnt)}`,
												background: (theme) =>
													theme.colors.ColorSecondaryBlueLight,
											}}
										/>
										<DNBTypography
											component='span'
											variant='caption-bold'
											sx={{
												marginLeft: 1,
												whiteSpace: 'nowrap',
											}}>
											{`${cnt?.toLocaleString('en-US')} Records`}
										</DNBTypography>
									</DNBTypography>
								</DNBTypography>
							}
							labelProps={{
								sx: {
									'width': '98%',
									'height': 56,
									'margin': '4px',
									':hover': {
										margin: '4px',
									},
								},
							}}
							title={colId}
							checked={selectedAttributeValues(list[index])}
							disabled={attributeCheckboxDisabled(attrId)}
							onChange={(e) => {
								refresh((key) => -key);
								setSelectedAttributeValues(
									attrId,
									addNewOneBkt,
									!e.target.checked,
									list[index] || {}
								);
								setStatus(!status);
							}}
							className={`addAttributes-checkbox ${
								selectedAttributeValues(list[index]) ? 'checked' : ''
							}`}
						/>
						<DNBDivider />
					</li>
				);
			}}
		</List>
	);
};

export const AddAttributesCenterSection = ({
	data,
	status,
	setStatus,
	displayValues,
	setDisplayValues,
	curAttribute,
	setCurAttribute,
	curEntity,
	setCurEntity,
	refresh,
	displaySearchResult,
	setDisplaySearchResult,
	searchAttributesText,
}: {
	data: IAddAttributeModalScope;
	status: boolean;
	setStatus: (status: boolean) => void;
	displayValues: boolean;
	setDisplayValues: (displayValues: boolean) => void;
	curAttribute: string;
	setCurAttribute: (attrId: string) => void;
	curEntity: Entity;
	setCurEntity: (entity: Entity) => void;
	refresh: React.Dispatch<React.SetStateAction<number>>;
	displaySearchResult: boolean;
	setDisplaySearchResult: (display: boolean) => void;
	searchAttributesText: string;
}): React.ReactElement => {
	const {
		attributes,
		filteredCategories,
		entity,
		activeCategory,
		isActivity,
		activityRestriction,
		scrollTo,
		setSearchText,
	} = data;
	const attrId = `${curEntity}.${curAttribute}`;
	const category = filteredCategories.find((category) => {
		return displayValues
			? category.name === attributes[attrId]?.Category
			: category.name === activeCategory?.name;
	});
	const dataChanges = (): void => {
		if (displayValues) {
			setSearchText('');
			setDisplayValues(false);
			if (searchAttributesText) {
				setDisplaySearchResult(false);
			}
		}
		setStatus(!status);
	};
	const {curExpendedSub} = activeCategory || {};
	const breadcrumbsList: ICommonBreadcrumbs[] = [
		{
			text: category?.name || '',
			onClick: () => {
				if (!category) {
					return;
				}
				if (category?.subCategories?.length > 0) {
					category.expanded = curExpendedSub !== category?.subCategories?.[0];
					scrollTo(
						category,
						curExpendedSub !== category?.subCategories?.[0]
							? category?.subCategories?.[0] || ''
							: ''
					);
					dataChanges();
				}
			},
			isLink: true,
			hide: true,
		},
		{
			text: curExpendedSub || '',
			onClick: () => {
				if (!category) {
					return;
				}
				dataChanges();
			},
			isLink: true,
			hide:
				!!category?.name &&
				!!activeCategory?.curExpendedSub &&
				(activeCategory.subCategories.length !== 1 ||
					curExpendedSub !== 'Other'),
		},
		{
			text: attributes?.[attrId]?.name || '',
			isLink: false,
			hide: !!displayValues && !!curAttribute && !!attributes?.[attrId]?.name,
		},
	];
	const allFilteredColIds = displaySearchResult
		? filteredCategories?.flatMap((inner) =>
				Object.values(inner.filterColIds).flatMap((a) => a)
		  )
		: [];
	return (
		<div className='addAttributesToggles'>
			<ul
				className={classNames({
					'addAttributes-togglesTree': true,
					'addAttributes-togglesTree-segment': entity === 'Member',
					'isActivity': isActivity && activityRestriction,
				})}>
				<div>
					<div className='navgationBar'>
						{displaySearchResult ? (
							<DNBTypography
								component='div'
								variant='overline'
								sx={{
									fontWeight: 850,
									textTransform: 'uppercase',
									margin: '10px 0',
								}}>
								{allFilteredColIds.length} search results
							</DNBTypography>
						) : (
							<CommonBreadcrumbs
								sx={{width: '800px', mt: '10px', mb: '10px'}}
								breadcrumbsList={breadcrumbsList.filter(({hide}) => hide)}
							/>
						)}
					</div>
					{category?.name && (
						<DNBDivider
							sx={{
								color: (theme) => theme.colors.ColorGrayIncidental,
							}}
							orientation='horizontal'
							variant='medium'
						/>
					)}
					{displayValues && !displaySearchResult && (
						<>
							{category?.subCategories?.[0] && (
								<div>
									{showValues(
										category?.filterColIds?.[category?.subCategories?.[0]],
										data,
										status,
										setStatus,
										curEntity,
										curAttribute,
										refresh
									)}
								</div>
							)}
						</>
					)}
					{!displayValues && !displaySearchResult && (
						<>
							{category &&
							curExpendedSub &&
							category?.filterColIds?.[curExpendedSub]?.length ? (
								<>
									{curExpendedSub && (
										<div>
											{showColIds(
												category,
												category?.filterColIds?.[curExpendedSub],
												data,
												status,
												setStatus,
												setDisplayValues,
												setCurAttribute,
												setCurEntity,
												refresh,
												setDisplaySearchResult
											)}
										</div>
									)}
								</>
							) : (
								<li>No attributes</li>
							)}
						</>
					)}
					{!displayValues && displaySearchResult && (
						<>
							{category && allFilteredColIds?.length ? (
								<div>
									{showColIds(
										category,
										allFilteredColIds,
										data,
										status,
										setStatus,
										setDisplayValues,
										setCurAttribute,
										setCurEntity,
										refresh,
										setDisplaySearchResult
									)}
								</div>
							) : (
								<li>No attributes</li>
							)}
						</>
					)}
				</div>
			</ul>
		</div>
	);
};
