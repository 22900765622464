import React from 'common/react-vendor';
import {
	DNBDrawer,
	DNBDrawerItem,
	DNBSideNavigationDrawerItem,
	DNBTag,
	DNBCheckbox,
	DNBTypography,
} from 'common/dnb-uux-vendor';
import {IAddAttributeModalScope} from '../AddAttributeModalScopeConst';
import {IModalCategory} from '../AddAttributesModalConst';

export const AddAttributesLeftSection = ({
	data,
	status,
	setStatus,
	displayValues,
	setDisplayValues,
	refresh,
	setDisplaySearchResult,
	searchAttributesText,
}: {
	data: IAddAttributeModalScope;
	status: boolean;
	setStatus: (status: boolean) => void;
	displayValues: boolean;
	setDisplayValues: (displayValues: boolean) => void;
	refresh: React.Dispatch<React.SetStateAction<number>>;
	setDisplaySearchResult: (display: boolean) => void;
	searchAttributesText: string;
}): React.ReactElement => {
	const {
		filteredCategories,
		scrollTo,
		filterCategories,
		getColIdsCount,
		activeCategory,
		setSearchText,
		isCategoryCanSelect,
		selectedCategory,
		setSelectedCategory,
		parent: {isCategorySelectEnabled = true},
	} = data;
	const [activeItem, setActiveItem] = React.useState('');
	React.useEffect(() => {
		if (activeCategory?.name) {
			if (Object.entries(activeCategory?.filterColIds).length > 0) {
				const newActiveItem = `${activeCategory?.name}.${activeCategory?.curExpendedSub}`;
				setActiveItem(
					activeCategory?.showSubCategories
						? newActiveItem
						: activeCategory?.name
				);
			}
		}
	}, [
		activeCategory?.name,
		activeCategory?.filterColIds,
		activeCategory?.curExpendedSub,
		activeCategory?.showSubCategories,
	]);
	const hasSubCategories = (category: IModalCategory): boolean => {
		return category.showSubCategories && category.subCategories.length > 0;
	};
	const dataChanges = (): void => {
		if (displayValues) {
			setSearchText('');
			setDisplayValues(false);
			if (searchAttributesText) {
				setDisplaySearchResult(false);
			}
		}
		setStatus(!status);
	};
	const getActiveItem = (category: IModalCategory): string => {
		if (searchAttributesText && !displayValues) {
			if (Object.entries(category?.filterColIds).length > 0) {
				if (
					category.name === activeCategory?.name &&
					category.curExpendedSub === activeCategory?.curExpendedSub
				) {
					return activeItem;
				}
				return category.name;
			}
			return '';
		}
		return activeItem;
	};
	return (
		<div className='addAttributesCategories'>
			<DNBDrawer relative variant='permanent' open={true}>
				{filteredCategories.map((category) => {
					let curCategory = category;
					let {expanded} = curCategory;
					if (activeCategory?.name === category.name) {
						curCategory = activeCategory;
						expanded = activeCategory.expanded;
					} else {
						expanded = false;
					}
					const item: DNBDrawerItem = {
						id: category.name,
						label: (
							<DNBTypography
								component='div'
								variant='compact-body'
								className='categoryLabel'>
								{isCategorySelectEnabled &&
								isCategoryCanSelect({name: category.name} as IModalCategory) ? (
									<DNBCheckbox
										name={category.name}
										size='small'
										id={category.name}
										checked={selectedCategory === category.name}
										onChange={(e) => {
											refresh((key) => -key);
											setSelectedCategory(
												e as unknown as Event,
												{
													name: category.name,
												} as IModalCategory
											);
										}}
										label={
											<DNBTypography variant='compact-body' noWrap>
												{category.name}
											</DNBTypography>
										}
									/>
								) : (
									<span className='text'>{category.name}</span>
								)}
								{!hasSubCategories(category) && (
									<span className='categoryCount'>
										{filterCategories(category.colIds, '') > 0 && (
											<DNBTag
												type='type-2'
												size='small'
												sx={{padding: '2px 8px 2px 8px', mb: 0, mr: 1}}
												label={
													<DNBTypography component='span' variant='caption'>
														{filterCategories(category.colIds, '')}
													</DNBTypography>
												}
											/>
										)}
										<DNBTypography component='span' variant='caption-bold'>
											{getColIdsCount(category.colIds)}
										</DNBTypography>
									</span>
								)}
							</DNBTypography>
						),
						value: category.name,
						expanded,
						subMenu: [],
					};
					if (hasSubCategories(category)) {
						category.subCategories.forEach((subCategory) => {
							const subItem: DNBDrawerItem = {
								id: `${category.name}.${subCategory}`,
								label: (
									<DNBTypography
										component='div'
										variant='compact-body'
										className='categoryLabel'>
										<span className='text'>{subCategory}</span>
										<span className='categoryCount'>
											{filterCategories(category.colIds, subCategory) > 0 && (
												<DNBTag
													type='type-2'
													size='small'
													sx={{padding: '2px 8px 2px 8px', mb: 0, mr: 1}}
													label={
														<DNBTypography component='span' variant='caption'>
															{filterCategories(category.colIds, subCategory)}
														</DNBTypography>
													}
												/>
											)}
											<DNBTypography component='span' variant='caption-bold'>
												{category.colIds?.[subCategory]?.length}
											</DNBTypography>
										</span>
									</DNBTypography>
								),
								value: `${category.name}.${subCategory}`,
							};
							item?.subMenu?.push(subItem);
						});
					}
					return (
						<DNBSideNavigationDrawerItem
							key={category.name}
							item={item}
							onClick={(item) => {
								setDisplaySearchResult(false);
								setActiveItem(item.id);
								if (item.subMenu !== undefined) {
									scrollTo(curCategory, '');
								} else {
									const index = item.id.indexOf('.');
									const id = item.id.slice(0, index);
									const value = item.id.slice(index + 1);
									if (
										activeCategory?.name === id &&
										activeCategory?.curExpendedSub === value
									) {
										return;
									}
									scrollTo(category, value);
								}
								dataChanges();
							}}
							selectedOption={getActiveItem(category)}
						/>
					);
				})}
			</DNBDrawer>
		</div>
	);
};
