import {isNil} from 'lodash';
import type {Bkt, BucketRestriction, Vals} from '../../query.types';
import {
	BucketCmp,
	BucketType,
	Entity,
	Period,
	TreeType,
} from '../../query.enums';
import {cmpMap} from './tree.constants';
import type {
	ChangeCmpValueParams,
	ChangeTimeframePeriodParams,
	ChangeValueParams,
	GetBktValuesReturn,
	GetBucketCmpParams,
	GetPeriodValueParams,
	GetValueParams,
	GetValuesBasedOnPositionReturn,
	GetValuesParams,
	RemoveKeyParams,
	ResetBktValuesParams,
} from './tree.helpers';
import {
	getBooleanValue,
	getLastValueOrNull,
	getNullOrLastValue,
	getValuesBasedOnPosition,
	isSameAttributeAndBucket,
	setValuesBasedOnPosition,
} from './tree.helpers';
import {getCompareReadable} from './percent/percentStore.helpers';

const validSubTypes = ['Time', 'Qty', 'Amt'];
type ValidSubTypes = 'Time' | 'Qty' | 'Amt';

// #region showType
interface ShowPurchaseHistoryTypeParams {
	bucketRestriction: BucketRestriction;
	type: TreeType;
	typeToShow: BucketType;
}

const showPurchaseHistoryType = ({
	bucketRestriction,
	type,
	typeToShow,
}: ShowPurchaseHistoryTypeParams): boolean => {
	if (type === TreeType.TimeSeries) {
		switch (typeToShow) {
			case BucketType.Boolean: {
				const txn = bucketRestriction.bkt?.Txn;

				return txn?.Negate !== undefined;
			}
			case BucketType.Date:
			case BucketType.Numerical:
				return false;
			case BucketType.Transaction:
				return true;
			default:
				return false;
		}
	}

	if (type === TreeType.PercentChange) {
		// TODO: Is this TODO still applicable?? Should we remove it??
		// TODO: change this with backend value
		return typeToShow === BucketType.Percent;
	}

	return typeToShow === BucketType.Numerical;
};
// #endregion showType

// #region getValuesBasedOnPosition
interface GetPurchaseHistoryValuesBasedOnPositionParams {
	cmp?: BucketCmp;
	values?: Vals;
	index: number;
}

const getPurchaseHistoryValuesBasedOnPosition = ({
	cmp,
	values = [],
	index,
}: GetPurchaseHistoryValuesBasedOnPositionParams): GetValuesBasedOnPositionReturn => {
	switch (cmp) {
		case BucketCmp.BETWEEN:
		case BucketCmp.BETWEEN_DATE:
		case BucketCmp.EQUAL:
		case BucketCmp.NOT_EQUAL:
			return values[index];
		case BucketCmp.AFTER:
			return getLastValueOrNull({values, index});
		case BucketCmp.BEFORE:
		case BucketCmp.WITHIN:
		case BucketCmp.PRIOR_ONLY: {
			return getNullOrLastValue({values, index});
		}
	}
};
// #endregion getValuesBasedOnPosition

// #region getOperationLabel
interface GetPurchaseHistoryOperationLabelParams {
	type: TreeType & BucketType;
	bucketRestriction: BucketRestriction;
}

const getPurchaseHistoryOperationLabel = ({
	type,
	bucketRestriction,
}: GetPurchaseHistoryOperationLabelParams): undefined | string => {
	const cmp = bucketRestriction.bkt?.Cmp;

	switch (type) {
		case TreeType.TimeSeries: {
			const txn = bucketRestriction.bkt?.Txn;
			let txnCmp = txn?.Time.Cmp;

			if (txn?.Negate !== undefined) {
				txnCmp = BucketCmp.IS;
			} else if (txn?.Amt) {
				txnCmp = txn.Amt.Cmp;
			}

			if (!txnCmp) {
				return;
			}

			return cmpMap[txnCmp];
		}
		case TreeType.PercentChange:
			return getCompareReadable(bucketRestriction);
		case BucketType.Enum:
			if (!cmp) {
				return;
			}

			return cmpMap[cmp];
	}
};
// #endregion getOperationLabel

// #region getAttributeRules

const sameValues = (bucketValues?: Vals, bktValues?: Vals): boolean => {
	if (bucketValues && bktValues && bucketValues.length === bktValues.length) {
		return bucketValues.every((value, index) => value === bktValues[index]);
	}

	return false;
};

interface GetPurchaseHistoryAttributeRulesParams {
	bkt: Bkt;
	bucket: Bkt;
	isSameAttribute: boolean;
}

const getPurchaseHistoryAttributeRules = ({
	bkt,
	bucket,
	isSameAttribute,
}: GetPurchaseHistoryAttributeRulesParams): boolean => {
	if (bucket && bucket.Txn && bkt && bkt.Txn) {
		const bucketQty = bucket.Txn.Qty;
		const bktQty = bkt.Txn.Qty;

		const bucketAmt = bucket.Txn.Amt;

		if (isNil(bucketQty) && isNil(bucketAmt) && isNil(bktQty)) {
			const bucketLbl = bucket.Lbl;
			const bktLbl = bkt.Lbl;

			const isSameBucket = bucketLbl === bktLbl;

			return isSameAttribute && isSameBucket;
		}
	}

	if (bucket.Chg && bkt.Chg) {
		const bucketDirection = bucket.Chg.Direction;
		const bktDirection = bkt.Chg.Direction;

		const bucketCmp = bucket.Chg.Cmp;
		const bktCmp = bkt.Chg.Cmp;

		const isSameBucket =
			bucketDirection === bktDirection && bucketCmp === bktCmp;

		if (isSameBucket) {
			return isSameAttribute && sameValues(bucket.Chg.Vals, bkt.Chg.Vals);
		}

		return isSameAttribute && isSameBucket;
	}

	if (!isNil(bucket.Vals) && !isNil(bkt.Vals)) {
		const bktValues = bkt.Vals;
		const bucketValues = bucket.Vals;

		const bktCmp = bkt.Cmp;
		const bucketCmp = bucket.Cmp;

		const bktDirection = bkt.Direction;
		const bucketDirection = bucket.Direction;

		return (
			isSameAttributeAndBucket({
				bktValues,
				bucketValues,
				bktCmp,
				bucketCmp,
				isSameAttribute,
			}) && bktDirection === bucketDirection
		);
	}

	return isSameAttribute;
};
// #region getAttributeRules

// #region getBktValues
const getStringValues = (values?: Vals | Bkt): string => {
	if (values) {
		const stringValues = values.toString();
		return stringValues.replace(/,/g, ' - ');
	}

	return '';
};

const getPurchaseHistoryBktValues = (
	bucketRestriction?: BucketRestriction,
	type?: TreeType | BucketType
): GetBktValuesReturn => {
	if (bucketRestriction?.bkt?.Txn) {
		if (type === TreeType.TimeSeries) {
			const value = getBooleanValue(bucketRestriction) as string;
			return [value];
		}

		return [];
	}

	if (bucketRestriction?.bkt?.Chg) {
		if (type === TreeType.PercentChange) {
			const values = bucketRestriction.bkt.Chg.Vals;

			return getStringValues(values);
		}

		return [];
	}

	const values = bucketRestriction?.bkt;

	return getStringValues(values);
};
// #endregion getBktValues

// #region getValue
const getPurchaseHistoryValue = ({
	bucketRestriction,
	type,
	index,
	subType,
}: GetValueParams): GetValuesBasedOnPositionReturn => {
	if (type === TreeType.TimeSeries && bucketRestriction?.bkt?.Txn) {
		const txn = bucketRestriction.bkt.Txn;

		if (subType && validSubTypes.includes(subType)) {
			const txnItem = txn[subType as ValidSubTypes];

			if (txnItem) {
				return getValuesBasedOnPosition({
					entity: Entity.PurchaseHistory,
					cmp: txnItem.Cmp,
					values: txnItem.Vals,
					index,
				});
			}
		}

		return 0;
	}

	if (type === TreeType.PercentChange && bucketRestriction?.bkt?.Chg) {
		return getValuesBasedOnPosition({
			entity: Entity.PurchaseHistory,
			cmp: bucketRestriction.bkt.Chg.Cmp,
			values: bucketRestriction.bkt.Chg.Vals,
			index,
		});
	}

	return getValuesBasedOnPosition({
		entity: Entity.PurchaseHistory,
		cmp: bucketRestriction?.bkt?.Cmp,
		values: bucketRestriction?.bkt?.Vals,
		index,
	});
};
// #endregion getValue

// #region getValues
const getPurchaseHistoryValues = ({
	bucketRestriction,
	type,
	subType,
}: GetValuesParams): Vals => {
	if (type === 'TimeSeries' && bucketRestriction?.bkt?.Txn) {
		const txn = bucketRestriction.bkt.Txn;

		if (subType && validSubTypes.includes(subType!)) {
			const txnItem = txn[subType as ValidSubTypes];

			if (txnItem) {
				return txnItem.Vals || [];
			}
		}

		return [];
	}

	if (type === TreeType.PercentChange && bucketRestriction?.bkt?.Chg) {
		return bucketRestriction.bkt.Chg.Vals || [];
	}

	return bucketRestriction?.bkt?.Vals || [];
};
// #endregion getValues

// #region changeBooleanValue
const changePurchaseHistoryBooleanValue = (
	bucketRestriction: BucketRestriction,
	booleanValue: string
): BucketRestriction => {
	const newBucketRestriction = {...bucketRestriction};

	const txn = newBucketRestriction.bkt?.Txn;

	if (txn !== undefined && newBucketRestriction.bkt) {
		if (booleanValue === 'Yes') {
			txn.Negate = false;

			newBucketRestriction.bkt.Lbl = 'Yes';
		} else if (booleanValue === 'No') {
			txn.Negate = true;

			newBucketRestriction.bkt.Lbl = 'No';
		} else {
			txn.Negate = null;

			newBucketRestriction.bkt.Lbl = 'Undefined';
		}
	}

	return newBucketRestriction;
};
// #endregion changeBooleanValue

// #region changeCmp
const changePurchaseHistoryCmp = ({
	bucketRestriction,
	type,
	value,
	subType,
}: ChangeCmpValueParams): BucketRestriction | undefined => {
	if (isNil(bucketRestriction)) {
		return;
	}

	const newBucketRestriction = {...bucketRestriction};

	if (type === TreeType.TimeSeries) {
		const txn = newBucketRestriction.bkt?.Txn;

		if (txn && subType && validSubTypes.includes(subType)) {
			const validSubType = subType as ValidSubTypes;

			const txnItem = txn[validSubType];

			if (txnItem) {
				txnItem.Cmp = value;
			} else {
				const newBucket = {
					Cmp: value,
					Vals: [],
				};

				if (validSubType === 'Time') {
					txn[validSubType] = {
						...newBucket,
						Period: Period.Month,
					};
				} else {
					txn[validSubType] = newBucket;
				}
			}
		}
	} else if (type === TreeType.PercentChange && newBucketRestriction.bkt?.Chg) {
		newBucketRestriction.bkt.Chg.Cmp = value;
	} else if (newBucketRestriction.bkt) {
		newBucketRestriction.bkt.Cmp = value;
	}

	return newBucketRestriction;
};
// #endregion changeCmp

// #region changeValue
const changePurchaseHistoryValue = ({
	bucketRestriction,
	type,
	value,
	index,
	subType,
}: ChangeValueParams): void => {
	const entity = Entity.PurchaseHistory;

	if (type === TreeType.TimeSeries) {
		const txn = bucketRestriction?.bkt?.Txn;

		if (txn && subType && validSubTypes.includes(subType)) {
			const validSubType = subType as ValidSubTypes;

			const txnItem = txn[validSubType];

			if (txnItem) {
				setValuesBasedOnPosition({
					entity,
					cmp: txnItem.Cmp,
					values: txnItem.Vals,
					index,
					value,
				});
			}
		}
	} else if (type === TreeType.PercentChange && bucketRestriction?.bkt?.Chg) {
		setValuesBasedOnPosition({
			entity,
			cmp: bucketRestriction?.bkt.Chg.Cmp,
			values: bucketRestriction?.bkt.Chg.Vals,
			index,
			value,
		});
	} else {
		setValuesBasedOnPosition({
			entity,
			cmp: bucketRestriction?.bkt?.Cmp,
			values: bucketRestriction?.bkt?.Vals,
			index,
			value,
		});
	}
};
// #endregion changeValue

// #region changeTimeframePeriod
const changePurchaseHistoryTimeframePeriod = ({
	bucketRestriction,
	type,
	value,
}: ChangeTimeframePeriodParams): BucketRestriction | undefined => {
	if (isNil(bucketRestriction)) {
		return;
	}

	const newBucketRestriction = {...bucketRestriction};

	if (type === TreeType.TimeSeries) {
		const txn = newBucketRestriction.bkt?.Txn;

		if (txn) {
			const tsTime = txn.Time;

			if (tsTime && typeof value === 'string') {
				tsTime.Period = value;
			}
		}
	}

	return newBucketRestriction;
};
// #endregion changeTimeframePeriod

// #region resetBktValues
const resetPurchaseHistoryBktValues = ({
	bucketRestriction,
	type,
	subType,
}: ResetBktValuesParams): BucketRestriction | undefined => {
	if (isNil(bucketRestriction)) {
		return;
	}

	const newBucketRestriction = {...bucketRestriction};

	if (type === TreeType.TimeSeries) {
		const txn = newBucketRestriction.bkt?.Txn;

		if (txn && subType && validSubTypes.includes(subType)) {
			const validSubType = subType as ValidSubTypes;

			const txnItem = txn[validSubType];

			if (txnItem) {
				txnItem.Vals = [];
			}
		}
	} else if (type === 'PercentChange' && newBucketRestriction.bkt?.Chg) {
		newBucketRestriction.bkt.Chg.Vals = [];
	} else if (newBucketRestriction.bkt) {
		newBucketRestriction.bkt.Vals = [];
	}

	return newBucketRestriction;
};
// #endregion resetBktValues

// #region removeKey
const removePurchaseHistoryKey = ({
	bucketRestriction,
	type,
	subType,
}: RemoveKeyParams): BucketRestriction | undefined => {
	if (isNil(bucketRestriction)) {
		return;
	}

	const newBucketRestriction = {...bucketRestriction};

	if (type === TreeType.TimeSeries) {
		const txn = newBucketRestriction?.bkt?.Txn;

		if (txn) {
			delete txn[subType];
		}
	}

	return newBucketRestriction;
};
// #endregion removeKey

// #region getBooleanModel
const getPurchaseHistoryBooleanModel = (
	bucketRestriction: BucketRestriction
): string => {
	const txn = bucketRestriction.bkt?.Txn;

	if (txn?.Negate === true) {
		return 'No';
	}

	if (txn?.Negate === false) {
		return 'Yes';
	}

	return '';
};
// #endregion getBooleanModel

// #region getBucketCmp
/**
 * We need to return an empty string
 * when there is no value.
 * This is because the previous implementations
 * compares the return value against empty string
 * instead of making a falsy comparison...
 */
const getPurchaseHistoryBucketCmp = ({
	bucketRestriction,
	type,
	subType,
}: GetBucketCmpParams): BucketCmp | '' => {
	const defaultCmp = bucketRestriction?.bkt?.Cmp;

	switch (type) {
		case TreeType.TimeSeries: {
			const txn = bucketRestriction?.bkt?.Txn;

			if (typeof txn === 'undefined') {
				console.warn('TimeSeries does not have Txn object');

				return defaultCmp || '';
			}

			if (subType && validSubTypes.includes(subType)) {
				const validSubType = subType as ValidSubTypes;

				const txnItem = txn[validSubType];

				if (txnItem) {
					return txnItem.Cmp || '';
				}
				return '';
			}

			return defaultCmp || '';
		}
		case TreeType.PercentChange: {
			return bucketRestriction?.bkt?.Chg?.Cmp || defaultCmp || '';
		}
		default:
			return defaultCmp || '';
	}
};
// #endregion getBucketCmp

// #region getPeriodValue
const getPurchaseHistoryPeriodValue = ({
	bucketRestriction,
	type,
	subType,
}: GetPeriodValueParams): Period | string => {
	if (type === TreeType.TimeSeries && subType === 'Time') {
		const txn = bucketRestriction?.bkt?.Txn;

		return txn?.Time.Period || '';
	}

	return '';
};
// #endregion getPeriodValue

export {
	showPurchaseHistoryType,
	getPurchaseHistoryValuesBasedOnPosition,
	getPurchaseHistoryOperationLabel,
	getPurchaseHistoryAttributeRules,
	getPurchaseHistoryBktValues,
	getPurchaseHistoryValue,
	getPurchaseHistoryValues,
	changePurchaseHistoryBooleanValue,
	changePurchaseHistoryCmp,
	changePurchaseHistoryValue,
	changePurchaseHistoryTimeframePeriod,
	resetPurchaseHistoryBktValues,
	removePurchaseHistoryKey,
	getPurchaseHistoryBooleanModel,
	getPurchaseHistoryBucketCmp,
	getPurchaseHistoryPeriodValue,
};
