import _ from 'lodash';
import {
	Bkt,
	Restriction,
	Restrictions,
} from 'common/components/datacloud/query/query.types';
import {BucketCmp} from 'common/components/datacloud/query/query.enums';
import {EntityType} from 'atlas/data/RestrictionConst';
import {
	CounterMap,
	IAttributeValues,
} from 'atlas/modelsOld/modals/AddAttributeModal/Helper/CounterMap';
import {IModalAttributeMap} from '../AddAttributesModalConst';
import {
	getAttributeIdKeyByRestriction,
	getRestrictionByAttributeIdKey,
} from '../AddAttributesModalWrapper';
import {
	getSelectedCountText,
	IModalController,
	searchPlaceHolder,
} from './ModalControllerType';

/**
 * Attribute modal controller
 * Used for account / contact and time series attributes
 */
class AttributeModalController implements IModalController {
	private modalAttributeMap: IModalAttributeMap;

	private restrictions: Restrictions;

	private entityType: EntityType;

	private isActivity: boolean;

	selectedCounterMap: CounterMap;

	constructor(
		modalAttributeMap: IModalAttributeMap,
		restrictions: Restrictions,
		entityType: EntityType,
		isActivity: boolean
	) {
		this.modalAttributeMap = modalAttributeMap;
		this.restrictions = restrictions;
		this.entityType = entityType;
		this.isActivity = isActivity;
		this.selectedCounterMap = new CounterMap(this.getSelectedAttributes());
	}

	name = (): string => {
		return 'attribute';
	};

	getTitle = (): string => {
		const entityName = this.isActivity ? 'Activity' : `${this.entityType}`;
		return `Add ${entityName} Attributes`;
	};

	searchPlaceHolder = (): string => {
		return searchPlaceHolder(this.name());
	};

	getCounterMap = (): CounterMap => this.selectedCounterMap;

	getSelectedAttributes = (): IAttributeValues[] => {
		const {restrictions} = this;
		return restrictions
			.map((restriction) => restriction)
			.filter(({bucketRestriction}) => !!bucketRestriction?.attr)
			.map(({bucketRestriction}) => {
				return {
					attrId: getAttributeIdKeyByRestriction(bucketRestriction!),
					bkt: bucketRestriction?.bkt
						? _.cloneDeep(bucketRestriction?.bkt)
						: undefined,
				};
			});
	};

	getSelectedCountText = (): string => {
		const selectCount = this.selectedCounterMap.totalCount();
		return getSelectedCountText(this.name(), selectCount);
	};

	isAttributeDisabled = (): boolean => {
		return false;
	};

	toAttributeKey = (restriction: Restriction): string => {
		const {bucketRestriction} = restriction;
		return bucketRestriction
			? getAttributeIdKeyByRestriction(bucketRestriction)
			: '';
	};

	toRestriction = (
		id: string,
		inRulesBasedModal: boolean,
		bkt?: Bkt
	): Restriction => {
		const {modalAttributeMap} = this;
		const modalAttribute = modalAttributeMap[id]!;
		const {topbkt} = modalAttribute;
		const {attr, entityType} = getRestrictionByAttributeIdKey(id);
		let newBkt = bkt || topbkt;
		if (bkt?.Cmp === BucketCmp.EQUAL) {
			newBkt = {
				...bkt,
				Cmp: BucketCmp.IN_COLLECTION,
			};
		}
		return {
			bucketRestriction: {
				attr,
				ignored: inRulesBasedModal,
				bkt: {..._.cloneDeep(newBkt), Cnt: -1}, // set count = -1, will refresh after save
				entityType,
			},
		};
	};

	needUpdateRestrictionCount = (): boolean => {
		return !this.isActivity;
	};
}

export {AttributeModalController};
