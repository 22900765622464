import React from 'common/react-vendor';
import {
	IChips,
	IChipsComponent,
	IChip,
	TreeContext,
	DelimiterType,
} from './ChipsTypes';
import {addCustomValuesWithDelimiterCheck} from './ChipsController';
import {ChipsInit} from './ChipsInit';

const {createContext} = React;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const DefaultDataUpdate = (): void => {};

interface IChipsContext {
	iconClass: string;
	chips: IChips;
	query: string;
	ID: keyof IChip;
	header?: string[];
	showHeader: boolean;
	sortReverse?: boolean;
	displayName: keyof IChip;
	sortId?: keyof IChip;
	queryScope: keyof IChip;
	datasource: IChip[];
	showIcon: boolean;
	inputDelimiter: string;
	customVals: boolean;
	enableremoverow?: boolean;
	chipsposition?: string;
	toBeAddedItems: Record<string, IChips>;
	previousItems: Record<string, IChips>;
	usePagination: boolean;
	showAll: boolean;
	lastPastedValue: string;
	disabled?: boolean;
	singleSelection: boolean;
	showloading?: boolean;
	stringPlaceholder: string;
	dontrandomizeid: boolean;
	treeContext: TreeContext;
	bulkEntryDelimiterType: DelimiterType;
	CallBack: (chips?: IChips) => void;
}

const DefaultChipsContext: IChipsContext = {
	iconClass: 'search',
	chips: {},
	query: '',
	ID: 'Lbl',
	header: ['VALUE'],
	showHeader: false,
	sortReverse: false,
	displayName: 'Lbl',
	sortId: 'Lbl',
	queryScope: 'Lbl',
	datasource: [],
	showIcon: false,
	inputDelimiter: '',
	customVals: true,
	enableremoverow: false,
	chipsposition: 'top',
	toBeAddedItems: {},
	previousItems: {},
	usePagination: false,
	showAll: true,
	lastPastedValue: '',
	disabled: false,
	singleSelection: false,
	showloading: false,
	stringPlaceholder: 'Search for attributes',
	dontrandomizeid: false,
	treeContext: {
		isActivity: false,
		enableQueryBuilderRedesign: false,
		tree: {
			bucketRestriction: {
				attr: '',
				ignored: false,
			},
		},
	},
	bulkEntryDelimiterType: DelimiterType.default,
	CallBack: DefaultDataUpdate,
};

interface IChipsContextUpdate {
	setChips: (chips: IChips) => void;
	setQuery: (query: string) => void;
	setToBeAddedItems: (item: IChips) => void;
	setPreviousItems: (item: IChips) => void;
	setLastPastedValue: (val: string) => void;
	setShowAll: (showAll: boolean) => void;
	setBulkEntryDelimiterType: (type: DelimiterType) => void;
}
const DefaultChipsContextUpdate: IChipsContextUpdate = {
	setChips: DefaultDataUpdate,
	setQuery: DefaultDataUpdate,
	setToBeAddedItems: DefaultDataUpdate,
	setPreviousItems: DefaultDataUpdate,
	setLastPastedValue: DefaultDataUpdate,
	setShowAll: DefaultDataUpdate,
	setBulkEntryDelimiterType: DefaultDataUpdate,
};

const ChipsContext = createContext(DefaultChipsContext);
const ChipsContextUpdate = createContext(DefaultChipsContextUpdate);

interface IChipsContextProvider {
	children: React.ReactNode;
	scope: IChipsComponent;
}

const {useState} = React;
function ChipsContextProvider({
	children,
	scope,
}: IChipsContextProvider): React.ReactElement {
	const {
		id,
		displayname,
		header,
		sortreverse,
		sortid,
		queryscope,
		datasource,
		showicon,
		inputdelimiter,
		allowcustomvalues,
		enableremoverow,
		chipsposition,
		usepagination,
		disabled,
		singleSelection,
		context: vm,
		showloading,
		icon,
		dontrandomizeid,
		placeholder,
		callback,
		updatedContext,
	} = scope;

	const {initChips} = React.useMemo(() => ChipsInit(scope), [scope]);

	const [chips, setChips] = useState(initChips);
	const [query, setQuery] = useState('');
	const [toBeAddedItems, setToBeAddedItems] = useState({});
	const [previousItems, setPreviousItems] = useState({});
	const [lastPastedValue, setLastPastedValue] = useState('');
	const [showAll, setShowAll] = useState(!usepagination);
	const [bulkEntryDelimiterType, setBulkEntryDelimiterType] = useState(
		DelimiterType.default
	);

	const CallBack = (newChips?: IChips): void => {
		if (typeof callback != undefined) {
			callback(Object.values(newChips || chips));
		}
	};

	const context = {
		iconClass: icon,
		chips,
		query,
		ID: id || displayname,
		header,
		showHeader: !!header?.length,
		sortReverse: sortreverse,
		displayName: displayname || id,
		sortId: sortid || id,
		queryScope: queryscope || displayname,
		datasource: datasource.filter((item) => item?.Lbl !== '*'),
		showIcon: showicon,
		inputDelimiter: inputdelimiter,
		customVals: allowcustomvalues,
		enableremoverow,
		chipsposition,
		toBeAddedItems, // items pasted in bulk entry modal, pending user confirmation
		previousItems, // items of previously
		usePagination: !!usepagination, // display show more/show less toggle if many chips visible
		showAll, // if pagination is disabled, show all by default. otherwise start hidden.
		lastPastedValue,
		disabled,
		singleSelection,
		showloading,
		stringPlaceholder: placeholder,
		dontrandomizeid,
		treeContext: vm,
		bulkEntryDelimiterType,
		CallBack,
	};

	const setContext = {
		setChips,
		setQuery,
		setToBeAddedItems,
		setPreviousItems,
		setLastPastedValue,
		setShowAll,
		setBulkEntryDelimiterType,
	};
	if (vm) {
		vm.ChipsController = {
			query,
			customVals: allowcustomvalues,
			addCustomValuesWithDelimiterCheck: (query: string) => {
				addCustomValuesWithDelimiterCheck(context, setContext, query, true);
			},
		};
		updatedContext && updatedContext('ChipsController', vm.ChipsController);
	}
	return (
		<ChipsContext.Provider value={context}>
			<ChipsContextUpdate.Provider value={setContext}>
				{children}
			</ChipsContextUpdate.Provider>
		</ChipsContext.Provider>
	);
}

export {ChipsContext, ChipsContextProvider, ChipsContextUpdate};
export type {IChipsContext, IChipsContextUpdate};
